import React, { useState, useEffect } from 'react';
import { createStyles, IconButton, makeStyles, Theme } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      bottom: 40,
      right: 10,
      float: 'right',
      position: 'sticky',
      display: 'flex',
    },
    hideContainer: {
      display: 'none',
    },
  }),
);

const ScrollToTopButton = () => {
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    window.addEventListener('scroll', checkScrollPosition);
  }, []);

  const checkScrollPosition = () => {
    if (window.pageYOffset > 500) {
      setHidden(true);
    } else {
      setHidden(false);
    }
  };

  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` });
  };

  const classes = useStyles();
  return (
    <IconButton
      onClick={handleClick}
      className={clsx(hidden ? classes.container : classes.hideContainer)}
      data-cy="buttonTop"
      aria-label="scroll to top button"
    >
      <ExpandLessIcon />
    </IconButton>
  );
};
export default ScrollToTopButton;
