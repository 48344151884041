import { Box, Container } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useUserContext } from '../../UserContext';
import { fetchAccountData } from '../../store/accountSlice';
import { fetchEventsData, setPopupTextPrivatepage } from '../../store/eventSlice';
import EventList from '../../components/eventsList';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CookieMessage from '../../components/cookiemessage';
import ClickWrap from '../../components/clickwrap';
import SessiontTmeout  from '../../components/sessiontimeout';
import { useCookieContext } from '../../CookieContext';
import LinearProgress from '@material-ui/core/LinearProgress';
import ErrorAlert from '../../components/erroralert';
import { useLocation } from 'react-router';	
import { useNavigate } from 'react-router-dom';
import { PRODUCTION_HOST_NAME_LIST } from '../../constants/constants';
import { checkUserPermission } from '../../core/authorization/service/authorizationService';
import { useTypedSelector } from '../../store';
import { getState, getEvents } from '../../selectors/selectors';
import { SuccessAlert } from '../../components/successalert';
import { setPopupText, setConfirmationBoxDisplayStatus } from '../../store/eventSlice';
import ConfirmationBox from '../../components/confirmationbox';
import { post, put } from '../../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accountPage: {
      minHeight: '100vh',
      paddingBottom: theme.spacing(10),
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
    loader:{
      margin:'10px 0px',
      height:30
    },
    pageTitle: {
      color: 'white',
      letterSpacing: '0.0625rem',
      fontWeight: 300,
      marginTop: theme.spacing(5),
      borderBottom:'1px solid',
      paddingBottom:20,
      [theme.breakpoints.down('md')]: {
        paddingBottom:10,
      },
    },
    container: {
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    }
  }));

const PrivateEventsPage: React.FC<{}> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { popUpValues, confirmationboxDisplayStatus, currentsubScriptionsData } = useTypedSelector(state => getEvents(state));
  const { popUpText } = popUpValues;
  const cookieDetails = useCookieContext();
  const location = useLocation();
  const querySearched = location.search || '';
  const currentHostName = window.location.hostname; 
  const params = new URLSearchParams(querySearched);
  const sponsorCode = params.get('sponsorCode') ||'';
  const { userDetails } = useTypedSelector(state => getState(state));
  const [showAltert, setShowAltert] = useState(false);
  const [loading, setLoading] =useState(false);
  const hasPermissionViewEvents = checkUserPermission(userDetails,'viewEvents'); 
  const navigate = useNavigate();
  let message = '';
  if(currentsubScriptionsData.action === "update"){
    message = 'Update action will Start sending notifications to the newly provided URL, are you sure to proceed?';
  }else{
    message = currentsubScriptionsData.status === 'subscribed'?'Unsubscribe action will Stop sending notifications to the provided URL, are you sure to proceed?':'Subscribe action will Start sending notifications to the provided URL, are you sure to proceed?'
  }
 
	//@ts-ignore 
	const { showCookieMessage } = cookieDetails;
  const {
    isAuthenticated,
    showClickWrap,
    exceptionClickWrap,
    acceptClickWrapdetails,
    showSessionTimeoutModel
  } = useUserContext();

  const handleClickwrapClose = () => {
    acceptClickWrapdetails();
  };
  const fetchData = useCallback(() => {
    const eventRequestParamters = {
      page:1,
      pageSize:500
    };
    if(isAuthenticated === true){
      if (PRODUCTION_HOST_NAME_LIST.indexOf (currentHostName) >-1) {
        //if production urls
        dispatch(fetchEventsData({...eventRequestParamters,sponsorCode:''}));
      }else{
        dispatch(fetchEventsData({...eventRequestParamters,sponsorCode:sponsorCode}));
      }
     
    }
    if (isAuthenticated === true && userDetails.roles === undefined) {
      
      if (PRODUCTION_HOST_NAME_LIST.indexOf (currentHostName) >-1) {
        //if production urls
        dispatch(fetchAccountData(''));
      }else{
        if(querySearched && querySearched.includes("sponsorCode")){
          dispatch(fetchAccountData(querySearched));
        }else{
          dispatch(fetchAccountData(''));
        }
      }
    }
  }, [dispatch, isAuthenticated,querySearched,currentHostName,userDetails,sponsorCode]);

  useEffect(() => {
    fetchData();
    return () => {
      dispatch(setPopupTextPrivatepage(''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated,dispatch]);

  useEffect(() => {
    if(popUpText.length > 0 )
     setShowAltert(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popUpText]);

  useEffect(() => {
    if(isAuthenticated === true && userDetails.roles && userDetails.roles.length>0){
      if(hasPermissionViewEvents !== true){
        navigate('/account');
      }
    }  
   
  }, [hasPermissionViewEvents,navigate,isAuthenticated,userDetails]);
  const  onClickYes = async()=> {
    dispatch(setConfirmationBoxDisplayStatus(false));
    setLoading(true);
    const subscriptionId = currentsubScriptionsData.subscriptionId;
    let subsribeUrl =   '';
    const eventRequestParamters = {
      page:1,
      pageSize:500
    };
    let response: any = {};
    const {action, displayEventName ,...filteredSubscriptionData } = currentsubScriptionsData;
    if(currentsubScriptionsData.action === "update"){
      if(currentsubScriptionsData.subscriptionId !== '' || currentsubScriptionsData.subscriptionId !== undefined){
        
        subsribeUrl = "/bff-private/event-subscriptions/"+subscriptionId; 
        response = await put(subsribeUrl, {
          ...filteredSubscriptionData,
          status:'subscribed'
      });
      }
    }else{
      const baseUrl = "/bff-private/event-subscriptions";
      if(currentsubScriptionsData.status === "subscribed"){
       if(currentsubScriptionsData.subscriptionId !== '' || currentsubScriptionsData.subscriptionId !== undefined){
          subsribeUrl = baseUrl + "/"+ subscriptionId; 
          response = await put(subsribeUrl, {
            ...filteredSubscriptionData,
            status:'unsubscribed'
          });
        }else{
          subsribeUrl = baseUrl;
          response = await post(subsribeUrl, {
            ...filteredSubscriptionData,
            status:'subscribed'
          });
        }
       }else{
        if(!!currentsubScriptionsData?.subscriptionId){
          subsribeUrl = baseUrl + "/"+ subscriptionId; 
          response = await put(subsribeUrl, {
            ...filteredSubscriptionData,
            status:'subscribed'
          });
        }else{
          subsribeUrl = baseUrl;
          response = await post(subsribeUrl, {
            ...filteredSubscriptionData,
            status:'subscribed'
          });
        } 
       }
    }
    if (response.error !== undefined) {
      dispatch(setPopupText(response.error.message));
      setLoading(false);
    } else {
      dispatch(setPopupText(response.message));
      setLoading(false);
      dispatch(fetchEventsData({...eventRequestParamters,sponsorCode:''}));
    }
    setShowAltert(true);
  };
  const onClickNo = ()=> {
    dispatch(setConfirmationBoxDisplayStatus(false));
  };
  const onClickClose =()=> {
    setShowAltert(false);
  };
  const renderMerics = ()=>{
    return (
      <React.Fragment>
          <Box data-testid='Account Page' className={classes.accountPage}>
          <Container maxWidth='md' className={classes.container}>
            <Typography variant="h4" className={classes.pageTitle}>
             My Events
            </Typography>
            {loading && (<div className={classes.loader}> <LinearProgress /></div>)}
            <EventList/>
          </Container>
        </Box>
        <CookieMessage state={showCookieMessage} ></CookieMessage>
          <ClickWrap state={showClickWrap} handleClose={handleClickwrapClose}></ClickWrap>
          <SessiontTmeout state={showSessionTimeoutModel}></SessiontTmeout>
          {exceptionClickWrap && <ErrorAlert></ErrorAlert>}
          <ConfirmationBox
            state= {confirmationboxDisplayStatus}
            title ={'Confirmation'}
            message={message}
            buttontext1={'Yes'}
            buttontext2={'No'}
            actionButton1={onClickYes}
            actionButton2={onClickNo}
            onClose={onClickNo}
          ></ConfirmationBox>
          {showAltert &&(<SuccessAlert message={popUpText} state={showAltert} alertType={'success'} onClose={onClickClose}></SuccessAlert>)}
      </React.Fragment>
    );
  }
  return renderMerics(); 
};

export default PrivateEventsPage;
