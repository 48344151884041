import React, { FC, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AllInclusive from '@material-ui/icons/AllInclusive';
import HdrWeakIcon from '@material-ui/icons/HdrWeak';
import FlareIcon from '@material-ui/icons/Flare';
import { useNavigate } from 'react-router';
import { BaseLayout } from '../../components/layout';
import JourneyCard from '../../components/cards/journey';
import Languages from '../../components/languages';
import Products from '../../components/products';
import Dots from '../../components/dots';
import { Box, ButtonBase } from '@material-ui/core';
import { useUserContext } from '../../UserContext';
import Award from '../../assets/WTGAFTA21-LOGO-NorthernTrust-DataMgmgt.jpg';
import { useCookieContext } from '../../CookieContext';
import CookieMessage from '../../components/cookiemessage';
import ClickWrap from '../../components/clickwrap';
import LinearProgress from '@material-ui/core/LinearProgress';
import ErrorAlert from '../../components/erroralert';
import SessiontTmeout from '../../components/sessiontimeout';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    landingTop: {
      display: 'flex',
      marginTop:120,
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 509,
      position: 'relative',
      padding: '0 1.5rem',
    },
    greetingBox: {
      // height: '37rem',
      // width: '40%',
      // padding: '1.5rem',
    },
    greetingTxt: {
      color: 'white',
      letterSpacing: '0.0625rem',
      fontWeight: 100,
    },
    greetingSubTxt: {
      color: 'white',
      marginTop: '1.5rem',
    },
    anchorBox: {
      // width: '40%',
      position: 'absolute',
      top: -theme.spacing(4),
      marginLeft: theme.spacing(2),
    },
    anchorImage: {
      width: '100%',
      height: '571px',
      objectFit: 'contain',
      objectPosition: 'center',
    },
    topCTAs: {
      marginTop: theme.spacing(5),
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
      },
    },
    mission: {
      marginLeft: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
        marginTop: theme.spacing(2),
      },
    },
    products: {
      minHeight: '42.9375rem',
      // background: '#100428',
      paddingTop: theme.spacing(9),
      paddingBottom: theme.spacing(9),
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },
      // padding: '0 1.5rem',
    },
    sectionTitle: {
      color: 'white',
      fontWeight: 100,
      letterSpacing: '0.0425rem',
    },
    sectionSubTitle: {
      color: 'white',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      letterSpacing: '0.0425rem',
    },
    exploreBtn: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      [theme.breakpoints.down('xs')]: {
        alignItems: 'center',
        marginTop: theme.spacing(4),
      },
    },
    productlist: {
      marginTop: theme.spacing(3),
      padding: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(4),
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    journeys: {
      minHeight: '42.9375rem',
      // background: '#100428',
      paddingTop: theme.spacing(9),
      paddingBottom: theme.spacing(9),
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },
    },
    journeylist: {
      padding: theme.spacing(8),
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(4),
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    ways: {
      minHeight: '32rem',
      paddingTop: theme.spacing(9),
      paddingBottom: theme.spacing(9),
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },
    },

    learnBtn: {
      marginTop: theme.spacing(5),
    },
    langNComplainceBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      height: '100%',
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(4),
        // paddingRight: theme.spacing(4),
      },
    },
    proLanguages: {
      display: 'flex',
      justifyContent: 'space-around',
    },
    complaincesHolder: {
      display: 'flex',
      justifyContent: 'space-around',
      marginTop: theme.spacing(4),
    },
    icon: {
      fontSize: 60,
    },
    awards: {
      minHeight: '32rem',
      paddingTop: theme.spacing(9),
      paddingBottom: theme.spacing(9),
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },
    },
    awardicon: {
      width: '100%',
      objectFit: 'contain',
      objectPosition: 'center',
    },

    awardcard: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(4),
      backgroundColor: 'white',
      mixBlendMode: 'normal',
      borderRadius: '10px',
      height: '100%',
      position: 'relative',
    },
  }),
);
const LandingPage: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const userdetails = useUserContext();
  // @ts-ignore
  const {authUser,showClickWrap,acceptClickWrapdetails,loadingClickWrap,exceptionClickWrap,showSessionTimeoutModel,} = userdetails;
  const cookieDetails = useCookieContext();
  //@ts-ignore
  const { showCookieMessage } = cookieDetails;
  const handleClickwrapClose = () => {
    acceptClickWrapdetails();
  };

  const deeplink = async () => {
    try {
      const apo_redirect_url = localStorage.getItem('apo_redirect_url') || '';
      
      if (apo_redirect_url !== null && apo_redirect_url !== '' && apo_redirect_url !== undefined && authUser) {
        navigate(`${apo_redirect_url}`);
        try {
          localStorage.setItem('apo_redirect_url', '');
        } catch (e) {}
      }
    } catch (ex) {}
  };
  //@ts-ignore
  useEffect(() => {
    deeplink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);
  return (
    <React.Fragment>
      {loadingClickWrap && <LinearProgress />}
      <BaseLayout>
        <div className={classes.landingTop}>
          <Container maxWidth="lg">
            <Grid container>
              <Grid item sm={10} md={8} lg={6}>
                <Typography variant="h3" className={classes.greetingTxt}>
                  Transform your business with Northern Trust APIs
                </Typography>
                <Typography variant="body1" className={classes.greetingSubTxt}>
                  The Northern Trust API Store puts the power of your data in your hands to open up
                  a whole new world of opportunity.
                </Typography>
                <div className={classes.topCTAs}>
                  <Button
                    id="getStartedButton"
                    variant="contained"
                    color="secondary"
                    aria-label="Get Started"
                    onClick={() => navigate('/get-started')}
                  >
                    GET STARTED
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
        <Dots />
        <div className={classes.products}>
          <Container>
            <Grid container>
              <Grid item sm={8}>
                <Typography variant="h4" className={classes.sectionTitle}>
                  Explore Our APIs
                </Typography>
              </Grid>
              <Grid item sm={4} className={classes.exploreBtn}>
                <Button
                  id="allProductsButton"
                  variant="contained"
                  color="secondary"
                  aria-label="all products"
                  onClick={() => navigate('/apis')}
                >
                  VIEW ALL
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              justify="flex-start"
              alignContent="flex-start"
              className={classes.productlist}
              spacing={4}
            >
              <Products />
            </Grid>
          </Container>
        </div>
        <Dots />
        <div className={classes.journeys}>
          <Container>
            <Grid container>
              <Grid item sm={8}>
                <Typography variant="h4" className={classes.sectionTitle}>
                  Your Journey with Us
                </Typography>
              </Grid>
              <Grid item sm={4} className={classes.exploreBtn}>
                <Button
                  id="journeyButton"
                  variant="contained"
                  color="secondary"
                  aria-label="Get Started"
                  onClick={() => navigate('/get-started')}
                >
                  GET STARTED
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="center"
              className={classes.journeylist}
              spacing={4}
            >
              <Grid item xs={12} sm={6} md={5} lg={4}>
                <JourneyCard
                  title="EXPLORE"
                  description="Browse our APIs and learn about their features."
                  icon={<FlareIcon className={classes.icon} />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={5} lg={4}>
                <JourneyCard
                  title="SET UP"
                  description="Determine which access level suits your needs and request access."
                  icon={<HdrWeakIcon className={classes.icon} />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={5} lg={4}>
                <JourneyCard
                  title="CREATE"
                  description="Review the technical specs and build your API connections."
                  icon={<AllInclusive className={classes.icon} />}
                />
              </Grid>
            </Grid>
          </Container>
        </div>
        <Dots />
        <div className={classes.ways}>
          <Container>
            <Grid container>
              <Grid item xs={12} sm={8} md={6}>
                <Typography variant="h4" className={classes.sectionTitle}>
                  Working the Way You Work
                </Typography>
                <Typography variant="body2" className={classes.sectionSubTitle}>
                  Northern Trust APIs are compliant with Open Banking and European API standards to
                  give you confidence that our APIs meet common standards.
                </Typography>
                <Typography variant="body2" className={classes.sectionSubTitle}>
                  We support all common developer languages.
                </Typography>
                <Typography variant="body2" className={classes.sectionSubTitle}>
                  Start exploring our APIs and specifications to learn more.
                </Typography>
                <Button
                  id="learnMoreButton"
                  variant="contained"
                  color="primary"
                  className={classes.learnBtn}
                  aria-label="learn more about our products"
                  onClick={() => navigate('/apis')}
                >
                  LEARN MORE
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.langNComplainceBox}>
                  <div className={classes.proLanguages}>
                    <Languages />
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
        <Box className={classes.awards}>
          <Container>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="h4" className={classes.sectionTitle}>
                  Best Data Management Initiative
                </Typography>
                <Typography variant="body2" className={classes.sectionSubTitle}>
                  Northern Trust was recognized as the 2021 AFTA winner in the 'The Best Data
                  Management Initiative' category for the API Store.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <ButtonBase
                  focusRipple
                  key={'award'}
                  target="_blank"
                  className={classes.awardcard}
                  href="https://www.northerntrust.com/united-states/insights-research/2022/cis/best-data-management-initiative-api-store"
                >
                  <img
                    src={Award}
                    className={classes.awardicon}
                    alt="The Best Data Management Initiative"
                  />
                </ButtonBase>
              </Grid>
            </Grid>
          </Container>
          {exceptionClickWrap && <ErrorAlert></ErrorAlert>}
        </Box>
        <CookieMessage state={showCookieMessage}></CookieMessage>
        <ClickWrap state={showClickWrap} handleClose={handleClickwrapClose}></ClickWrap>
        <SessiontTmeout state={showSessionTimeoutModel}></SessiontTmeout>
      </BaseLayout>
    </React.Fragment>
  );
};

export default LandingPage;
