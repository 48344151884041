import { makeStyles, createStyles, Theme, Box, useMediaQuery } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';
import { AccordionTableProvider } from './context';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionTableWrapper: {
      width: '100%',
      marginBottom: 20
    },
    accordionTable: {
      width: '100%',
      borderCollapse: 'collapse',
      '& thead tr': {
        borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
        '& th': {
          paddingTop: 15,
          paddingBottom: 15,
          paddingLeft: 20,
          paddingRight: 20,
          textAlign: 'left'
        }
      }
    },
    accordionMobileTable: {
      width: '100%',
      borderCollapse: 'collapse',
      '& thead tr': {
        borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
        '& th': {
          paddingTop: 15,
          paddingBottom: 15,
          paddingLeft: 8,
          paddingRight: 8,
          textAlign: 'left'
        }
      }
    }
  }));

export interface IAccordionTableProps {
  numColumns: number;
  renderHeadings: () => JSX.Element;
}

export const AccordionTable: React.FC<IAccordionTableProps> =
  (props: PropsWithChildren<IAccordionTableProps>) => {
    const classes = useStyles();
    const isSmallScreen = useMediaQuery((theme:any) => theme.breakpoints.down("sm"));
    return (
      <Box className={classes.accordionTableWrapper}>
        <AccordionTableProvider numColumns={props.numColumns}>
          <table className={isSmallScreen?classes.accordionMobileTable:classes.accordionTable}>
            <thead>
              <tr>
                {props.renderHeadings()}
              </tr>
            </thead>
            <tbody>
              {props.children}
            </tbody>
          </table>
        </AccordionTableProvider>
      </Box>
    );
  };
