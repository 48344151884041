import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

let theme = createMuiTheme({
  typography: {
    fontFamily: 'Roboto',
  },
  palette: {
    type: 'dark',
    primary: {
      main: '#05a8cc',
    },
    secondary: {
      main: '#00C4B4',
    },
  },
  direction: 'ltr',
});
theme = responsiveFontSizes(theme);

export default theme;
