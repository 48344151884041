import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { TermsLayout } from '../../components/layout';
import Dots from '../../components/dots';
import CookieMessage from '../../components/cookiemessage';
import { useCookieContext } from '../../CookieContext';
import { useUserContext } from '../../UserContext';
import TermsContent from './termscontent';
import ClickWrap from '../../components/clickwrap';
import LinearProgress from '@material-ui/core/LinearProgress';
import ErrorAlert from '../../components/erroralert';
import SessiontTmeout  from '../../components/sessiontimeout'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      padding: theme.spacing(2),
      marginTop:120
    },
    title: {
      fontSize: '1.625rem',
      color: 'white',
      margin: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  }),
);

const TermsPage: FC = () => {
  const classes = useStyles();
  const cookieDetails = useCookieContext();
  //@ts-ignore
  const { showCookieMessage } = cookieDetails;
  const userdetails = useUserContext();
  // @ts-ignore
  const { showClickWrap, exceptionClickWrap, loadingClickWrap, acceptClickWrapdetails,showSessionTimeoutModel } = userdetails;
 
 
  const handleClickwrapClose = () => {
    acceptClickWrapdetails();
  };
  return (
    <React.Fragment>
        {loadingClickWrap && <LinearProgress />}
    <TermsLayout >
      <div className={classes.content}>
        <Container maxWidth="md">
          <Typography variant="h4" className={classes.title}>
            Legal Terms and Conditions
          </Typography>
          <Dots />
          <TermsContent></TermsContent>
        </Container>
        {exceptionClickWrap && <ErrorAlert></ErrorAlert>}
      </div>
      <CookieMessage state={showCookieMessage} ></CookieMessage>
      <ClickWrap state={showClickWrap} handleClose={handleClickwrapClose}></ClickWrap>
      <SessiontTmeout state={showSessionTimeoutModel}></SessiontTmeout>
    </TermsLayout>
    </React.Fragment>
  );
};

export default TermsPage;
