import { createStyles, makeStyles, Theme, Typography, Grid, Checkbox,FormControlLabel } from '@material-ui/core';
import React, { PropsWithChildren, useEffect } from 'react';
import { useTypedSelector } from '../../store';
import { getApiProducts, getApplications } from '../../selectors/selectors';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreOutlined';
import { ApiProductCard } from '../../components/cards';  
import LinearProgress from '@material-ui/core/LinearProgress';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordion: {
      backgroundColor: 'transparent',
      boxShadow: 'inset 0 0 5px #ffffff29',
      margin:'10px 0px'
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    heading: {
      fontWeight: 'bold'
    },
    paragraphs: {
      fontSize: '1rem'
    },
    noDatafoundMessage:{
      color: 'white',   
      fontWeight: 400,
      fontSize:15,
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      marginTop:'10%'
    },
    accordionHeader:{
      display:'flex',
      justifyContent:'space-between',
      alignItems:'center',
      width:'100%'
    }
  }));
  type Domain = {
    name: string;
    apiProducts: Array<string>;
  };
  
  type ApiProduct = {
    uuid: string;
    name: string;
    version: string;
    selected?:string;
  };
  
  interface AllDomains {
    [key: string]: Domain;
  }
  interface AllApiPrdocuts {
    [key: string]: ApiProduct;
  }
  export type ApiProductsContainerProps = {
    selelectionFilter:boolean;
    checkedApis:any;
    handleDomainsChange: (e:any) =>void;
    handleApisChange:(e:any,newValue:any,domain:string)=> void;
  };
 

  const ApiProductsContainer: React.FC<ApiProductsContainerProps> = 
  (props: PropsWithChildren<ApiProductsContainerProps>) => {

  const { selelectionFilter,checkedApis,handleApisChange,handleDomainsChange } = props;  
  const classes = useStyles();
  const{ apiProducts }  = useTypedSelector(state => getApiProducts(state));
  const appsData  = useTypedSelector(state => getApplications(state));
  const AllDomains: AllDomains = selelectionFilter === true? appsData.domains: apiProducts?.domains;
  const currentapiProducts : AllApiPrdocuts =  selelectionFilter === true? appsData.apiProducts: apiProducts?.data ;
  const loading = apiProducts?.loading;
  const AllDomainsKeys = AllDomains && Object.keys(AllDomains);

  const handleEventChange =(event:any) =>{
    event.stopPropagation();
  };
  const setValues = () => {};

  useEffect(() => {
    if(selelectionFilter === true){
      setValues();
    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentapiProducts,selelectionFilter]);

  
  function returnProducts (domain: any, apiProductsLength: any) {
  const areAllApiSelected = selelectionFilter === true ? Object.values(checkedApis[domain.name]).every(value => value) : false;
  let domainAPis=[];
   domainAPis =  (apiProductsLength !== 0) && domain.apiProducts.map((eachGroup: string | number, index: any)=>{
          return currentapiProducts[eachGroup];
  });
  domainAPis =domainAPis.sort(function(a:any, b:any) {
    return a.name.localeCompare(b.name)
  });
    return ( 
      apiProductsLength !==0 && (
        <Accordion classes={{ root: classes.accordion }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />} aria-controls="panel1a-content" id="panel1a-header" >
            <div  className={classes.accordionHeader}>
            <Typography color="secondary" className={classes.heading}>
              {domain.name}
            </Typography>
            {selelectionFilter && <FormControlLabel label="Select All"
                 name={`${domain.name}`}
                 onClick={(e:any)=>{handleEventChange(e)}}
                 control={
                <Checkbox 
                     //@ts-ignore
                    name={`${domain.name}`}
                    checked={areAllApiSelected || false}
                    data-testid={`${domain.name}`}
                    //@ts-ignore
                    onChange={(event:any,newValue:boolean)=>{handleDomainsChange(event,newValue)}}
                />
              }/>}
            </div> 
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
          <Grid container spacing={2}>
          {domain.apiProducts && 
            domainAPis.map((eachGroup: any | number, index: any) => {
              return (
                eachGroup !== undefined && (
                  <Grid sm={6} md={6} xs={12} lg={6} item key={eachGroup.uuid}>
                    <ApiProductCard
                      uuid={eachGroup.uuid}
                      name={eachGroup.name}
                      version={eachGroup.version}
                      grpId={''}
                      selectionFilter={selelectionFilter}
                      handleCheckBox={handleApisChange}
                      checkedTiles={checkedApis[domain.name]}
                      domain={domain.name}
                    />
                  </Grid>
                )
              );
            })}
            </Grid>
          </AccordionDetails>
        </Accordion>

          

    ))
  }
  
    const renderApiProductsContainer = () => {
    return (
      <React.Fragment >
         {AllDomainsKeys 
            &&
            AllDomainsKeys.map((eachDomain: any) => {
                const domain: Domain = AllDomains[eachDomain];
                const apiProductsLength = domain.apiProducts.length;
                return(
                  returnProducts(domain, apiProductsLength) )})}
      </React.Fragment >

    )
  }
  const renderNoApiProducts = () => {
    return (
      <React.Fragment >
         <div className={classes.noDatafoundMessage}> No products found </div>
      </React.Fragment >

    )
  }
  const renderLoading = () => {
    return (<LinearProgress/>)
  }

  const renderContent = () => currentapiProducts && Object.keys(currentapiProducts).length > 0  ? renderApiProductsContainer() :renderNoApiProducts()
  return loading ? renderLoading():renderContent();
};

export default ApiProductsContainer;
