import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { TermsLayout } from '../../components/layout';
import Dots from '../../components/dots';
import { PolicyCard } from '../../components/cards';
import { useCookieContext } from '../../CookieContext';
import { useUserContext } from '../../UserContext';
import CookieMessage from '../../components/cookiemessage';
import ClickWrap from '../../components/clickwrap';
import LinearProgress from '@material-ui/core/LinearProgress';
import ErrorAlert from '../../components/erroralert';
import SessiontTmeout  from '../../components/sessiontimeout';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      padding: theme.spacing(2),
      minHeight: '100vh',
      marginTop:120
    },
    title: {
      fontSize: '1.625rem',
      color: 'white',
      margin: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    contentContainer: {
      paddingTop: theme.spacing(2),
      margin: theme.spacing(2),
      color: 'white',
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(1),
      },
    },
    heading: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    paragraphs: {
      fontSize: '1rem',
      marginTop: theme.spacing(2),
      color: 'white',
    },
    cards: {
      marginTop: theme.spacing(2),
    },
  }),
);

const PrivacyPage: FC = () => {
  const classes = useStyles();
  const cookieDetails = useCookieContext();
  //@ts-ignore
  const { showCookieMessage } = cookieDetails;
  const userdetails = useUserContext();
  // @ts-ignore
  const { showClickWrap, exceptionClickWrap, loadingClickWrap, acceptClickWrapdetails, showSessionTimeoutModel } = userdetails;
  const handleClickwrapClose = () => {
    acceptClickWrapdetails();
  };
  return (
    <React.Fragment>
        {loadingClickWrap && <LinearProgress />}
    <TermsLayout>
      <div className={classes.content}>
        <Container maxWidth="md">
          <Typography variant="h4" className={classes.title}>
            Global Privacy Standard
          </Typography>
          <Dots />
          <div className={classes.contentContainer}>
            <Typography variant="body1" className={classes.paragraphs}>
              We are dedicated to protecting your privacy and safeguarding your confidential
              personal and financial information. In fact, it is one of our highest priorities and
              remains one of the cornerstones of our commitment to you. When you provide personal
              information to us, we use vigorous security and privacy safeguards to ensure your
              personal and financial information is protected.
            </Typography>
            <Grid container spacing={10} className={classes.cards}>
              <Grid item xs={12} sm={6} md={6}>
                <PolicyCard
                  title="Europe, Middle East & Africa"
                  to="/global-privacy-standards/emea-privacy-notice"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <PolicyCard
                  title="North America"
                  to="/global-privacy-standards/north-america-privacy-notice"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <PolicyCard
                  title="Latin America"
                  to="/global-privacy-standards/latin-america-privacy-notice"
                />
              </Grid>
            </Grid>
          </div>
        </Container>
        {exceptionClickWrap && <ErrorAlert></ErrorAlert>}
      </div>
      <CookieMessage state={showCookieMessage} ></CookieMessage>
      <ClickWrap state={showClickWrap} handleClose={handleClickwrapClose}></ClickWrap>
      <SessiontTmeout state={showSessionTimeoutModel}></SessiontTmeout>
    </TermsLayout>
    </React.Fragment>
  );
};

export default PrivacyPage;
