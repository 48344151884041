import { Box, createStyles, makeStyles, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { AccordionTable } from '../accordionTable/accordionTable';
import { TableRow } from '../accordionTable/tableRow';
import { getEvents, getEventsCount } from '../../selectors/selectors';
import { Event, EventStatus as StatusEnum, EventCollection, fetchEventsData } from '../../store/eventSlice';
import React, { useState, useEffect } from 'react';
import { useTypedSelector } from '../../store';
import { EventDetails } from '../eventDetails';
import LinearProgress from '@material-ui/core/LinearProgress';
import Pagination from '@material-ui/lab/Pagination';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PRODUCTION_HOST_NAME_LIST } from '../../constants/constants';
import EventStatus from '../eventStatus';
import clsx from 'clsx';

const pageSize= 5;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    applicationsContainer: {
      flexDirection: 'column',
      width: 'calc(100% - 40px)',
      margin: 20,
      marginTop: 60,
      display: 'flex'
    },
    applicationsContainerMobile: {
      flexDirection: 'column',
      width: 'calc(100% - 2px)',
      marginTop: 40,
      display: 'flex',
      maxWidth: 'calc(100% - 2px)',
      overflow:'auto'
    },
    noAppsContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: 'calc(100% - 80px)',
      backgroundColor: 'rgba(255, 255, 255, 0.012)',
      borderRadius: 20,
      margin: 40
    },
    noAppsContainerMobile: {
      display: 'flex',
      flexDirection: 'column',
      width: 'calc(100% - 2px)',
      backgroundColor: 'rgba(255, 255, 255, 0.012)',
      borderRadius: 20,
      margin: 0
    },
    noAppsInfoTextContainer: {
      display: 'flex',
      width: '100%',
      paddingTop: 30,
      paddingBottom: 30,
      paddingLeft: 20,
      paddingRight: 20,
      flexDirection: 'column'
    },
    noAppsInfoTitle: {
      color: '#ffffff',
      fontWeight: 300,
      marginBottom: 20
    },
    noAppsInfoBody: {
      color: '#ffffff',
      fontWeight: 300
    },
    dummyAppListContainer: {

    },
    eventName: {
      color: '#00e2d0',
      fontWeight: 500
    },
    myAppsTitle: {
      color: '#ffffff',
      fontWeight: 500,
      marginBottom: 30
    },
    tableHeaders:{
      color: 'white',   
      fontWeight: 500
    },
    loader:{
      margin:'10px 0px',
      height:30
    }
  }));

const EventList: React.FC<{}> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  
  const totalcount = useTypedSelector(state => getEventsCount(state));
  const isSmallScreen = useMediaQuery((theme:any) => theme.breakpoints.down("sm"));
  const pagesCount= Math.ceil(totalcount/pageSize);
  const location = useLocation();
  const querySearched = location.search || '';
  const params = new URLSearchParams(querySearched);
  const sponsorCode = params.get('sponsorCode') ||'';
  const currentHostName = window.location.hostname;  
  const [pagination, setPagination] = useState({
    from: 0,
    to: pageSize,
    count: totalcount
  });
  const [page, setPage] = React.useState(1);

  const eventsData = useTypedSelector(state => getEvents(state));
  let events: EventCollection = eventsData?.data;
  if(events && Object.keys(events).length > 0){
    events = Object.keys(events).slice(pagination.from, pagination.to).reduce((result:EventCollection, key) => {
      result[key] = events[key];
      return result;
    }, {}); 
  }
 
  const isLoading = eventsData?.loading;
  useEffect(()=>{ 
    const ApplicationsParamters = {
        page:1,
        pageSize:500
    };
    if (PRODUCTION_HOST_NAME_LIST.indexOf (currentHostName) > -1 ) {
      if( eventsData && eventsData.totalCount === 0){
        dispatch(fetchEventsData({
          ...ApplicationsParamters,
          sponsorCode:''
        }));
      }
      
    }else{
      if( eventsData && eventsData.totalCount === 0){
      dispatch(fetchEventsData({
        ...ApplicationsParamters,
        sponsorCode:sponsorCode
      }));
      } 
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dispatch,sponsorCode,currentHostName,eventsData.totalCount]);
  const testApplications: EventCollection = {
    'fc06ec4e-3766-49eb-adb8-1d3fc096104e': {
      eventId: 'fc06ec4e-3766-49eb-adb8-1d3fc096104e',
      displayEventName:'Custody End of Day',
      eventName: 'Custody End of Day',
      domainName: 'Custody',
      status: StatusEnum.SUBSCRIBED,
      subscriptionId: "9335a55e-6caf-31e4-8326-ba662107d1f2",
      eventDetails:{
        eventDescription: 'This event provides a notification when the Custody Book of Record is refreshed with current available data.',
        callbackUrl: 'http://www.string.com'	
      }
    }
  };
  const handlePageChange =  (event:React.ChangeEvent<unknown>,page:number)=> {
      const from =(page - 1)*pageSize;
      const to =(page - 1)*pageSize + pageSize;
      setPage(page);

      setPagination({...pagination,from:from, to:to})
  };

  const renderNoEvents = () => {
    return (
      <Box
        data-testid={'NoAppsContainer'}
        data-cy={'NoAppsContainer'}
        className={isSmallScreen? classes.noAppsContainerMobile:classes.noAppsContainer}>
        <Box className={classes.noAppsInfoTextContainer}>
          <Typography variant={'h6'} className={classes.noAppsInfoTitle}>
            No Events
          </Typography>
          <Typography variant={'body2'} className={classes.noAppsInfoBody}>
            Currently you have no Events available.<br /><br />
            Once your organisation admin adds the first application, you should see it in the following format:
          </Typography>
        </Box>
        <Box className={classes.dummyAppListContainer}>
          <AccordionTable numColumns={5} renderHeadings={() => (
            <React.Fragment>
              <th className={classes.tableHeaders} colSpan={3}>Domain</th>
              <th className={classes.tableHeaders} colSpan={3}>Name</th>
              <th className={classes.tableHeaders} colSpan={3}>Status</th>
            </React.Fragment>
          )}>
            {
              Object.values(testApplications).map((event: Event) => (
                <TableRow
                  key={event.eventId}
                  disabled
                  activeId={event.eventId}
                  tableRowContent={() => (
                    <React.Fragment>
                      <td colSpan={3}>
                        <Typography
                          variant={'body2'}
                          className={classes.eventName}>
                          {event.domainName}
                        </Typography>
                      </td>
                      <td colSpan={3}>
                        <Typography
                          variant={'body2'}
                          className={classes.eventName}>
                          {event.displayEventName}
                        </Typography>
                      </td>
                      <td colSpan={3} 
                          data-cy={'EventStatus'}
                        >
                          <EventStatus {...event} />
                      </td>
                    </React.Fragment>
                  )}
                  accordionContent={() => (
                    <Box style={{ width: '100%', height: '400px' }} />
                  )} />
              ))
            }
          </AccordionTable>
        </Box>
      </Box>
    )
  }

  const renderEvents = () => {
    return (
      <Box
        data-testid={'AppsContainer'}
        data-cy={'AppsContainer'}
        className={clsx(isSmallScreen ? classes.applicationsContainerMobile : classes.applicationsContainer)}>
        <AccordionTable numColumns={12} renderHeadings={() => (
          <React.Fragment>
            <th className={classes.tableHeaders} colSpan={3}>Domain</th>
            <th className={classes.tableHeaders} colSpan={6}>Name</th>
            <th className={classes.tableHeaders} colSpan={3}>Status</th>
          </React.Fragment>
        )}>
          {
            events && Object.values(events).map((event: Event) => (
              <TableRow
                key={event.eventId}
                activeId={event.eventId}
                tableRowContent={() => (
                  <React.Fragment>
                    <td colSpan={3} data-cy={'ApplicationName'}>
                      <Typography
                        variant={'body2'}
                        className={classes.eventName}>
                        {event.domainName}
                      </Typography>
                    </td>
                    <td colSpan={6} data-cy={'ApplicationName'}>
                      <Typography
                        variant={'body2'}
                        className={classes.eventName}>
                        {event.displayEventName}
                      </Typography>
                    </td>
                    <td colSpan={3} 
                          data-cy={'EventStatus'}
                        >
                          <EventStatus {...event} />
                    </td>
                  </React.Fragment>
                )}
                accordionContent={() => (
                  <EventDetails eventId={event.eventId} />
                )} />
            ))
          }
        </AccordionTable>
        <Box justifyContent={'center'} alignItems={'center'} display={'flex'}>
            <Pagination count={pagesCount}
              onChange={handlePageChange}
              page={page}
            />
        </Box>
       
      </Box>
     
    )
  }

  const renderContent = () => {
    return ( 
      <React.Fragment>
       <div>  
        {events && ( Object.keys(events).length > 0)? renderEvents():renderNoEvents()} 
      </div>
    </React.Fragment>);     

  };
  return isLoading ? <React.Fragment > 
  <div className={classes.loader}> <LinearProgress /></div>
  </React.Fragment > : renderContent();
};

export default EventList;