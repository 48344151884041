import { Box, Container } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useUserContext } from '../../UserContext';
import { fetchAccountData } from '../../store/accountSlice';
import AccountHeader from '../../components/accountHeader';
import ApplicationList from '../../components/applicationList';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CookieMessage from '../../components/cookiemessage';
import ClickWrap from '../../components/clickwrap';
import SessiontTmeout  from '../../components/sessiontimeout';
import { useCookieContext } from '../../CookieContext';
import ErrorAlert from '../../components/erroralert';
import { useLocation } from 'react-router';	
import { PRODUCTION_HOST_NAME_LIST } from '../../constants/constants';
import { useTypedSelector } from '../../store';
import { getState, getApplications } from '../../selectors/selectors';
import { SuccessAlert } from '../../components/successalert';
import { fetchApplicationsData, setShowAlert, setMessage, setSelectedApplication } from 'store/applicationsSlice';
import ConfirmationBox from '../../components/confirmationbox';
import Appurls from 'constants/appurls';
import { put } from '../../utils';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accountPage: {
      minHeight: '100vh',
      paddingBottom: theme.spacing(10),
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
    container: {
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    }
  }));

const AccountPage: React.FC<{}> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cookieDetails = useCookieContext();
  const appDeleteMessage = "App disabled Successfully";
  const { userDetails } = useTypedSelector(state => getState(state));
  const { showAlert, message, currentApplication } = useTypedSelector(state => getApplications(state));
  const [confirmationDisplayStatus, setConfirmationDisplayStatus] = useState<boolean>(false); 
  const alertType = (message === appDeleteMessage || message ==="App created Successfully") ? "success" :"error";
  const location = useLocation();
  const querySearched = location.search || '';
  const currentHostName = window.location.hostname;  
	//@ts-ignore 
	const { showCookieMessage } = cookieDetails;
  const {
    isAuthenticated,
    showClickWrap,
    exceptionClickWrap,
    acceptClickWrapdetails,
    showSessionTimeoutModel
  } = useUserContext();

  const handleClickwrapClose = () => {
    acceptClickWrapdetails();
  };
  const onClickClose =()=> {
    dispatch(setShowAlert(false));
  };
  
  const fetchData = useCallback(() => {
    if (isAuthenticated === true &&  userDetails.roles?.length === 0) {
      if (PRODUCTION_HOST_NAME_LIST.indexOf (currentHostName) >-1) {
        dispatch(fetchAccountData(''));
      }else{
        if(querySearched && querySearched.includes("sponsorCode")){
          dispatch(fetchAccountData(querySearched));
        }else{
          dispatch(fetchAccountData(''));
        }
      }
    }
  }, [dispatch, isAuthenticated,querySearched,currentHostName,userDetails]);

  useEffect(() => {
    fetchData();
    if(localStorage.getItem("currenteEventId") !==''){
      localStorage.setItem("currenteEventId","");
    }  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onActionsClick =(action:string,data:any) =>{
    dispatch(setSelectedApplication(data));
    setConfirmationDisplayStatus(true);
  };
  const onClickYes =  async() =>{
    const deleteApplicationUrl = Appurls.disableApplication();
    const applicationsParamters = {
      page:1,
      pageSize:500
    };
    const response = await put(deleteApplicationUrl, {
          applicationUUID:currentApplication.uuid,
          env:currentApplication.environment
    });
    setConfirmationDisplayStatus(false);
    if (response.error !== undefined) {
      dispatch(setMessage("Something went wrong. Please check with support team."));
      dispatch(setShowAlert(true));
    } else {
      dispatch(setMessage(appDeleteMessage));
      dispatch(setShowAlert(true));
      dispatch(fetchApplicationsData({
        ...applicationsParamters,
        sponsorCode:''
      }));
  }
  };
  const onClickNo =() =>{
      setConfirmationDisplayStatus(false);
  };
  return (
    <React.Fragment>
        <Box data-testid='Account Page' className={classes.accountPage}>
        <Container maxWidth='md' className={classes.container}>
          <AccountHeader />
          <ApplicationList onAcionsClick={onActionsClick}/>
        </Container>
      </Box>
      <CookieMessage state={showCookieMessage} ></CookieMessage>
        <ClickWrap state={showClickWrap} handleClose={handleClickwrapClose}></ClickWrap>
        <SessiontTmeout state={showSessionTimeoutModel}></SessiontTmeout>
        {exceptionClickWrap && <ErrorAlert></ErrorAlert>}
        {showAlert &&(<SuccessAlert message={message} state={showAlert} alertType={alertType} onClose={onClickClose}></SuccessAlert>)}
        <ConfirmationBox
            state= {confirmationDisplayStatus}
            title ={'Confirmation'}
            message={'Are you sure to disable application?'}
            buttontext1={'Yes'}
            buttontext2={'No'}
            actionButton1={onClickYes}
            actionButton2={onClickNo}
            onClose={onClickNo}
          ></ConfirmationBox>
    </React.Fragment>
  );
};

export default AccountPage;
