import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreOutlined';
import { Link } from 'react-router-dom';
import { useUserContext } from '../../UserContext';
import ClickWrap from '../../components/clickwrap';
import ErrorAlert from '../../components/erroralert';
import SessiontTmeout  from '../../components/sessiontimeout';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullContainer: {
      minHeight: '100vh',
    },
    container: {
      marginBottom: theme.spacing(5),
    },
    pageTitle: {
      color: 'white',
      letterSpacing: '0.0625rem',
      fontWeight: 300,
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
    },
    subTitle: {
      fontWeight: 300,
      color: 'white',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    groupTitle: {
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    rule: {
      height: 1,
      backgroundColor: grey[400],
    },
    accordion: {
      backgroundColor: 'transparent',
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    heading: {
      fontWeight: 'bold',
      // fontSize: '1.25rem',
    },
    paragraphs: {
      fontSize: '1rem',
      marginTop: theme.spacing(1),
    },
    link: {
      textDecoration: 'none',
      color: '#00C4B4',
    },
  }),
);

const FaqPage: FC = () => {
  const classes = useStyles();
  const userdetails = useUserContext();
  // @ts-ignore
  const { showClickWrap, exceptionClickWrap,acceptClickWrapdetails, showSessionTimeoutModel} = userdetails;
  const handleClickwrapClose = () => {
    acceptClickWrapdetails();
  };

  return (
    
     
    <div className={classes.fullContainer}>
      <Container maxWidth="md" className={classes.container}>
        <Typography variant="h4" className={classes.pageTitle}>
          Frequently Asked Questions (FAQs)
        </Typography>
        <Typography variant="h5" className={classes.subTitle}>
          General
        </Typography>
        <Typography variant="body2" color="primary" className={classes.groupTitle}>
          ABOUT THE API STORE
        </Typography>
        <Accordion classes={{ root: classes.accordion }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />} aria-controls="panel1a-content" id="panel1a-header" >
            <Typography color="secondary" className={classes.heading}>
              What is the Northern Trust API Store?
            </Typography></AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Typography variant="body2" className={classes.paragraphs}>
              The Northern Trust API Store is a digital marketplace to explore and learn about the
              available APIs. It is also a developer portal that provides access to our entire suite
              of APIs.
            </Typography></AccordionDetails>
        </Accordion>
        <Accordion classes={{ root: classes.accordion }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography color="secondary" className={classes.heading}>
              What are the benefits of an API?
            </Typography></AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Typography variant="body2" className={classes.paragraphs}>
              Northern Trust APIs allow clients to seamlessly integrate their business processes
              with their Northern Trust data to mitigate manual processes, develop their own
              applications, and make use of analytical tools. When considering a data as a service
              application, APIs are one tool at an architect’s disposal.
            </Typography></AccordionDetails>
        </Accordion>
        <Accordion classes={{ root: classes.accordion }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />} aria-controls="panel1a-content"
            id="panel1a-header">
            <Typography color="secondary" className={classes.heading}>
              Is an API right for me?
            </Typography></AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Typography variant="body2" className={classes.paragraphs}>
              The best use cases for APIs are activity monitoring using intraday APIs and
              reconciliation processes using end of day or point in time position and / or
              transaction APIs. They are an appropriate tool for clients that are comfortable with
              creating and maintaining their own data requests.
            </Typography> </AccordionDetails>
        </Accordion>
        <Accordion classes={{ root: classes.accordion }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}aria-controls="panel1a-content" id="panel1a-header">
            <Typography color="secondary" className={classes.heading}>
              Do I need to be a Northern Trust client to use the API?
            </Typography> </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Typography variant="body2" className={classes.paragraphs}>
              Yes, you must be a Northern Trust client or a client-designated / approved provider to
              sign up for API access through our API Store.
            </Typography></AccordionDetails>
        </Accordion>
        <Accordion classes={{ root: classes.accordion }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography color="secondary" className={classes.heading}>
              Who should I speak with if I'm interested in APIs with Northern Trust?
            </Typography></AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Typography variant="body2" className={classes.paragraphs}>
              To learn more about APIs with Northern Trust and how they might be used within your
              organization, please{' '}
              <Link className={classes.link} to="/contact-us">
                Contact Us
              </Link>
              .
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Typography variant="h5" className={classes.subTitle}>
          APIs
        </Typography>
        <Typography variant="body2" color="primary" className={classes.groupTitle}>
          SANDBOX
        </Typography>
        <Accordion classes={{ root: classes.accordion }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography color="secondary" className={classes.heading}>
              How can I try my application before going live?
            </Typography> </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Typography variant="body2" className={classes.paragraphs}>
              Once you get access, create an Application where you can start testing against our
              Sandbox APIs.
            </Typography>
          </AccordionDetails></Accordion>
        <Accordion classes={{ root: classes.accordion }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography color="secondary" className={classes.heading}>
              What functionality is available in the sandbox?
            </Typography></AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Typography variant="body2" className={classes.paragraphs}>
              We provide simulated secure access to our APIs. Check out our{' '}
              <Link className={classes.link} to="/apis">
                Explore APIs
              </Link>{' '}
              and documentation to see what’s available.
            </Typography></AccordionDetails>
        </Accordion>
        <Accordion classes={{ root: classes.accordion }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}aria-controls="panel1a-content" id="panel1a-header">
            <Typography color="secondary" className={classes.heading}>
              Is the data in the Sandbox real client data?
            </Typography> </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Typography variant="body2" className={classes.paragraphs}>
              No, the data in the Sandbox is all test data and does not contain any real Northern
              Trust client or Legal Entity data. The Sandbox data is however mocked to provide a
              representative sample of what can be expected from the Live environment to provide a
              valid test for your applications.
            </Typography></AccordionDetails>
        </Accordion>
        <Accordion classes={{ root: classes.accordion }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />} aria-controls="panel1a-content"
            id="panel1a-header">
            <Typography color="secondary" className={classes.heading}>
              How do I test the APIs before implementing them?
            </Typography> </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Typography variant="body2" className={classes.paragraphs}>
              You can test your API using our Sandbox APIs. We will reach out to you to provide you
              with our Sandbox APIs URL and API Key.
            </Typography> </AccordionDetails>
        </Accordion>
        <Accordion classes={{ root: classes.accordion }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}aria-controls="panel1a-content"id="panel1a-header">
            <Typography color="secondary" className={classes.heading}>
              Why do I keep receiving a ‘429’ response?
            </Typography> </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Typography variant="body2" className={classes.paragraphs}>
              In the Sandbox, we cap the amount of API calls you can make in a given period of time.
              Simply wait a minute and try again. If the issue persists, please{' '}
              <Link className={classes.link} to="/contact-us">
                Contact Us
              </Link>
              .
            </Typography> </AccordionDetails>
        </Accordion>
        <Typography variant="body2" color="primary" className={classes.groupTitle}>
          AUTHENTICATION AND AUTHORIZATION
        </Typography>
        <Accordion classes={{ root: classes.accordion }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />} aria-controls="panel1a-content"id="panel1a-header">
            <Typography color="secondary" className={classes.heading}>
              Do I need a certificate to work in the Sandbox? What about production?
            </Typography> </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Typography variant="body2" className={classes.paragraphs}>
              You need a signed certificate to prototype in the Sandbox. For production, we’ll
              provide a certificate and require TLS 1.2 implementation.
            </Typography>  </AccordionDetails>
        </Accordion>
        <Accordion classes={{ root: classes.accordion }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}aria-controls="panel1a-content"id="panel1a-header">
            <Typography color="secondary" className={classes.heading}>
              What authentication method do you use for your APIs, and how do I get authorized to
              make calls?
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Typography variant="body2" className={classes.paragraphs}>
              We use a certificate based authentication. You will have to include X.509 based
              certificate in your authentication request. Once authenticated you will received a JWT
              (JSON Web Token) token that you can use to gain access to protected Northern Trust
              resources. Check out our{' '}
              <Link className={classes.link} to="/get-started/access-setup">
                Authorize Documentation
              </Link>{' '}
              to get more information on implementing Authentication and Authorization flow.
            </Typography> </AccordionDetails>
        </Accordion>
        <Accordion classes={{ root: classes.accordion }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}aria-controls="panel1a-content"id="panel1a-header">
            <Typography color="secondary" className={classes.heading}>
              How can I use the JWT Token to gain access to protected resource?
            </Typography> </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Typography variant="body2" className={classes.paragraphs}>
              Use the JWT Token acquired from authentication steps as bearer token to get access to
              protected resources. Please check the{' '}
              <Link className={classes.link} to="/get-started/access-setup">
                Access Setup
              </Link>{' '}
              page for more information.
            </Typography></AccordionDetails>
        </Accordion>
        <Accordion classes={{ root: classes.accordion }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}aria-controls="panel1a-content" id="panel1a-header">
            <Typography color="secondary" className={classes.heading}>
              When do the JWT token expire?
            </Typography> </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Typography variant="body2" className={classes.paragraphs}>
              JWT Token expiry time is 30 minutes.
            </Typography> </AccordionDetails>
        </Accordion>
        <Accordion classes={{ root: classes.accordion }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}aria-controls="panel1a-content"id="panel1a-header">
            <Typography color="secondary" className={classes.heading}>
              I keep receiving a 401 ‘Unauthorized’ response. What can I do to resolve it?
            </Typography>  </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Typography variant="body2" className={classes.paragraphs}>
              Here are some common fixes:
              <ul>
                <li>Check that your certificate are signed by a valid Certificate Authority.</li>
                <li>Make sure that your access token is not invalidated or expired.</li>
              </ul>
              If none of these help, please{' '}
              <Link className={classes.link} to="/contact-us">
                Contact Us
              </Link>
              .
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Container>
      {exceptionClickWrap && <ErrorAlert></ErrorAlert>}
      <ClickWrap state={showClickWrap} handleClose={handleClickwrapClose}></ClickWrap>
      <SessiontTmeout state={showSessionTimeoutModel}></SessiontTmeout>
    </div>
  );
};

export default FaqPage;
