import { AppBar, Box, createStyles, makeStyles, Tab, Tabs, Theme, Typography, withStyles,Grid, useMediaQuery } from '@material-ui/core';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useTypedSelector } from '../../store';
import { getEvents, getState } from '../../selectors/selectors';
import { EVENT_DETAILS_MAPPING } from '../../constants/constants';
import {  EventListMapping, EventAttributes, setCurrentSubscriptionsData, setConfirmationBoxDisplayStatus, updateEventRecord } from '../../store/eventSlice';
import { checkUserPermission } from '../../core/authorization/service/authorizationService';
import { Controller, useForm } from 'react-hook-form';
import { trapSpacesForRequiredFields, urlRegex } from '../../utils';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%'
    },
    keys:{
      color: 'white',   
      fontWeight: 400,
      fontSize:15,
      marginLeft:20
    },
    values:{
      color: 'white',   
      fontWeight: 300,
      fontSize:14,
      overflowWrap: 'break-word'
    },
    actionButton:{
      margin:10,
      minWidth: '110px'
    },
    tabPanel:{
      background:'#31405a',
      minHeight:350,
      maxHeight:350,
      overflowY:'auto',
      '&::-webkit-scrollbar': {
        width: '0.2em',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#00C4B4',
        outline: '1px solid slategrey',
      },
    }
  }));

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function TabPanel(props: { [x: string]: any; children: any; value: any; index: any; }) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={classes.tabPanel}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
interface IFormInput {
  callbackUrl: string;
  action:''
 }

interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}
const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: 'rgba(0,0,0,0)',
    },
  },
})((props: StyledTabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

interface StyledTabProps {
  label: string;
};

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'uppercase',
      borderTopLeftRadius : '15px',
      borderTopRightRadius : '15px',
      color: '#00C4B4',
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(13),
      marginRight: theme.spacing(1),
      opacity: 1,
      '&.Mui-selected': {
        backgroundColor: '#31405a'
      }
    }
    
  }),
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

export type EventDetailsProps = {
  eventId: string;
};
export const EventDetails: React.FC<EventDetailsProps> =
  (props: PropsWithChildren<EventDetailsProps>) => {
    const ariaLabel = 'aria-label';
    const classes = useStyles();
    const dispatch = useDispatch();
    const { eventId } = props;
    const { data,subscriptionId } = useTypedSelector(state => getEvents(state));
    const { userDetails } = useTypedSelector(state => getState(state));
    const [currentAction, setCurrentAction] = useState("default");
    const isSmallScreen = useMediaQuery((theme:any) => theme.breakpoints.down("sm"));
    const currentCallbackUrl = data && data[eventId]?.eventDetails?.callbackUrl;
    const eventDetails :EventAttributes = data && data[eventId]?.eventDetails;
    const {callbackUrl, ...filteredEventDetails} = eventDetails;
    const currentSubscribeStatus = data && data[eventId]?.status;
    const currenteventName = data && data[eventId]?.eventName;
    const currentdisplayEventName = data && data[eventId]?.displayEventName;
    const currenteventDomainName = data && data[eventId]?.domainName;
    const currenteventSubscriptionId = subscriptionId;
    const { control, handleSubmit, errors, setValue } = useForm<IFormInput>({
      defaultValues: {
        callbackUrl: currentCallbackUrl,
        action:''
      },
    });
    useEffect(() => {
      setValue('callbackUrl', currentCallbackUrl?? '');
      setValue('action', currentAction);
    }, [currentCallbackUrl, setValue, currentAction]);
  
    const
      onSubmit = async (data: IFormInput) => {
   
          dispatch(setCurrentSubscriptionsData({
            action:data.action,
            callbackUrl: data.callbackUrl,
            subscriptionId:currenteventSubscriptionId,
            displayEventName:currentdisplayEventName,
            eventId:eventId,
            eventName:currenteventName,
            domain:currenteventDomainName,
            status:currentSubscribeStatus
          }));
          dispatch(setConfirmationBoxDisplayStatus(true));
          dispatch(updateEventRecord({
            eventId:eventId,
            callbackUrl:data.callbackUrl
          }))
      };
    
    const eventListMappping :EventListMapping = EVENT_DETAILS_MAPPING;
    const hasPermissionSubscribeEvents = checkUserPermission(userDetails,'subscribeEvents');
    
    const [value, setValueTab] = React.useState(0);

    const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
      setValueTab(newValue);
    };
    const renderEventDetails =  () => {
      return ( 
        eventDetails && Object.keys(eventDetails).length && Object.keys(eventDetails).map((event:string ) => (
          <Grid container spacing={5} direction="row">
          <Grid item xs={6} sm={3}>
          <Typography className ={classes.keys}>{eventListMappping[event]}</Typography>
          </Grid>
          <Grid item xs={6} sm={9}>
         <Typography className ={classes.values}>{eventDetails[event]}</Typography>
          </Grid>
          </Grid>
        ))
        )
    };
    const renderUpdateContainer =  () => {
      return ( 
        <Grid container spacing={5} direction="row">
        {filteredEventDetails && Object.keys(filteredEventDetails).length && Object.keys(filteredEventDetails).map((application:string ) => (
          <React.Fragment>
          <Grid item xs={12} sm={12}  lg={3} md={3}>
          <Typography className ={classes.keys}>{eventListMappping[application]}</Typography>
          </Grid>
          <Grid item xs={12} sm={12}  lg={9} md={9}>
         <Typography className ={classes.values}>{filteredEventDetails[application]}</Typography>
          </Grid>
         </React.Fragment>
        ))}
        <Grid item xs={12} sm={12} lg={3} md={3}>
          <Typography className ={classes.keys}>Callback Url</Typography>
        </Grid>
        <Grid item xs={12} sm={12} lg={9} md={9}>
          <Controller
                    name="callbackUrl"
                    control={control}
                    rules={{
                      required: true,
                      validate: trapSpacesForRequiredFields,
                      pattern: urlRegex
                    }}
                    render={({ onChange, value }) => (
                      <FormControl fullWidth>
                        <TextField
                          id="callbackUrl"
                          type="callbackUrl"
                          label="Callback Url"
                          required
                          fullWidth
                          variant="filled"
                          value={value}
                          onChange={onChange}
                          inputProps={{ [ariaLabel]: 'Please enter callback url' }}
                          error={errors.callbackUrl !== undefined}
                        />
                      </FormControl>
                    )}
                  />
          </Grid>
         {!isSmallScreen &&  <Grid item xs={6} sm={6} md={3} lg={3}></Grid> } 
        <Grid item xs={6} sm={6} md={9} lg={9}>
        {currentSubscribeStatus ==="subscribed" && <Button
                    variant="contained"
                    color="secondary"
                    aria-label={'Submit callback url'}
                    onClick={handleSubmit((data) => {
                      setCurrentAction('update');
                      // @ts-ignore
                      data.action ='update';
                      setValue('action', 'update');
                      onSubmit(data);
                    })}
                    className={classes.actionButton}
                  >
                   UPDATE
                  </Button>}
          <Button
                    variant="contained"
                    color="secondary"
                    aria-label={'Submit callback url'}
                    onClick={handleSubmit((data) => {
                      setCurrentAction('submit');
                       // @ts-ignore
                       data.action ='submit';
                      setValue('action', 'submit');
                      onSubmit(data);
                    })}
                    className={classes.actionButton}
                  >
                   {currentSubscribeStatus ==="subscribed"? "UNSUBSCRIBE":"SUBSCRIBE"}
                  </Button>
          </Grid>
        </Grid>
        )
    };

    return (
      <Box className={classes.container}>
        <AppBar position="static" style={{ background: '#25334a' }}>
          <StyledTabs value={value} onChange={handleChange} aria-label="application details">
            <StyledTab label="Details" {...a11yProps(0)} />
          </StyledTabs>
        </AppBar>
        <TabPanel value={value} index={0}>
           {hasPermissionSubscribeEvents? renderUpdateContainer() :renderEventDetails()}
        </TabPanel>
        
      </Box>
    );
  };
  