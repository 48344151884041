import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { TermsLayout } from '../../components/layout';
import Dots from '../../components/dots';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { Link as MaterialLink } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useCookieContext } from '../../CookieContext';
import { useUserContext } from '../../UserContext';
import CookieMessage from '../../components/cookiemessage';
import ClickWrap from '../../components/clickwrap';
import SessiontTmeout  from '../../components/sessiontimeout';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      padding: theme.spacing(2),
      marginTop:120,
      minHeight: '100vh',
    },
    title: {
      fontSize: '1.625rem',
      color: 'white',
      margin: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    contentContainer: {
      paddingTop: theme.spacing(2),
      margin: theme.spacing(2),
      color: 'white',
    },
    heading: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    paragraphs: {
      fontSize: '1rem',
      marginTop: theme.spacing(2),
      color: 'white',
    },
    cards: {
      marginBottom: theme.spacing(2),
    },
    bar: {
      height: 1,
      backgroundColor: '#BB86FC',
      opacity: 0.3,
    },
    link: {
      textDecoration: 'none',
      color: '#00C4B4',
    },
  }),
);

const CaliforniaRightsPage: FC = () => {
  const classes = useStyles();
  const cookieDetails = useCookieContext();
  //@ts-ignore
  const { showCookieMessage } = cookieDetails;
  const userdetails = useUserContext();
  // @ts-ignore
  const { showClickWrap, showSessionTimeoutModel } = userdetails;
  const handleClickwrapClose = () => {
  
  };
  return (
    <TermsLayout>
      <div className={classes.content}>
        <Container maxWidth="md">
          <Link
            to="/global-privacy-standards/north-america-privacy-notice"
            className={classes.link}
          >
            <Button variant="text" color="primary">
              <ArrowBackIosIcon fontSize="small" />
              NORTH AMERICA PRIVACY STANDARDS
            </Button>
          </Link>
          <Typography variant="h4" className={classes.title}>
            California Consumer Privacy Act - Individual Rights Request
          </Typography>
          <Dots />
          <div className={classes.contentContainer}>
            <Typography variant="body1" className={classes.paragraphs}>
              You may exercise your rights by calling 1-877-265-3729, Monday through Friday, 9:00 am
              to 10:30 pm Pacific Time and Saturday and Sunday, 9:00 am to 5:30 pm Pacific Time; or
              by filling out a{' '}
              <MaterialLink
                className={classes.link}
                target="_blank"
                href="https://privacyportal.onetrust.com/webform/daac40dd-2b6b-45ba-9a0d-752ac74af465/f33b88f4-1c76-40bd-a6cf-da46effcdbc5"
              >
                Data Subject Access Request
              </MaterialLink>
              .
            </Typography>

            <Typography variant="body1" className={classes.paragraphs}>
              We cannot respond to your request or provide you with Personal Information if we
              cannot verify your identity or authority to make the request and confirm the Personal
              Information relates to you. Making a verifiable consumer request does not require you
              to create an account with us. Where possible, we will only use Personal Information
              that Northern Trust already stores to verify your identity or authority to make the
              request.
            </Typography>

            <Typography variant="body1" className={classes.paragraphs}>
              To verify your identity, we ask that you to provide us with the following information
              when you submit your request:
              <ul>
                <li>Name</li>
                <li>Account Number (if available)</li>
                <li>Email Address</li>
                <li>
                  Depending on your type of request or the information requested by you, we may
                  require additional information in order to verify your identity and fulfill your
                  request.
                </li>
              </ul>
            </Typography>

            <Typography variant="body1" className={classes.paragraphs}>
              We follow our standard client identification procedures including voice recognition,
              identifying question or signature verification to verify requests.
            </Typography>
            <Typography variant="h5" className={classes.heading}>
              Contact Information
            </Typography>
            <Typography variant="body1" className={classes.paragraphs}>
              If you have any questions or comments about this CCPA Privacy Notice, the Privacy
              Notice of The Northern Trust Company, the ways in which we collect, use and share your
              Personal Information, your choices and rights regarding such use, or wish to exercise
              your rights under California law, please do not hesitate to contact us at:{' '}
              <MaterialLink
                className={classes.link}
                target="_blank"
                href="mailto:Privacy_Compliance@ntrs.com"
              >
                Privacy_Compliance@ntrs.com
              </MaterialLink>
              .
            </Typography>
          </div>
        </Container>
      </div>
      <CookieMessage state={showCookieMessage} ></CookieMessage>
      <ClickWrap state={showClickWrap} handleClose={handleClickwrapClose}></ClickWrap>
      <SessiontTmeout state={showSessionTimeoutModel}></SessiontTmeout>
    </TermsLayout>
  );
};

export default CaliforniaRightsPage;
