import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { get } from '../utils'


type Api = {
  uuid: string;
  name: string;
  description: string;
  version: string;
  soon: boolean;
  hidespec: boolean;
}

type Details = {
  icon: string,
  title: string
}

type Group = {
  name: string;
  product: string;
  about: string;
  hero: string;
  details: [
    Details?
  ];
  features: [string?];
  availability: string;
  apis: {
    [key: string]: Api
  },
  status: string;
  description: string;
  uuid: string;
}

// Define a type for the slice state
export type GroupState = {
  loading: boolean;
  error: string | null;
  group: Group
  status: number;
}

type FetchGroupError = {
  status: number;
  message: string
}


// Define the initial state using that type
const initialState: GroupState = {
  loading: false,
  error: '',
  status: 0,
  group: {
    name: '',
    product: '',
    about: '',
    hero: '',
    details: [

    ],
    features: [],
    availability: '',
    apis: {},
    status: '',
    description: '',
    uuid: ''
  }
}

export const fetchGroup = createAsyncThunk<Group, string, { rejectValue: FetchGroupError }>(
  "group/fetch",
  async (groupId: string, thunkApi) => {
    const response = await get(`/bff/groups/${groupId}`);
    if (response.error !== undefined) {
      // Return the error message:
      return thunkApi.rejectWithValue({
        status: response.error.status,
        message: response.error.message
      });
    }
    // Also, set a type for the `data` constant:
    const data: Group = response.data;
    return data;

  }
);

export const GroupSlice = createSlice({
  name: 'group',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    clearGroup: (state) => {
      state.group = { ...initialState.group }
    }
  },
  extraReducers: (builder) => {

    // When we send a request,
    // `fetchGroup.pending` is being fired:
    builder.addCase(fetchGroup.pending, (state) => {
      // At that moment,
      // change `loading` back to `true`.
      // and clear all the previous errors:
      state.loading = true
      state.error = null;
    });

    // When a server responses with the data,
    // `fetchGroup.fulfilled` is fired:
    builder.addCase(fetchGroup.fulfilled,
      (state, { payload }) => {
        // We add all the new todos into the state
        // and change `loading` back to `false`.
        state.group = { ...payload }
        state.loading = false
      });

    // When a server responses with an error:
    builder.addCase(fetchGroup.rejected,
      (state, { payload }) => {
        // We show the error message
        // and change `loading` back to `false` again.
        if (payload) state.error = payload.message;
        state.loading = false
      });
  },
})

export const { actions: groupActions, reducer: groupReducer } = GroupSlice;

export const { clearGroup } = groupActions;
