import React, { useState, useEffect, createContext } from 'react';
import Cookies from 'universal-cookie';

//@ts-ignore
const CookieContext = createContext();

const CookieContextProvider = ({ children }: { children: any }) => {
  const [showCookieMessage, setshowCookieMessage] = useState(false);
  const cookieEnabled = window.navigator.cookieEnabled; 
  //@ts-ignore
  const cookies = new Cookies();

  const checkCookieAcess = async () => {
    if (!cookieEnabled) {
        setshowCookieMessage(true);
    }else{
      setshowCookieMessage(false);
    }
    
  };
  useEffect(() => {
    cookies.set('ref', 'apo', {
      path: '/',
      domain: window.location.hostname === 'localhost'
        ? 'localhost'
        : '.ntrs.com'
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    checkCookieAcess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[cookieEnabled]);
  //@ts-ignore
  return <CookieContext.Provider value={{ showCookieMessage, setshowCookieMessage }}> {children}</CookieContext.Provider>;
};
const useCookieContext = () => {
  return React.useContext(CookieContext);
};
export { CookieContextProvider, useCookieContext, CookieContext };
