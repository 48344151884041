import React, { createContext, PropsWithChildren, useContext, useState } from 'react';
export interface IAccordionTableContext {
  activeId: string | null;
  toggleActive: (id: string) => void;
  updateActive: (id: string) => void;
  numColumns: number;
}

export const AccordionTableContext = createContext<IAccordionTableContext>(
  {} as IAccordionTableContext
);

export type AccordionContextProps = {
  numColumns: number;
};

export const AccordionTableProvider: React.FC<AccordionContextProps> =
  (props: PropsWithChildren<AccordionContextProps>) => {
    const [activeId, setActiveId] = useState<string | null>(null);
   
    const toggleActive = (id: string) => {
      setActiveId(id === activeId ? null : id);
    };
    const updateActive = (id: string) => {
      setActiveId(id);
    };
    
   
    const context = {
      activeId,
      toggleActive,
      updateActive,
      numColumns: props.numColumns
    } as IAccordionTableContext;

    return (
      <AccordionTableContext.Provider value={context}>
        {props.children}
      </AccordionTableContext.Provider>
    );
  };

export const useAccordionTable = (): IAccordionTableContext =>
  useContext(AccordionTableContext);
  