import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { TermsLayout } from '../../components/layout';
import Dots from '../../components/dots';
import { Link } from 'react-router-dom';
import { Link as MaterialLink } from '@material-ui/core';
import { useCookieContext } from '../../CookieContext';
import { useUserContext } from '../../UserContext';
import CookieMessage from '../../components/cookiemessage';
import ClickWrap from '../../components/clickwrap';
import LinearProgress from '@material-ui/core/LinearProgress';
import ErrorAlert from '../../components/erroralert';
import SessiontTmeout  from '../../components/sessiontimeout';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      padding: theme.spacing(2),
      marginTop:60
    },
    title: {
      fontSize: '1.625rem',
      color: 'white',
      margin: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    contentContainer: {
      paddingTop: theme.spacing(2),
      margin: theme.spacing(2),
      color: 'white',
    },
    heading: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },

    paragraphs: {
      fontSize: '1rem',
      marginTop: theme.spacing(2),
    },
    link: {
      textDecoration: 'none',
      color: '#00C4B4',
    },
  }),
);

const CookiePage: FC = () => {
  const classes = useStyles();
  const cookieDetails = useCookieContext();
  //@ts-ignore
  const { showCookieMessage } = cookieDetails;
  const userdetails = useUserContext();
  // @ts-ignore
  const { showClickWrap, exceptionClickWrap, loadingClickWrap,acceptClickWrapdetails, showSessionTimeoutModel } = userdetails;
  
  const handleClickwrapClose = () => {
    acceptClickWrapdetails();
  };
  return (
    <React.Fragment>
      {loadingClickWrap && <LinearProgress />}
    <TermsLayout>
      <div className={classes.content}>
        <Container maxWidth="md" className={classes.content}>
          <Typography variant="h4" className={classes.title}>
            Northern Trust Cookie Policy
          </Typography>
          <Dots />
          <div className={classes.contentContainer}>
            <Typography variant="h5" className={classes.heading}>
              Introduction
            </Typography>
            <Typography variant="body2" className={classes.paragraphs}>
              Northern Trust ("we", "us", "our") use a variety of cookies across all of our
              websites. We do this to provide you with the most secure and effective way of viewing
              pages on our sites. In general, cookies:
            </Typography>
            <ul>
              <li>Allow our sites to work as expected, and keep it secure</li>
              <li> Help us understand how you prefer to use our sites</li>
              <li>
                Make our sites easier to use by remembering certain information you have entered
              </li>
              <li>Improve your experience by showing you information relevant to you</li>
            </ul>
            <Typography variant="body2" className={classes.paragraphs}>
              This policy explains what cookies are, how we use them, how they affect you and what
              you can do if you do not wish to accept some or all of the cookies we use on our
              websites.
            </Typography>
            <Typography variant="body2" className={classes.paragraphs}>
              Please read this policy in conjunction with our{' '}
              <Link className={classes.link} to="/global-privacy-standards">
                Consumer Privacy Policy
              </Link>
              .
            </Typography>
            <Typography variant="h5" className={classes.heading}>
              What are cookies?
            </Typography>
            <Typography variant="body2" className={classes.paragraphs}>
              Cookies are text files containing small amounts of information that are placed on your
              computer, smartphone, tablet or other means of access the internet (each, a "device")
              whenever you visit most websites. Cookies are then sent back to the originating
              website on each subsequent visit, or to another website that recognizes the cookie.
            </Typography>
            <Typography variant="body2" className={classes.paragraphs}>
              Cookies are useful because they allow us to recognise your device. They serve many
              different purposes, like allowing you to navigate between pages more efficiently,
              remembering your preferences, and generally improving your overall experience of our
              websites.
            </Typography>
            <Typography variant="body2" className={classes.paragraphs}>
              Cookies may be used to record details of pages relating to particular products and
              services you have looked at on our websites. This is to provide us with data to allow
              us to improve our websites and to provide you with information in which you may be
              interested.
            </Typography>
            <Typography variant="body2" className={classes.paragraphs}>
              Cookies can also be used to recognize and count the number of visitors to our websites
              and to see how you navigate our websites when you use them. This helps us improve the
              way our websites work (for example, by making sure you can easily find what you need).
            </Typography>
            <Typography variant="body2" className={classes.paragraphs}>
              You can find more information about cookies at:{' '}
              <MaterialLink
                className={classes.link}
                target="_blank"
                href="http://www.allaboutcookies.org/"
              >
                All About Cookies
              </MaterialLink>{' '}
              and{' '}
              <MaterialLink
                className={classes.link}
                target="_blank"
                href="http://youronlinechoices.com/"
              >
                Your Online Choices.
              </MaterialLink>{' '}
            </Typography>
            <Typography variant="h5" className={classes.heading}>
              Introduction
            </Typography>
            <Typography variant="body2" className={classes.paragraphs}>
              There are two types of cookie we use on our websites:
            </Typography>
            <ul>
              <li>
                Session cookies are temporary cookies that remain in the cookie file of your device
                until you close your browser (at which point they are deleted)
              </li>
              <li>
                Persistent or stored cookies remain permanently on the cookie file of your computer,
                unless you choose to delete them
              </li>
            </ul>
            <Typography variant="body2" className={classes.paragraphs}>
              Both types of cookie can either be first-party (i.e. owned by us) or third-party (i.e.
              owned by a trusted affiliate of ours).
            </Typography>
            <Typography variant="h5" className={classes.heading}>
              What is the difference between first-party and third-party cookies?
            </Typography>
            <Typography variant="body2" className={classes.paragraphs}>
              Whether a cookie is first-party or third-party depends on which website the cookie
              comes from. First-party cookies are those set by us when you visit any of our
              websites. All other cookies are third-party cookies. We use both first-party and
              third-party cookies, which you can read more about under the four categories of
              cookies described below.
            </Typography>
            <Typography variant="h5" className={classes.heading}>
              How do we use cookies and what do they do?
            </Typography>
            <Typography variant="body2" className={classes.paragraphs}>
              The cookies used on our websites fall into one of four categories, based on the
              International Chamber of Commerce guide to cookie categories. The categories are:
              "strictly necessary", "performance", "functionality", and "targeting".
            </Typography>
            <Typography variant="h5" className={classes.heading}>
              "Strictly necessary" cookies
            </Typography>
            <Typography variant="body2" className={classes.paragraphs}>
              These cookies are essential to help you move around our websites and use the
              functionality, such as accessing secure areas of our website. Without these cookies,
              certain features and functionality will not be available to you.
            </Typography>
            <Typography variant="body2" className={classes.paragraphs}>
              We categorize the following cookies as strictly necessary:
            </Typography>
            <ul>
              <li>
                Session ID cookies — These cookies are used to help us load pages you would like to
                view faster. They are also used for our websites requiring a login to verify your
                device. The information used to do this is not collected and is not logged for
                tracking, sales or marketing purposes.
              </li>
              <li>
                Assess time cookies — These cookies are used to help us understand the time it takes
                for you to log in to your Northern Trust online account. The information used to do
                this is not collected and is not logged for tracking, sales or marketing purposes.
              </li>
            </ul>
            <Typography variant="h5" className={classes.heading}>
              "Performance" and "functionality" cookies
            </Typography>
            <Typography variant="body2" className={classes.paragraphs}>
              These cookies give you extra functionality, such as being able to ask us to remember
              your preferences. They also let us monitor how our websites are performing so we can
              take steps to improve them.
            </Typography>
            <Typography variant="body2" className={classes.paragraphs}>
              We categorize the following cookies as performance or functional cookies:
            </Typography>
            <ul>
              <li>
                Site location cookies — We sometimes use these cookies to remember certain sections
                of our websites you visited and redirect you there the next time you visit. The
                information used to do this is not collected and is not logged for tracking, sales
                or marketing purposes.
              </li>
              <li>
                Marketo cookie — This cookie is associated with an email marketing service provided
                by Marketo. This tracking cookie allows us to link your behavior to an email
                marketing campaign, to measure campaign effectiveness.
              </li>
              <li>
                Analytics cookies — These cookies enable us to track visitor behavior and measure
                website performance.
              </li>
              <li>
                Google Universal Analytics — These cookies are used to distinguish unique users by
                assigning a randomly generated number as a client identifier. The identifier is
                included in each page request in a website and used to calculate visitor, session
                and campaign data for the website analytics reports.
              </li>
            </ul>
            <Typography variant="h5" className={classes.heading}>
              Targeting cookies
            </Typography>
            <Typography variant="body2" className={classes.paragraphs}>
              These cookies contain a unique key that can distinguish individual users’ browsing
              habits or store a code that can be translated into to a set of browsing habits or
              preferences using information stored elsewhere.
            </Typography>
            <Typography variant="body2" className={classes.paragraphs}>
              They do not store personal information directly, but are based on uniquely identifying
              your browser and internet device. If you do not allow these cookies, you will
              experience less targeted advertising. We categorize the following cookies as targeting
              cookies:
            </Typography>
            <ul>
              <li>
                Advertising cookies — These cookies are used to deliver online advertisements on
                other websites that are more relevant to you and your interests. They are also used
                to limit the number of times you see such advertisements as well as help measure the
                effectiveness of the advertising campaign. The hosts include:
              </li>
              <ul>
                <li>Bing</li>
                <li>Facebook (applies to northerntrustcareers.com)</li>
                <li>Google/DoubleClick</li>
                <li>LinkedIn</li>
                <li>Twitter</li>
                <li>YouTube</li>
              </ul>
            </ul>
            <Typography variant="h5" className={classes.heading}>
              Can I prevent my preferred browser from accepting cookies?
            </Typography>
            <Typography variant="body2" className={classes.paragraphs}>
              The web browsers of most devices are initially set up to accept cookies. If you
              prefer, you can set your web browser to disable cookies or to let you know when a
              website is attempting to add a cookie. You can also delete cookies that have
              previously been added to your device's cookie file.
            </Typography>
            <Typography variant="body2" className={classes.paragraphs}>
              For more information on how to modify your browser settings, identify your browser and
              click on the link to review:
            </Typography>
            <ul>
              <li>
                <MaterialLink
                  className={classes.link}
                  href="http://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies"
                  target="_blank"
                >
                  Microsoft Internet Explorer (any version)
                </MaterialLink>
              </li>
              <li>
                <MaterialLink
                  className={classes.link}
                  href="http://support.mozilla.org/en-US/kb/how-do-i-turn-do-not-track-feature#w_how-does-the-do-not-track-feature-work"
                  target="_blank"
                >
                  Mozilla Firefox
                </MaterialLink>
              </li>
              <li>
                <MaterialLink
                  className={classes.link}
                  href="https://www.apple.com/safari/"
                  target="_blank"
                >
                  Safari
                </MaterialLink>
              </li>
              <li>
                <MaterialLink
                  className={classes.link}
                  href="https://support.google.com/chrome/answer/95647?hl=en"
                  target="_blank"
                >
                  Google Chrome
                </MaterialLink>
              </li>
            </ul>
            <Typography variant="body2" className={classes.paragraphs}>
              If you choose to turn on the do-not-track (DNT) and block third-party cookies command
              or to block all cookies, Northern Trust will not send cookies to the browser you
              modified or to third-parties.
            </Typography>
            <Typography variant="body2" className={classes.paragraphs}>
              If you disable session cookies, you will still be able to view most of the pages on
              our websites, but you may not be able to log into any secure pages (i.e. those which
              require a username and password).
            </Typography>
            <Typography variant="body2" className={classes.paragraphs}>
              Please visit Manage Cookie Preferences to update your cookie preferences.
            </Typography>
            <Typography variant="h5" className={classes.heading}>
              Is there anything else I should know about cookies?
            </Typography>
            <Typography variant="body2" className={classes.paragraphs}>
              By using our websites, you agree to us placing certain types of cookies described in
              this policy on your device; however, if you choose to, you can block these cookies
              using your browser settings.
            </Typography>
            <Typography variant="body2" className={classes.paragraphs}>
              Cookies cannot retrieve information from your device which tells us about you or your
              family and, unless you have logged in to a secure part of the website, cookies cannot
              be used to identify who you are.
            </Typography>
            <Typography variant="body2" className={classes.paragraphs}>
              Cookies cannot be used by anyone else who has access to your device to find out
              anything about you, other than someone using the same device has visited a certain
              site. Cookies do not compromise the security of your device.
            </Typography>
            <Typography variant="body2" className={classes.paragraphs}>
              Cookies will not be used to contact you for any sales or marketing purposes.
            </Typography>
          </div>
        </Container>
        {exceptionClickWrap && <ErrorAlert></ErrorAlert>}
      </div>
      <CookieMessage state={showCookieMessage} ></CookieMessage>
      <ClickWrap state={showClickWrap} handleClose={handleClickwrapClose}></ClickWrap>
      <SessiontTmeout state={showSessionTimeoutModel}></SessiontTmeout>
    </TermsLayout>
    </React.Fragment>
  );
};

export default CookiePage;
