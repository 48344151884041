import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { get } from '../utils';

export type Product = {
  uuid: string;
  name: string;
  filterkey:string;
  description:string;
  status :string;
  groups:string[];
};
export type Group = {
  uuid: string;
  name: string;
  description:string;
  status :string;
  soon:boolean;
  thumbnail:string;
};
export type ProductsCollection = {
  [key: string]: Product;
};
export type GroupsCollection = {
  [key: string]: Group;
};

export type GetProductsResponse = {
  data: {
    groups: GroupsCollection;
    products: ProductsCollection;
  }
  error?:any;
};

export interface ProductsState {
  products: ProductsCollection;
  groups:GroupsCollection;
  loading: boolean;
  filteredProducts: string;
  exception:boolean;
  currentDomain:string;
}

type FetchApplicationError = {
  status: number;
  message: string
};
const initialState: ProductsState = {
  products:{},
  groups:{},
  loading: false,
  filteredProducts:'',
  exception: false,
  currentDomain:''
};

export const fetchProductsData = createAsyncThunk<
GetProductsResponse,
  string,
  {
    rejectValue: FetchApplicationError
  }
>(
  'products/fetchProdcuts',
  async (string,thunkApi) => {
    try {
      const response = await get(`/bff/products`);
     return response as GetProductsResponse
    }
    catch (error: any) {
      return thunkApi.rejectWithValue({
        status: error.status,
        message: error.message
      })
    }
  }
);

export const ProductsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    filterProducts: (state, action: PayloadAction<string>) => {
      const filterDomain = action.payload;
      state.filteredProducts = filterDomain;
    },
    clearProducts: (state ) => {
      state.filteredProducts = '';
    },
    setCurrentDomain: (state, action: PayloadAction<string>) => {
      const filterDomain = action.payload;
      state.currentDomain = filterDomain;
    },
   },
  extraReducers: (builder) => {
    builder.addCase(fetchProductsData.fulfilled, (state, { payload }) => {
      state.loading = false;
      if(payload && payload?.error && payload?.error !== undefined  ){
        state.products = {};
        state.groups = {};
        state.exception = true;
      }else{
        state.products = payload?.data?.products;
        state.groups = payload?.data?.groups;
        state.exception = false;
      }  
    });
    builder.addCase(fetchProductsData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchProductsData.rejected, (state) => {
      state.products = {};
      state.groups = {};
      state.loading = false;
    });
  }
});

export const { actions: productActions, reducer: productsReducer } = ProductsSlice;


export const { filterProducts, clearProducts, setCurrentDomain } = productActions;