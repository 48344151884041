import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import { CommonLayout } from '../../components/layout';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Outlet, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { useCookieContext } from '../../CookieContext';
import { useUserContext } from '../../UserContext';
import CookieMessage from '../../components/cookiemessage';
import ClickWrap from '../../components/clickwrap';
import LinearProgress from '@material-ui/core/LinearProgress';
import ErrorAlert from '../../components/erroralert';
import SessiontTmeout  from '../../components/sessiontimeout';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullContainer: {
      minHeight: '100vh',
      marginTop:120
    },
    container: {},
    navContainer: {
      padding: theme.spacing(2),
      position:'fixed',
      [theme.breakpoints.down('sm')]: {
        position: 'initial'
      },
    },
    navlinks: {
      fontWeight: 400,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    link: {
      textDecoration: 'none',
      color: 'white',
    },
    active: {
      fontWeight: 'bold',
    },
  }),
);

const ResourcesPage: FC = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const cookieDetails = useCookieContext();
  
  //@ts-ignore
  const { showCookieMessage } = cookieDetails;
  const userdetails = useUserContext();
  // @ts-ignore
  const { showClickWrap, exceptionClickWrap, loadingClickWrap, acceptClickWrapdetails,showSessionTimeoutModel } = userdetails;
  const handleClickwrapClose = () => {
    acceptClickWrapdetails();
  };
  return (
    <React.Fragment>
       {loadingClickWrap && <LinearProgress />}
    <CommonLayout>
      <div className={classes.fullContainer}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item sm={2} className={classes.navContainer}>
              <Typography variant="body1" className={classes.navlinks}>
                <Link
                  to="/resources/releases-and-maintanence"
                  className={clsx(
                    classes.link,
                    pathname === '/resources/releases-and-maintanence' ? classes.active : '',
                  )}
                >
                  Releases & Maintenance
                </Link>
              </Typography>
              <Typography variant="body1" className={classes.navlinks}>
                <Link
                  to="/resources/faqs"
                  className={clsx(
                    classes.link,
                    pathname === '/resources/faqs' ? classes.active : '',
                  )}
                >
                  FAQs
                </Link>
              </Typography>
            </Grid>
            <Grid item sm={2} ></Grid>
            <Grid item sm={10}>
              <Outlet />
            </Grid>
          </Grid>
        </Container>
        {exceptionClickWrap && <ErrorAlert></ErrorAlert>}
      </div>
      <CookieMessage state={showCookieMessage} ></CookieMessage>
      <ClickWrap state={showClickWrap} handleClose={handleClickwrapClose}></ClickWrap>
      <SessiontTmeout state={showSessionTimeoutModel}></SessiontTmeout>
    </CommonLayout>
    </React.Fragment>
  );
};

export default ResourcesPage;
