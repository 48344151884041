import { Box, Container } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useUserContext } from '../../UserContext';
import { fetchAccountData } from '../../store/accountSlice';
import { fetchApplicationsData } from '../../store/applicationsSlice';
import MetricsList from '../../components/metrcisList';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CookieMessage from '../../components/cookiemessage';
import ClickWrap from '../../components/clickwrap';
import SessiontTmeout  from '../../components/sessiontimeout';
import { useCookieContext } from '../../CookieContext';
import ErrorAlert from '../../components/erroralert';
import { useLocation } from 'react-router';	
import { useNavigate } from 'react-router-dom';
import { PRODUCTION_HOST_NAME_LIST } from '../../constants/constants';
import { checkUserPermission } from '../../core/authorization/service/authorizationService';
import { useTypedSelector } from '../../store';
import { getState } from '../../selectors/selectors';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accountPage: {
      minHeight: '100vh',
      paddingBottom: theme.spacing(10),
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
    pageTitle: {
      color: 'white',
      letterSpacing: '0.0625rem',
      fontWeight: 300,
      marginTop: theme.spacing(5),
      borderBottom:'1px solid',
      paddingBottom:20,
      [theme.breakpoints.down('md')]: {
        paddingBottom:10,
      },
    },
    container: {
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    }
  }));

const MetricsPage: React.FC<{}> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cookieDetails = useCookieContext();
  const location = useLocation();
  const querySearched = location.search || '';
  const currentHostName = window.location.hostname; 
  const params = new URLSearchParams(querySearched);
  const sponsorCode = params.get('sponsorCode') ||'';
  const { userDetails } = useTypedSelector(state => getState(state));
  const hasPermission = checkUserPermission(userDetails,'viewMetrics'); 
  const navigate = useNavigate();

	//@ts-ignore 
	const { showCookieMessage } = cookieDetails;
  const {
    isAuthenticated,
    showClickWrap,
    exceptionClickWrap,
    acceptClickWrapdetails,
    showSessionTimeoutModel
  } = useUserContext();

  const handleClickwrapClose = () => {
    acceptClickWrapdetails();
  };
  const fetchData = useCallback(() => {
    const ApplicationsParamters = {
      page:1,
      pageSize:500
    };
    if(isAuthenticated === true){
      if (PRODUCTION_HOST_NAME_LIST.indexOf (currentHostName) >-1) {
        //if production urls
        dispatch(fetchApplicationsData({...ApplicationsParamters,sponsorCode:''}));
      }else{
        dispatch(fetchApplicationsData({...ApplicationsParamters,sponsorCode:sponsorCode}));
      }
     
    }
    if (isAuthenticated === true && userDetails.roles === undefined) {
      
      if (PRODUCTION_HOST_NAME_LIST.indexOf (currentHostName) >-1) {
        //if production urls
        dispatch(fetchAccountData(''));
      }else{
        if(querySearched && querySearched.includes("sponsorCode")){
          dispatch(fetchAccountData(querySearched));
        }else{
          dispatch(fetchAccountData(''));
        }
      }
    }
  }, [dispatch, isAuthenticated,querySearched,currentHostName,userDetails,sponsorCode]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);
  useEffect(() => {
    if(isAuthenticated === true && userDetails.roles && userDetails.roles.length > 0){
     
      if(hasPermission !== true){
        
        navigate('/account');
      }
    }  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPermission,navigate,isAuthenticated,userDetails]);

  useEffect(() => {
    if(localStorage.getItem("currenteEventId") !==''){
      localStorage.setItem("currenteEventId","");
    }  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const renderMerics = ()=>{
    return (
      <React.Fragment>
          <Box data-testid='Account Page' className={classes.accountPage}>
          <Container maxWidth='md' className={classes.container}>
            <Typography variant="h4" className={classes.pageTitle}>
             Metrics
            </Typography>
            <MetricsList/>
          </Container>
        </Box>
        <CookieMessage state={showCookieMessage} ></CookieMessage>
          <ClickWrap state={showClickWrap} handleClose={handleClickwrapClose}></ClickWrap>
          <SessiontTmeout state={showSessionTimeoutModel}></SessiontTmeout>
          {exceptionClickWrap && <ErrorAlert></ErrorAlert>}
      </React.Fragment>
    );
  }
  return renderMerics(); 
};

export default MetricsPage;
