import React, { useState, useEffect, useCallback, createContext } from 'react';
import { OktaAuth } from '@okta/okta-auth-js';
import oktaConfig from './config';
import { endAndStartTimer, clearTimers } from './utils/utils';
import { REACT_APP_OKTA_DEFAULT_SESSION_TIMEOUT, PRODUCTION_HOST_NAME_LIST } from './constants/constants';
import { get, post } from './utils';
import { login, logout, UserDetails, fetchAccountData } from './store/accountSlice';
import { useTypedSelector } from './store';
import { useDispatch } from 'react-redux';
import { useIdleTimer } from "react-idle-timer";

export interface IUserContext {
  isAuthenticated: boolean;
  userDetails: UserDetails;
  authUser: boolean;
  setAuthUser: React.Dispatch<React.SetStateAction<boolean>>;
  tokenDetails: {} | undefined;
  setTokenDetails: React.Dispatch<React.SetStateAction<{}>>;
  showClickWrap: boolean;
  setShowClickWrap: React.Dispatch<React.SetStateAction<boolean>>;
  acceptClickWrapdetails: () => Promise<void>;
  loadingClickWrap: boolean;
  setLoadingClickWrap: React.Dispatch<React.SetStateAction<boolean>>;
  setexceptionClickWrap: React.Dispatch<React.SetStateAction<boolean>>;
  exceptionClickWrap: boolean;
  showSessionTimeoutModel: boolean;
  setShowSessionTimeoutModel: React.Dispatch<React.SetStateAction<boolean>>;
  displaysessionTimeOut: number;
  setdisplaysessionTimeOut: React.Dispatch<React.SetStateAction<number>>;
  handleLogout: () => void;
  reactivateTimer: () => void;
  pauseTimer: () => void;
  startTimer: () => void;
}

const UserContext = createContext<IUserContext>({} as IUserContext);


const oktaAuth = new OktaAuth(oktaConfig.oidc);

const UserContextProvider = ({ children }: { children: any }) => {
  const isuserauthenticated = 'is-user-authenticated';
  const currentHostName = window.location.hostname;  
  const location = window.location;
  const dispatch = useDispatch();
  const [authUser, setAuthUser] = useState(false);
  const [tokenDetails, setTokenDetails] = useState({});
  const [showClickWrap, setShowClickWrap] = useState<boolean>(false);
  const [loadingClickWrap, setLoadingClickWrap] = useState<boolean>(false);
  const [exceptionClickWrap, setexceptionClickWrap] = useState<boolean>(false);
  const [showSessionTimeoutModel, setShowSessionTimeoutModel] = useState<boolean>(false);
  const [ timerActive, setTimerActive ] = useState<boolean>(false);
  const [displaysessionTimeOut, setdisplaysessionTimeOut] = useState<number>(
    REACT_APP_OKTA_DEFAULT_SESSION_TIMEOUT,
  );

  const {
    isAuthenticated,
    userDetails
  } = useTypedSelector(state =>state.account);

  const getClickWrap = useCallback(async () => {
    setLoadingClickWrap(true);
    try {
      const token = await oktaAuth.token.getWithoutPrompt();
      const session = await oktaAuth.session.exists();
      console.log(token);
      if (token !== null && session === true) {
        let jwtVal: any = '';
        jwtVal = token.tokens.accessToken?.accessToken;
        localStorage.setItem('jwt', jwtVal);
      }
    }
    catch (e) {
      console.log({ e });
    }
    const clickwrapDetails = await get(
      `/bff-private/click-wraps?tncId=c10a2bd2-26b3-4ff7-8340-c75383b87993`,
    );
    if (clickwrapDetails.error !== undefined) {
      if (clickwrapDetails.error.status === 404) {
        setLoadingClickWrap(false);
      }
    } else {
      if (clickwrapDetails.data) {
        setShowClickWrap(clickwrapDetails.data.displayClickWrap);
        setLoadingClickWrap(false);
      }
    }
  }, []);
  const acceptClickWrapdetails = async () => {
    setLoadingClickWrap(true);
    const clickwrapDetails = await post(
      `/bff-private/terms-conditions/c10a2bd2-26b3-4ff7-8340-c75383b87993/click-wraps`,
      {
        tncId: 'c10a2bd2-26b3-4ff7-8340-c75383b87993',
        tncVersion: '1',
      },
    );
    if (clickwrapDetails.error !== undefined) {
      if (
        clickwrapDetails.error.status === 404 || clickwrapDetails.error.status === 403 || clickwrapDetails.error.status === 500
      ) {
        setLoadingClickWrap(false);
        setShowClickWrap(false);
        setexceptionClickWrap(true);
      }
    } else {
      if (clickwrapDetails) {
        setShowClickWrap(false);
        setLoadingClickWrap(false);
        setexceptionClickWrap(false);
      }
    }
  };
  const handleLogout = async () => {
    setAuthUser(false);
    clearTimers();
    localStorage.setItem('apo_redirect_url', '');
    await oktaAuth.signOut();
    localStorage.setItem(isuserauthenticated, '');
    setShowSessionTimeoutModel(false);
    setLoadingClickWrap(false);
    setexceptionClickWrap(false);
    localStorage.setItem('jwt', '');
    dispatch(logout());

  };
  const reNewToken = async () => {
      //@ts-ignore
      const accessToken =JSON.parse(localStorage.getItem('accessToken'));
      //@ts-ignore
      if(accessToken){
        const token = await oktaAuth.token.renew(accessToken);
          if (token !== null) {
            const jwt = JSON.stringify(token);
            const parsed = JSON.parse(jwt);
            const parsedJwt = parsed['accessToken'];
             //@ts-ignore
            setTokenDetails(token);
            setAuthUser(true);
             //@ts-ignore
            localStorage.setItem('accessToken',JSON.stringify(token));
            localStorage.setItem(isuserauthenticated, 'true');
            localStorage.setItem('jwt', parsedJwt);

          } else {
            setAuthUser(false);
            localStorage.setItem('jwt', '');
          }
      }
      
      
  };
  const setTimers = (interval: any) => {
    endAndStartTimer('displaysessionTimer', interval, reNewToken);
  };
  const checkuserAuthenticated = async () => {
    try {
      const token = await oktaAuth.token.getWithoutPrompt();
      const session = await oktaAuth.session.exists();
      console.log({
        token,
        session
      });
      if (token !== null && session === true) {
        startTimer();
        console.log(new Date());
        dispatch(login(token));
        setTokenDetails(token);
        setAuthUser(true);
        localStorage.setItem(isuserauthenticated, 'true');
        localStorage.setItem('accessToken',JSON.stringify(token.tokens.accessToken));
        let jwt: any = '';
        jwt = token.tokens.accessToken?.accessToken;
        localStorage.setItem('jwt', jwt);
        if (PRODUCTION_HOST_NAME_LIST.indexOf (currentHostName) >-1) {
          dispatch(fetchAccountData(''));
        }else{
          if(location.search && location.search.includes("sponsorCode")){
            dispatch(fetchAccountData(location.search));
          }else{
            dispatch(fetchAccountData(''));
          }
        }
        setTimers(1000*60*10);
      } else {
        setAuthUser(false);
        dispatch(logout());
        localStorage.setItem('jwt', '');
      }
    } catch (e) {
      console.log({ e });
    }
  };
  useEffect(() => {
    oktaAuth.start();
    checkuserAuthenticated();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (Object.keys(tokenDetails).length > 0) {
      getClickWrap();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenDetails, getClickWrap]);
  //@ts-ignore
  const onPrompt = () => {
    console.log("on prompt called");
    console.log(new Date());
    setShowSessionTimeoutModel(true);
  }

  const onIdle = () => {
    console.log("on idle called");
    console.log(new Date());
    setShowSessionTimeoutModel(false);
    handleLogout();
  }

  const {
    start: startTimer,
    activate: reactivateTimer,
    pause: pauseTimer,
  } = useIdleTimer({
    onPrompt,
    onIdle,
    timeout: 1000 * 60 * 10,
    promptTimeout: ((1000 * 60 * 5)-20000),
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange'
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: false,
    startManually: true,
    stopOnIdle: true,
    crossTab: false,
    name: 'idle-timer',
    syncTimers: 0,
    leaderElection: false
  });

  useEffect(() => {
    if (isAuthenticated) {
      if (timerActive) {
        reactivateTimer();
      }
      else {
        setTimerActive(true);
        startTimer();
      }
    } else {
      pauseTimer();
    }
  }, [
    isAuthenticated,
    timerActive,
    reactivateTimer,
    setTimerActive,
    startTimer,
    pauseTimer
  ]);

  const context: IUserContext = {
    isAuthenticated,
    userDetails,
    authUser,
    setAuthUser,
    tokenDetails,
    setTokenDetails,
    showClickWrap,
    setShowClickWrap,
    acceptClickWrapdetails,
    loadingClickWrap,
    setLoadingClickWrap,
    setexceptionClickWrap,
    exceptionClickWrap,
    showSessionTimeoutModel,
    setShowSessionTimeoutModel,
    displaysessionTimeOut,
    setdisplaysessionTimeOut,
    handleLogout,
    reactivateTimer,
    pauseTimer,
    startTimer,
  };

  return (
    <UserContext.Provider value={context}>
      {children}
    </UserContext.Provider>
  );
};
const useUserContext = () => {
  return React.useContext(UserContext);
};
export { UserContextProvider, useUserContext, UserContext };
