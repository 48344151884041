import React, { FC } from 'react';
import Card from '@material-ui/core/Card';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    journey: {
      minWidth: 298,
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
    card: {
      padding: theme.spacing(3),
      background: 'rgba(255, 255, 255, 0.07)',
      borderRadius: '10px',
      // transform: 'matrix(1, 0, 0, -1, 0, 0)',
      // height: '325px',
      position: 'relative',
      flexShrink: 0,
    },
    journeyBtn: {
      alignSelf: 'flex-end',
      marginTop: theme.spacing(4),
    },
    journeyTop: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      height: 200,
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },

    titleTxt: {
      color: 'white',
      fontWeight: 500,
      width: '51%',
      textDecoration: 'none',
    },
    rule: {
      backgroundColor: '#05a8cc',
      height: 4,
      width: '30%',
      marginTop: theme.spacing(2),
    },
    arrow: {
      width: 90,
      height: 90,
      position: 'absolute',
      bottom: 0,
      right: 0,
      border: '4px solid #00C4B4',
      marginBottom: '-20px',
      marginRight: '-20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        width: 60,
        height: 60,
      },
    },
    link: {
      textDecoration: 'none',
    },
  }),
);

interface Props {
  title: string;
  to: string;
}

const PolicyCard: FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.journey}>
      <Link to={props.to} className={classes.link}>
        <Card className={classes.card} raised>
          <div className={classes.journeyTop}>
            <Typography variant="h5" className={classes.titleTxt}>
              {props.title}
            </Typography>
            <div className={classes.rule} />
          </div>
        </Card>
        <div className={classes.arrow}>
          <ChevronRightIcon color="primary" fontSize="large" />
        </div>
      </Link>
    </div>
  );
};

export default PolicyCard;
