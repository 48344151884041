import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Header from '../../header';
import Footer from '../../footer';
import bgImage from '../../../assets/topbg.jpg';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: '100vh',
      backgroundImage:
        'linear-gradient(180deg, rgba(12,26,41,0.9948354341736695) 33%, rgba(12,26,41,0.9948354341736695) 97%)',
      position: 'relative',
    },
    topbg: {
      height: 630,
      backgroundImage: `url(${bgImage})`,
      mixBlendMode: 'overlay',
      backgroundRepeat: 'no-repeat',
      position: 'absolute',
      top: 0,
      right: 0,
      backgroundPosition: 'right',
      [theme.breakpoints.up('md')]: {
        backgroundSize: '100%',
      },
      width: '100%',
    },
  }),
);

interface Props {
  children?: React.ReactElement<any, string> | React.ReactElement[];
}

const BaseLayout: FC<Props> = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Header />
      <div className={classes.topbg}></div>
      {children}
      <Footer />
    </div>
  );
};
export default BaseLayout;
