import React, { FC, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import Dotnet from '../../assets/languages/dotnet.png';

import Go from '../../assets/languages/go.png';
import Java from '../../assets/languages/java.png';
import Node from '../../assets/languages/node.png';
import Python from '../../assets/languages/python.png';
import Ruby from '../../assets/languages/ruby.png';
import Php from '../../assets/languages/php.png';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    logoHolder: {
      height: 60,
      width: 60,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: {
      height: 40,
      width: 40,
      objectFit: 'contain',
      objectPosition: 'center',
    },
    logoHolderSmall: {
      height: 30,
      width: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    iconSmall: {
      height: 20,
      width: 20,
      objectFit: 'contain',
      objectPosition: 'center',
    },
    name: {
      fontWeight: 400,
      fontSize: '1rem',
      color: 'white',
    },
    nameSmall: {
      fontWeight: 400,
      fontSize: '.8rem',
      color: 'white',
    },
  }),
);

type Props = {
  size?: 'small' | 'large';
  onPress?: (l: string) => void;
  clickable?: boolean;
};

const Languages: FC<Props> = (props) => {
  const [languages] = useState([
    { id: 1, name: 'Ruby', image: Ruby, sdkName: 'ruby' },
    { id: 1, name: 'Python', image: Python, sdkName: 'python' },
    { id: 1, name: 'PHP', image: Php, sdkName: 'php' },
    { id: 1, name: 'Java', image: Java, sdkName: 'java' },
    { id: 1, name: 'Node.js', image: Node, sdkName: 'typescript-node' },
    { id: 1, name: 'Go', image: Go, sdkName: 'go' },
    { id: 1, name: '.NET', image: Dotnet, sdkName: 'csharp-dotnet2' },
  ]);
  const classes = useStyles();

  return (
    <Grid container spacing={1} justify="center">
      {languages.map((eachLanguage) => {
        return (
          <div key={eachLanguage.name}>
            <ButtonBase
              disabled={!props.clickable}
              className={classes.container}
              data-testid={eachLanguage.sdkName}
              data-cy={eachLanguage.sdkName}
              //@ts-ignore
              onClick={() => (props.clickable ? props.onPress(eachLanguage.sdkName) : null)}
            >
              <div
                className={props.size === 'small' ? classes.logoHolderSmall : classes.logoHolder}
              >
                <img
                  src={eachLanguage.image}
                  className={props.size === 'small' ? classes.iconSmall : classes.icon}
                  alt={eachLanguage.name}
                 
                />
              </div>

              <Typography className={props.size === 'small' ? classes.nameSmall : classes.name}>
                {eachLanguage.name}
              </Typography>
            </ButtonBase>
          </div>
        );
      })}
    </Grid>
  );
};

export default Languages;
