import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      paddingTop: theme.spacing(2),
      margin: theme.spacing(2),
      color: 'white',
    },
    bulletPointNumbering: {
      fontSize: '1rem',
      listStyleType: 'decimal',
    },
    bulletPointCharacters: {
      listStyleType: 'lower-latin',
    },
    paragraphs: {
      fontSize: '1rem',
      marginTop: theme.spacing(2),
    },
    boldparagraphs: {
      fontSize: '1rem',
      marginTop: theme.spacing(2),
      fontWeight: 'bold',
    },
    hyperLink: {
      color: '#00C4B4',
    },
  }),
);

const TermsContent: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.contentContainer}>
      <Typography variant="body2" className={classes.boldparagraphs}>
        IMPORTANT NOTICE: THESE TERMS INCLUDE AN AGREEMENT TO MANDATORY ARBITRATION, WHICH MEANS
        THAT YOU AGREE TO SUBMIT ANY DISPUTE RELATED TO YOUR (OR YOUR ORGANIZATION’S) USE OF THE
        PLATFORM, INCLUDING NORTHERN’S APIS, TO BINDING ARBITRATION RATHER THAN PROCEED IN COURT.
        THESE TERMS ALSO INCLUDE A JURY WAIVER.
      </Typography>
      <Typography variant="body2" className={classes.paragraphs}>
        Here are the Northern Trust <b>API Store</b> Terms and Conditions (these "
        <b>Terms and Conditions</b>") that form and constitute a binding agreement between you and
        the organization you represent (“<b>Organization</b>” or “<b>you</b>”) and The Northern
        Trust Company and its affiliates or subsidiaries, including, but not limited to, Northern
        Trust Holding and Northern Trust Global Services SE (each herein called "<b>Northern</b>").
        These Terms and Conditions govern Organization’s access and use of the Northern{' '}
        <b>API Store</b> web site ("<b>Web Site</b>"), and the certain application programming
        interfaces, software development kits, and their associated software, tools and
        documentation (and any changes, updates, and modifications thereto and content, data, and
        information available therein) (“<b>APIs</b>”) available on or through the Web Site
        (collectively, the “<b>Platform</b>”). By accessing the Platform, including any or all
        components thereof, Organization agrees to be bound by these Terms and Conditions.
      </Typography>
      <Typography variant="h5" className={classes.boldparagraphs}>
        <i>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY.</i>
      </Typography>
      <ul className={classes.bulletPointNumbering}>
        <li>
          <Box p={1} fontWeight="bold">
            Parties; Binding Agreement
          </Box>
          <ul className={classes.bulletPointCharacters}>
            <li>
              <Box p={1}>
                If you are accessing the Platform on behalf of your employer, you warrant that you
                have authority to bind your employer, as “Organization” to these Terms and
                Conditions; you have read and understand these Terms and Conditions; and on behalf
                of the Organization that you represent, you agree to these Terms and Conditions.
                Organization may access the pages of the Web Site and make use of the APIs offered
                through the Web Site only if Organization accepts and agrees to these Terms and
                Conditions.
              </Box>
            </li>
            <li>
              <Box p={1}>
                <b>
                  If you do not have the legal authority to bind Organization to this Agreement,
                  please do not use the Platform. If Organization does not wish to accept these
                  Terms and Conditions, you should simply exit this Web Site.
                </b>
              </Box>
            </li>
            <li>
              <Box p={1}>
                These Terms and Conditions and the services accessible through the Platform
                (including the APIs) may be changed from time to time by Northern at its discretion.
                Northern will post any such changes to these Terms and Conditions in an updated
                version of these Terms and Conditions to the Web Site. All revisions are effective
                when posted on the Web Site and shall apply to all access and use of the Platform
                thereafter. You and Organization are responsible for regularly reviewing these Terms
                and Conditions. You can view the current version of the Terms and Conditions at any
                time via a link on the Web Site. YOUR CONTINUED USE OF THE PLATFORM AFTER THE
                EFFECTIVE DATE OF ANY POSTED REVISIONS, WILL CONSTITUTE AN ON-GOING ACCEPTANCE OF
                AND AGREEMENT BY ORGANIZATION TO BE BOUND BY ALL THE TERMS AND CONDITIONS HEREIN, AS
                SO AMENDED. IF AT ANY TIME ORGANIZATION DOES NOT WISH TO CONTINUE TO ACCEPT THESE
                TERMS AND CONDITIONS, ORGANIZATION SHOULD CEASE USING OR ACCESSING THE PLATFORM,
                INCLUDING ANY APIS.
              </Box>
            </li>
          </ul>
        </li>
        <li>
          <Box p={1} fontWeight="bold">
            Access; Security; Privacy
          </Box>
          <ul className={classes.bulletPointCharacters}>
            <li>
              <Box p={1}>
                In order to access the APIs offered through the Platform, you must login by entering
                your user ID, name, email, phone number, organization information, password, and any
                other information requested upon login. Organization agrees that the foregoing
                access procedure is reasonable and accepts such access procedure. Organization will
                be fully responsible for maintaining the confidentiality of, and to protect against
                the unauthorized use of any passwords, identification information, and similar
                security information of Organization, and Organization will establish and maintain
                such procedures as necessary and appropriate to protect the same, including by way
                of example, storing information on a secure server with controlled access, firewall
                and intrusion detection, network monitoring and other reasonable requirements.
              </Box>
            </li>
            <li>
              <Box p={1}>
                Organization shall not sell, transfer, sublicense or otherwise disclose its login
                information to third parties. Organization is solely responsible for all
                communications, acts, or omissions that occur through the use of Organization’s
                login information by Organization or any person or entity using Organization’s login
                information, whether or not such access or use has been authorized by Organization.
                Organization hereby acknowledges and consents to Northern’s reliance on the fact
                that any person using the foregoing access procedure is authorized by Organization
                to do so and has been informed of Northern’s processing of the person’s personal
                information associated with access to the website as described in Northern’s Privacy
                Policy located at
                <a className={classes.hyperLink} href="/global-privacy-standards">
                  {' '}
                  API Store privacy policy
                </a>
                , and that person may access the services offered through the Platform on behalf of
                Organization. If Organization installs or enables any software or service that
                stores information from the Platform on any mobile device or computer, it is
                Organization’s responsibility, prior to disposal or transfer of such device, to
                remove that information and otherwise disable access to such software or service, in
                order to prevent unauthorized access to Organization’s information or account. If
                Organization becomes aware of any suspicious or unauthorized conduct concerning its
                information, accounts, identification information, logins, passwords, or security
                challenge questions and answers, Organization agrees to contact Northern
                immediately, at the email address listed in Section 17 (Miscellaneous) below.
                NORTHERN WILL NOT BE LIABLE OR RESPONSIBLE FOR ANY LOSS OR DAMAGE ARISING FROM
                ORGANIZATION’S FAILURE TO COMPLY WITH THIS PARAGRAPH.{' '}
              </Box>
            </li>
            <li>
              <Box p={1}>
                {' '}
                Depending on where you are accessing the API Store from, Northern may use certain
                cookies to gather certain information regarding your use of the website and APIs.
                When you visit any website, it may store or retrieve information on your browser,
                mostly in the form of cookies. This information might be about you, your preferences
                or your device and is mostly used to make the site work as you expect it to. The
                information does not usually directly identify you, but it can give you a more
                personalised web experience. Because we respect your right to privacy, you can
                choose not to allow some types of cookies. However, blocking some types of cookies
                may impact your experience of the site and the services we are able to offer. For
                further information about Northern's Cookie Policy, see Northern's Cookie Policy
                located{' '}
                <a className={classes.hyperLink} href="/cookie-policy ">
                  {' '}
                  here
                </a>
                .
              </Box>
            </li>
          </ul>
        </li>
        <li>
          <Box p={1} fontWeight="bold">
            Termination; Suspension
          </Box>
          <ul className={classes.bulletPointCharacters}>
            <li>
              <Box p={1}>
                These Terms and Conditions are effective until terminated. Northern may terminate
                these Terms and Conditions, for any or no reason at any time, with or without notice
                to you, by terminating your account or otherwise discontinuing the Web Site(s), the
                Platform, or any APIs available on the Platform. Organization shall promptly notify
                Northern if any user is no longer entitled to (or requires) access the API Store
                website (e.g., if such user is no longer employed by Organization). Upon any
                termination of these Terms and Conditions, all rights and licenses granted to
                Organization hereunder shall immediately cease and all such rights to use the APIs
                shall revert to Northern, Organization shall, and shall cause its employees and end
                users to, immediately cease using the APIs and Platform, destroy any copies, cancel
                login credentials, deactivate access to and cease accessing the APIs, and return all
                Northern materials and Confidential Information to Northern.
              </Box>
            </li>
            <li>
              <Box p={1}>
                Northern may temporarily suspend Organization’s access to the Platform (or any part
                thereof) at any time and for any reason. Developer may contact Northern for
                information regarding any suspension of access. Northern may permanently suspend or
                terminate Organization or an end user’s access to the Platform if Organization or
                the end user is unable or unwilling to comply with Northern’s security procedures or
                breaches these Terms and Conditions. Northern may notify Organization of any end
                users whose access have been permanently suspended or terminated.
              </Box>
            </li>
            <li>
              <Box p={1}>
                NORTHERN MAY REMOVE ANY MATERIAL STORED ON THE PLATFORM. IN THE EVENT THAT YOUR
                ACCESS TO THE PLATFORM IS TERMINATED, SUSPENDED, CANCELLED, OR REMOVED, YOU WILL NOT
                HAVE ANY FURTHER ACCESS. YOU AGREE THAT NORTHERN SHALL NOT BE LIABLE FOR ANY LOSS OR
                DAMAGE CAUSED, DIRECTLY OR INDIRECTLY, BY ANY SUCH TERMINATION, SUSPENSION, CHANGE,
                DISCONTINUANCE, OR REMOVAL.
              </Box>
            </li>
          </ul>
        </li>
        <li>
          <Box p={1} fontWeight="bold">
            Platform and API License Grant; Restrictions
          </Box>
          <ul className={classes.bulletPointCharacters}>
            <li>
              <Box p={1}>
                Subject to and conditioned on your compliance with these Terms and Conditions,
                Northern hereby grants to Organization a limited, revocable, nonexclusive,
                non-assignable, non-sublicensable license and right to access the Web Site, through
                a generally available web browser solely for the proper purposes set forth in these
                Terms and Conditions.
              </Box>
            </li>
            <li>
              <Box p={1}>
                Subject to and conditioned on your compliance with these Terms and Conditions and
                execution of and compliance with a separate license agreement between Organization
                and Northern (the “<b>API License Agreement</b>”), Northern hereby grants to
                Organization a limited, revocable, nonexclusive, non-assignable, non-sublicensable
                license and right to run, download, access, configure, and load onto Organization’s
                systems the applicable APIs available on the Platform for purposes of integrating
                such API into various Organization Applications (as defined below). Organization
                shall only access (or attempt to access) the APIs by the means described in the
                documentation for the APIs provided by or specified by Northern. Organization will
                not misrepresent or mask its identity when using the Platform.
              </Box>
            </li>
            <li>
              <Box p={1}>
                Except as expressly authorized under these Terms and Conditions, Organization shall
                not (and shall not permit or cause others to): (i) access, use, modify, copy,
                duplicate, reproduce, transmit, or create derivative works of the APIs or Platform;
                (ii) disclose, permit or assist any third party to access the APIs or Platform, or
                allow or cause any information transmitted from Northern’s databases available
                through use of the Platform to be published, redistributed or retransmitted, without
                Northern’s prior express written consent; (iii) license, sublicense, sell, transfer,
                assign, rent, lease, or distribute the APIs or Platform to third parties; (iv) host
                the APIs or Platform for the benefit of unaffiliated third parties in a service
                bureau or timesharing mode; (v) attack, defeat or work around any technical security
                restrictions designed to prevent unauthorized use of the APIs or Platform, or
                disassemble, decompile or otherwise reverse engineer the APIs or Platform, or
                attempt to do any of the foregoing or to derive any of the source code for the APIs
                or Platform, in whole or in part; (vi) use the APIs or Platform in a manner, as
                determined by Northern in its sole discretion, that exceeds reasonable request
                volume, constitutes excessive or abusive usage, or otherwise fails to comply or is
                inconsistent with any part of the Northern’s associated documentation; (vii)
                interfere with or disrupt the APIs, the Platform, the servers or networks providing
                the APIs or Platform, or any other party’s use of the Platform, including without
                limitation by knowingly transmitting or uploading to the Platform any item
                containing or embodying any virus, worm, defect, trojan horse, software bomb, or
                other harmful or malicious code or feature that does damage to or could interfere
                with, or degrade the Platform or the IT infrastructure used to operate the Platform;
                (viii) perform, or attempt to perform, any unauthorized actions that could
                materially and adversely interfere with the proper working of the APIs or Platform;
                (ix) upload or otherwise transmit on or through the APIs or Platform content that is
                subject to any third-party rights unless any holder of such rights has given express
                written authorization for distribution on the API or Platform; or (x) use the
                Platform or APIs in any manner that is unlawful.
              </Box>
            </li>
            <li>
              <Box p={1}>
                Furthermore, Organization shall not alter or override the intended functionality of
                any API, including as such APIs are incorporated into an Organization Application
                (e.g., if a particular API solely permits read-only access, Organization shall only
                permit read-only access to that API via the Organization Application). Organization
                shall only use the APIs for Organization’s internal purposes, unless Organization
                has obtained Northern’s prior written consent. Northern shall have no obligation to
                update the Platform or APIs. Organization acknowledges and agrees that Northern may
                update or modify the Platform or APIs from time to time at Northern’s sole
                discretion, and may require Organization to access and use the most recent version
                of an API. As such, Organization may be required to make changes to the Organization
                Applications for which the APIs are used to enhance, as well as obtain additional
                authorizations from applicable third parties, at Organization’s sole cost and
                expense.
              </Box>
            </li>
          </ul>
        </li>
        <li>
          <Box p={1} fontWeight="bold">
            Intellectual Property and Proprietary Information
          </Box>
          <ul className={classes.bulletPointCharacters}>
            <li>
              <Box p={1}>
                As between the Parties, the APIs and Platform, including content on the Platform,
                including but not limited to the screen formats, interactive design techniques, text
                and images and their arrangement, information, databases, report formats, formulae,
                software, systems, processes, know-how, programs, algorithms, methods, training
                guides, documentation and other information made available to you by Northern as
                part of the Platform and through the use of the Platform, and all copyrights, trade
                secrets, patents, trademarks and other intellectual property or proprietary rights
                of Northern or the Northern Trust Corporation, the parent company of The Northern
                Trust Company (“
                <b>Material</b>") is exclusively owned by Northern, Northern shall retain all right,
                title and interest therein. Such items may be protected by U.S. and international
                intellectual property or proprietary rights laws. All rights reserved. EXCEPT AS
                OTHERWISE SET FORTH IN THESE TERMS AND CONDITIONS OR AGREED TO BY NORTHERN IN AN
                EXPRESS SIGNED WRITING, YOU ACKNOWLEDGE AND AGREE THAT YOU HAVE NO INTELLECTUAL
                PROPERTY OR OTHER RIGHTS IN THE APIS, PLATFORM, OR ANY MATERIALS, REGARDLESS OF
                WHETHER SUCH ITEMS WERE PROVIDED BY NORTHERN, YOU, OR ANOTHER USER OF THE SERVICES.{' '}
              </Box>
            </li>
            <li>
              <Box p={1}>
                The APIs are designed to help Organization enhance and build out Organization’s
                applications (“<b>Organization Applications</b>”), for which Organization shall be
                financially and administratively responsible. Organization hereby agrees that
                Northern may monitor Organization’s (and its employees and users) use of the APIs to
                ensure quality, improve Northern’s products and services, and verify Organization’s
                (and its employees and users) compliance with these Terms and Conditions. Such
                monitoring includes Northern’s right to access and use the Organization Applications
                (e.g., to identify security issues that could impact Northern or its other clients)
                without interference from Organization. As between Northern and Organization,
                Organization is responsible for all acts and omissions of Organization’s employees
                and users in connection with the Organization Applications and their use of any
                APIs.{' '}
              </Box>
            </li>
            <li>
              <Box p={1}>
                Except as expressly permitted herein, the Material may not be copied, posted,
                displayed, published, transmitted or distribute in any way (whether electronically
                or otherwise), licensed, modified, downloaded, re-posted, reused, reproduced, sold,
                used to create other works based on it, or otherwise used for public or commercial
                purposes without the prior written consent of Northern. Copies made incidentally in
                the ordinary course of your use of the Platform (such as in cache memory) and a
                reasonable number of printed copies for Organization's own internal use are
                permitted, provided you do not delete or change any copyright, trademark or other
                notice. With respect to any information that is provided on or through the Platform
                by Organization, Organization hereby grants to Northern the non-exclusive,
                worldwide, royalty-free right and license to exploit any and all intellectual
                property rights in and to such information as is necessary for Northern to provide
                the Platform and any API services. You agree that any information that you provide
                on or through the Platform does not create an obligation for Northern to provide you
                any payment or other remuneration. Organization shall not upload or otherwise
                transmit on or through the Platform (including any API), any information that is
                subject to any third-party rights unless the holder of such rights has provided you
                expressed written authorization for distribution on or through the Platform.
                Furthermore Organization agrees that misappropriation or misuse of the Material on
                the Platform will cause serious damage to Northern or its affiliates or licensors
                and that money damages may not constitute sufficient compensation. Organization
                agrees that in the event of any misappropriation or misuse by Organization of the
                Material, including of the APIs or Platform, Northern, or its affiliates or
                licensors has the right to obtain injunctive relief in addition to any other
                remedies available to it under this Agreement, at law or in equity. The Anchor
                Design, Dream Makers' Forum, Intelligent Indexing, Interclear, Market Signals,
                Northern Anchor Account, Northern Funds, Northern Trust Passport, The Wealth
                Management Group, Trust/Rite, Vision Keepers, Northern Trust Matrix and variations
                thereon are registered service marks or trademarks of Northern Trust Corporation.
                Northern Trust Corporation may also claim service mark or trademark rights in other
                marks contained in these pages or the APIs. All trademarks, service marks, trade
                names and logos appearing on the Platform are the property of their respective
                owners.
              </Box>
            </li>
            <li>
              <Box p={1}>
                Nothing herein shall prevent Northern from developing and distributing its own
                modifications to the Platform or APIs based on Organization’s or its end users
                suggestions, requests, comments, or feedback (“<b>Feedback</b>”) or upon similar
                ideas or concepts developed by Northern. Organization acknowledges and agrees (and
                agrees on behalf of Organization’s employees and end users) that Northern may, and
                may permit Northern’s other licensees to, make, have made, use, have used,
                reproduce, license, distribute and otherwise commercialize the Feedback in the
                Platform, APIs or in other products, technologies or materials without the payment
                of any royalties or fees to Organization. All Feedback becomes the sole property of
                Northern and may be used in any manner Northern sees fit, and Organization hereby
                assigns (and assigns on behalf of Organization employees and end users) to Northern
                all right, title and interest in and to any Feedback. Northern has no obligation to
                respond to Feedback or to incorporate Feedback into the Platform or APIs.
              </Box>
            </li>
          </ul>
        </li>

        <li>
          <Box p={1} fontWeight="bold">
            Confidential Information
          </Box>
          <ul className={classes.bulletPointCharacters}>
            <li>
              <Box p={1}>
                The Platform described herein and the databases, computer programs, APIs, screen
                formats, report formats, processes, systems, software, formulae, programs, records,
                files, documentation, technical, operational, administrative, economic, business and
                other information or Material made available to Organization by Northern through the
                use of the Platform, excluding Organization's account information, and all
                copyrights, trademarks, and service marks related thereto are the exclusive,
                valuable and confidential property of Northern and its relevant licensors (“
                <b>Confidential Information</b>”). Organization agrees to strictly maintain the
                confidentiality of Northern's Confidential Information with at least as great a
                degree of care as it uses for its own Confidential Information, and at a minimum,
                Organization shall take necessary and reasonable precautions to prevent such
                information from being disclosed to any person, firm, or company not specifically
                permitted by these Terms and Conditions or specifically authorized in writing by
                Northern. Organization agrees not to sell, transfer, publish, disclose, display or
                otherwise make available to others, nor to use any Confidential Information except
                to carry out the purposes for which Northern disclosed the Confidential Information
                to Organization. The foregoing shall not apply to Confidential Information that is
                in the public domain or required by law to be made public.
              </Box>
            </li>
          </ul>
        </li>
        <li>
          <Box p={1} fontWeight="bold">
            Disclaimers
          </Box>
          <ul className={classes.bulletPointCharacters}>
            <li>
              <Box p={1}>
                NEITHER NORTHERN NOR ANY OF ITS AFFILIATES OR LICENSORS MAKES ANY REPRESENTATION
                ABOUT THE SUITABILITY OF THE APIS, THE CONTENT OF THE PLATFORM OR ANY RELATED
                OFFERINGS OR SERVICES FOR ANY PURPOSE. NORTHERN DOES NOT WARRANT THAT YOU WILL BE
                ABLE TO ACCESS OR USE THE PLATFORM (INCLUDING THE APIS) AT TIMES OR LOCATIONS OF
                YOUR CHOOSING. ORGANIZATION ACKNOWLEDGES AND AGREES THAT THE APIS, PLATFORM, AND ALL
                MATERIALS, CONTENT, OFFERINGS AND SERVICES ARE PROVIDED BY NORTHERN "AS IS" AND “AS
                AVAILABLE” WITHOUT ANY WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, AND
                NORTHERN, ITS AFFILIATES AND LICENSORS EXPRESSLY DISCLAIM ANY WARRANTIES AND
                REPRESENTATIONS WITH RESPECT THERETO, OR OTHERWISE WITH RESPECT TO THESE TERMS AND
                CONDITIONS, INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF MERCHANTABILITY,
                FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR NON-INFRINGEMENT, EXCEPT TO THE EXTENT
                THAT ANY WARRANTIES CANNOT BE VALIDLY WAIVED UNDER APPLICABLE LAW, AND NORTHERN, ITS
                AFFILIATES, AND LICENSORS FURTHER DISCLAIM ANY WARRANTY THAT THE PLATFORM, APIS, OR
                ANY AUTOMATED SYSTEMS (AS DEFINED BELOW), WILL BE ERROR FREE, ACCURATE, ADEQUATE,
                DEFECT FREE, VIRUS FREE, COMPLETE, TIMELY OR PROVIDED WITHOUT INTERRUPTION.
                ORGANIZATION AGREES THAT NORTHERN, ITS AFFILIATES, AND THE LICENSORS ARE NOT AND
                WILL NOT BE HELD BY ORGANIZATION TO BE IN ANY WAY LIABLE FOR ANY LOSSES, COSTS,
                DAMAGES OR LIABILITIES OF ANY KIND RESULTING FROM USE BY ORGANIZATION OR ITS END
                USERS OF THE APIS.
              </Box>
            </li>
            <li>
              <Box p={1}>
                WITHOUT LIMITING THE PROVISIONS OF SECTION 7(a) OR ANY OTHER DISCLAIMER OR
                LIMITATION OF LIABILITY IN THESE TERMS AND CONDITIONS, NORTHERN, ITS AFFILIATES, AND
                LICENSORS HEREBY EXPRESSLY DISCLAIM ANY WARRANTY THAT THE APIS OR ORGANIZATION
                APPLICATIONS BASED THEREON OR CONNECTED THERETO WILL RESULT IN ANY GAIN, PROFIT OR
                OTHER EFFECT OR OUTCOME FOR, WILL MITIGATE ANY RISKS OR POTENTIAL LIABILITIES OF, OR
                WILL RESULT IN OR LEAD TO THE REGULATORY OR LEGAL COMPLIANCE OF, ORGANIZATION OR ITS
                END USERS IN CONNECTION WITH ANY TRADE OR TRANSACTION FOR WHICH ANY PROPRIETARY
                RIGHTS ARE USED, AND NORTHERN, ITS AFFILIATES, AND LICENSORS HEREBY EXPRESSLY
                DISCLAIM, AND ORGANIZATION ACKNOWLEDGES AND AGREES THAT NORTHERN, ITS AFFILIATES,
                AND LICENSORS WILL NOT BE LIABLE FOR, ANY LOSSES INCURRED BY ORGANIZATION OR ANY
                THIRD PARTY WITH RESPECT TO ANY INVESTMENT, CONTRACT, TRADE, POSITION OR PRODUCT
                MADE THROUGH, UTILIZING, OR RELATED TO, THE APIS OR ORGANIZATION APPLICATIONS BASED
                THEREON OR CONNECTED THERETO.
              </Box>
            </li>
            <li>
              <Box p={1}>
                Northern has made reasonable efforts to ensure that the Platform (and any components
                thereof) is virus free but cannot guarantee or warrant that it is virus free.
                Northern and its affiliates expressly disclaim any representation that the Platform
                is virus free and Organization agrees that Northern shall have no liability of any
                kind for viruses, worms, Trojan horses, or other similar harmful components that may
                enter your computer, device or system by downloading information, software or other
                materials from the Platform. Northern encourages you to routinely scan your
                computer, device, and system using a reliable virus protection product to detect and
                remove any viruses found.
              </Box>
            </li>
          </ul>
        </li>
        <li>
          <Box p={1} fontWeight="bold">
            Limitation of Liability; Indemnity
          </Box>
          <ul className={classes.bulletPointCharacters}>
            <li>
              <Box p={1}>
                Organization understands that the use of the Internet and other automated systems
                that provide Organization with Internet access to the services available through the
                Platform (collectively, the "<b>Automated Systems</b>") entails risks, including,
                but not limited to, interruptions of service, system or communications failures,
                delays in service, errors or omissions in information provided, errors in the design
                or functioning of such Automated Systems and corruption of Organization's data or
                systems (collectively, a "<b>System Failure</b>"), that could cause substantial
                damage, expense or liability to Organization. The Internet is an open system, and
                there is no absolute guarantee that the information being retrieved will not be
                intercepted by others and decrypted. Any reports, data, information, software,
                products, services or other materials downloaded from the Platform are done at
                Organization's own risk.
              </Box>
            </li>
            <li>
              <Box p={1}>
                ORGANIZATION AND YOUR USERS UTILIZE THE PLATFORM SOLELY AT YOUR OWN RISK, TO THE
                MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW. IN CONNECTION WITH THESE TERMS AND
                CONDITIONS AND THE APIS AND SERVICES PROVIDED HEREUNDER, NORTHERN, AND ITS
                AFFILIATES AND LICENSORS, WILL NOT BE LIABLE TO ORGANIZATION OR ANY THIRD PARTY FOR
                ANY DAMAGES OR INJURY, INCLUDING BUT NOT LIMITED TO THOSE CAUSED BY ANY FAILURE OF
                PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DEFECT, DELAY IN OPERATION OR
                TRANSMISSION, COMPUTER VIRUS, OR SYSTEM FAILURE EXCEPT DIRECT DAMAGES CAUSED BY
                NORTHERN'S OR ITS AFFILIATES GROSS NEGLIGENCE OR WILLFUL MISCONDUCT. IN NO EVENT
                WILL NORTHERN OR ANY OF ITS AFFILIATES OR LICENSORS BE LIABLE FOR ANY SPECIAL,
                INDIRECT, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF
                USE, DATA, WORKS, OR PROFITS ARISING OUT OF OR RELATING TO THE USE OF THE SERVICES
                THROUGH THE PLATFORM OR THE USE, COPYING, OR DISPLAY OF MATERIALS OR INFORMATION
                ACCESSED THROUGH THE PLATFORM, WHETHER OR NOT NORTHERN OR ANY AFFILIATE OR LICENSOR
                WAS ADVISED OF OR WAS AWARE OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING
                DISCLAIMER OF LIABILITY IS EFFECTIVE WITHOUT REGARD TO THE FORM OF ANY ACTION,
                INCLUDING BUT NOT LIMITED TO CONTRACT, NEGLIGENCE, TORT, STRICT LIABILITY OR ANY
                OTHER THEORY.
              </Box>
            </li>
            <li>
              <Box p={1}>
                Without limiting the foregoing, Northern and its affiliates will not be liable for
                any losses or damages incurred as a result of any information or confidential
                information submitted to it through the Platform or for its transmission of
                information or confidential information to any person(s) or entity(ies) as a result
                of a request for such transmission reasonably believed by Northern or an affiliate
                to have been made by such person or entity.
              </Box>
            </li>
            <li>
              <Box p={1}>
                Without limiting the foregoing, Northern and its affiliates will not be liable for
                the actual or alleged insufficient exercise of care in selecting any suppliers or
                sub-agents in connection with the delivery of services through the Platform or in
                selecting, monitoring or operating any Automated System, for any failure or delay in
                informing Organization of any System Failure or in taking action to prevent or
                correct any such System Failure. Northern and its affiliates have no responsibility
                to inform Organization of (i) any decision to use, not to use or cease using any
                Automated System, (ii) the characteristics, function, design or purpose of any
                Automated System, or (iii) any specific risks inherent in any Automated System.
              </Box>
            </li>
            <li>
              <Box p={1}>
                Organization will defend, indemnify, and hold Northern, and its directors, officers,
                employees, licensors, advisors, agents, subcontractors, customers, and assignees
                (the “<b>Northern Indemnified Parties</b>”) harmless from and against all threatened
                or actual claims, demands, suits, proceedings, losses, or expenses, including
                without limitation, reasonable attorneys' fees, suffered or incurred by any Northern
                Indemnified Party to the extent arising out of Organization's (or its employees or
                end users): (i) use or modification of the Platform or APIs, (ii) violation or
                breach of these Terms and Conditions; (iii) Organization Applications or
                Organization’s or its affiliate’s business; and (iv) negligence, fraud, or willful
                misconduct. Notwithstanding the foregoing, at Northern’s option, Northern may
                participate in, or assume control of, the defense of any claim, demand, suit, or
                proceeding under this Section, using counsel chosen by Northern.
              </Box>
            </li>
            <li>
              <Box p={1}>
                BECAUSE SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR THE LIMITATION OF
                LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH STATES OR JURISDICTIONS,
                THE LIABILITY OF NORTHERN SHALL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY LAW.
                ORGANIZATION HEREBY RELEASES AND FOREVER WAIVES ANY AND ALL CLAIMS IT MAY HAVE
                AGAINST NORTHERN OR ITS SUCCESSORS, ASSIGNS, SUBCONTRACTORS, AFFILIATES,
                REPRESENTATIVES, EMPLOYEES, AGENTS, OFFICERS, DIRECTORS, OR SHAREHOLDERS FOR LOSSES
                OR DAMAGES YOU OR ORGANIZATION SUSTAIN IN CONNECTION WITH ORGANIZATION’S USE OF THIS
                PLATFORM. ORGANIZATION ACKNOWLEDGES AND AGREES THAT ITS EXCLUSIVE REMEDY FOR ANY
                DISPUTE WITH NORTHERN IS TO STOP USING THE PLATFORM.
              </Box>
            </li>
            <li>
              <Box p={1}>
                On behalf of Organization, you agree that the foregoing disclaimers, limitations of
                liability and indemnity are part of the bargain between the parties, and acknowledge
                that without such protections Northern and its affiliates would not have offered the
                services accessible through the Platform, or would have done so only on
                significantly different terms.{' '}
              </Box>
            </li>
          </ul>
        </li>
        <li>
          <Box p={1} fontWeight="bold">
            Third-Party Data Providers: Third-Party Data
          </Box>
          <ul className={classes.bulletPointCharacters}>
            <li>
              <Box p={1}>
                "<b>Third-Party Data</b>” is defined as any data that is sourced from third-party
                data providers (“<b>Third-Party Data Provider(s)</b>”) and that is not proprietary
                to Northern. A “Third-Party Data Provider” is defined as any data provider that is
                not The Northern Trust Company or any of its affiliates or subsidiaries.
              </Box>
            </li>
            <li>
              <Box p={1}>
                Third-Party Data is proprietary to the Third-Party Data Provider that supplies it
                and/or its licensors. Organization agrees and acknowledges that the Third-Party Data
                provided is and shall remain valuable intellectual property owned by, or licensed
                to, each Third-Party Data Provider, and that no proprietary rights are being
                transferred to Organization. Any use of Third-Party Data by Organization other than
                in connection with the standard services provided for in Organization’s agreement
                with Northern is prohibited, unless Organization has written approval of or a direct
                agreement with the applicable Third-Party Data Provider outlining an additional
                approved use case.
              </Box>
            </li>
            <li>
              <Box p={1}>
                In the event that Organization has a direct license in place with a given
                Third-Party Data Provider, then Organization understands that Northern may be
                required by such Third-Party Data Provider to validate and/or provide to such
                Third-Party Data Provider certain Organization information for reporting purposes.
              </Box>
            </li>
            <li>
              <Box p={1}>
                Organization agrees and acknowledges that the CUSIP database and the information
                contained therein is and shall remain valuable intellectual property owned by, or
                licensed to, CUSIP Global Services (“<b>CGS</b>”) and the American Bankers
                Association (“<b>ABA</b>”), and that no proprietary rights are being transferred to
                Organization in such materials or in any of the information contained therein. Any
                use by Organization outside of the clearing and settlement of transactions requires
                a license from CGS, along with an associated fee based on usage. Organization agrees
                that misappropriation or misuse of such materials will cause serious damage to CGS
                and ABA, and that in such event money damages may not constitute sufficient
                compensation to CGS and ABA; consequently, Organization agrees that in the event of
                any misappropriation or misuse, CGS and ABA shall have the right to obtain
                injunctive relief in addition to any other legal or financial remedies to which CGS
                and ABA may be entitled.
              </Box>
            </li>
            <li>
              <Box p={1}>
                NEITHER NORTHERN NOR ANY OF ITS AFFILIATES AND/OR THIRD-PARTY DATA PROVIDERS MAKE
                ANY WARRANTIES, EXPRESS OR IMPLIED, AS TO THE ACCURACY, ADEQUACY OR COMPLETENESS OF
                ANY OF THE THIRD-PARTY DATA. SUCH THIRD-PARTY DATA IS PROVIDED TO ORGANIZATION ON AN
                "AS IS" BASIS, WITHOUT ANY WARRANTIES AS TO MERCHANTABILITY OR FITNESS FOR A
                PARTICULAR PURPOSE OR USE OR WITH RESPECT TO THE RESULTS THAT MAY BE OBTAINED FROM
                THE USE OF SUCH THIRD-PARTY DATA. NEITHER NORTHERN, THEIR AFFILIATES NOR THEIR
                THIRD-PARTY DATA PROVIDERS SHALL HAVE ANY RESPONSIBILITY OR LIABILITY FOR ANY ERRORS
                OR OMISSIONS NOR SHALL THEY BE LIABLE FOR ANY DAMAGES, WHETHER DIRECT OR INDIRECT,
                SPECIAL OR CONSEQUENTIAL, EVEN IF THEY HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
                DAMAGES. FURTHERMORE, NEITHER NORTHERN NOR ANY OF THEIR AFFILIATES AND/OR
                THIRD-PARTY DATA PROVIDERS SHALL HAVE ANY RESPONSIBILITY OR LIABILITY FOR DELAYS OR
                FAILURES DUE TO CIRCUMSTANCES BEYOND THEIR CONTROL.
              </Box>
            </li>
          </ul>
        </li>
        <li>
          <Box p={1} fontWeight="bold">
            Links to Third Party Sites
          </Box>
          <ul className={classes.bulletPointCharacters}>
            <li>
              <Box p={1}>
                Some of the sites listed as links in the Platform will let you leave Northern's
                servers, and those sites are not under Northern's control. Access to any other
                Internet site linked to the Platform is at the user's own risk and Northern is not
                responsible for the accuracy or reliability of any information, data, opinions,
                advice or statements made on those linked sites. Northern does not make any
                representations whatsoever concerning the content of those sites. The fact that
                Northern has provided a link to a site is NOT an endorsement, authorization,
                sponsorship, or affiliation by Northern with respect to such site, its owner or its
                providers; Northern is providing these links only as a convenience to you. Northern
                has not tested any information, software, products or services found on those sites
                and therefore cannot make any representations whatsoever with respect thereto.
                Northern will not accept any responsibility for any loss or damage in whatever
                manner, howsoever caused, resulting from Organization's disclosure to third parties
                of its own information.
              </Box>
            </li>
            <li>
              <Box p={1}>
                You may not create a link to any page of the Platform without the express written
                consent of Northern.
              </Box>
            </li>
          </ul>
        </li>
        <li>
          <Box p={1} fontWeight="bold">
            Uploads
          </Box>
          <ul className={classes.bulletPointCharacters}>
            <li>
              <Box p={1}>
                Organization represents and warrants that all data, content, or information provided
                on or through the Platform, and any Organization Applications used or developed in
                connection with the APIs or Platform, shall not (i) infringe the intellectual
                property rights or privacy rights of any person, (ii) be false, misleading,
                inaccurate, or contain a virus or other harmful components, or (iii) be defamatory,
                libelous, harassing, obscene or pornographic. You agree, on behalf of Organization,
                not to use the Platform to commit fraud, violate applicable law, create liability
                for Northern or interfere with or disrupt the operation of the Platform. Northern
                reserves the right to monitor, audit, and/or remove data, content or information
                (including messages) for any purpose sent over Northern’s network and the Platform.
              </Box>
            </li>
          </ul>
        </li>
        <li>
          <Box p={1} fontWeight="bold">
            Terms and Conditions for Organization Applications
          </Box>
          <ul className={classes.bulletPointCharacters}>
            <li>
              <Box p={1}>
                Organization shall post and maintain terms and conditions and privacy policies
                (collectively “<b>Application Terms</b>”) for each of the Organization Applications.
                Organization shall include in all Application Terms (i) a disclosure that the
                Organization Application uses Northern’s API, including any necessary notices or
                disclosures for the collection and use of an end user’s data by Organization or
                Northern in connection with such API (if applicable), (ii) a statement that Northern
                is not a party to the Application Terms, and (iii) a disclaimer stating that
                Northern shall have no liability whatsoever under the Application Terms or with
                respect to the Organization Application, any other services or products offered by
                Organization, or otherwise.{' '}
              </Box>
            </li>
            <li>
              <Box p={1}>
                Organization shall ensure the Application Terms comply with all applicable laws, and
                Organization and the Organization Applications shall at all times comply with such
                Application Terms.
              </Box>
            </li>
          </ul>
        </li>
        <li>
          <Box p={1} fontWeight="bold">
            Location of Products and Services
          </Box>
          <Typography variant="body2" className={classes.paragraphs}>
            Northern's products and services are offered only in jurisdictions where they may be
            lawfully offered for sale and are subject to the terms of and governed by the applicable
            agreement. NOT ALL PRODUCTS OR SERVICES MENTIONED HEREIN ARE AVAILABLE IN ALL
            JURISDICTIONS, AND ORGANIZATION SHALL NOT USE OR ACCESS THE PLATFORM (OR MAKE THE
            ORGANIZATION APPLICATION AVAILABLE) IN OR TO COUNTRIES OR PERSONS PROHIBITED UNDER
            EXPORT CONTROL LAWS, OR TO ANYONE ON THE U.S. TREASURY DEPARTMENT LIST OF SPECIALLY
            DESIGNATED NATIONALS AND BLOCKED PERSONS OR THE U.S. COMMERCE DEPARTMENT’S TABLE OF DENY
            ORDERS.
          </Typography>
        </li>
        <li>
          <Box p={1} fontWeight="bold">
            Jurisdiction
          </Box>
          <Typography variant="body2" className={classes.paragraphs}>
            The services provided hereunder are being performed in the State of Illinois, United
            States of America. These Terms and Conditions will be governed by and be interpreted
            pursuant to the laws of the State of Illinois, United States of America, without giving
            effect to any principles of conflicts of law. On behalf of Organization, you agree to be
            bound by all applicable laws and regulations that may pertain to the Platform, including
            U.S. export and re-import laws and regulations.
          </Typography>
        </li>
        <li>
          <Box p={1} fontWeight="bold">
            Compliance with Laws; International Use
          </Box>
          <Typography variant="body2" className={classes.paragraphs}>
            Organization shall comply with all applicable laws, including local laws, rules, and
            regulations with respect to Organization's use of the Platform, including APIs, and in
            connection with the Organization Application. In addition, you and Organization agree to
            comply with all material laws, rules, codes, and regulations regarding the transmission
            of technical data exported to or from the United States. You and Organization further
            agree to comply with all material laws, rules, codes and regulations regarding the
            transmission of personally identifiable information in, to or from the United States.
            The Platform may be stored on servers in the United States or other jurisdictions. You
            and Organization hereby agree and undertake that to the extent that personally
            identifiable information is contained in any data uploaded by you and Organization to
            the Platform, that any consequent processing of that personal data by the Platform shall
            be considered lawful under all applicable data privacy legislation.
          </Typography>
        </li>
        <li>
          <Box p={1} fontWeight="bold">
            Arbitration
          </Box>
          <ul className={classes.bulletPointCharacters}>
            <li>
              <Box p={1}>
                PLEASE READ THIS PROVISION CAREFULLY. IT INCLUDES AN AGREEMENT TO MANDATORY
                ARBITRATION, WHICH MEANS THAT YOU AGREE TO SUBMIT ANY DISPUTE RELATED TO THESE TERMS
                AND CONDITIONS AND YOUR USE OF THE PLATFORM (INCLUDING THE APIS) TO BINDING
                ARBITRATION RATHER THAN PROCEED IN COURT. THIS PROVISION ALSO INCLUDES A WAIVER OF
                JURY TRIAL.
              </Box>
            </li>
            <li>
              <Box p={1}>
                Any dispute arising out of or relating to these Terms and Conditions, shall be
                determined by binding arbitration in Chicago, Illinois before a single arbitrator.
                The arbitration shall be administered by JAMS pursuant to its Comprehensive
                Arbitration Rules and Procedures. Judgment on the award may be entered in any court
                having jurisdiction. This clause shall not preclude either party from seeking
                provisional remedies (including but not limited to injunctive relief) in aid of
                arbitration from a court of appropriate jurisdiction. The parties shall maintain the
                confidential nature of the arbitration proceeding and the award, including the
                hearing, except as may be necessary to prepare for or conduct the arbitration
                hearing on the merits or as may be necessary in connection with a court application
                for a preliminary remedy, a judicial challenge to an award or its enforcement or
                unless otherwise required by Law or judicial decision. Each party shall bear its own
                costs and attorney’s fees. All costs of the arbitration (including but not limited
                to the fees and expenses of JAMS) shall be split evenly between the parties. The
                findings, reasoning and decision of the arbitrator shall be in writing. THE PARTIES
                AGREE THAT WHETHER ANY DISPUTE ARISING OUT OF OR RELATING TO THESE TERMS AND
                CONDITIONS IS IN ARBITRATION OR IN COURT, EACH PARTY WAIVES AND RELINQUISHES ALL
                RIGHTS TO A JURY TRAIL.
              </Box>
            </li>
          </ul>
        </li>
        <li>
          <Box p={1} fontWeight="bold">
            Miscellaneous
          </Box>
          <ul className={classes.bulletPointCharacters}>
            <li>
              <Box p={1}>
                These Terms and Conditions, including any API-Specific Terms (as defined below), and
                the API License Agreement, set forth the entire agreement between Northern and
                Organization relating to the use of the Platform, including the APIs, and supersedes
                any prior arrangement, agreement or representation between the parties to the extent
                that they relate in any way to the use of the Platform. Organization acknowledges
                and agrees that the use and license of APIs, any data accessed, processed, or
                otherwise transmitted by or through the APIs, and other services provided by
                Northern, shall also be subject to the API License Agreement, which must be executed
                by both parties. Organization may not alter these Terms and Conditions without the
                express written consent of Northern, demonstrated through a signed writing by
                Northern.{' '}
              </Box>
            </li>
            <li>
              <Box p={1}>
                Additional terms and conditions may apply to individual APIs available on the
                Platform (“<b>API-Specific Terms</b>”). Any such API-Specific Terms are hereby
                incorporated herein by reference and shall form part of these Terms and Conditions.
              </Box>
            </li>
            <li>
              <Box p={1}>
                Neither party shall have any right, power or authority, express or implied, to bind
                the other. These Terms and Conditions do not limit or restrain in any way the right
                of Northern to in any manner deal with or otherwise dispose of counterparts of the
                APIs or Platform or parts thereof to any other persons or firms or to execute
                agreements providing for such transfer of rights. If any part of these Terms and
                Conditions is held to be unlawful, void, or unenforceable, that part will be deemed
                severable and will not affect the lawfulness, validity and enforceability of any
                remaining provisions. No term or provision shall be deemed waived and no breach
                excused unless such waiver or consent shall be in writing and signed by the party
                claimed to have waived or consented.
              </Box>
            </li>
            <li>
              <Box p={1}>
                Organization shall not assign any of its rights under these Terms and Conditions
                without the prior express written consent of Northern. Organization agrees that a
                printed version of these Terms and Conditions shall be admissible in legal, judicial
                or administrative proceedings to the same extent as other records or documents
                concerning business between the parties. Organization agrees that neither any
                content provided on or though the Platform, nor end users’ use thereof, shall amend
                or modify the terms and conditions of any other existing agreement between Northern
                and Organization.
              </Box>
            </li>
            <li>
              <Box p={1}>
                Notices to you may be made via posting to the Platform, by email, or by regular
                mail, in Northern’s discretion. Any notice or other communication you wish to
                provide to Northern may be sent in writing via email to{' '}
                <a className={classes.hyperLink} href="mailto:API_Store_Product@ntrs.com">
                  API Store Product
                </a>
                . Such notice to Northern shall be deemed to have given on the date such notice is
                received by Northern.
              </Box>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default TermsContent;
