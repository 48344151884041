import React, { FC } from 'react';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: (styleprops: any) => ({
      minWidth: 298,
      padding: theme.spacing(2),
      mixBlendMode: 'normal',
      borderRadius: '10px',
      backgroundColor: styleprops.backgroundColor,
      // transform: 'matrix(1, 0, 0, -1, 0, 0)',
      height: 220,
      position: 'relative',
      // flexGrow: 1,
    }),

    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      justifyContent: 'center',
      padding: 0,
    },
    title: {
      color: 'white',
      fontSize: 21,
      // fontWeight: 400,

      zIndex: 5,
    },
    description: {
      color: 'white',
      fontSize: 17,
      height: 120,
      overflow: 'hidden',
      zIndex: 5,
      marginBottom: theme.spacing(0),
    },
    avatar: {
      color: '#00C4B4',
    },
    actions: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 5,
    },
    shaddowTopRight: {
      borderLeft: '76px solid transparent',
      borderRight: '0px solid transparent',
      borderBottom: '124px solid rgba(16,4,40,0.2)',
      height: 0,
      width: 0,
      position: 'absolute',
      right: 0,
      zIndex: 2,
      top: 0,
    },
    shaddowTopLeft: {
      borderLeft: '0px solid rgba(16,4,40,0.1)',
      borderRight: '206px solid transparent',
      borderBottom: '50px solid rgba(16,4,40,0.1)',
      height: 0,
      width: 0,
      position: 'absolute',
      left: 0,
      zIndex: 2,
      top: '33%',
    },
    shaddowBottomLeft: {
      borderLeft: '0px solid rgba(16,4,40,0.1)',
      borderBottom: '97px solid rgba(16,4,40,0.1)',
      height: 0,
      width: 0,
      position: 'absolute',
      left: 0,
      zIndex: 2,
      bottom: 0,
      borderRight: '299px solid rgba(16,4,40,0.1)',
    },
    shaddowBottomRight: {
      borderLeft: '140px solid transparent',
      borderRight: '75px solid rgba(16,4,40,0.1)',
      borderBottom: '96px solid rgba(16,4,40,0.1)',
      height: 0,
      width: 0,
      position: 'absolute',
      right: 0,
      zIndex: 2,
      bottom: 0,
    },
    titleBox: {
      backgroundColor: 'rgba(255,255,255,0.24)',
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(2),
      width: 142,
      alignSelf: 'center',
      borderRadius: 7,
      height: 48,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);
interface Props {
  title: string;
  description: string;
  color?: string;
}

const PricingCard: FC<Props> = ({ title, description, color = '#03DAC5' }) => {
  const styleprops = { backgroundColor: color };
  const classes = useStyles(styleprops);

  return (
    <Card className={classes.card} raised={true}>
      <div className={classes.shaddowTopRight}></div>
      <div className={classes.shaddowTopLeft}></div>
      <div className={classes.shaddowBottomLeft}></div>
      <div className={classes.shaddowBottomRight}></div>

      <CardContent>
        <div className={classes.cardContent}>
          <Box className={classes.titleBox}>
            <Typography variant="h3" className={classes.title}>
              {title}
            </Typography>
          </Box>
          <Typography variant="body2" className={classes.description}>
            {description}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export default PricingCard;
