import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import React, { PropsWithChildren } from 'react';
export type SuccessAlertProps = {
  message: string;
  state:  boolean;
  onClose:any;
  alertType: string;
};
export const SuccessAlert: React.FC<SuccessAlertProps> =
  (props: PropsWithChildren<SuccessAlertProps>) => {
    const { message, state, onClose, alertType } = props;
    return (
    <div data-testid="successalert">
      <Snackbar open={state} autoHideDuration={3000} data-cy="SuccessAlert" onClose={()=>onClose()}>
      {/* 
        // @ts-ignore */}
        <MuiAlert severity={alertType} elevation={6} variant="filled">
          {message}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};