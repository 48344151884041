import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listBox: {
      display: 'flex',
      padding: theme.spacing(1),
      alignItems: 'center',
    },
    list: {
      color: 'white',
      paddingLeft: theme.spacing(1),
    },
    icon: {
      paddingRight: theme.spacing(2),
      fontSize: 40,
    },
  }),
);

export const FeatureList: FC<{ list?: string }> = ({ list }) => {
  const classes = useStyles();
  return (
    <div className={classes.listBox}>
      <CheckCircleIcon color="primary" fontSize="default" />
      <Typography variant="body1" className={classes.list}>
        {list}
      </Typography>
    </div>
  );
};
