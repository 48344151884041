const cdnBaseDocUrl =  'https://www.northerntrust.com';
const cdnReletiveDocurl = '/content/dam/northerntrust/pws/nt/documents/legal/';
const getSnippetData  =(apiId: string| string[]| null |undefined, path: string | undefined, method: string | undefined) => { 
        return `/bff-private/${apiId}/snippet?path=${path}&method=${method}`
    };
const changePasswordOskUrl = (userId: string)=> { 
    return `/osk/user-management/api/v1/users/${userId}/credentials/change_password?strict=false`;
}
const disableApplication = () => {
    return '/bff-private/disable-application'

};
const getPdfUrl = (fielName: string) =>{
    return `${cdnBaseDocUrl}${cdnReletiveDocurl}${fielName}`
} 

const Appurls = {
    getSnippetData,
    changePasswordOskUrl,
    disableApplication,
    getPdfUrl
};
export default Appurls;