import { Box, createStyles, Icon, makeStyles, Theme, Typography } from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import { Event, EventStatus as StatusEnum } from '../../store/eventSlice';
import React from 'react';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    applicationStatusWrapper: {
      display: 'flex'
    },
    statusText: {
      color: '#ffffff',
      display: 'flex',
      alignItems: 'center',
      textTransform:'capitalize'
    },
    icon: {
      marginRight: '10px'
    },
    active: {
      color: '#03dac5'
    },
    pending: {
      color: '#985eff'
    },
    disabled: {
      color: '#cf6679'
    }
  }))

const EventStatus: React.FC<Event> = (event: Event) => {
  const classes = useStyles();

  const renderIcon = () => {
    switch (event.status) {
      case StatusEnum.UNSUBSCRIBED: {
        return (
          <Icon className={clsx(
            classes.icon,
            classes.disabled
          )}>
            <PersonAddIcon />
          </Icon>
        );
      }

      case StatusEnum.SUBSCRIBED: {
        return (
          <Icon className={clsx(
            classes.icon,
            classes.active
          )}>
            <PersonAddDisabledIcon />
          </Icon>
        );
      }
    };
  };
  return (
    <Box className={classes.applicationStatusWrapper}>
      <Typography variant={'body2'} className={classes.statusText}>
        {renderIcon()}
        {event.status}
      </Typography>
    </Box>
  );
};

export default EventStatus;
