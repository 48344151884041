import React, { useState, FC } from 'react';
import TextField from '@material-ui/core/TextField';
import { Typography, Grid, InputAdornment, IconButton } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Requirement } from './requirement';
import { Visibility, VisibilityOff } from '@material-ui/icons';
interface Props {
 
  email: string,
  passwordWrapper: string,
  revokeSessions: boolean,
  handleResetPassword: (data:any) => void,
  alertMessage:string,
  showAltert:boolean,
  altertType:string
  handleAltert:() => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@global':{
      "input::-ms-reveal, input::-ms-clear": {
        display: "none"
      }
    },
    fullContainer: {
      minHeight: '100vh'
    },
    requirementAlert:{
      padding:10,
      color:'white',
      display:'flex',
      alignItems:'center',
      fontSize:16,
      background: '#ffffff1f',
      fontWeight: 300
    },
    successAlert:{
      padding:10,
      color:'#00C4B4',
      display:'flex',
      alignItems:'center',
      fontSize:16,
      background: '#ffffff1f',
      fontWeight: 300
    },
    errorAlert:{
      padding:10,
      color:'#cf6679',
      display:'flex',
      alignItems:'center',
      fontSize:16,
      background: '#ffffff1f',
      fontWeight: 300
    },
    fieldContainer:{
      padding:15,
      border:'1px solid #ffffff1f'

    },
    formfield:{
      margin:5,
      padding:5
    },
    requirementTitle:{
      color:'white'
    },
    requirementDetails:{
      color:'white'
    },
    requirementNote:{
      color:'white',
      padding:theme.spacing(1)
    },
    requirementContainer:{
      padding:10
    },
    textInput:{
      "& ::-ms-clear": {
        display: 'none'
      },
      "& ::-ms-reveal": {
        display: 'none'
      }
    },
    noteMessage:{
      padding:10,
      color:'white',
      display:'flex',
      alignItems:'center',
      fontSize:16,
      fontWeight: 300
    }
  })
);

const ResetPassword: FC<Props> = ({
  email,
  showAltert,
  altertType,
  revokeSessions,
  handleResetPassword,
  handleAltert,
  alertMessage
}) => {
  const classes = useStyles();
  const ariaLabel = 'aria-label';
  const altert = altertType === "success" ? classes.successAlert: classes.errorAlert;
  const [oldPassword, setOldPassword] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [requirementStatus, setRequirementStatus] = useState({
    rule1: null,
    rule2: null,
    rule3: null,
    rule4: null,
    rule5: null,
    rule6: null,
    rule7: null,
    readyToSubmit: false
  });
  const [showRequirementAlert, setShowRequirementAlert] = useState(false);
  const rules = [
    'At least 8 characters',
    'Include a symbol (e.g., !@#$%^&*)',
    'A lowercase letter',
    'An uppercase letter',
    'A number',
    'Does not include your User ID',
    'The password fields must match'
  ];

  const cleanUp = () => {
    setNewPassword('');
    setConfirmNewPassword('');
    setOldPassword('');
    setRequirementStatus({
      rule1: null,
      rule2: null,
      rule3: null,
      rule4: null,
      rule5: null,
      rule6: null,
      rule7: null,
      readyToSubmit: false
    });
  };

  const checkPasswordRequirements = (newInput: any, confirmInput:any) => {
    const regSpecialSymbols = /[;!"@#$%&')(*+,-./:<=>?\][\\^_~`}{|]/;
    const regLowerCase = /[a-z]/;
    const regUpperCase = /[A-Z]/;
    const regNumbers = /[0-9]/;
   
    const result:any = {};
    result.rule1 = newInput.length >= 8;
    result.rule2 = regSpecialSymbols.test(newInput);
    result.rule3 = regLowerCase.test(newInput);
    result.rule4 = regUpperCase.test(newInput);
    result.rule5 = regNumbers.test(newInput);
    result.rule6 = !newInput.toLowerCase().includes(email);
    result.rule7 = newInput === confirmInput;

    if (Object.values(result).every(value => value === true)) {
      result.readyToSubmit = true;
    } else {
      result.readyToSubmit = false;
    }
    setRequirementStatus(result);
  };

  const handleOldPasswordChange = (e:any) => {
    setOldPassword(e.target.value);
    handleAltert();
  };

  const handleNewPasswordChange = (e:any) => {
    setNewPassword(e.target.value);
    handleAltert();
    checkPasswordRequirements(e.target.value, confirmNewPassword);
    if (showRequirementAlert) {
      setShowRequirementAlert(false);
    }
  };
  const handleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };
  const handleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  }; 
  const handleConfirmPasswordVisibility = () => {
    setShowConfirmNewPassword(!showConfirmNewPassword);
  }; 
  const handleConfirmNewPassword = (e:any) => {
    setConfirmNewPassword(e.target.value);
    checkPasswordRequirements(newPassword, e.target.value);
    if (showRequirementAlert) {
      setShowRequirementAlert(false);
    }
    handleAltert();
  };
  
  const handleResetPasswordBtn = async () => {
      if (!requirementStatus.readyToSubmit) {
        setShowRequirementAlert(true);
      } else {
        const data = {
          oldPassword,
          newPassword,
          revokeSessions
        };
        handleResetPassword(data);
        cleanUp();
    }
  };

  return (
    <div>
      <Grid container spacing={4} direction="row">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {showRequirementAlert && (<div className={classes.requirementAlert}>
            You must create a new password that meets the minimum security requirements. Please review the list below
            and create a new password.
          </div>)}
        {showAltert && (<div className={altert}>
            {alertMessage}
          </div>)}  
          
      </Grid>     
      <Grid item xs={12} sm={12} md={6} lg={6}>
      
      <Grid container direction="column" className={classes.fieldContainer}> 

          <TextField
            id="change-password-old-password"
            data-testid="change-password-modal__old-password"
            className={classes.formfield}
            size="medium"
            type={showOldPassword ? 'text' : 'password'}
            label="Existing password"
            onChange={handleOldPasswordChange}
            value={oldPassword}
            inputProps={{ [ariaLabel]: 'Show Old Password Input',
            "data-testid":"oldPassword",
            "className":"textInput"
            }}
            InputProps={{ 
               endAdornment:(
                <InputAdornment position="end" >
                  <IconButton onClick={() => handleOldPasswordVisibility()}> 
                    { showOldPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>   
              )
           }}
          />
          <TextField
            id="change-password-modal-new-password"
            data-testid="change-password-modal__new-password"
            size="medium"
            className={classes.formfield}
            type={showNewPassword ? 'text' : 'password'}
            label="New password"
            onChange={handleNewPasswordChange}
            value={newPassword}
            inputProps={{ [ariaLabel]: 'Show New Password Input',
            "data-testid":"newPassword"
            }}
            InputProps={{ 
              endAdornment:(
               <InputAdornment position="end" >
                 <IconButton onClick={() => handleNewPasswordVisibility()}> 
                   { showNewPassword ? <VisibilityOff /> : <Visibility />}
                 </IconButton>
               </InputAdornment>   
             )
          }}
          />
          <TextField
            id="change-password-modal__confirm-password"
            data-testid="change-password-modal__confirm-password"
            className={classes.formfield}
            size="medium"
            type={showConfirmNewPassword ? 'text' : 'password'}
            label="Confirm new password"
            onChange={handleConfirmNewPassword}
            value={confirmNewPassword}
            inputProps={{ [ariaLabel]: 'Show Confirm Password Input',
            "data-testid":"confirmPassword" 
            }}
            InputProps={{ 
              endAdornment:(
               <InputAdornment position="end" >
                 <IconButton onClick={() => handleConfirmPasswordVisibility()}> 
                   { showConfirmNewPassword ? <VisibilityOff /> : <Visibility />}
                 </IconButton>
               </InputAdornment>   
             )
          }}
          />
        <Button
          variant="contained"
          color="secondary"
          aria-label={'Submit callback url'}
          disabled={!requirementStatus.readyToSubmit}
          className={''}
          onClick={handleResetPasswordBtn}
          >
          CHANGE PASSWORD
        </Button>
        </Grid>
        </Grid>
      
      <Grid item xs={12} sm={12} md={6} lg={6} className={classes.requirementContainer}>     
       
        <Typography className={classes.requirementTitle}>
          <p>Requirements:</p>
        </Typography>
        <Typography className={classes.requirementDetails}>
          <div>
            {rules.slice(0, 4).map((ruleDetail, index) => (
              
              <Requirement
                key={ruleDetail}
                //@ts-ignore 
                ruleStatus={requirementStatus[`rule${index + 1}`]}
                ruleDetail={ruleDetail}
              />
            ))}
          </div>
          <div>
            {rules.slice(4, 7).map((ruleDetail, index) => (
              <Requirement
                key={ruleDetail}
                //@ts-ignore 
                ruleStatus={requirementStatus[`rule${index + 5}`]}
                ruleDetail={ruleDetail}
              />
            ))}
          </div>
        </Typography>
        <Typography className={classes.requirementNote}>
        <p>
          Your new password cannot:
            <ul>
              <li>match any of your previous 12 passwords.</li>
            </ul>
            </p> 
          </Typography> 
       </Grid> 
       </Grid>
      </div>
      
  );
};

export default ResetPassword;