import { RootState } from '../store';
import { AccountState,  } from "../store/accountSlice";
import { latencyState } from '../store/latencySlice';
import { UsageState } from '../store/apiUsageSlice';
import { ApiProductsState } from '../store/apiProductsSlice';
import { ProductsState } from '../store/productsSlice';
import { ApplicationsState } from '../store/applicationsSlice';
import { eventsState } from '../store/eventSlice';
import { publicEventsState } from 'store/publicEventSlice';

export const getState = (state: RootState): AccountState =>
  state.account;

export const getApplications = (state: RootState): ApplicationsState =>
  state.applications;

export const getEventsCount = (state: RootState): number =>
  state.events.totalCount

export const getEvents = (state: RootState): eventsState =>
  state.events;

export const getpublicEvents = (state: RootState): publicEventsState =>
  state.publicEvents;  

export const getApplicationsCount = (state: RootState): number =>
  state.applications.totalCount;

export const isLoadingAccountData = (state: RootState): boolean =>
  state.account.loading

export const getLatencies = (state: RootState):latencyState =>
 state.latency;

export const getUsage = (state: RootState):UsageState =>
 state.apiUsage;

 export const getApiProducts = (state: RootState): ApiProductsState =>
state.apiProducts;

export const getProducts = (state: RootState): ProductsState =>
state.products;