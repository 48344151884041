import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router';
import Header from '../../header';
import Footer from '../../footer';
import pathwayImage from '../../../assets/pathway.png';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: '100vh',
      backgroundImage:
        'linear-gradient(180deg, rgba(12,26,41,0.9948354341736695) 33%, rgba(12,26,41,0.9948354341736695) 97%)',
      position: 'relative',
    },
    topbg: {
    },
    pathway: {
      position: 'absolute',
      left: '45%',
      top: '5%',
      [theme.breakpoints.up('lg')]: {
        left: '52%',
      },
      [theme.breakpoints.down('lg')]: {
        left: '48%',
      },
    },
    pathwayImg: {
      width: 850,
      height: '2000px',
      objectFit: 'cover',
      objectPosition: 'right',
      display: 'block',
      [theme.breakpoints.down('lg')]: {
        width: 700,
        objectPosition: 'left',
      },
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
  }),
);

interface Props {
  children?: React.ReactElement<any, string> | React.ReactElement[];
}

const CommonLayout: FC<Props> = ({  children }) => {
  const classes = useStyles();
  const { pathname } = useLocation();

  return (
    <div className={classes.root}>
     <Header />
      {pathname === '/get-started' && (
        <div className={classes.pathway}>
          <img
            src={pathwayImage}
            className={classes.pathwayImg}
            alt="apistore pathway"
          />
        </div>
      )}
      {children}
      <Footer />
    </div>
  );
};
export default CommonLayout;
