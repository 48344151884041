import { Box, createStyles, makeStyles, Theme, Typography, useMediaQuery, Button, Tooltip, IconButton } from '@material-ui/core';
import { AccordionTable } from '../accordionTable/accordionTable';
import { TableRow } from '../accordionTable/tableRow';
import { getApplications, getApplicationsCount, getState } from '../../selectors/selectors';
import { Application, ApplicationStatus as StatusEnum, ApplicationCollection, fetchApplicationsData, getEntitlementsData } from '../../store/applicationsSlice';
import React, { useState, useEffect, PropsWithChildren } from 'react';
import { useTypedSelector } from '../../store';
import { ApplicationDetails } from '../applicationDetails';
import TableHeader from '../tableHeader/tableheader';
import ApplicationStatus from '../applicationStatus';
import CircularProgress from '@material-ui/core/CircularProgress';
import Pagination from '@material-ui/lab/Pagination';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PRODUCTION_HOST_NAME_LIST } from '../../constants/constants';
import { useNavigate } from 'react-router';
import { checkUserPermission } from '../../core/authorization/service/authorizationService';
import clsx from 'clsx';
import CancelIcon from '@material-ui/icons/Cancel';

const pageSize= 5;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    applicationsContainer: {
      flexDirection: 'column',
      width: 'calc(100% - 40px)',
      margin: 20,
      marginTop: 60,
      display: 'flex'
    },
    applicationsMoblieContainer:{
      flexDirection: 'column',
      width: 'calc(100% - 2px)',
      margin: 0,
      marginTop: 60,
      display: 'flex'
    },
    noAppsContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: 'calc(100% - 80px)',
      backgroundColor: 'rgba(255, 255, 255, 0.012)',
      borderRadius: 20,
      margin: 40
    },
    noAppsInfoTextContainer: {
      display: 'flex',
      width: '100%',
      maxWidth:500,
      paddingTop: 30,
      paddingBottom: 30,
      paddingLeft: 20,
      paddingRight: 20,
      flexDirection: 'column'
    },
    actionButton:{
      maxWidth: '120px',
      color:'#000000de'
    },
    actionButtonNoApps:{
      margin:10
    },
    appsHeader:{
      display:'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    noAppsInfoTitle: {
      color: '#ffffff',
      fontWeight: 300,
      marginBottom: 20
    },
    noAppsInfoBody: {
      color: '#ffffff',
      fontWeight: 300
    },
    dummyAppListContainer: {

    },
    applicationName: {
      color: '#00e2d0',
      fontWeight: 500
    },
    actions:{
      color: '#00e2d0',
      fontWeight: 500,
      cursor:'pointer'
    },
    myAppsTitle: {
      color: '#ffffff',
      fontWeight: 500,
      marginBottom: 30,
      flexGrow:1
    },
    tableHeaders:{
      color: 'white',   
      fontWeight: 500
    },
    loader:{
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      height:300
    },
    noOrganizationHeader:{
      background:'#ffffff1f',
      padding:10,
      marginBottom:25,
      color:'white',
      fontWeight: 300
    },
    beta:{
      padding:7,
      margin:10,
      color:'#00c4b4',
      border:'1px solid #00c4b4',
      borderRadius:7
    },
    disabled: {
      color: '#cf6679'
    }
  }));
  export type ApplicationListProps = {
    onAcionsClick: (action:string, data:any) => void;
  };

const ApplicationList: React.FC<ApplicationListProps> = (props: PropsWithChildren<ApplicationListProps>) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { onAcionsClick } = props;
  const { userDetails } = useTypedSelector(state => getState(state));
  const totalcount = useTypedSelector(state => getApplicationsCount(state));
  const pagesCount= Math.ceil(totalcount/pageSize);
  const hasPermissionCreateApps = checkUserPermission(userDetails,'createApps'); 
 
  const location = useLocation();
  const querySearched = location.search || '';
  const params = new URLSearchParams(querySearched);
  const sponsorCode = params.get('sponsorCode') ||'';
  const currentHostName = window.location.hostname;  
  const isSmallScreen = useMediaQuery((theme:any) => theme.breakpoints.down("sm"));
  const [pagination, setPagination] = useState({
    from: 0,
    to: pageSize,
    count: totalcount
  });
  const [page, setPage] = React.useState(1);
  const [showHeaderAlert,setShowHeaderAltert] = useState(false);
  const navigate = useNavigate();

  const applicationsdata = useTypedSelector(state => getApplications(state));
  let applications: ApplicationCollection = applicationsdata?.data;
  if(applications && Object.keys(applications).length > 0){
    applications = Object.keys(applications).slice(pagination.from, pagination.to).reduce((result:ApplicationCollection, key) => {
      result[key] = applications[key];
      return result;
    }, {}); 
  }
 
  const isLoading = applicationsdata?.loading;
  const domains =  applicationsdata?.domains;
  
  const tooltipMessage = !hasPermissionCreateApps ?"You don't have permissions. Please check with support team.":""; 
  const isActionDisabled = showHeaderAlert || (!hasPermissionCreateApps);
  useEffect(()=>{ 
    const ApplicationsParamters = {
        page:1,
        pageSize:500
    };
    if (PRODUCTION_HOST_NAME_LIST.indexOf (currentHostName) > -1 ) {
      if( applicationsdata && applicationsdata.totalCount === 0){
        dispatch(fetchApplicationsData({
          ...ApplicationsParamters,
          sponsorCode:''
        }));
      }
      
    }else{
      if( applicationsdata && applicationsdata.totalCount === 0){
      dispatch(fetchApplicationsData({
        ...ApplicationsParamters,
        sponsorCode:sponsorCode
      }));
      } 
    }
    dispatch(getEntitlementsData({
      env:'sandbox'
    }));
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dispatch,sponsorCode,currentHostName,applicationsdata?.totalCount]);

  useEffect(()=>{
    if(domains && Object.keys(domains).length > 0 ){
      setShowHeaderAltert(false);
    }else
    {
      setShowHeaderAltert(true);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[applicationsdata?.domains])
  
  const testApplications: ApplicationCollection = {
    'fc06ec4e-3766-49eb-adb8-1d3fc096104e': {
      uuid: 'fc06ec4e-3766-49eb-adb8-1d3fc096104e',
      name: 'Example Application',
      environment:'Production',
      status: StatusEnum.PENDING,
      applicationDetails:{
        description:'Example Application Details'
      },
      keyTokenDetails:{
        apiKey:"sdd6b058d720ee44ab2e14a26c6a41d040",
        apiSecret:"0oa2d4shtxUjA0KMD1d711"
      }

    }
  };
  const handlePageChange =  (event:React.ChangeEvent<unknown>,page:number)=> {
      const from =(page - 1)*pageSize;
      const to =(page - 1)*pageSize + pageSize;
      setPage(page);

      setPagination({...pagination,from:from, to:to})
  };
  const handleNavigation = ()=> {
    navigate('/account/application');
  };
  const renderNoApplications = () => {
    return (
      <Box
        data-testid={'NoAppsContainer'}
        data-cy={'NoAppsContainer'}
        className={classes.noAppsContainer}>
        <div className={classes.appsHeader}>   
         <Box className={classes.noAppsInfoTextContainer}>
          <Typography variant={'h6'} className={classes.noAppsInfoTitle}>
            No Applications
          </Typography>
          
          <Typography variant={'body2'} className={classes.noAppsInfoBody}>
            Currently you have no applications available.<br /><br />
            Once your organisation admin adds the first application, you should see it in the following format:
          </Typography>
        </Box> 
        <Tooltip title={tooltipMessage} placement="bottom-start">
        <span>
        <Button
          variant="contained"
          color="secondary"
          aria-label={'Create App'}
          className={classes.actionButtonNoApps}
          onClick={()=>{ handleNavigation()}}
          disabled={showHeaderAlert || (!hasPermissionCreateApps) }
        >
          Create App
        </Button>
        </span>
        </Tooltip>
    
        </div> 
        
        <Box className={classes.dummyAppListContainer}>
          <AccordionTable numColumns={5} renderHeadings={() => (
            <React.Fragment>
              <th className={classes.tableHeaders} colSpan={3}>Application</th>
              <th className={classes.tableHeaders} >Environment</th>
              <th className={classes.tableHeaders}>Status</th>
            </React.Fragment>
          )}>
            {
              Object.values(testApplications).map((application: Application) => (
                <TableRow
                  key={application.uuid}
                  disabled
                  activeId={application.uuid}
                  tableRowContent={() => (
                    <React.Fragment>
                      <td colSpan={3}>
                        <Typography
                          variant={'body2'}
                          className={classes.applicationName}>
                          {application.name}
                        </Typography>
                      </td>
                      <td>
                        <Typography
                          variant={'body2'}
                          className={classes.applicationName}>
                          {application.environment}
                        </Typography>
                      </td>
                      <td>
                        <ApplicationStatus {...application} />
                      </td>
                    </React.Fragment>
                  )}
                  accordionContent={() => (
                    <Box style={{ width: '100%', height: '400px' }} />
                  )} />
              ))
            }
          </AccordionTable>
        </Box>
      </Box>
    )
  }

  const renderApplications = () => {
    return (
      <Box
        data-testid={'AppsContainer'}
        data-cy={'AppsContainer'}
        className={isSmallScreen ?classes.applicationsMoblieContainer:classes.applicationsContainer}> 
        <TableHeader
            action="Create APP"
            actionHandler={()=>handleNavigation()}
            isBeta={true}
            isActionDisabled ={isActionDisabled}
            actionMessage={tooltipMessage}
            title={'MY APPS'}
        >
        </TableHeader>
        <AccordionTable numColumns={4} renderHeadings={() => (
          <React.Fragment>
            <th className={classes.tableHeaders}>Application</th>
            <th className={classes.tableHeaders} >Environment</th>
            <th className={classes.tableHeaders}>Status</th>
          </React.Fragment>
        )}>
          {
            applications && Object.values(applications).map((application: Application) => (
              <TableRow
                key={application.uuid}
                activeId={application.uuid}
                disabled={application.status === StatusEnum.DISABLED? true:false}
                tableRowContent={() => (
                  <React.Fragment>
                      <td  data-cy={'ApplicationName'}>
                        <Typography
                          variant={'body2'}
                          className={classes.applicationName}>
                          {application.name}
                        </Typography>
                      </td>
                      <td data-cy={'ApplicationEnv'}>
                        <Typography
                          variant={'body2'}
                          className={classes.applicationName}>
                          {application.environment}
                        </Typography>
                      </td>
                      <td data-cy={'ApplicationStatus'}>
                        <ApplicationStatus {...application} />
                      </td>
                      <td data-cy={'ApplicationStatus'}>
                      <Typography
                        variant={'body2'}
                        className={clsx(
                          classes.actions,
                          classes.disabled
                        )}
                        >
                        {application.environment ==="Sandbox" && application.status === StatusEnum.ENABLED && (
                        <Tooltip title={<h3>Disable Application</h3>} placement="bottom-start">
                        <IconButton
                         size="small"
                         className={classes.disabled}
                         onClick = {()=>onAcionsClick("delete",application)}
        
                         >  
                        <CancelIcon/>
                        </IconButton>
                        </Tooltip>
                        )}  
                      </Typography>
                    </td>
                    
                  </React.Fragment>
                )}
                accordionContent={() => (
                  <ApplicationDetails applicationId={application.uuid} />
                )} />
            ))
          }
        </AccordionTable>
        <Box justifyContent={'center'} alignItems={'center'} display={'flex'}>
            <Pagination count={pagesCount}
              onChange={handlePageChange}
              page={page}
            />
        </Box>
       
      </Box>
     
    )
  }

  const renderContent = () =>
  (applications && ( Object.keys(applications).length > 0))
      ? renderApplications()
      : renderNoApplications();

  return isLoading ? <React.Fragment > 
  <div className={classes.loader}> <CircularProgress /></div>
  </React.Fragment > : renderContent();
};

export default ApplicationList;