import React, { FC } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(2),
      backgroundColor: '#3d4b63',
      justifyContent: 'flex-end',
      alignItems:'center',
      mixBlendMode: 'normal',
      borderRadius: '10px',
      position: 'relative',
      display:'flex',
      
    },
    alignleft: {
      float: 'left'
    },
    alignright: {
      float: 'right'
    },
    name: {
      color: 'white',
      fontSize:15,
      flexGrow:1
    },
    live: {
      color: '#00beae',
      fontSize:15
    },
    notLive: {
      color: '#cf6679',
      fontSize:15
    }
  }),
);
interface Props {
  uuid: string;
  grpId: string;
  name: string;
  version:string;
  checkedTiles:any;
  selectionFilter:boolean;
  handleCheckBox: (event:any, newValue:any,domain:string) => void;
  domain:string;
}

const ApiProductCard: FC<Props> = ({ name, uuid, version,grpId,checkedTiles, handleCheckBox, selectionFilter,domain }) => {
  const classes = useStyles();
  return (

      <div className={classes.card}>
          <div  className={classes.name}>
            {name}
          </div>
          <div  className={classes.live}>
            {version}
          </div>
        { selectionFilter && (  <Checkbox
          checked={checkedTiles[uuid] || false}
          onChange={(event:any,newValue:any)=>{ handleCheckBox(event,newValue,domain) }}
          name={uuid}
        />)

        }      
   
      </div>
    
  );
};

export default ApiProductCard;
