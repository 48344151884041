import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { CommonLayout } from '../../components/layout';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Outlet, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Buttonbar from '../../components/buttonbar';
import { checkUserPermission } from '../../core/authorization/service/authorizationService';
import { useTypedSelector } from '../../store';
import { getState } from '../../selectors/selectors';
import { useUserContext } from '../../UserContext';
import LinearProgress from '@material-ui/core/LinearProgress';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullContainer: {
      minHeight: '100vh',
      marginTop:120
    },
    container: {},
    navContainer: {
      padding: theme.spacing(2),
    },
    subContainer: {
      //borderBottom: '1px solid #ffffff1f'
    },
    navlinks: {
      fontWeight: 300,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    navMainLinks:{
      fontWeight:500,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      color:'white',
      borderBottom: '1px solid #ffffff1f',
      fontSize:16
    },
    link: {
      textDecoration: 'none',
      color: 'white',
    },
    active: {
      fontWeight: 'bold',
    },
  }),
);

const AccountBasePage: FC = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { userDetails } = useTypedSelector(state => getState(state));
  const hasPermissionMetrcis = checkUserPermission(userDetails,'viewMetrics');
  const hasPermissionEvents = checkUserPermission(userDetails,'viewEvents');
  const {loadingClickWrap } = useUserContext();

  return (
    <React.Fragment>
        
    <CommonLayout>
       <div className={classes.fullContainer}>
       {loadingClickWrap && <LinearProgress />}
        <Container maxWidth="lg">
          <Grid container>
            <Grid item sm={2} className={classes.navContainer}>
              <div className={classes.subContainer}>
               <div className={classes.navMainLinks}>General</div>
               <div>  
              <Typography variant="body1" className={classes.navlinks}>
                <Link
                  data-cy="Applications"
                  to="/account"
                  className={clsx(
                    classes.link,
                    pathname === '/account' ? classes.active : '',
                  )}
                >
                  Applications
                </Link>
              </Typography>
              {
                hasPermissionMetrcis?(<Typography variant="body1" className={classes.navlinks}>
                <Link
                  data-cy="metrics"
                  to="/account/metrics"
                  className={clsx(
                    classes.link,
                    pathname === '/account/metrics' ? classes.active : '',
                  )}
                >
                 Metrics
                </Link>
              </Typography>):null
              }
              {
                hasPermissionEvents?(<Typography variant="body1" className={classes.navlinks}>
                <Link
                  data-cy="privateEvents"
                  to="/account/events"
                  className={clsx(
                    classes.link,
                    pathname === '/account/events' ? classes.active : '',
                  )}
                >
                 Events
                </Link>
              </Typography>):null
              }
              </div> 
              </div>
               <div className={classes.subContainer}>
               <div className={classes.navMainLinks}>Profile</div>
               <div>  
              <Typography variant="body1" className={classes.navlinks}>
                <Link
                  data-cy="settings"
                  to="/account/settings"
                  className={clsx(
                    classes.link,
                    pathname === '/account/settings' ? classes.active : '',
                  )}
                >
                  Reset Password 
                </Link>
              </Typography>
              </div> 
              </div> 
            </Grid>
            <Grid item sm={10}>
              <Outlet />
            </Grid>
          </Grid>
        </Container>
        <Buttonbar />
      </div>
    </CommonLayout>
    </React.Fragment>
  );
};

export default AccountBasePage;
