import React, { FC } from 'react';
import Card from '@material-ui/core/Card';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { createStyles, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      background: 'transparent',
      flexShrink: 0,
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
      boxShadow: 'none',
    },
    titleTxt: {
      color: 'white',
    },
    box: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: theme.spacing(2),
    },
    textBox: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: theme.spacing(2),
    },
    subTitle: {
      fontWeight: 'bold',
    },
  }),
);

interface Props {
  title: string;
  start: string;
  end: string;
}

const MaintanenceCard: FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <Card className={classes.card} raised>
      <Typography variant="body1" className={classes.titleTxt}>
        {props.title}
      </Typography>
      <div className={classes.box}>
        <CancelIcon color="primary" />
        <div className={classes.textBox}>
          <Typography variant="body1" className={classes.subTitle}>
            Scheduled Start {props.start}
          </Typography>
          <Typography variant="body2"> Scheduled maintenance starts</Typography>
        </div>
      </div>
      <div className={classes.box}>
        <CheckCircleIcon color="secondary" />
        <div className={classes.textBox}>
          <Typography variant="body1" className={classes.subTitle}>
            Anticipated Completion {props.end}
          </Typography>
          <Typography variant="body2"> All systems back up and running</Typography>
        </div>
      </div>
    </Card>
  );
};

export default MaintanenceCard;
