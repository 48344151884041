import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Container, Typography, LinearProgress, Grid, Tabs, Tab, Collapse, Box, Accordion, AccordionSummary, Button, AccordionDetails } from '@material-ui/core';
import { CommonLayout } from '../../components/layout';
import Languages from '../../components/languages';
import Download from '@material-ui/icons/GetApp';
import { useNavigate, Link  } from 'react-router-dom';
import { useTypedDispatch, useTypedSelector } from '../../store/hooks';
import { useLocation, useParams } from 'react-router';
import queryString from 'query-string';
import { fetchGroup, fetchSpec } from '../../store';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreOutlined';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { get } from '../../utils';
import FileSaver from 'file-saver';
import CodeSnippet from '../../components/snippet/snippet';
import clsx from 'clsx';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import nightOwl from 'react-syntax-highlighter/dist/cjs/styles/hljs/night-owl';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { validUuid } from '../../utils/utils';
import { useCookieContext } from '../../CookieContext';
import CookieMessage from '../../components/cookiemessage';
import ClickWrap from '../../components/clickwrap';
import { useUserContext } from '../../UserContext';
import ErrorAlert from '../../components/erroralert';
import SessiontTmeout from '../../components/sessiontimeout';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullContainer: {
      minHeight: '100vh',
      marginTop:120,
      paddingBottom: theme.spacing(10),
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
      color: 'white',
    },
    container: {},
    pageTitle: {
      color: 'white',
      letterSpacing: '0.0625rem',
      fontWeight: 300,
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(2),
    },
    pageSubTitle: {
      color: 'white',
      letterSpacing: '0.0625rem',
      fontWeight: 300,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
    bar: {
      height: 1,
      backgroundColor: '#05a8cc',
    },
    tabs: {
      backgroundColor: 'rgba(20, 35, 60, 0.39)',
      color: 'white',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    description: {
      color: '#C0C0C0',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    titlBar: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: theme.spacing(1),
      color: 'white',
    },
    languageCard: {
      backgroundColor: 'transparent',
      mixBlendMode: 'normal',
      borderRadius: '10px',
      border: '1px solid #3c4257',
    },
    languageHeader: {
      backgroundColor: 'rgba(35, 53, 82, 1)',
      color: 'white',
      padding: theme.spacing(2),
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
    },

    secondaryCard: {
      backgroundColor: 'transparent',
      mixBlendMode: 'normal',
      borderRadius: '10px',
      border: '1px solid #3c4257',
    },
    secondaryCardheader: {
      backgroundColor: 'rgba(35, 53, 82, 1)',
      color: 'white',
      padding: theme.spacing(1),
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
    },
    specWrapper: {
      width: '100%',
      overflowX: 'auto'
    },
    guideInfo:{
      display:'flex',
      alignItems:'center'
    },
    secondaryCardSyntax: {
      overflow: 'initial',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      [theme.breakpoints.between(0, 285)]: {
        width: '65%',
      },
      '@media (orientation: landscape)': {
        width: '100%',
      },
    },
    mobileCard: {
      [theme.breakpoints.between(0, 285)]: {
        maxWidth: '40%',
      },
      [theme.breakpoints.between(290, 395)]: {
        maxWidth: '50%',
      },
    },

    paddingVertical: {
      marginTop: theme.spacing(2),
    },
    languageContent: {
      padding: theme.spacing(2),
    },
    accentColor: {
      color: '#C0C0C0',
    },
    guideContainer:{
      color: '#C0C0C0',
      display:'flex',
      alignItems:'center',
    },
    infoIcon:{
      marginRight:5
    },
	  hyperLink: {
      cursor: 'pointer',
      textDecoration: 'none',
      color: '#00C4B4',
    },
    accentBar: {
      height: 1,
      backgroundColor: '#3c4257',
    },
    propsTitle: {
      // textTransform : 'uppercase',
      fontWeight: 500,
    },
    link: {
      textDecoration: 'none',
    },
    backBtn: {
      paddingTop: theme.spacing(4),
    },
    accordion: {
      backgroundColor: 'transparent',
      margin: 0,
      padding: 0,
      borderColor: '#3c4257',
      borderHeight: 10,
      '& .MuiAccordionDetails-root': {
        padding: 0
      }
    },
    accordionSummary: {
      padding: 0,
      '& .MuiAccordionSummary-content': {
        width: '100%'
      }
    },
    childAccordion: {
      marginLeft: theme.spacing(4),
      backgroundColor: 'transparent',
      borderWidth: 0,
    },
    collapseBox: {
      borderColor: '#3c4257',
      borderWidth: 1,
      borderStyle: 'solid',
      borderRadius: 10,
    },
    hiddenText: {
      overflow: 'hidden',
      height: 500,
      width: '100%',
      position: 'relative',
      marginBottom: theme.spacing(1),
    },
    showMore: {
      display: 'none',
    },
    showMoreButton: {
      position: 'absolute',
      color: 'primary',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(5),
    },
    hideShowMoreButton: {
      display: 'none',
    },
    showMoreAccordion: {
      backgroundColor: 'transparent',
      margin: 0,
      padding: 0,
      WebkitMaskImage: 'linear-gradient(180deg, #000 40%, transparent)',
    },
    accentBarExpanded: {
      height: 1,
      backgroundColor: '#3c4257',
      marginTop: theme.spacing(5),
    },
    accordianSummaryRequest:{
      marginLeft:20,
      padding: 0,
      '& .MuiAccordionSummary-content': {
        width: '100%'
      },
      '& .MuiAccordionSummary-expandIcon': {
        display:'none'
      }
    }
  }),
);

type Api = {
  name: string;
  description: string;
  uuid: string;
  version: string;
  soon?: boolean;
};

const SpecsPage: FC = () => {
  const classes = useStyles();

  const location = useLocation();
  const { id } = useParams();
  const querySearched = queryString.parse(location.search);
  const apiId = querySearched?.api;
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<number>(0);
  const { loading, specs } = useTypedSelector((states) => states.specs);
  const { group } = useTypedSelector((states) => states.group);
  const dispatch = useTypedDispatch();
  const [activeApi, setActiveApi] = useState<Api | null>(null);

  const [currentApiId, setCurrentApidId] = useState(apiId);
  const [downloading, setDowloading] = useState(false);
  const [isShowMore, setIsShowMore] = useState(true);
  const [expanded, setExpanded] = React.useState(false);
  
  const [isShowMoreRequest, setIsShowMoreRequest] = useState(true);
  const [expandedRequest, setExpandedRequest] = React.useState(false);
  const cookieDetails = useCookieContext();
  //@ts-ignore
  const { showCookieMessage } = cookieDetails;
  const userdetails = useUserContext();
  // @ts-ignore
  // prettier-ignore
  const { showClickWrap, exceptionClickWrap, loadingClickWrap, acceptClickWrapdetails, showSessionTimeoutModel, } = userdetails;

  const handleClickwrapClose = () => {
    acceptClickWrapdetails();
  };
  useEffect(() => {
    if (group.name === '') {
      // @ts-ignore:
      dispatch(fetchGroup(id));
    }
    // @ts-ignore:
    if (validUuid(currentApiId)) {
      // @ts-ignore:
      dispatch(fetchSpec(currentApiId));
    } else {
      navigate('/notFound');
    }
  }, [id, group, currentApiId, dispatch, navigate]);

  useEffect(() => {
    if (group.name !== '' && currentApiId !== null) {
      // @ts-ignore: Unreachable code error
      setActiveApi(group.apis[currentApiId]);
    }
  }, [currentApiId, group]);
  const apis = Object.keys(group?.apis);

  useEffect(() => {
    // @ts-ignore:
    if (apis.length > 0 && apis.indexOf(currentApiId) !== -1) {
      // @ts-ignore:
      setActiveTab(apis.indexOf(currentApiId));
    }
  }, [apis, currentApiId]);

  // @ts-ignore: Unreachable code error
  const activeSpec = specs[currentApiId];

  const changeTab = (event: ChangeEvent<{}>, newIndex: number) => {
    setActiveTab(newIndex);
    setCurrentApidId(apis[newIndex]);
    setIsShowMore(true);
    setIsShowMoreRequest(true);
    setExpanded(false);
    setExpandedRequest(false);
  };

  const downloadSpec = async () => {
    try {
      setDowloading(true);
      const data = await get(`/bff-private/api/${currentApiId}/assets`, true);
      FileSaver.saveAs(data, 'spec.json');
      setDowloading(false);
    } catch (e) {
      setDowloading(false);
    }
  };

  const downloadSdk = async (language: string) => {
    try {
      const data = await get(`/bff-private/api/${currentApiId}/clients/${language}`, true);
      FileSaver.saveAs(data, `${language}-${activeApi?.name}.zip`);
    } catch (e) { }
  };

  const RenderChildren = (props: { data?: any }) => {
    const [open, setOpen] = useState(true);
    return (
      <React.Fragment>
        <Typography variant="body2" color="primary" onClick={() => setOpen(!open)}>
          {open && (
            <Box
              height={50}
              marginBottom={2}
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
              className={classes.collapseBox}
            >
              <RemoveIcon />
              CLOSE CHILD ELEMENTS
            </Box>
          )}
          {!open && (
            <Box
              height={50}
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
              className={classes.collapseBox}
            >
              <AddIcon />
              SHOW CHILD ELEMENTS
            </Box>
          )}
        </Typography>
        <Collapse in={open} className={classes.childAccordion}>
          {props.data.length > 0 &&
            props.data.map((eachProperty: any, index: number) => {
              return (
                <Box key={index}>
                  <Box display="flex" flexDirection="row" marginBottom={2}>
                    <Typography>{eachProperty.name}</Typography>
                    <Box marginLeft={2}>
                      <Typography className={classes.accentColor}>{eachProperty.type}</Typography>
                    </Box>
                  </Box>
                  <Typography className={classes.accentColor}>
                    {eachProperty.description}
                  </Typography>
                  {eachProperty.children?.length > 0 && (
                    <RenderChildren data={eachProperty.children} />
                  )}
                </Box>
              );
            })}
        </Collapse>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <CommonLayout>
        <div className={classes.fullContainer} data-testid="specs">
        {(loading || loadingClickWrap) && <LinearProgress />}
          <Container maxWidth="md">
            <div className={classes.backBtn}>
              <Link to={`/groups/${group?.uuid}`} className={classes.link}>
                <Button variant="text" color="primary">
                  <ArrowBackIosIcon fontSize="small" />
                  BACK
                </Button>
              </Link>
            </div>
            <Typography variant="h4" className={classes.pageTitle}>
              {`${group?.product} ${group?.name}`}
            </Typography>
            <Typography variant="h6" className={classes.pageSubTitle}>
              {group?.description}
            </Typography>
            <Tabs
              value={activeTab}
              className={classes.tabs}
              onChange={changeTab}
              variant="scrollable"
              scrollButtons="on"
            >
              {apis?.length > 0 &&
                apis.map((eachApi, index) => {
                  const api = group?.apis[eachApi];
                  return <Tab label={api.name} key={index} />;
                })}
            </Tabs>
            <Grid container spacing={10}>
              <Grid item xs={12} sm={6}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h5">{activeApi?.name}</Typography>
                  <Box className={classes.titlBar}>
                    <Typography variant="body2" color="secondary">
                      {activeSpec?.version}
                    </Typography>
                  </Box>
                </Box>

                <Typography variant="body1" className={classes.description}>
                  {activeApi?.description}
                </Typography>
              </Grid>
              <Grid item xs={10} sm={6}>
                <Box className={classes.titlBar}>
                  <Button
                    variant="text"
                    color="primary"
                    startIcon={<Download />}
                    onClick={downloadSpec}
                  >
                    {`Download${downloading ? 'ing' : ''} full spec`}
                  </Button>
                </Box>

                <div className={classes.languageCard}>
                  <div className={classes.languageHeader}>
                    <Typography>Available Languages</Typography>
                  </div>
                  <Box p={2} color="#C0C0C0">
                    <Typography variant="body2">
                      By default, the NT API Docs demonstrate using curl to interact with the API
                      over HTTP. Select one of our official client libraries to see examples.
                    </Typography>
                    <Box marginTop={4}>
                      <Languages size="small" onPress={downloadSdk} clickable={true} />
                    </Box>
                  </Box>
                </div>
              </Grid>
            </Grid>
            <Box
              className={!expanded ? classes.accentBar : classes.accentBarExpanded}
              marginY={4}
            />
            <Box marginTop={2} marginBottom={2}>
              <Typography variant="h6">{activeApi?.name} Object</Typography>
            </Box>
            <Accordion
              expanded={expanded}
              classes={{ root: !expanded ? classes.showMoreAccordion : classes.accordion }}
            >
              <AccordionSummary
                className={classes.accordionSummary}
                data-testid="ShowMore"
                data-cy="responseShow"
              >
                <div
                  className={isShowMore ? clsx(classes.hiddenText) : clsx(classes.showMore)}
                  data-testid={'snippetShowMore'}
                >


                  <Grid container spacing={5} direction="row">
                    <Grid item xs={12} sm={6} className={classes.mobileCard}>
                      <Typography variant="body2" color="primary" className={classes.propsTitle}>
                       Response Body
                      </Typography>
                      <Box className={classes.accentBar} marginY={1} />
                      <Box marginTop={2} marginBottom={2} />
                      {activeSpec?.model?.schema?.map((eachProperty: any, index: number) => {
                        return (
                          <Box key={index} overflow={'auto'}>
                            <Box display="flex" flexDirection="row" marginBottom={2}>
                              <Typography>{eachProperty.name}</Typography>
                              <Box marginLeft={2}>
                                <Typography className={classes.accentColor}>
                                  {eachProperty.type}
                                </Typography>
                              </Box>
                            </Box>
                            <Typography className={classes.accentColor}>
                              {eachProperty.description}
                            </Typography>
                            {eachProperty.children?.length > 0 && (
                              <RenderChildren data={eachProperty.children} />
                            )}
                            <Box className={classes.accentBar} marginY={2} />
                          </Box>
                        );
                      })}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Box
                        className={classes.secondaryCardheader}
                        flexDirection="row"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography variant="body2">{activeApi?.name}</Typography>
                        <Box display="flex" flexDirection="row" justifyContent="flex-end">
                          <CopyToClipboard text={JSON.stringify(activeSpec?.model.sample)}>
                            <Button color="primary" startIcon={<FileCopyIcon />}>
                              COPY
                            </Button>
                          </CopyToClipboard>
                        </Box>
                      </Box>

                      {activeSpec?.model?.sample.responseSample && (
                        <SyntaxHighlighter language="JSON" style={nightOwl}>
                          {JSON.stringify(activeSpec?.model?.sample.responseSample, null, 2)}
                        </SyntaxHighlighter>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </AccordionSummary>

              <Box marginY={-5} />

              <AccordionDetails>
                <Box style={{ width: '100%' }}>
                  <Grid container spacing={5} direction="row">
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2" color="primary" className={classes.propsTitle}>
                        Response Body
                      </Typography>
                      <Box className={classes.accentBar} marginY={1} />
                      <Box marginTop={2} marginBottom={2} />
                      <Box className={classes.specWrapper}>
                        {activeSpec?.model?.schema?.map((eachProperty: any, index: number) => {
                          return (
                            <Box
                              key={index}
                              overflow={'auto'}
                              className={classes.secondaryCardSyntax}
                            >
                              <Box display="flex" flexDirection="row" marginBottom={2}>
                                <Typography>{eachProperty.name}</Typography>
                                <Box marginLeft={2}>
                                  <Typography className={classes.accentColor}>
                                    {eachProperty.type}
                                  </Typography>
                                </Box>
                              </Box>
                              <Typography className={classes.accentColor}>
                                {eachProperty.description}
                              </Typography>
                              {eachProperty.children?.length > 0 && (
                                <RenderChildren data={eachProperty.children} />
                              )}
                              <Box className={classes.accentBar} marginY={2} />
                            </Box>
                          );
                        })}
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <div className={classes.secondaryCardSyntax}>
                        <Box
                          className={classes.secondaryCardheader}
                          flexDirection="row"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography variant="body2">{activeApi?.name}</Typography>
                          <Box display="flex" flexDirection="row" justifyContent="flex-end">
                            <CopyToClipboard text={JSON.stringify(activeSpec?.model.sample)}>
                              <Button color="primary" startIcon={<FileCopyIcon />}>
                                COPY
                              </Button>
                            </CopyToClipboard>
                          </Box>
                        </Box>

                        {activeSpec?.model?.sample?.responseSample && (
                          <SyntaxHighlighter language="JSON" style={nightOwl}>
                            {JSON.stringify(activeSpec?.model?.sample?.responseSample, null, 2)}
                          </SyntaxHighlighter>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>

            <Box textAlign="center" marginBottom={3}>
              <Button
                color="primary"
                onClick={() => {
                  setExpanded(!expanded);
                  setIsShowMore(!isShowMore);
                }}
                className={classes.showMoreButton}
                data-testid={!expanded ? 'Hide' : 'ShowMore'}
                data-cy="ShowMore"
              >
                {!expanded ? 'SHOW MORE' : 'HIDE'}{' '}
                {!expanded ? <KeyboardArrowDownIcon /> : <ExpandLessIcon />}
              </Button>
            </Box>
            <Box marginTop={8}>
              <Typography variant="h6">{activeSpec?.paths.length > 0 && activeSpec?.paths[0].summary}</Typography>
            </Box>
              {activeSpec?.paths.length > 0 &&
              activeSpec.paths.map((eachPath: any, index: any) => {
                return (
                  <Accordion
                    expanded={expandedRequest}
                    classes={{ root: !expandedRequest ? classes.showMoreAccordion : classes.accordion }}
                    key={index}
                  >
                    <AccordionSummary
                      classes={{ root: classes.accordianSummaryRequest }}
                      expandIcon={<ExpandMoreIcon color="primary" />}
                    >
                     <div
                  className={isShowMoreRequest ? clsx(classes.hiddenText) : clsx(classes.showMore)}
                  data-testid={'snippetShowMoreRequest'}
                >
                <Box style={{ width: '100%' }}>
                  <Grid container spacing={4} direction="row">
                    <Grid item xs={12} sm={6}>
                      
                      <Typography variant="body2" color="primary" className={classes.propsTitle}>
                      {eachPath.method === 'GET' ? 'Properties' : 'Request Body'}
                      </Typography>
                      <Box className={classes.accentBar} marginY={1} />
                      <Box marginTop={2} marginBottom={2} />
                      <Box className={classes.specWrapper}>
                        {eachPath.method === 'GET' && eachPath?.parameters?.map((eachProperty: any, index: number) => {
                          return (
                            <Box
                              key={index}
                              overflow={'auto'}
                              className={classes.secondaryCardSyntax}
                            >
                              <Box display="flex" flexDirection="row" marginBottom={2}>
                                <Typography>{eachProperty.name}</Typography>
                                <Box marginLeft={2}>
                                  <Typography className={classes.accentColor}>
                                    {eachProperty.type}
                                  </Typography>
                                </Box>
                              </Box>
                              <Typography className={classes.accentColor}>
                                {eachProperty.description}
                              </Typography>
                              {eachProperty.children?.length > 0 && (
                                <RenderChildren data={eachProperty.children} />
                              )}
                              <Box className={classes.accentBar} marginY={2} />
                            </Box>
                          );
                        })}
                        {eachPath.method === 'POST' && eachPath?.requestBody?.map((eachProperty: any, index: number) => {
                          return (
                            <Box
                              key={index}
                              overflow={'auto'}
                              className={classes.secondaryCardSyntax}
                            >
                              <Box display="flex" flexDirection="row" marginBottom={2}>
                                <Typography>{eachProperty.name}</Typography>
                                <Box marginLeft={2}>
                                  <Typography className={classes.accentColor}>
                                    {eachProperty.type}
                                  </Typography>
                                </Box>
                              </Box>
                              <Typography className={classes.accentColor}>
                                {eachProperty.description}
                              </Typography>
                              {eachProperty.children?.length > 0 && (
                                <RenderChildren data={eachProperty.children} />
                              )}
                              <Box className={classes.accentBar} marginY={2} />
                            </Box>
                          );
                        })}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <div className={classes.secondaryCard}>
                          <div className={clsx(classes.secondaryCardheader,classes.guideInfo)}>
                            <InfoIcon className={classes.infoIcon}></InfoIcon>
                            <Typography variant="body2">HOW TO USE?</Typography>
                          </div>
                          <Box display="flex" flexDirection="row" m={2}>
                            <Box marginLeft={1}>
                              <Typography variant="body2" className={classes.guideContainer}>
                               <span><></>   
                                <Link className={classes.hyperLink} to="/get-started/access-setup">
                                  Click here
                                </Link>
                                {' '}to learn how to use your{' '} credentials.            
                                </span>
                              </Typography>
                            </Box>
                          </Box>
                        </div>   
                    <div className={clsx(classes.secondaryCard, classes.paddingVertical)}>
                          <div className={classes.secondaryCardheader}>
                            <Typography variant="body2">PATH</Typography>
                          </div>
                          <Box display="flex" flexDirection="row" m={2}>
                            <Typography variant="body2" color="secondary">
                              {eachPath?.method}
                            </Typography>
                            <Box marginLeft={1}>
                              <Typography variant="body2" className={classes.accentColor}>
                                {eachPath?.path}
                              </Typography>
                            </Box>
                          </Box>
                        </div>
                        <CodeSnippet
                          apiId={currentApiId}
                          path={eachPath?.path}
                          method={eachPath?.method}
                        />
                        {eachPath?.method=== 'POST' && (<div className={classes.secondaryCardSyntax}>
                        <Box
                          className={classes.secondaryCardheader}
                          flexDirection="row"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography variant="body2">{activeApi?.name}</Typography>
                          <Box display="flex" flexDirection="row" justifyContent="flex-end">
                            <CopyToClipboard text={JSON.stringify(activeSpec?.model?.sample?.requestSample)}>
                              <Button color="primary" startIcon={<FileCopyIcon />}>
                                COPY
                              </Button>
                            </CopyToClipboard>
                          </Box>
                        </Box>

                        {activeSpec?.model?.sample?.requestSample && (
                          <SyntaxHighlighter language="JSON" style={nightOwl}>
                            {JSON.stringify(activeSpec?.model?.sample?.requestSample, null, 2)}
                          </SyntaxHighlighter>
                        )}
                      </div>)}
                    </Grid>
                   
                    
                      
                    
                  </Grid>
                </Box>
                </div>
                    </AccordionSummary>
                    
                    <AccordionDetails>
                    <Box style={{ width: '100%' }}>
                  <Grid container spacing={5} direction="row">
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2" color="primary" className={classes.propsTitle}>
                      {eachPath.method === 'GET' ? 'Properties' : 'Request Body'}
                      </Typography>
                      <Box className={classes.accentBar} marginY={1} />
                      <Box marginTop={2} marginBottom={2} />
                      <Box className={classes.specWrapper}>
                        {eachPath.method === 'GET' && eachPath?.parameters?.map((eachProperty: any, index: number) => {
                          return (
                            <Box
                              key={index}
                              overflow={'auto'}
                              className={classes.secondaryCardSyntax}
                            >
                              <Box display="flex" flexDirection="row" marginBottom={2}>
                                <Typography>{eachProperty.name}</Typography>
                                <Box marginLeft={2}>
                                  <Typography className={classes.accentColor}>
                                    {eachProperty.type}
                                  </Typography>
                                </Box>
                              </Box>
                              <Typography className={classes.accentColor}>
                                {eachProperty.description}
                              </Typography>
                              {eachProperty.children?.length > 0 && (
                                <RenderChildren data={eachProperty.children} />
                              )}
                              <Box className={classes.accentBar} marginY={2} />
                            </Box>
                          );
                        })}
                        {eachPath.method === 'POST' && eachPath?.requestBody?.map((eachProperty: any, index: number) => {
                          return (
                            <Box
                              key={index}
                              overflow={'auto'}
                              className={classes.secondaryCardSyntax}
                            >
                              <Box display="flex" flexDirection="row" marginBottom={2}>
                                <Typography>{eachProperty.name}</Typography>
                                <Box marginLeft={2}>
                                  <Typography className={classes.accentColor}>
                                    {eachProperty.type}
                                  </Typography>
                                </Box>
                              </Box>
                              <Typography className={classes.accentColor}>
                                {eachProperty.description}
                              </Typography>
                              {eachProperty.children?.length > 0 && (
                                <RenderChildren data={eachProperty.children} />
                              )}
                              <Box className={classes.accentBar} marginY={2} />
                            </Box>
                          );
                        })}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <div className={clsx(classes.secondaryCard, classes.paddingVertical)}>
                          <div className={clsx(classes.secondaryCardheader,classes.guideInfo)}>
                            <InfoIcon className={classes.infoIcon}></InfoIcon>
                            <Typography variant="body2">HOW TO USE?</Typography>
                          </div>
                          <Box display="flex" flexDirection="row" m={2}>
                            <Box marginLeft={1}>
                              <Typography variant="body2" className={classes.guideContainer}>
                               <span><></>   
                                <Link className={classes.hyperLink} to="/get-started/access-setup">
                                  Click here
                                </Link>
                                {' '}to learn how to use your{' '} credentials.            
                                </span>
                              </Typography>
                            </Box>
                          </Box>
                        </div> 
                    <div className={clsx(classes.secondaryCard,classes.paddingVertical)}>
                          <div className={classes.secondaryCardheader}>
                            <Typography variant="body2">PATH</Typography>
                          </div>
                          <Box display="flex" flexDirection="row" m={2}>
                            <Typography variant="body2" color="secondary">
                              {eachPath?.method}
                            </Typography>
                            <Box marginLeft={1}>
                              <Typography variant="body2" className={classes.accentColor}>
                                {eachPath?.path}
                              </Typography>
                            </Box>
                          </Box>
                        </div>
                        <CodeSnippet
                          apiId={currentApiId}
                          path={eachPath?.path}
                          method={eachPath?.method}
                        />
                      {eachPath?.method=== 'POST' && (<div className={classes.secondaryCardSyntax}>
                        <Box
                          className={classes.secondaryCardheader}
                          flexDirection="row"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography variant="body2">{activeApi?.name}</Typography>
                          <Box display="flex" flexDirection="row" justifyContent="flex-end">
                            <CopyToClipboard text={JSON.stringify(activeSpec?.model?.sample?.requestSample)}>
                              <Button color="primary" startIcon={<FileCopyIcon />}>
                                COPY
                              </Button>
                            </CopyToClipboard>
                          </Box>
                        </Box>

                        {activeSpec?.model?.sample?.requestSample && (
                          <SyntaxHighlighter language="JSON" style={nightOwl}>
                            {JSON.stringify(activeSpec?.model?.sample?.requestSample, null, 2)}
                          </SyntaxHighlighter>
                        )}
                      </div>)}
                    </Grid>
                   </Grid>
                </Box>
              </AccordionDetails>
                </Accordion>
                );
              })}
              <Box textAlign="center" marginBottom={3}>
              <Button
                color="primary"
                onClick={() => {
                  setExpandedRequest(!expandedRequest);
                  setIsShowMoreRequest(!isShowMoreRequest);
                }}
                className={classes.showMoreButton}
                data-testid={!expandedRequest ? 'HideRequest' : 'ShowMoreRequest'}
                data-cy="ShowMoreRequest"
              >
                {!expandedRequest ? 'SHOW MORE' : 'HIDE'}{' '}
                {!expandedRequest ? <KeyboardArrowDownIcon /> : <ExpandLessIcon />}
              </Button>
            </Box>

            <Box marginTop={10} />
          </Container>
          {exceptionClickWrap && <ErrorAlert></ErrorAlert>}
        </div>
        <CookieMessage state={showCookieMessage}></CookieMessage>
        <ClickWrap state={showClickWrap} handleClose={handleClickwrapClose}></ClickWrap>
        <SessiontTmeout state={showSessionTimeoutModel}></SessiontTmeout>
      </CommonLayout>
    </React.Fragment>
  );
};

export default SpecsPage;
