import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import Grid from '@material-ui/core/Grid';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import ContactMailOutlinedIcon from '@material-ui/icons/ContactMailOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import FlareOutlinedIcon from '@material-ui/icons/FlareOutlined';
import TrackChangesOutlinedIcon from '@material-ui/icons/TrackChangesOutlined';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import { StepCard } from '../../components/cards';
import { useCookieContext } from '../../CookieContext';
import { useUserContext } from '../../UserContext';
import CookieMessage from '../../components/cookiemessage';
import ClickWrap from '../../components/clickwrap';
import LinearProgress from '@material-ui/core/LinearProgress';
import ErrorAlert from '../../components/erroralert'
import SessiontTmeout  from '../../components/sessiontimeout';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullContainer: {
      minHeight: '100vh',
    },
    container: {
      // minHeight: '1450px',
    },
    pathway: {
      // height: 'auto',
      position: 'absolute',
      // top: 200,
      left: '45%',
    },
    pathwayImg: {
      // width: 850,
      height: '1913px',
      objectFit: 'none',
      objectPosition: 'right',
      // transform: 'rotate(-2deg)',
      marginLeft: 115,
      [theme.breakpoints.down('lg')]: {
        // width: 650,
        height: 1500,
      },
      [theme.breakpoints.down('md')]: {
        // width: 420,
        height: 1400,
        transform: 'rotate(0)',
      },
    },
    pageTitle: {
      color: 'white',
      letterSpacing: '0.0625rem',
      fontWeight: 300,
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
    },
    subTitle: {
      fontWeight: 500,
    },
    rule: {
      height: 1,
      backgroundColor: grey[400],
    },
    icon: {
      fontSize: 70,
      opacity: 0.4,
    },
  }),
);

const GetStartedPage: FC = () => {
  const classes = useStyles();
  const cookieDetails = useCookieContext();
  //@ts-ignore
  const { showCookieMessage } = cookieDetails;
  const userdetails = useUserContext();
  // @ts-ignore
  const { showClickWrap, exceptionClickWrap, loadingClickWrap,acceptClickWrapdetails, showSessionTimeoutModel } = userdetails;
  
  const handleClickwrapClose = () => {
    acceptClickWrapdetails();
  };
  return (
    <React.Fragment>
      {loadingClickWrap && <LinearProgress />}
    <div className={classes.fullContainer}>
      <Container maxWidth="md" className={classes.container}>
        <Typography variant="h4" className={classes.pageTitle}>
          Get Started
        </Typography>
        <Grid container>
          <Grid item sm={10} md={6}>
            <Grid container spacing={5} direction="column">
              <Grid item sm={10}>
                <StepCard
                  step={'1'}
                  title="Explore our APIs"
                  description="Browse our available APIs and learn more about their features"
                  buttonLabel="VIEW ALL"
                  buttonLink="/apis"
                  icon={<VisibilityOutlinedIcon color="primary" className={classes.icon} />}
                />
              </Grid>
              <Grid item sm={10}>
                <StepCard
                  step={'2'}
                  title="Setup Access"
                  description="Request access by initiating the registration process "
                  buttonLabel="Setup Access"
                  buttonLink="/get-started/access-setup"
                  icon={<ContactMailOutlinedIcon color="primary" className={classes.icon} />}
                />
              </Grid>
              <Grid item sm={10}>
                <StepCard
                  step={'3'}
                  title="View our API Specs"
                  description="Navigate through the technical documentation of relevant APIs "
                  icon={<AssignmentOutlinedIcon color="primary" className={classes.icon} />}
                />
              </Grid>
              <Grid item sm={10}>
                <StepCard
                  step={'4'}
                  title="Register your App"
                  description="Provide preliminary information to add your application"
                  icon={<FlareOutlinedIcon color="primary" className={classes.icon} />}
                />
              </Grid>
              <Grid item sm={10}>
                <StepCard
                  step={'5'}
                  title="Test your App"
                  description="Test how your application works in our sandbox environment"
                  icon={<TrackChangesOutlinedIcon color="primary" className={classes.icon} />}
                />
              </Grid>
              <Grid item sm={10}>
                <StepCard
                  step={'6'}
                  title="Go Live!"
                  description="Submit your application for approval to connect with our live APIs"
                  icon={<EmojiObjectsOutlinedIcon color="primary" className={classes.icon} />}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {exceptionClickWrap && <ErrorAlert></ErrorAlert>}
      <CookieMessage state={showCookieMessage} ></CookieMessage>
      <ClickWrap state={showClickWrap} handleClose={handleClickwrapClose}></ClickWrap>
      <SessiontTmeout state={showSessionTimeoutModel}></SessiontTmeout>
    </div>
    </React.Fragment>
  );
};

export default GetStartedPage;
