import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import { CommonLayout } from '../../components/layout';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Outlet, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Buttonbar from '../../components/buttonbar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullContainer: {
      minHeight: '100vh',
      marginTop:120
    },
    container: {},
    navContainer: {
      padding: theme.spacing(2),
      position:'fixed',
      [theme.breakpoints.down('sm')]: {
        position: 'initial'
      },
    },
    navlinks: {
      fontWeight: 400,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    link: {
      textDecoration: 'none',
      color: 'white',
    },
    active: {
      fontWeight: 'bold',
    },
  }),
);

const GetStartedBasePage: FC = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  return (
    <CommonLayout>
      <div className={classes.fullContainer}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item sm={2} className={classes.navContainer}>
              <Typography variant="body1" className={classes.navlinks}>
                <Link
                  to="/get-started"
                  className={clsx(classes.link, pathname === '/get-started' ? classes.active : '')}
                >
                  Get Started
                </Link>
              </Typography>
              <Typography variant="body1" className={classes.navlinks}>
                <Link
                  to="/get-started/access-setup"
                  className={clsx(
                    classes.link,
                    pathname === '/get-started/access-setup' ? classes.active : '',
                  )}
                >
                  Access Setup
                </Link>
              </Typography>
              <Typography variant="body1" className={classes.navlinks}>
                <Link
                  to="/get-started/event-notification-setup"
                  className={clsx(
                    classes.link,
                    pathname === '/get-started/event-notification-setup' ? classes.active : '',
                  )}
                >
                  Event Notification Setup
                </Link>
              </Typography>
            </Grid>
            <Grid item sm={2}></Grid>
            <Grid item sm={10}>
              <Outlet />
            </Grid>
          </Grid>
        </Container>
        <Buttonbar />
      </div>
    </CommonLayout>
  );
};

export default GetStartedBasePage;
