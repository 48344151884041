import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import { Typography, Container } from '@material-ui/core';
import { CommonLayout } from '../../components/layout';
import { useCookieContext } from '../../CookieContext';
import CookieMessage from '../../components/cookiemessage';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullContainer: {
      minHeight: '100vh',
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
    pageNotFoundContent: {
      color: 'white',
      letterSpacing: '0.0625rem',
      fontWeight: 300,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    pageNotFoundTitle: {
      color: 'white',
      letterSpacing: '0.0625rem',
      marginTop: theme.spacing(5),
      fontWeight: 200,
    },
  }),
);

const NotFoundPage: FC = () => {
  const classes = useStyles();
  const cookieDetails = useCookieContext();
  //@ts-ignore
  const { showCookieMessage } = cookieDetails;

  return (
    <CommonLayout>
      <div className={classes.fullContainer}>
        <Container maxWidth="md" data-cy="404NotFound">
          <Typography variant="h4" className={classes.pageNotFoundTitle}>
            404
          </Typography>
          <Typography variant="h4" className={classes.pageNotFoundContent}>
            Oops! Page not found.....
          </Typography>
        </Container>
      </div>
      <CookieMessage state={showCookieMessage} ></CookieMessage>
    </CommonLayout>
  );
};

export default NotFoundPage;
