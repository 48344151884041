import { Box, Container, Grid, Button, useMediaQuery, FormControl, TextField } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useUserContext } from '../../UserContext';
import { fetchAccountData } from '../../store/accountSlice';
import ApiProductsContainer from '../../containers/apiProductsContainer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CookieMessage from '../../components/cookiemessage';
import ClickWrap from '../../components/clickwrap';
import SessiontTmeout  from '../../components/sessiontimeout';
import { useCookieContext } from '../../CookieContext';
import LinearProgress from '@material-ui/core/LinearProgress';
import ErrorAlert from '../../components/erroralert';
import { useLocation } from 'react-router';	
import { useNavigate } from 'react-router-dom';
import { PRODUCTION_HOST_NAME_LIST } from '../../constants/constants';
import { checkUserPermission } from '../../core/authorization/service/authorizationService';
import { useTypedSelector } from '../../store';
import { getState, getApplications } from '../../selectors/selectors';
import { post, alphanumericRegex } from '../../utils';
import { SuccessAlert } from '../../components/successalert';
import { setShowAlert, getEntitlementsData, fetchApplicationsData, setMessage } from '../../store/applicationsSlice';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Controller, useForm } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accountPage: {
      minHeight: '100vh',
      paddingBottom: theme.spacing(10),
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
    loader:{
      margin:'10px 0px',
      height:30
    },
    pageTitle: {
      color: 'white',
      letterSpacing: '0.0625rem',
      flexGrow:1,
      fontWeight: 300,
      marginTop: theme.spacing(5),
      paddingBottom:20,
      [theme.breakpoints.down('md')]: {
        paddingBottom:10,
      },
    },
    container: {
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    navigationContainer:{
      display:'flex',
      alignItems:'center',
      justifyContent:'space-between',
      padding:'20px 10px'
    },
    headerContainer:{
      display:'flex',
      alignItems:'baseLine',

      borderBottom:'1px solid white'  
    },
    backButton:{
      marginRight:15
    },
    actionButton:{
      minWidth:110
    },
    noOrganizationHeader:{
      background:'#ffffff1f',
      padding:10,
      marginBottom:25,
      color:'white',
      fontWeight: 300
    },
    mobilecontainer: {
      width: '100%'
    },
    header:{
      width: '100%',
      padding:'15px 0px',
      color:'white',
      fontSize:16,
      fontWeight:300
    },
    tag:{
      background:'#ffffff1f',
      color:'white',
      padding:5,
      margin:3,
      borderRadius:'1px solid #ffffff1f'
    },
    beta:{
      padding:7,
      margin:10,
      color:'#00c4b4',
      border:'1px solid #00c4b4',
      borderRadius:7
    }
  }));

  type Domain = {
    name: string;
    apiProducts: Array<string>;
  };
  
  type ApiProduct = {
    uuid: string;
    name: string;
    version: string;
    selected?:string;
  };
  
  interface AllDomains {
    [key: string]: Domain;
  }
  interface AllApiProdcuts {
    [key: string]: ApiProduct;
  }
  interface IFormInput {
    applicationName: string;
    description: string;
  }

const AppManagementPage: React.FC<{}> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cookieDetails = useCookieContext();
  const location = useLocation();
  const querySearched = location.search || '';
  const currentHostName = window.location.hostname; 
  const params = new URLSearchParams(querySearched);
  const sponsorCode = params.get('sponsorCode') ||'';
  const { userDetails } = useTypedSelector(state => getState(state));
  const [formError, setformError] = useState('');
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [applicationName, setApplicationName] = useState('');
  const [description, setDescription] = useState('');
  
  const [showAltert, setShowAltert] = useState(false);
  const [showHeaderAlert,setShowHeaderAltert] = useState(false);
  const [areApisSelected, setAreApisSelected] = useState(true);
  const hasPermissionCreateApps = checkUserPermission(userDetails,'createApps'); 


  const appsData  = useTypedSelector(state => getApplications(state));
  const AllDomains: AllDomains = appsData?.domains;
  const currentapiProducts : AllApiProdcuts = appsData?.apiProducts;
  let AllDomainsChekboxes: any = {};

  AllDomains && Object.keys(AllDomains).length > 0  && Object.entries(AllDomains).forEach(([domainkey])=>{
    //@ts-ignore
    let checkedApis: any = {};
    AllDomains[domainkey].apiProducts.forEach((key :any)=>{
    const flag = currentapiProducts[key].selected === "true"? true: false;
    //@ts-ignore
    checkedApis = {
      ...checkedApis,
      [currentapiProducts[key].uuid]:flag
    }

  });
  //@ts-ignore
    AllDomainsChekboxes = {
      ...AllDomainsChekboxes,
      [AllDomains[domainkey].name]:checkedApis
    }

  });
  
  const [checkedApis, setCheckedApis] = useState(AllDomainsChekboxes);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((theme:any) => theme.breakpoints.down("sm"));
  //@ts-ignore 
	const { showCookieMessage } = cookieDetails;
  const {
    isAuthenticated,
    showClickWrap,
    exceptionClickWrap,
    acceptClickWrapdetails,
    showSessionTimeoutModel
  } = useUserContext();

  const handleClickwrapClose = () => {
    acceptClickWrapdetails();
  };
 
  const fetchData = useCallback(() => {
    if (isAuthenticated === true && userDetails.roles === undefined) {
      
      if (PRODUCTION_HOST_NAME_LIST.indexOf (currentHostName) >-1) {
        //if production urls
        dispatch(fetchAccountData(''));
      }else{
        if(querySearched && querySearched.includes("sponsorCode")){
          dispatch(fetchAccountData(querySearched));
        }else{
          dispatch(fetchAccountData(''));
        }
      }
    }
    dispatch(getEntitlementsData({
      env:'sandbox'
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isAuthenticated,querySearched,currentHostName,userDetails,sponsorCode]);

  useEffect(() => {
    if(isAuthenticated === true && userDetails.roles && userDetails.roles.length>0){
      if(hasPermissionCreateApps !== true){
        navigate('/account');
      }
    }  
   
  }, [hasPermissionCreateApps,navigate,isAuthenticated,userDetails]);

  const ariaLabel = 'aria-label';
  const { control, handleSubmit, errors, setValue } = useForm<IFormInput>({
    mode: 'onChange',
    defaultValues: {
      applicationName:  applicationName ||'',
      description:   description || ''
    },
  });

  useEffect(() => {
    setValue('description', description ?? '');
    setValue('applicationName', applicationName ?? '');
  }, [ applicationName, description, setValue, step]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated,dispatch]);
  
  useEffect(()=>{
    if(AllDomains && Object.keys(AllDomains).length > 0 ){
      setShowHeaderAltert(false);
    }else
    {
      setShowHeaderAltert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[appsData?.domains])

  const handleDomainsCheckboxChange = (event: any) =>{
    event.stopPropagation();
    const domain = event.target.name;
    const apiSelected = Object.values(checkedApis[domain]).every(value => value);
    const newState = {
        ...checkedApis,
         //@ts-ignore
      [domain]: Object.fromEntries(
        Object.keys(checkedApis[domain]).map(key =>[key, !apiSelected])
      )
    };
    setCheckedApis((prevState:any) =>({
      ...prevState,
       //@ts-ignore
      [domain]: Object.fromEntries(
        Object.keys(prevState[domain]).map(key =>[key, !apiSelected])
      )
    }));
    const apiIds = getSelctedApis(newState);
    if(apiIds.length > 0 ){
        setAreApisSelected(false);
    }else{
      setAreApisSelected(true);
    }

};
  const getSelctedApis = (data:any)=> {
    let selectedApis: string[] = [];
      //@ts-ignore
    Object.entries(data).forEach(([domainkey, domainValue])=>{
      //@ts-ignore
      Object.entries(domainValue).forEach(([apiKey,apiValue])=>{
         if(apiValue === true){
          selectedApis.push(apiKey);
         } 
      });
    });
    return selectedApis;
  };
  
  const handleApisCheckBoxChange = (event: any, newValue:any,domain:string) =>{
    event.stopPropagation();
    const checkBoxstate: any = {
      ...checkedApis[domain],
      [event.target.name]: !checkedApis[domain][event.target.name]
    }
    
    setCheckedApis((prevState:any) =>({
        ...prevState,
        [domain]:checkBoxstate
    }));
    const selectedApis= {
      ...checkedApis,
      [domain]:checkBoxstate
    }
    const apiIds = getSelctedApis(selectedApis);
    if(apiIds.length > 0 ){
        setAreApisSelected(false);
    }else{
      setAreApisSelected(true);
    }
  };
 
  const handleStepButtonClick = () => {
    setStep(1);
  };
  const handleSubmitbtnClick = async() =>{
    const apiIds = getSelctedApis(checkedApis);
    if(apiIds.length > 0){
      setLoading(true);
      setformError('');
      
      const applicationsParamters = {
        page:1,
        pageSize:500
      };
      const createAppUrl ='/bff-private/application-setup';
      const response = await post(createAppUrl, {
        "environment": 'sandbox',
        "appName":applicationName,
        "appId":"",
        "appDescription":description,
        "apiIds":apiIds
      });
      if (response.error !== undefined) {
          setformError(response.error.message);
        setLoading(false);
        
      } else {
        setLoading(false);
        setShowAltert(true);
        dispatch(setMessage('App created Successfully'));
        dispatch(setShowAlert(true));
        dispatch(fetchApplicationsData({
            ...applicationsParamters,
            sponsorCode:''
        }));
        navigate('/account');
      }
    }
   
  };
  const handleNextButton = (data: IFormInput) =>{
    setApplicationName(data.applicationName);
    setDescription(data.description);
    setStep(2);
  };
  const onClickClose =()=> {
    setShowAltert(false);
  };
  const onErrorClickClose = ()=>{
    setformError('');
  };
  const renderAppManagement = ()=>{
    return (
      <React.Fragment>
          <Box data-testid='Account Page' className={classes.accountPage}>
          <Container maxWidth='md' className={classes.container}>
            <div className={classes.headerContainer}>
            {
                  (step === 1) &&
                <IconButton 
                       className={classes.backButton}
                       data-cy="backtoMyapps"
                       data-testid="backtoMyapps"
                       onClick={()=>{navigate('/account')}}
                       edge="end"> 
                      <ArrowBackIcon/>
               </IconButton>
            }
            <Typography variant="h4" className={classes.pageTitle}>
              { (step === 1) ?' Create an APP':'Create an APP: select APIS'}
            </Typography>
            <div className={classes.beta}>Sandbox</div>
            </div>
            {showHeaderAlert && (<div className={classes.noOrganizationHeader}>User cant create app for Selected organisation DEMO1 Does not exist.Please check with support team.</div>)} 
            {loading && (<div className={classes.loader}> <LinearProgress /></div>)}
           { step === 1 && (
                  <Box className={isSmallScreen ? classes.mobilecontainer: classes.container}>
                  <div className={classes.header}>In order to register your application to begin using our APIs, Please complete the form:</div>
                  <Grid container item spacing={2} data-cy="application-form" data-testid='application-form' xs={12} md={12} lg={12} sm={12}>
                        <React.Fragment>
                          <Grid item xs={12} md={12} sm={12} lg={12}>
                            <Controller
                              name="applicationName"
                              control={control}
                              rules={{
                                required: true,
                                pattern:{
                                  value:alphanumericRegex,
                                  message:'Please enter allowed characters.'
                                },
                              }}
                              render={({onChange,value }) => (
                                <FormControl fullWidth>
                                  <TextField
                                    id="app"
                                    name="applicationName"
                                    type="text"
                                    required
                                    label="Application Name"
                                    fullWidth
                                    variant="filled"
                                    value={value}
                                    data-testid='applicatiion-detail-name'
                                    onChange={onChange}
                                    inputProps={{ [ariaLabel]: 'Please enter application Name',
                                    maxLength: 50,
                                    "data-testid":"applicationName" 
                                    }}
                                    error={errors.applicationName !== undefined}
                                    helperText={errors?.applicationName ? errors.applicationName.message : null}
                                  />
                                </FormControl>
                              )}
                            />
                            <div className={classes.header}>Maximum name length is 50 characters. Allowed characters:
                            <span className={classes.tag}>A-Z</span>
                            <span className={classes.tag}>a-z</span>
                            <span className={classes.tag}>0-9</span>
                            <span className={classes.tag}>SPACE</span>
                            </div>
                          </Grid>
                          
                          <Grid item xs={12} md={12}>
                            <Controller
                              name="description"
                              control={control}
                              rules={{
                                required: true
                              }}
                              render={({ onChange, value }) => (
                                <FormControl fullWidth>
                                  <TextField
                                    fullWidth
                                    required
                                    id="description"
                                    label="Description"
                                    name="description"
                                    type="text"
                                    data-testid='applicatiion-detail-description'
                                    rows={5}
                                    multiline={true}
                                    variant="filled"
                                    value={value}
                                    onChange={onChange}
                                    inputProps={{
                                      [ariaLabel]: 'description',
                                       maxLength: 150,
                                       "data-testid":"description" 
                                    }}
                                    error={errors.description !== undefined}
                                    helperText={errors?.description ? errors.description.message : null}
                                  />
                                </FormControl>
                              )}
                            />
                            <div className={classes.header}>Maximum description length is 150 characters.</div>
                          </Grid>
                          <Grid item xs={12} md={12} container justify="flex-end" alignItems="flex-end">
                            <Button
                              variant="contained"
                              onClick={handleSubmit(handleNextButton)}
                              color="secondary"
                              aria-label={'Submit details'}
                              className={classes.actionButton}
                              disabled={showHeaderAlert}
                              data-testid='applicatiion-details-Next'
                            >
                              Next
                            </Button>
                          </Grid>
                        </React.Fragment>
                      </Grid>
                </Box>
            )}
             { step === 2 && (
            <Grid sm={12} md={12} xs={12} lg={12} item key={'ok'}>
                  
                  <ApiProductsContainer
                      selelectionFilter ={true}
                      checkedApis={checkedApis}
                      handleApisChange={(e:any,newValue:any,domain:string) => handleApisCheckBoxChange(e,newValue,domain)}
                      handleDomainsChange={(e:any) => handleDomainsCheckboxChange(e)}
                  ></ApiProductsContainer>
                  <div className={classes.navigationContainer}>
                    <Button
                    className={classes.actionButton}
                    variant="contained"
                    data-testid='applicatiion-detail-previous'
                    onClick={handleStepButtonClick}
                    color="secondary"
                    aria-label={'go-to-back'}>
                    prev
                  </Button>
                  <Button
                    className={classes.actionButton}
                    variant="contained"
                    onClick={handleSubmitbtnClick}
                    color="secondary"
                    aria-label={'Submit details'}
                    disabled ={areApisSelected}
                  >
                    Submit
                  </Button>
                </div>
            </Grid>)}
          </Container>
        </Box>
        <CookieMessage state={showCookieMessage} ></CookieMessage>
          <ClickWrap state={showClickWrap} handleClose={handleClickwrapClose}></ClickWrap>
          <SessiontTmeout state={showSessionTimeoutModel}></SessiontTmeout>
          { (formError) && <div data-testid="erroralert">
            <Snackbar  open={formError.length>0 } autoHideDuration={3000} data-cy="ErrorAlert" onClose={onErrorClickClose}>
              <MuiAlert severity="error" elevation={6} variant="filled">
                {formError || ("Something went wrong, please try again.")}
              </MuiAlert>
            </Snackbar>
          </div>}
          { (exceptionClickWrap) && <ErrorAlert></ErrorAlert>}
          {showAltert &&(<SuccessAlert message={'App created Successfully'} state={showAltert} alertType={'success'} onClose={onClickClose}></SuccessAlert>)}
      </React.Fragment>
    );
  }
  return renderAppManagement(); 
};

export default AppManagementPage;
