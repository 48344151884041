import React, { FC, useEffect, useState, useCallback, useRef } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import clsx from 'clsx';
import { useNavigate, useParams } from 'react-router';
import Grid from '@material-ui/core/Grid';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import BarChartIcon from '@material-ui/icons/BarChart';
import RecentActorsOutlinedIcon from '@material-ui/icons/RecentActorsOutlined';
import { CommonLayout } from '../../components/layout';
import { get } from '../../utils';
import { EventCard } from '../../components/cards';
import ErrorAlert from '../../components/erroralert';
import { useCookieContext } from '../../CookieContext';
import { useUserContext } from '../../UserContext';
import CookieMessage from '../../components/cookiemessage';
import ClickWrap from '../../components/clickwrap';
import SessiontTmeout  from '../../components/sessiontimeout';
import ConfirmationBox from '../../components/confirmationbox';
import MessageBox from '../../components/messageBox';
import EventFormDialog from '../../components/eventformDialog';
import { redirectToOktaUrl } from '../../utils/utils';
import { useDispatch } from 'react-redux';
import { fetchEventsData } from '../../store/eventSlice';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { setCurrentSubscriptionsData, setConfirmationBoxDisplayStatus, setPopupText} from '../../store/publicEventSlice';
import { setPopupTextPrivatepage } from '../../store/eventSlice';
import { useTypedSelector } from '../../store';
import { getEvents, getpublicEvents } from '../../selectors/selectors';
import { post, put } from '../../utils';
import { SuccessAlert } from '../../components/successalert';
import { getState } from '../../selectors/selectors';
import { checkUserPermission } from '../../core/authorization/service/authorizationService';
import { useMediaQuery } from '@material-ui/core';
import RootRef from '@material-ui/core/RootRef'; 


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullContainer: {
      minHeight: '100vh',
      position:'relative',
      paddingTop:120,
      paddingBottom:120,
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
    container: {},
    navlinkContainer:{
      display:'flex',
      flexDirection:'row',
      alignItems:'center',
      height:45
    },
    pageTitle: {
      color: 'white',
      letterSpacing: '0.0625rem',
      fontWeight: 300,
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
    },
    navlinks: {
      fontWeight: 200,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    bar: {
      height: 1,
      backgroundColor: '#05a8cc',
    },
    link: {
      textDecoration: 'none',
      color: 'white',
      cursor:'pointer',
      fontWeight:200
    },
    active: {
      fontWeight: 'bold',
    },
    description: {
      color: 'white',
    },
    selected: {
      backgroundColor: '#00C4B4',
      color: 'primary',
      marginRight: '20px'
    },
    notSelected: {
      color: 'white',
      marginRight: '20px',
      backgroundColor: 'rgba(256,256,256,0.1)'
    },
    navContainer: {
      padding: theme.spacing(2),
      position:'fixed'
    },
    navContainerMobile:{
      padding: theme.spacing(2)
    },
    icon:{
      marginRight:5,
      color:'white'
    }
  }),
);

type Domain = {
  displayDomainName: string;
  filterkey: string;
  displayDomainDescription: string;
  events: Array<string>;
};

type Event = {
  eventId: string;
  displayEventName: string;
  displayEventDescription: string;
};

interface AllDomains {
  [key: string]: Domain;
}
interface Allevents {
  [key: string]: Event;
}
export const EventsPage: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [AllDomains, setAllDomains] = useState<AllDomains>({});
  const [allevents, setAllevents] = useState<Allevents>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [showAltert, setShowAltert] = useState<boolean>(false);
  const [alertType, setAlertType ] =  useState<string>('');
  const { userDetails } = useTypedSelector(state => getState(state));
  const [privateInputBoxDisplayStatus, setpPrivateInputBoxDisplayStatus] = useState<boolean>(false);
  let hasPermissionSubscribeEvents = checkUserPermission(userDetails,'subscribeEvents');
  const [confirmationBoxDisplayStatus, setConfirmationBoxDisplayStatus] = useState<boolean>(false); 
  const [privateconfirmationBoxDisplayStatus, setPrivateconfirmationBoxDisplayStatus] = useState<boolean>(false); 
  const [privateconMessageDisplayStatus, setPrivateconMessageDisplayStatus] = useState<boolean>(false); 
  const [isdeaultContactusText, setIsdeaultContactusText] = useState<boolean>(false);  
  const [eventName, setCurrentEventname] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  //@ts-ignore
  const { popUpValues, currentsubScriptionsData } = useTypedSelector(state => getpublicEvents(state));
  const { popUpText } = popUpValues;
  const { data, subscriptionId } = useTypedSelector(state => getEvents(state));
  const cookieDetails = useCookieContext();
  const isSmallScreen = useMediaQuery((theme:any) => theme.breakpoints.down("sm"));
  //@ts-ignore
  const { showCookieMessage } = cookieDetails;
  const userdetails = useUserContext();
  // @ts-ignore
  const { showClickWrap, exceptionClickWrap, loadingClickWrap,acceptClickWrapdetails, showSessionTimeoutModel, isAuthenticated } = userdetails;
  const { displayDomainName } = useParams();

  const contentRef = useRef();
  // @ts-ignore
  const [activeNav, setActiveNav] = useState('');
  // @ts-ignore
  
  //@ts-ignore
  const { setshowCookieMessage } = cookieDetails;
  const [exception, setexception] = useState<boolean>(false);
  const navigate = useNavigate();
  const iconArray = [
    <AccountBalanceIcon className={classes.icon} />,
    <LocalAtmIcon className={classes.icon}/>,
    <BarChartIcon className={classes.icon}/>,
    <RecentActorsOutlinedIcon className={classes.icon}/>,
  ];
  const handleClickwrapClose = () => {
    acceptClickWrapdetails();
  };
  const pullDomains = async () => {
    setLoading(true);
    const events = await get('/bff/events');

    if (events.error !== undefined) {
      setexception(true);
      setLoading(false);
      if (events.error.status === 404) {
        navigate('/notfound');
      }
    } else {
      setexception(false);
      if (events.data) {
        setAllDomains(events.data.domains);
        setAllevents(events.data.events);
      }
      setLoading(false);
    }
  };
  useEffect(() => {
   
    pullDomains();
    // eslint-disable-next-line
  }, []);
  const deeplink = async () => {
    try {
      const is_subscribe_button_clicked = localStorage.getItem('is_subscribe_button_clicked') || '';
      
      if (is_subscribe_button_clicked !== null && is_subscribe_button_clicked !== '' && is_subscribe_button_clicked !== undefined && userDetails?.roles && userDetails?.roles.length > 0 ) {
         const currenteEventId = localStorage.getItem("currenteEventId") ||'';
          if(Object.keys(data).length > 0 && isAuthenticated === true ){
          hasPermissionSubscribeEvents = checkUserPermission(userDetails,'subscribeEvents');
          const currenteEventStatus = data[currenteEventId]?.status || 'nonEntitled';
          onCardButtonClick({},currenteEventId,currenteEventStatus,hasPermissionSubscribeEvents);
          localStorage.setItem('is_subscribe_button_clicked', '');
         }

      }
    } catch (ex) {}
  };
  //@ts-ignore
  useEffect(() => {
    deeplink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated,data,userDetails?.roles,AllDomains]);



  const AllDomainsKeys = Object.keys(AllDomains);
  useEffect(() => {
    if (
      (!AllDomainsKeys.includes(displayDomainName)) &&
      displayDomainName !== undefined &&
      loading !== false &&
      AllDomainsKeys.length > 0
    ) {
      navigate('/notfound');
    }
    // eslint-disable-next-line
  }, [displayDomainName, loading, AllDomainsKeys, navigate]);

  const fetchPrivateEventsData = useCallback(() => {
    const eventRequestParamters = {
      page:1,
      pageSize:500
    };
    if(isAuthenticated === true){
      dispatch(fetchEventsData({...eventRequestParamters,sponsorCode:''}));
      
     
    }
  }, [dispatch, isAuthenticated]);
  useEffect(() => {
    fetchPrivateEventsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated,dispatch,allevents]);

  useEffect(() => {
    if(alertType === "success")
    navigate('/account/events');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertType]);

  const onClickNo = ()=> {
    setConfirmationBoxDisplayStatus(false);
  };
  
  const onClickClose =()=> {
    setShowAltert(false);
  };
  const onClickNoPrivatePage = ()=> {
    setPrivateconfirmationBoxDisplayStatus(false);
  };
  const onClickNoPrivatInputBoxPage = ()=> {
    setpPrivateInputBoxDisplayStatus(false);
  };
  const onClickYesPrivatInputBoxPage = (data:any)=> {
    setpPrivateInputBoxDisplayStatus(false);
    if(data.callbackUrl){
      makeRequest(data.callbackUrl);
    }
  };
  const onClickYesPrivatepage = async()=> {
    setPrivateconfirmationBoxDisplayStatus(false);
    makeRequest('');
  };
  const makeRequest = async(callbackUrlForm :string)=> {
    setLoading(true);
    const subscriptionId = currentsubScriptionsData.subscriptionId;
    let subsribeUrl =   '';
    let response: any = {};
    const {action,callbackUrl, ...filteredSubscriptionData } = currentsubScriptionsData;
    const baseUrl = "/bff-private/event-subscriptions";
      if(currentsubScriptionsData.status === "subscribed"){
       if(currentsubScriptionsData.subscriptionId !== '' || currentsubScriptionsData.subscriptionId !== undefined){
          subsribeUrl = baseUrl + "/"+ subscriptionId; 
          response = await put(subsribeUrl, {
            ...filteredSubscriptionData,
            callbackUrl:callbackUrlForm,
            status:'unsubscribed'
          });
        }else{
          subsribeUrl = baseUrl;
          response = await post(subsribeUrl, {
            ...filteredSubscriptionData,
            callbackUrl:callbackUrlForm,
            status:'subscribed'
          });
        }
       }else{
        if(!!currentsubScriptionsData?.subscriptionId){
          subsribeUrl = baseUrl + "/"+ subscriptionId; 
          response = await put(subsribeUrl, {
            ...filteredSubscriptionData,
            callbackUrl:callbackUrlForm,
            status:'subscribed'
          });
        }else{
          subsribeUrl = baseUrl;
          response = await post(subsribeUrl, {
            ...filteredSubscriptionData,
            callbackUrl:callbackUrlForm,
            status:'subscribed'
          });
        } 
       }
    
    if (response.error !== undefined) {
      dispatch(setPopupText(response.error.message));
      dispatch(setPopupTextPrivatepage(response.error.message));
      setLoading(false);
      setAlertType('error');
      setShowAltert(true);
     
    } else {
      dispatch(setPopupText(response.message));
      dispatch(setPopupTextPrivatepage(response.message));
      setLoading(false);
      pullDomains();
      setShowAltert(true);
      setAlertType('success');
    }
   
  };
  const onCardButtonClick = (event: any,eventId:string,eventStatus:string,hasPermissionSubscribeEvents:boolean)=> {
      let message = '';
      localStorage.setItem("currenteEventId",eventId);
      setIsdeaultContactusText(false);
      setCurrentEventname('');
      if(isAuthenticated === true){
        const is_subscribe_button_clicked = localStorage.getItem('is_subscribe_button_clicked') || '';
        if (is_subscribe_button_clicked !== null && is_subscribe_button_clicked !== ''){
          if(eventStatus === "subscribed"){
            message = 'You are already subscribed.'; 
            setMessage(message);
            setPrivateconMessageDisplayStatus(true);
          }else{
            if(hasPermissionSubscribeEvents === true){
              if(eventStatus !=='nonEntitled'){
                const callbackUrl = data[eventId]?.eventDetails?.callbackUrl || '';
                const domainName = data[eventId]?.domainName || '';
                const eventName = data[eventId]?.eventName || '';
                dispatch(setCurrentSubscriptionsData({
                  action:'submit',
                  callbackUrl: callbackUrl,
                  subscriptionId:subscriptionId,
                  eventId:eventId,
                  eventName:eventName,
                  domain:domainName,
                  status:eventStatus
                }));
                message = eventStatus === 'subscribed'?'Unsubscribe action will Stop sending notifications to the provided URL, are you sure to proceed?':'Subscribe action will Start sending notifications to the provided URL, are you sure to proceed?';
                setMessage(message);
                if(eventStatus === 'subscribed'){
                  setPrivateconfirmationBoxDisplayStatus(true);
                }else{
                  setpPrivateInputBoxDisplayStatus(true);
                }
              }else{
                const eventName = allevents[eventId]?.displayEventName || '';
                setIsdeaultContactusText(true);
                setCurrentEventname(eventName);
                setPrivateconMessageDisplayStatus(true);
              }
            }else{
              message = 'Insufficient privileges - You dont have permissions. Please check with support team.'; 
              setMessage(message);
              setPrivateconMessageDisplayStatus(true);
            }
          }
          
          localStorage.setItem('is_subscribe_button_clicked','');
         
        }else{
          if(hasPermissionSubscribeEvents === true){
            if(eventStatus !=='nonEntitled'){
              const callbackUrl = data[eventId]?.eventDetails?.callbackUrl || '';
              const domainName = data[eventId]?.domainName || '';
              const eventName = data[eventId]?.eventName || '';
              dispatch(setCurrentSubscriptionsData({
                action:'submit',
                callbackUrl: callbackUrl,
                subscriptionId:subscriptionId,
                eventId:eventId,
                eventName:eventName,
                domain:domainName,
                status:eventStatus
              }));
              message = eventStatus === 'subscribed'?'Unsubscribe action will Stop sending notifications to the provided URL, are you sure to proceed?':'Subscribe action will Start sending notifications to the provided URL, are you sure to proceed?';
              setMessage(message);
              if(eventStatus === 'subscribed'){
                setPrivateconfirmationBoxDisplayStatus(true);
              }else{
                setpPrivateInputBoxDisplayStatus(true);
              }
            }else{
              const eventName = allevents[eventId]?.displayEventName || '';
              setIsdeaultContactusText(true);
              setCurrentEventname(eventName);
              setPrivateconMessageDisplayStatus(true);
            }
          }else{
            message = 'You dont have permissions. Please check with support team.'; 
            setMessage(message);
            setPrivateconMessageDisplayStatus(true);
          }
          
        }
      
      
      
    }else{
      localStorage.setItem('is_subscribe_button_clicked',"true");
      setConfirmationBoxDisplayStatus(true);
    }
    
  };
  const onClickConfirmation =()=> {
    setPrivateconMessageDisplayStatus(false);
  };
  const onClickYes =()=> {
     // @ts-ignore
     try {
      if (!window.navigator.cookieEnabled) {
        setshowCookieMessage(true);
      } else {
        localStorage.setItem('apo_redirect_url', window.location.pathname || '/');
        redirectToOktaUrl();
      }
    } catch (e) { }
    setConfirmationBoxDisplayStatus(false);
  };
  const getEventStatus =(eventId :string) => {
      let EventStatus =   (data && data[eventId]?.status)  || 'nonEntitled';
      return EventStatus;
  };
  const handleNavClick = (index: any)=>{
    //@ts-ignore
    const contentSections = contentRef.current.children;
    const section = contentSections[index];
    window.scrollTo({
      top: section.offsetTop-70,
      behavior: 'smooth'
    });
    setActiveNav(section.id);
};

useEffect(() => {
    const handleScroll = () => {
    
      //@ts-ignore
      const contentSections = contentRef.current.children;
      const scrollPosition = window.scrollY;
 
      for (let i = 0; i < contentSections.length; i++) {
        const section = contentSections[i];
        const sectionTop = section.offsetTop-120;
        const sectionBottom = sectionTop + section.offsetHeight;
        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          setActiveNav(section.id);
          break;
        }
      }
    }
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  function returnEvents (domain: any, eventsLength: any) {
    return ( 
      eventsLength !==0 && (
      <Grid container spacing={4} direction="column" key={domain.filterkey} id={domain.filterkey} data-testid={`details-${domain.filterkey}`}>
      <Grid item xs={10} sm={10} md={10} lg={10}>
        <Typography variant="h5" className={classes.pageTitle}>
          {domain.displayDomainName}
        </Typography>
        {(loading) && <div><LinearProgress /></div>}
        <div className={classes.bar} />
      </Grid>
      <Grid item xs={10} sm={10} md={10} lg={10}>
        <Typography variant="body1" className={classes.description}>
          {domain.displayDomainDescription}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={8}>
          {domain.events && 
            domain.events.map((eachGroup: string | number, index: any) => {
              const event = allevents[eachGroup];
              const eventStatus = getEventStatus(event?.eventId);
              return (
                event !== undefined && (
                  <Grid sm={4} md={4} item key={event.eventId}>
                    <EventCard
                      eventId={event.eventId}
                      name={event.displayEventName}
                      description={event.displayEventDescription}
                      onClickButton={onCardButtonClick}
                      eventStatus={eventStatus}
                    />
                  </Grid>
                )
              );
            })}
        </Grid>
      </Grid>
    </Grid>
    ))
  }
  return (
    <React.Fragment>
      
      <CommonLayout>
        <div className={classes.fullContainer} data-testid="domains">
        {(loading || loadingClickWrap) && <LinearProgress />}
          <Container maxWidth="lg">
          <Grid container>
          <Grid item sm={2} className={isSmallScreen? classes.navContainerMobile: classes.navContainer}>
          {AllDomainsKeys &&
                AllDomainsKeys.map((item, i) => {
                  const domain: Domain = AllDomains[item];
                  return (
                    <div className= {classes.navlinkContainer} data-testid={`nav${domain.filterkey}`} onClick={(index:any)=>handleNavClick(i)}>
                     {iconArray[i]} 
                    <Typography variant="body1" className={classes.navlinks}>
                    <div  data-testid={domain.filterkey}  data-cy="buttonFilter" className={clsx(classes.link, activeNav === domain.filterkey ? classes.active : '')}
                    >
                    {domain.displayDomainName}
                  </div>
                    </Typography>
                   </div> 
                  );
              })}

          </Grid>
          {!isSmallScreen && <Grid item sm={2}></Grid>}
          <Grid item sm={10}>
          <Container maxWidth="md" className={classes.container}>
          <Typography variant="h4" className={classes.pageTitle}>
              Explore Our Events Offerings 
            </Typography>
            <RootRef rootRef={contentRef}>
             <div>  
            {AllDomainsKeys.length > 0 &&  
            AllDomainsKeys.map((eachDomain: any) => {
                const domain: Domain = AllDomains[eachDomain];
                const eventsLength = domain.events.length;
                return(
                returnEvents(domain, eventsLength) )})}
              </div>  
             </RootRef>   
            </Container>
          </Grid>
          </Grid>
          </Container>
          { (exception || exceptionClickWrap) && <ErrorAlert />}
        </div>
        <CookieMessage state={showCookieMessage} ></CookieMessage>
        <MessageBox
            state = {privateconMessageDisplayStatus}
            title ={'Information'}
            message={message}
            buttontext1={'Ok'}
            isdeaultContactusText={isdeaultContactusText}
            eventName={eventName}
            actionButton1={onClickConfirmation}   
        ></MessageBox>
        <ConfirmationBox
            state= {confirmationBoxDisplayStatus}
            title ={'Want to subscirbe this event? '}
            message={'Sign in to subscirbe this event'}
            buttontext1={'Sign In'}
            buttontext2={''}
            actionButton1={onClickYes}
            actionButton2={onClickNo}
            onClose={onClickNo}
          ></ConfirmationBox>
         <ConfirmationBox
            state= {privateconfirmationBoxDisplayStatus}
            title ={'Confirmation'}
            message={message}
            buttontext1={'YES'}
            buttontext2={'NO'}
            actionButton1={onClickYesPrivatepage}
            actionButton2={onClickNoPrivatePage}
            onClose={onClickNoPrivatePage}
          ></ConfirmationBox> 
          <EventFormDialog
            state= {privateInputBoxDisplayStatus}
            title ={'Confirmation'}
            message={message}
            buttontext1={'SAVE'}
            buttontext2={'CANCEL'}
            actionButton1={onClickYesPrivatInputBoxPage}
            actionButton2={onClickNoPrivatInputBoxPage}
            onClose={onClickNoPrivatInputBoxPage}
          ></EventFormDialog> 
           <SuccessAlert message={popUpText} state={showAltert} onClose={onClickClose} alertType={alertType}></SuccessAlert>
        <ClickWrap state={showClickWrap} handleClose={handleClickwrapClose}></ClickWrap>
        <SessiontTmeout state={showSessionTimeoutModel}></SessiontTmeout> 
           
      </CommonLayout>
    </React.Fragment>
  );
};

export default EventsPage;
