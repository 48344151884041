import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { get } from '../utils'

type Spec = {
  name: string;
  uuid: string;
}

// Define a type for the slice state
export type SpecsState = {
  loading: boolean;
  error: string | null;
  specs: {
    [key: string]: Spec
  }
  status: number;
}

type fetchSpecError = {
  status: number;
  message: string
}


// Define the initial state using that type
const initialState: SpecsState = {
  loading: false,
  error: '',
  status: 0,
  specs: {}
}

/* istanbul ignore next */
export const fetchSpec = createAsyncThunk<Spec, string | string[] | null, { rejectValue: fetchSpecError }>(
  "specs/fetch",
  async (apiId: string | string[] | null, thunkApi) => {
    const response = await get(`/bff-private/api/${apiId}?expand=specifications`);
    if (response.error !== undefined) {
      // Return the error message:
      return thunkApi.rejectWithValue({
        status: response?.error?.status,
        message: response?.error?.message
      });
    }
    // Also, set a type for the `data` constant:
    const data: Spec = response.data;
    // @ts-ignore: Unreachable code error
    data.uuid = apiId;
    return data;
  }
);

/* istanbul ignore next */
export const SpecsSlice = createSlice({
  name: 'specs',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {

    // When we send a request,
    // `fetchSpec.pending` is being fired:
    builder.addCase(fetchSpec.pending, (state) => {
      // At that moment,
      // change `loading` back to `true`.
      // and clear all the previous errors:
      state.loading = true
      state.error = null;
    });

    // When a server responses with the data,
    // `fetchSpec.fulfilled` is fired:
    builder.addCase(fetchSpec.fulfilled,
      (state, { payload }) => {
        // We add all the new todos into the state
        // and change `loading` back to `false`.
        state.specs[payload.uuid] = { ...payload }
        state.loading = false
      });

    // When a server responses with an error:
    builder.addCase(fetchSpec.rejected,
      (state, { payload }) => {
        // We show the error message
        // and change `loading` back to `false` again.
        if (payload) state.error = payload.message;
        state.loading = false
      });
  },
})

export const { actions: specsActions, reducer: specsReducer } = SpecsSlice;
