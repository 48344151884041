import React, { FC } from 'react';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useNavigate } from 'react-router';
import Soon from '../../soon';
import ButtonBase from '@material-ui/core/ButtonBase';
import fundaccountingtransactionsthumbnailjpg from '../../../assets/fund-accounting/transactions/thumbnail.jpg';
import fundaccountingtransactionshero from '../../../assets/fund-accounting/transactions/hero.jpg';
import custodyactivitymonitoringthumbnailjpg from '../../../assets/custody/activity-monitoring/thumbnail.jpg';
import fundaccountingsupplementaldatathumbnailjpg from '../../../assets/fund-accounting/supplemental-data/thumbnail.jpg';
import custodytransactionsthumbnailjpg from '../../../assets/custody/transactions/thumbnail.jpg';
import custodyvaluationthumbnailjpg from '../../../assets/custody/valuation/thumbnail.jpg';
import fundaccountingholdingsthumbnailjpg from '../../../assets/fund-accounting/holdings/thumbnail.jpg';
import fundaccountingvaluationthumbnailjpg from '../../../assets/fund-accounting/valuation/thumbnail.jpg';
import assetservicingdatajpg from '../../../assets/data.jpg';
import { useDispatch } from 'react-redux';
import { setCurrentDomain } from '../../../store/productsSlice';

import riskandperformanceattributionsthumbnailjpg from '../../../assets/risk-and-performance/attributions/thumbnail.jpg';
import riskandperformanceholdingsthumbnailjpg from '../../../assets/risk-and-performance/holdings/thumbnail.jpg';
import riskandperformancemetricsthumbnailjpg from '../../../assets/risk-and-performance/metrics/thumbnail.jpg';
import transferagencyfunddetailsthumbnailjpg from '../../../assets/transfer-agency/fund-details/thumbnail.jpg';
import transferagencyinvestordetailsthumbnailjpg from '../../../assets/transfer-agency/investor-details/thumbnail.jpg';
import transferagencyinvestorholdingsthumbnailjpg from '../../../assets/transfer-agency/investor-holdings/thumbnail.jpg';
import transferagencyinvestortransactionsthumbnailjpg from '../../../assets/transfer-agency/investor-transactions/thumbnail.jpg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      minWidth: 275,
      padding: theme.spacing(1),
      backgroundColor: 'rgba(256,256,256,0.1)',
      mixBlendMode: 'normal',
      borderRadius: '10px',
      height: '340px',
      position: 'relative',
    },
    cardWithPointer: {
      cursor: 'pointer',
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      width: '235px',
      textAlign: 'center',
      justifyContent: 'center',
    },
    title: {
      color: 'white',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      fontWeight: 'bold',
    },
    description: {
      color: 'white',
      overflow: 'hidden',
    },
    avatar: {
      color: '#00C4B4',
    },
    actions: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 5,
    },
    imgContainer: {
      height: 160,
      width: '100%',
    },
    prodImg: {
      objectFit: 'cover',
      objectPosition: 'center',
    },
    cardButton: {
      position: 'relative',
    },
  }),
);
interface Props {
  name: string;
  description: string;
  img: string;
  uuid: string;
  soon?: boolean;
  domainName: string;
}

const GroupCard: FC<Props> = ({ name, description, img, uuid, soon, domainName }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const imagePath = img.toLowerCase().split("/").slice(6,9).join("").replace(/[.-]/g, '');
  const dispatch = useDispatch();
  const imageMapping : any = {
    "fundaccountingtransactionsthumbnailjpg":fundaccountingtransactionsthumbnailjpg, 			
    "fundaccountingtransactionshero":fundaccountingtransactionshero, 					
    "custodyactivitymonitoringthumbnailjpg":custodyactivitymonitoringthumbnailjpg, 			    
    "fundaccountingsupplementaldatathumbnailjpg":fundaccountingsupplementaldatathumbnailjpg ,		
    "custodytransactionsthumbnailjpg":custodytransactionsthumbnailjpg ,				    
    "custodyvaluationthumbnailjpg":custodyvaluationthumbnailjpg, 					    
    "fundaccountingholdingsthumbnailjpg":fundaccountingholdingsthumbnailjpg, 				
    "fundaccountingvaluationthumbnailjpg":fundaccountingvaluationthumbnailjpg, 			                                                      
    "riskandperformanceattributionsthumbnailjpg":riskandperformanceattributionsthumbnailjpg, 		
    "riskandperformanceholdingsthumbnailjpg":riskandperformanceholdingsthumbnailjpg, 			
    "riskandperformancemetricsthumbnailjpg":riskandperformancemetricsthumbnailjpg, 			    
    "transferagencyfunddetailsthumbnailjpg":transferagencyfunddetailsthumbnailjpg, 			    
    "transferagencyinvestordetailsthumbnailjpg":transferagencyinvestordetailsthumbnailjpg, 		    
    "transferagencyinvestorholdingsthumbnailjpg":transferagencyinvestorholdingsthumbnailjpg, 		
    "transferagencyinvestortransactionsthumbnailjpg":transferagencyinvestortransactionsthumbnailjpg,
    "assetservicingdatajpg":assetservicingdatajpg 	
  };
  const onGroupCardClick= (domainName:string)=>{
    if(!soon){
      navigate(`/groups/${uuid}`);
      dispatch(setCurrentDomain(domainName));
    }
  };
  return (
    <ButtonBase
      focusRipple
      key={uuid}
      className={classes.cardButton}
      disabled={soon}
      onClick={() => onGroupCardClick(domainName)}
    >
      <Card className={classes.card} raised={true}>
        {soon && <Soon />}
        <CardContent>
          <div className={classes.cardContent}>
            <div className={classes.imgContainer}>
              <img
                src={imageMapping[imagePath]}
                className={classes.prodImg}
                height={160}
                width={'100%'}
                alt={`${name} product`}
              />
            </div>
            <Typography variant="body1" className={classes.title}>
              {name}
            </Typography>
            <Typography variant="body2" className={classes.description}>
              {description}
            </Typography>
          </div>
        </CardContent>
      </Card>
    </ButtonBase>
  );
};

export default GroupCard;
