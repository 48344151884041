import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { PropsWithChildren  } from 'react';
import { Line } from "react-chartjs-2";
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chartContainer:{
      [theme.breakpoints.down('sm')]: {
        width:'90%',
        padding:12,
      }, 
      height:'100%',
      margin: 10,
      padding:24,
      borderRadius:10,
      position:'relative',
      background:'#3d4b63'
    },
    menuItem:{
      background:'#31405a'
    },
    dropDown:{
      '& > ul': {
       padding: 0
      }
    },
    noDatafoundMessage:{
      color: 'white',   
      fontWeight: 400,
      fontSize:15,
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      marginTop:'10%'
    }
    
  }));

export type AnalyticsChartProps = {
  xAxisLabels?:string[];
  yAxisLabels:number[];
  isLoading:Boolean;
  chartData:any;
  chartOptions:any;
};

const AnalyticsChart: React.FC<AnalyticsChartProps> = 
(props: PropsWithChildren<AnalyticsChartProps>) => {

  const classes = useStyles();
  const { isLoading, chartOptions, chartData, xAxisLabels, yAxisLabels} = props;
  
  function renderChart(chartData :any){
    return (<Line
      data={chartData}
      options={chartOptions}
      /> )
  }
  return (
    <React.Fragment>
      {isLoading && <LinearProgress/>}
    <div className={classes.chartContainer}>
      {( xAxisLabels && xAxisLabels.length> 0 && yAxisLabels.length > 0)? 
      renderChart(chartData)
     :<div className={classes.noDatafoundMessage}> No data found </div>}
       </div> 
    </React.Fragment>   
  );
};

export default AnalyticsChart;