import React, { FC } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { useUserContext } from '../../UserContext';
import { redirectToOktaUrl } from '../../utils/utils';
import { useCookieContext } from '../../CookieContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: 240,
      background:
        'linear-gradient(180deg, rgba(6,30,48,0.9668242296918768) 33%, rgba(12,26,41,0.9948354341736695) 97%)',
      padding: theme.spacing(3),
    },
    navlinks: {
      fontWeight: 400,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    link: {
      textDecoration: 'none',
      color: 'inherit',
    },
    active: {
      color: '#00C4B4',
    },
  }),
);

interface Props {
  mobileOpen: boolean;
  handleDrawerToggle: (index: number) => void;
  window?: () => Window;
}
const MobileDrawer: FC<Props> = ({ mobileOpen, handleDrawerToggle, window }) => {
  const classes = useStyles();
  const { oktaAuth } = useOktaAuth();
  // @ts-ignore
  const userDetails = useUserContext();
  const theme = useTheme();
  let { pathname } = useLocation();
  // @ts-ignore
  const { authUser, setAuthUser } = userDetails;
  const cookieDetails = useCookieContext();
  //@ts-ignore
  const { setshowCookieMessage } = cookieDetails;
  const navigate = useNavigate();
  const onLogOutClick = async (event: any) => {
    // @ts-ignore
    try {
      localStorage.setItem('apo_redirect_url', '');
      await oktaAuth.signOut();
      setAuthUser(false);
      // @ts-ignore
      localStorage.setItem('is-user-authenticated', '');
      localStorage.setItem('jwt', '');
      navigate('/');
    } catch (e) {
      console.log(' session call error', e);
    }
    event.preventDefault();
  };

  const onLoginClick = (event: any) => {
    // @ts-ignore
    try {
      // eslint-disable-next-line no-restricted-globals
      if (!navigator.cookieEnabled) {
        setshowCookieMessage(true);
      } else {
        // eslint-disable-next-line no-restricted-globals
        localStorage.setItem('apo_redirect_url', location.pathname || '/');
        redirectToOktaUrl();
      }
    } catch (e) {}
    event.preventDefault();
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <React.Fragment>
      {authUser ? (
      <Link
          to="/account"
          className={clsx(
            classes.link,
            pathname === '/account' ? classes.active : '',
          )}
        >
          <Typography variant="h6" className={classes.navlinks}>
            My Account
          </Typography>
        </Link>):null}
        <Link
          to="/get-started"
          className={clsx(classes.link, pathname === '/get-started' ? classes.active : '')}
        >
          <Typography variant="h6" className={classes.navlinks}>
            Get Started
          </Typography>
        </Link>

        <Link to="/apis" className={clsx(classes.link, pathname === '/apis' ? classes.active : '')}>
          <Typography variant="h6" className={classes.navlinks}>
            Explore APIs
          </Typography>
        </Link>
        <Link
          to="/events"
          className={clsx(classes.link, pathname === '/events' ? classes.active : '')}
        >
          <Typography variant="h6" className={classes.navlinks}>
            Events
          </Typography>
        </Link>
        <Link
          to="/resources/releases-and-maintanence"
          className={clsx(
            classes.link,
            pathname === '/resources/releases-and-maintanence' ? classes.active : '',
          )}
        >
          <Typography variant="h6" className={classes.navlinks}>
            Resources
          </Typography>
        </Link>

        <Link
          to="/contact-us"
          className={clsx(classes.link, pathname === '/contact-us' ? classes.active : '')}
        >
          <Typography variant="h6" className={classes.navlinks}>
            Contact Us
          </Typography>
        </Link>
        {authUser ? (
          <Link to="/" onClick={(event) => onLogOutClick(event)} className={clsx(classes.link)}>
            <Typography variant="h6" className={classes.navlinks}>
              Log Out
            </Typography>
          </Link>
        ) : (
          <Link to="/" onClick={(event) => onLoginClick(event)} className={clsx(classes.link)}>
            <Typography variant="h6" className={classes.navlinks}>
              Log In
            </Typography>
          </Link>
        )}
      </React.Fragment>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Drawer
      data-testid="drawer"
      data-cy="drawer"
      container={container}
      variant="temporary"
      anchor={theme.direction === 'rtl' ? 'right' : 'left'}
      open={mobileOpen}
      onClose={(event) => handleDrawerToggle(0)}
      classes={{
        paper: classes.drawerPaper,
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      {drawer}
    </Drawer>
  );
};

export default MobileDrawer;
