import { TokenResponse } from '@okta/okta-auth-js';
import { createSlice, PayloadAction,createAsyncThunk } from '@reduxjs/toolkit';
import { get, parseJwt } from '../utils';
export enum ApplicationStatus {
  ENABLED = 'ENABLED',
  PENDING = 'PENDING',
  DISABLED = 'DISABLED'
};
export type UserDetails = {
  firstName?: string;
  lastName?: string;
  organisationId?: string;
  organisationName?: string;
  orgUuid?: string;
  email?: string;
  sponsorCode?: string;
  roles?: string[];
};

export type GetAccountResponse = {
  data: {
    userInfo: {
      name: string;
      email: string;
      role: string;
      organizationName: string;
      orgUuid?: string;
    }
  };
};
export interface AccountState {
  isAuthenticated: boolean;
  userDetails: UserDetails;
  tokenDetails?: TokenResponse;
  loading:boolean
}

type FetchAccountError = {
  status: number;
  message: string
};
const initialState: AccountState = {
  isAuthenticated: false,
  userDetails: {},
  loading: false
};

export const fetchAccountData = createAsyncThunk<
  GetAccountResponse,
  string,
  {
    rejectValue: FetchAccountError
  }
>(
  'account/fetchAccountData',
  async (queryParams:string, thunkApi) => {
    try {
      if(queryParams.length> 0){
        const response = await get('/bff-private/mockaccounts' +queryParams);
        return response as GetAccountResponse
      }else{
        const response = await get('/bff-private/accounts' +queryParams);
        return response as GetAccountResponse
      }
     
    }
    catch (error: any) {
      return thunkApi.rejectWithValue({
        status: error.status,
        message: error.message
      })
    }
  }
);
export const AccountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<TokenResponse>) => {
      const tokenDetails = action.payload;
      const accessToken = parseJwt(tokenDetails?.tokens?.accessToken?.accessToken!);

      state.isAuthenticated = true;
      state.tokenDetails = tokenDetails;
      state.userDetails = {
        firstName: accessToken.firstname,
        lastName: accessToken.lastname,
        email: accessToken.emailAddress,
        organisationId: accessToken.orgId,
        sponsorCode: accessToken.sub?.slice(0, 5)
      };
    },
    logout: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAccountData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAccountData.fulfilled, (state, { payload }) => {
      console.log({ fulfilled: payload });
      state.loading = false;
      state.userDetails.orgUuid = payload.data?.userInfo?.orgUuid;
      state.userDetails.organisationName = payload.data?.userInfo.organizationName ?? '';
      state.userDetails.roles = [payload.data?.userInfo?.role];
    });
    builder.addCase(fetchAccountData.rejected, (state, { payload }) => {
      state.loading = false;
      state.userDetails.orgUuid = ''
      state.userDetails.organisationName = ''
      state.userDetails.roles = [''];
    });
  }
});

export const { actions: accountActions, reducer: accountReducer } = AccountSlice;

export const { login, logout } = accountActions;
