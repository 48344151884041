import React, { FC } from 'react';
import Card from '@material-ui/core/Card';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useNavigate } from 'react-router';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      background: 'transparent',
      marginTop: theme.spacing(2),
      flexShrink: 0,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      boxShadow: 'none',
    },
    titleTxt: {
      color: 'white',
      fontWeight: 'bolder',
    },

    line: {
      height: 3,
      backgroundColor: '#05a8cc',
      opacity: 0.4,
    },
    box: {
      display: 'flex',
      justifyContent: 'flex-start',
      paddingTop: theme.spacing(2),
      // width: '80%',
    },
    textBox: {
      display: 'flex',
      flexDirection: 'column',
    },
    subTitle: {
      fontWeight: 'lighter',
      marginBottom: theme.spacing(2),
    },
    button: {
      width: 137,
      marginTop: theme.spacing(2),
    },
  }),
);

interface Props {
  step: string;
  title: string;
  description: string;
  buttonLabel?: string;
  buttonLink?: string;
  icon?: any;
}

const StepCard: FC<Props> = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Card className={classes.card} raised>
      <Typography variant="h3" className={classes.titleTxt}>
        {props.step}
      </Typography>
      <div className={classes.line} />
      <div className={classes.box}>
        <div className={classes.textBox}>
          <Typography variant="h5" className={classes.subTitle}>
            {props.title}
          </Typography>
          <Typography variant="body1"> {props.description}</Typography>
          {props.buttonLabel && (
            <Button
              color="secondary"
              variant="contained"
              className={classes.button}
              aria-label={`navigate to ${props.buttonLabel}`}
              onClick={() => navigate(props.buttonLink ? props.buttonLink : '/')}
            >
              {props.buttonLabel}
            </Button>
          )}
        </div>
        {props.icon}
      </div>
    </Card>
  );
};

export default StepCard;
