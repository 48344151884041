import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';
import ProductCard from '../cards/products';
import Custody from '../../assets/custody.jpg';
import fundAccounting from '../../assets/fund-accounting.jpg';
import RiskAndPerformance from '../../assets/rish-and-performance.jpg';
import MiddleOffice from '../../assets/middle-office.jpg';
import TransferAgency from '../../assets/transfer-agency.jpg';
import BankingandTreasuryImage from '../../assets/currency-connected-world-624x351.jpg';


const APIs: React.FC = () => {
  const [Products] = useState([
    {
      name: 'Banking and Treasury',
      description: 'Access a range of Banking solutions including Payments, Balance and Transaction Reporting.',
      img: BankingandTreasuryImage
    },
    {
      name: 'Custody',
      description: 'Monitor activity, reconcile transactions and positions.',
      img: Custody
    },
    {
      name: 'Fund Accounting',
      description: 'Support operational, fund oversight, analytical and reconciliation functions.',
      img: fundAccounting
    },
    {
      name: 'Middle Office',
      description: 'Monitor investment activity, reconcile transactions and positions.',
      img: MiddleOffice
    },
    
    {
      name: 'Risk and Performance',
      description: 'Enable streamlined reporting, analytical and operational workflows.',
      soon: false,
      img: RiskAndPerformance
    },
    {
      name: 'Transfer Agency',
      description: 'Detailed information on investor transactions and holdings.',
      soon: false,
      img: TransferAgency
    },
  ]);

  return (
    <React.Fragment>
      {Products.map((eachProduct, index) => {
        return (
          <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
            <ProductCard
              name={eachProduct.name}
              description={eachProduct.description}
              img={eachProduct.img}
            />
          </Grid>
        );
      })}
    </React.Fragment>
  );
};
export default APIs;
