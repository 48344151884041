import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const ErrorAlert = () => {
  return (
    <div data-testid="erroralert">
      <Snackbar open={true} autoHideDuration={6000} data-cy="ErrorAlert">
        <MuiAlert severity="error" elevation={6} variant="filled">
          Something went wrong, please try again.
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default ErrorAlert;
