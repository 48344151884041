import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { get } from '../utils';

export enum EventStatus {
  SUBSCRIBED = 'subscribed',
  UNSUBSCRIBED = 'unsubscribed'
};
export type getEventRequestParameters = {
  page: number;
  pageSize:number;
  sponsorCode:string;
};

export type subScriptionsData ={
  callbackUrl: string
  eventId:string,
  subscriptionId:string,
  eventName:string,
  displayEventName:string,
  domain:string,
  status:string,
  action:string
};

export type Event = {
  eventId: string;
  eventName: string;
  domainName: string;
  displayEventName:string;
  subscriptionId:string;
  status:string;
  eventDetails: {
    eventDescription: string;
    callbackUrl:string;
  }
}
export type EventCollection = {
  [key: string]: Event;
};
export type EventListMapping = {
  [key: string]: string;
};
export type EventAttributes = {
  [key: string]: string;
};
export type GetEventsResponse = {
  data: {
    subscriptionId: string;
    totalCount: number;
    events: EventCollection;
  };
};

export interface eventsState {
  data: EventCollection;
  loading: boolean;
  totalCount:number;
  popUpValues: { popUpText: string, errorText: string },
  currentsubScriptionsData:subScriptionsData,
  subscriptionId:string;
  confirmationboxDisplayStatus:boolean;
  
};
type FetchApplicationError = {
  status: number;
  message: string
};
export type eventRecord = {
  eventId:string;
  callbackUrl:string
};
const initialState: eventsState = {
    data: {},
    loading: false,
    totalCount:0,
    popUpValues:{
      popUpText:'',
      errorText:''
    },
    confirmationboxDisplayStatus:false,
    currentsubScriptionsData:{
      callbackUrl: '',
      eventId:'',
      subscriptionId:'',
      displayEventName:'',
      eventName:'',
      domain:'',
      status:'',
      action:''
    },
    subscriptionId:''
};

export const fetchEventsData = createAsyncThunk<
GetEventsResponse,
getEventRequestParameters,
  {
    rejectValue: FetchApplicationError
  }
>(
  'events/fetchEventsData',
  async ({page,pageSize,sponsorCode},thunkApi) => {
    try {
      const response = await get(`/bff-private/events?pageNo=${(page)}&pageSize=${pageSize}`);
      return response as GetEventsResponse
    }
    catch (error: any) {
      return thunkApi.rejectWithValue({
        status: error.status,
        message: error.message
      })
    }
  }
);

export const EventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setPopupText: (state,action: PayloadAction<string>) => {
      state.popUpValues.popUpText =  action.payload;
    },
    setPopupTextPrivatepage: (state,action: PayloadAction<string>) => {
      state.popUpValues.popUpText =  action.payload;
    },
    setCurrentSubscriptionsData: (state,action: PayloadAction<subScriptionsData>) => {
      state.currentsubScriptionsData =  action.payload;
    },
    setConfirmationBoxDisplayStatus: (state,action: PayloadAction<boolean>) => {
      state.confirmationboxDisplayStatus =  action.payload;
    },
    setErrorPopupText: (state,action: PayloadAction<string>) => {
      state.popUpValues.errorText =  action.payload;
    },
    setEventsLoading: (state,action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    updateEventRecord: (state,action: PayloadAction<eventRecord>) => {
      const eventId = action.payload.eventId;
      if(state.data && state.data[eventId]){
        state.data[eventId].eventDetails.callbackUrl =  action.payload.callbackUrl;
      }
      
    },
   },
  extraReducers: (builder) => {
    builder.addCase(fetchEventsData.fulfilled, (state, { payload }) => {
      state.totalCount = payload?.data?.totalCount;
      state.loading = false;
      state.data = payload?.data?.events;
      state.subscriptionId = payload?.data?.subscriptionId;
    });
    builder.addCase(fetchEventsData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchEventsData.rejected, (state) => {
      state.data = {};
      state.totalCount = 0;
      state.loading = false;
    });
  }
});

export const { actions: eventActions, reducer: eventsReducer } = EventsSlice;
export const { setPopupText, setErrorPopupText, setCurrentSubscriptionsData, setConfirmationBoxDisplayStatus, updateEventRecord,setEventsLoading, setPopupTextPrivatepage } = eventActions;
