export const AdminPermissions = {
  VIEW_METRICS: 'viewMetrics',
  VIEW_EVENTS:'viewEvents',
  SUBSCRIBE_EVENTS:'subscribeEvents',
  VIEW_CREDS:'viewCreds',
  CREATE_APPS:'createApps'
};
export const DeveloperPermssions = {
  VIEW_APPLICATIONS:'viewApplications',
  VIEW_EVENTS:'viewEvents'
};
export const Rules: { [key: string]: { static: string[] } } = {
  Admin: {
    static: [
      ...Object.values(AdminPermissions)
    ]
  },
  Developer: {
    static: [
      ...Object.values(DeveloperPermssions)
    ]
  }  
};