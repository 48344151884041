import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { TermsLayout } from '../../components/layout';
import Dots from '../../components/dots';
import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreOutlined';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      padding: theme.spacing(2),
      minHeight: '100vh',
      marginTop:120
    },
    title: {
      fontSize: '1.625rem',
      color: 'white',
      margin: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    contentContainer: {
      paddingTop: theme.spacing(2),
      margin: theme.spacing(2),
      color: 'white',
    },
    heading: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    paragraphs: {
      fontSize: '1rem',
      color: 'white',
      marginTop: theme.spacing(2),
    },
    link: {
      textDecoration: 'none',
      paddingLeft: theme.spacing(1),
      paddingTop: theme.spacing(1),
    },
    accordion: {
      backgroundColor: 'transparent',
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    bulletPointNumbering: {
      listStyleType: 'disc',
    },
    table: {
      fontWeight: 600,
      backgroundImage:
        'linear-gradient(180deg, rgba(12,26,41,0.9948354341736695) 33%, rgba(12,26,41,0.9948354341736695) 97%)',
      tableLayout: 'fixed',
      align: 'right',
    },
    hyperLink: {
      color: '#00C4B4',
    },
  }),
);

const PrivacyDetailsPage: FC = () => {
  const classes = useStyles();

  function createServices(line: string, overview: string) {
    return { line, overview };
  }
  const addressIreland = 'Georges Court, 54-62 Townsend St, Dublin 2, Ireland';
  const addressLondon = '50 Bank Street, Canary Wharf, London E14 5NT, United Kingdom';
  const servicesOfferedCustody = 'e.g. safekeeping, income collection, proxy voting, trade settlement, corporate action processing and tax reclamation services.'
  const servicesFATA = 'including administration of funds including full investor servicing, transaction processing, reporting and record keeping.'
  const services = [
    createServices(
      'Investment Management',
      'Managing client investment portfolios on a discretionary basis in separately managed accounts.',
    ),
    createServices(
      'Custody',
      `Custody of global portfolio of assets of clients with numerous add on services offered ${ servicesOfferedCustody }`
    ,
    ),
    createServices(
      'Fund Administration (with Transfer Agency)',
      `Providing fund valuation and accounting services and transfer agency services to clients, ${servicesFATA}`,
    ),
    createServices(
      'Fund Administration (without Transfer Agency)',
      'Providing fund valuation and accounting services to clients, including administration of funds including transaction processing, reporting and record keeping.',
    ),
    createServices(
      'Depositary',
      'Providing depositary services plus oversight for fund clients and asset verification, cash monitoring and reconciliation and investment compliance services.',
    ),
    createServices(
      'Management Company Services',
      'Providing management company services including management of investments, fund administration and marketing / distribution services to clients.',
    ),
    createServices(
      'Brokerage',
      'Execution-only dealing services in relation to a number of different investment types made by clients.',
    ),
    createServices(
      'Wealth Management / GFO',
      'Providing trustee services and/or custody arrangements, banking services, preparation of financial statements and tax reporting services to high net worth clients.',
    ),
    createServices(
      'Private Equity',
      'Administration of both general and limited partnerships for clients.',
    ),
  ];

  function createData(name: string, address: string, email: string) {
    return { name, address, email };
  }

  const rows = [
    createData(
      'Northern Trust Global Services SE* with Branches in the United Kingdom, Sweden, The Netherlands and Abu Dhabi and subsidiary in Switzerland ',
      "10 Rue du Château d'Eau, L-3364 Leudelange,Grand-Duché de Luxembourg",
      'For all Northern Trust Group entities, please contact: Privacy_Compliance@ntrs.com',
    ),
    createData(
      'Northern Trust Global Services SE, Netherlands Branch',
      'Vinoly 7th floor, Claude Debussylaan 18A, 1082 MD Amsterdam, The Netherlands',
      '',
    ),
    createData(
      'Northern Trust Fund Managers (Ireland) Limited, Netherlands Branch',
      'Vinoly 7th floor, Claude Debussylaan 18A, 1082 MD Amsterdam, The Netherlands',
      '',
    ),
    createData(
      'Northern Trust International Fund Administration Services (Ireland) Limited* ',
      addressIreland,
      '',
    ),
    createData('Northern Trust Management Services (Ireland) Limited', addressIreland, ''),
    createData('Northern Trust Fiduciary Services (Ireland) Limited', addressIreland, ''),
    createData('Northern Trust Fund Managers (Ireland) Limited', addressIreland, ''),
    createData('Northern Trust Fund Services (Ireland) Limited', addressIreland, ''),
    createData('Northern Trust Pension Trustees (Ireland) Limited', addressIreland, ''),
    createData(
      'Northern Trust Global Services SE Sweden Bankfilial',
      'Ingmar Bergmans gata 4, 1st Floor, 11434 Stockholm, Sweden',
      '',
    ),
    createData(
      'Northern Trust Fund Managers (Ireland) Limited, Sweden Branch',
      'Ingmar Bergmans gata 4, 1st Floor, 11434 Stockholm, Sweden',
      '',
    ),
    createData('Northern Trust Switzerland AG', 'Aeschenplatz 6, Basel, CH-4052 Switzerland', ''),
    createData('Northern Trust Global Services SE, United Kingdom Branch* ', addressLondon, ''),
    createData('The Northern Trust Company, London Branch', addressLondon, ''),
    createData('Northern Trust Investor Services Limited', addressLondon, ''),
    createData('Northern Trust Global Investments Limited', addressLondon, ''),
    createData(
      'Northern Trust Securities LLP with a Branch in Sydney, Australia',
      addressLondon,
      '',
    ),
    createData('Northern Trust Management Services Limited', addressLondon, ''),
    createData('The Northern Trust Company UK Pension Plan Limited ', addressLondon, ''),
    createData(
      'Northern Trust (Guernsey) Limited',
      'PO Box 71, Trafalgar Court, Les Banques, St Peter Port, Guernsey GY1 3DA',
      '',
    ),
    createData(
      'Northern Trust International Fund Administration Services (Guernsey) Limited* ',
      'PO Box 255, Trafalgar Court, Les Banques, St Peter Port, Guernsey GY1 3QL',
      '',
    ),
    createData(
      'Northern Trust Fiduciary Services (Guernsey) Limited',
      'PO Box 71, Trafalgar Court, Les Banques, St Peter Port, Guernsey GY1 3DA',
      '',
    ),
  ];
  return (
    <TermsLayout>
      <div className={classes.content}>
        <Container maxWidth="md">
          <Link to="/global-privacy-standards/emea-privacy-notice" className={classes.link}>
            <Button variant="text" color="primary">
              <ArrowBackIosIcon fontSize="small" />
              Europe, Middle East & Africa
            </Button>
          </Link>

          <Typography variant="h4" className={classes.title}>
            EMEA Privacy Notice
          </Typography>
          <Dots />
          <div className={classes.contentContainer}>
            <Typography variant="body1" className={classes.paragraphs}>
              We take your privacy seriously and value transparency at the highest possible
              standard. For more than half a century, we have continued to operate inside of the
              Europe, Middle East and Africa region and to meet the privacy needs of our clients.
            </Typography>

            <Typography variant="h6" className={classes.heading}>
              Who We Are
            </Typography>

            <Accordion classes={{ root: classes.accordion }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="body2" className={classes.heading}>
                  THIS NOTICE SETS OUT HOW MEMBERS OF THE NORTHERN TRUST GROUP WILL PROCESS PERSONAL
                  INFORMATION AS A CONTROLLER OR JOINT CONTROLLER - READ MORE
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Typography variant="body2" className={classes.paragraphs}>
                  This notice sets out how members of the Northern Trust group (collectively and
                  individually, "Northern Trust", "we" or "us") will process personal information as
                  a controller or joint controller in connection with the:
                </Typography>
                <ul>
                  <li>
                    <Box p={1}>provision of its products and services from within EMEA;</Box>
                  </li>
                  <li>
                    <Box p={1}>
                      provision or offering products or services to you if you are an individual
                      located in EMEA;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      provision of the Northern Trust global investor solution (the "Northern Trust
                      Matrix");
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      marketing of Northern Trust products or services conducted by Northern Trust;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      regulatory obligations of Northern Trust, including know-your-customer and
                      anti-money laundering;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>management and operation of our businesses and websites; or</Box>
                  </li>
                  <li>
                    <Box p={1}>management and operation of our properties and premises.</Box>
                  </li>
                </ul>
                <Typography variant="body2" className={classes.paragraphs}>
                  In this notice, "you" means:
                </Typography>
                <ul className={classes.bulletPointNumbering}>
                  <li>
                    <Box p={1}>
                      (i) any individual client of Northern Trust or individual investor in an
                      investment fund in respect of which Northern Trust acts as a service provider
                      (each a “Fund”);
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      (ii) any individual who represents or is employed by any institutional client
                      of Northern Trust or any institutional investor in a Fund, or who is a
                      shareholder, settlor, beneficiary, director, officer or ultimate beneficial
                      owner of such institutional client or institutional investor;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      (iii) any individual who represents or is employed by any promoter, investment
                      manager, fund manager or alternative investment fund manager of a Fund (each a
                      “Manager”);
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      (iv)any individual employed by our service providers and business partners.
                    </Box>
                  </li>
                </ul>
                <Typography variant="body2" className={classes.paragraphs}>
                  If you have any questions about our use of your personal information, please
                  contact our Data Protection Officer at{' '}
                  <a className={classes.hyperLink} href="mailto:Privacy_Compliance@ntrs.com">
                    Privacy Compliance
                  </a>{' '}
                  .
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Typography variant="h6" className={classes.heading}>
              How We Obtain Your Personal Data
            </Typography>

            <Accordion classes={{ root: classes.accordion }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="body2" className={classes.heading}>
                  HOW AND WHY WE OBTAIN YOUR PERSONAL DATA MAY DEPEND ON THE CAPACITY IN WHICH YOU
                  INTERACT WITH US. - READ MORE
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Typography variant="body2" className={classes.paragraphs}>
                  How and why we obtain your personal data may depend on the capacity in which you
                  interact with us.
                </Typography>
                <Typography variant="body2" className={classes.paragraphs}>
                  We will obtain some of your personal information directly from you, such as when
                  you are a customer for our products and services or you complete a form, sign up
                  for marketing or choose to give us personal information as part of the Northern
                  Trust Matrix, including in connection with the security features of the Northern
                  Trust investor portal forming part of the Northern Trust Matrix ("Matrix Portal").
                </Typography>
                <Typography variant="body2" className={classes.paragraphs}>
                  We will also obtain some of your information indirectly from Northern Trust
                  clients, from Funds, from an investor in the Fund(s) (each an "Investor"), or from
                  a Manager, where you have been appointed by that client, Investor or Manager to
                  access and use Northern Trust products and services, including the Northern Trust
                  Matrix, on its behalf. Where you are an investor in a Fund, references to Investor
                  in this notice should be read as references to you.
                </Typography>
                <Typography variant="body2" className={classes.paragraphs}>
                  We will also obtain some of your information indirectly when we provide the
                  following services to our institutional clients:
                </Typography>
                <TableContainer component={Paper}>
                  <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Business Line</TableCell>
                        <TableCell align="left">Brief Overview</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {services.map((service) => (
                        <TableRow key={service.line}>
                          <TableCell component="th" scope="row">
                            {service.line}
                          </TableCell>
                          <TableCell align="justify">{service.overview}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography variant="body2" className={classes.paragraphs}>
                  We will receive information about you when Northern Trust acts as a service
                  provider to its institutional clients and where you represent or are employed by
                  such an institutional client or where you are an underlying investor, shareholder,
                  settlor, beneficiary, director, officer or beneficial owner of such institutional
                  client. We also obtain information about you from our service providers and
                  business partners where you work with us in the course of their business.
                </Typography>
                <Typography variant="body2" className={classes.paragraphs}>
                  We will also collect some information from you when you interact with our websites
                  or visit our premises.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Typography variant="h6" className={classes.heading}>
              When We Obtain Information About Third Parties From You
            </Typography>

            <Accordion classes={{ root: classes.accordion }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="body2" className={classes.heading}>
                  WHERE WE OBTAIN PERSONAL INFORMATION FROM YOU IN RESPECT OF THIRD PARTIES - READ
                  MORE
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Typography variant="body2" className={classes.paragraphs}>
                  Where we obtain personal information from you in respect of third parties, you
                  warrant that you are authorised to provide that information to us, that you will
                  only do so in accordance with applicable data protection laws, and that you will
                  ensure that before doing so, the individuals in question are made aware of the
                  fact that Northern Trust will hold information relating to them and that it may
                  use it for any of the purposes set out in this privacy notice, and where necessary
                  that you will obtain consent to Northern Trust's use of the information.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Typography variant="h6" className={classes.heading}>
              Types of Personal Data We Collect
            </Typography>
            <Accordion classes={{ root: classes.accordion }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="body2" className={classes.heading}>
                  THE TYPES OF PERSONAL INFORMATION COLLECTED BY NORTHERN TRUST - READ MORE
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Typography variant="body2" className={classes.paragraphs}>
                  <b>Northern Trust Matrix and Other Services</b>
                </Typography>
                <Typography variant="body2" className={classes.paragraphs}>
                  The types of personal information collected by Northern Trust include, without
                  limitation:
                </Typography>
                <ul>
                  <li>
                    <Box p={1}>
                      in respect of individual clients and Investors, the name, address (including
                      postal and/or e-mail address), telephone number, bank account details, date of
                      birth, place of birth, nationality, source of wealth, proof of identity
                      details (passport, driving licence or nationality identity), occupation,
                      country of residence/tax residence, invested amount and holdings of that
                      Investor;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      in respect of institutional clients and institutional Investors, and their
                      underlying investors, shareholders, settlors, beneficiaries, directors,
                      officers or beneficial owners, the name and address (including postal and/or
                      e-mail address), date of birth, nationality, country of residence/tax
                      residence and percentage ownership of underlying investors, shareholders,
                      settlors, beneficiaries, directors, officers or beneficial owners;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      in the case of all clients and Investors, any personal data which is required
                      in order to comply with regulatory requirements, including information which
                      is necessary for tax requirements (including tax number and documentation) and
                      for compliance with local or foreign laws;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      in respect of users of Northern Trust websites and systems, including the
                      Matrix Portal, name, address (including postal and /or email address) and
                      security details, and special categories of personal data, such as biometric
                      data.
                    </Box>
                  </li>
                </ul>
                <Typography variant="body2" className={classes.paragraphs}>
                  <b>Operation of the Business</b>
                </Typography>
                <Typography variant="body2" className={classes.paragraphs}>
                  Northern Trust will also collect and use information in the day-to-day operation
                  of our business, including:
                </Typography>
                <ul>
                  <li>
                    <Box p={1}>
                      name, address (including postal and /or email address), title and role of
                      employees and representatives of our institutional clients, service providers
                      and business partners;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>name and contact details for direct marketing;</Box>
                  </li>
                  <li>
                    <Box p={1}>content and metadata of call recordings;</Box>
                  </li>
                  <li>
                    <Box p={1}>CCTV images;</Box>
                  </li>
                  <li>
                    <Box p={1}>
                      name, date, time and purpose of visit for visitors of our premises.
                    </Box>
                  </li>
                </ul>
                <Typography variant="body2" className={classes.paragraphs}>
                  <b>Operating our Electronic Infrastructure</b>
                </Typography>
                <Typography variant="body2" className={classes.paragraphs}>
                  Northern Trust will collect information through your use of our website or other
                  online services such apps, including:
                </Typography>
                <ul>
                  <li>
                    <Box p={1}>
                      device information such as operating system, unique device identifiers, the
                      mobile network system;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>hardware and browser settings;</Box>
                  </li>
                  <li>
                    <Box p={1}>IP address;</Box>
                  </li>
                  <li>
                    <Box p={1}>pages you visit on our site and search engine terms used.</Box>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>

            <Typography variant="h6" className={classes.heading}>
              Why We Process Your Personal Data and Legal Basis
            </Typography>
            <Accordion classes={{ root: classes.accordion }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="body2" className={classes.heading}>
                  NORTHERN TRUST MAY PROCESS YOUR PERSONAL INFORMATION FOR THE FOLLOWING PURPOSES -
                  READ MORE
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Typography variant="body2" className={classes.paragraphs}>
                  Northern Trust may process your personal information for the following purposes:
                </Typography>
                <Typography variant="body2" className={classes.paragraphs}>
                  <b>Relationship Management and Administration</b>
                </Typography>
                <ul>
                  <li>
                    <Box p={1}>
                      Managing and administering your holdings in any relevant Fund and/or managing
                      any account you hold with Northern Trust on an on-going basis;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      Managing our relationship with you or the institution you represent;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      Updating and maintaining client records and records for fee billing.
                    </Box>
                  </li>
                </ul>
                <Typography variant="body2" className={classes.paragraphs}>
                  The legal bases for this processing are:
                </Typography>

                <ul>
                  <li>
                    <Box p={1}>
                      Northern Trust's legitimate interests in understanding and developing our
                      relationship with you or the institution you represent;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>Contractual obligations;</Box>
                  </li>
                  <li>
                    <Box p={1}>Consent (where required by applicable law).</Box>
                  </li>
                </ul>
                <Typography variant="body2" className={classes.paragraphs}>
                  <b>Development and Management of our Services</b>
                </Typography>
                <Typography variant="body2" className={classes.paragraphs}>
                  Northern Trust may process your personal information for the following purposes:{' '}
                </Typography>

                <ul>
                  <li>
                    <Box p={1}>Day to day administration and management of our businesses;</Box>
                  </li>
                  <li>
                    <Box p={1}>Carrying out statistical analysis and market research;</Box>
                  </li>
                  <li>
                    <Box p={1}>
                      Monitoring and recording calls and electronic communication for (i) processing
                      and verification of instructions, (ii) enforcing and defending Northern
                      Trust's rights itself or through third parties to whom it delegates such
                      responsibility, (iii) quality, business analysis, training and related
                      purposes;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      Providing you with information about products and services which may be of
                      interest to you or to the institution you represent;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      Disclosing information to third parties, such as service providers of Northern
                      Trust, auditors, regulatory authorities and technology providers;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      Processing in the event of a merger, reorganisation or disposal of, or a
                      proposed merger, reorganisation or disposal of any member (or the assets of
                      any member) of the Northern Trust group.
                    </Box>
                  </li>
                </ul>

                <Typography variant="body2" className={classes.paragraphs}>
                  The legal bases for this processing are
                </Typography>

                <ul>
                  <li>
                    <Box p={1}>
                      Northern Trust's legitimate interests in understanding, improving and
                      developing our services and products, identifying relevant clients or
                      opportunities for our services and products, making and defending against
                      legal claims, legitimate interests in connection with mergers, reorganisations
                      or disposals;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>Contractual obligations;</Box>
                  </li>
                  <li>
                    <Box p={1}>Legal obligations;</Box>
                  </li>
                  <li>
                    <Box p={1}>Consent (where required by applicable law).</Box>
                  </li>
                </ul>

                <Typography variant="body2" className={classes.paragraphs}>
                  <b>Crime Prevention and Detection</b>
                </Typography>
                <Typography variant="body2" className={classes.paragraphs}>
                  Northern Trust may process your personal information for the following purposes:
                </Typography>

                <ul>
                  <li>
                    <Box p={1}>
                      Northern Trust will itself (or through a third party e.g. credit reference
                      agency) process certain information about you or your directors, officers and
                      employees and your beneficial owners (if applicable) in order to carry out
                      anti-money laundering (AML) checks and related actions which Northern Trust
                      considers appropriate in accordance with Northern Trust's AML procedures;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      Monitoring and recording calls and electronic communications for investigation
                      and fraud prevention purposes, for crime detection, prevention, investigation
                      and prosecution, and to enforce or defend Northern Trust and its affiliates'
                      rights, itself or through third parties to whom it delegates such
                      responsibilities or rights in order to comply with a legal obligation
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      Retaining AML and other records of individuals to assist with subsequent
                      screening and ongoing AML customer due diligence and monitoring of them
                      including in relation to investment or other funds or the provision of other
                      services by Northern Trust.
                    </Box>
                  </li>
                </ul>

                <Typography variant="body2" className={classes.paragraphs}>
                  The legal bases for this processing are:
                </Typography>

                <ul>
                  <li>
                    <Box p={1}>
                      Northern Trust's legitimate interests relating to the prevention of fraud,
                      money laundering, terrorist financing, bribery, corruption, tax evasion and to
                      prevent the provision of financial services to persons who may be subject to
                      economic or trade sanctions, on an on-going basis, relating to making or
                      defending against legal claims, or relating to Northern Trust's ability to
                      support its clients' legitimate interests;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>Public interest;</Box>
                  </li>
                  <li>
                    <Box p={1}>Contractual obligations;</Box>
                  </li>
                  <li>
                    <Box p={1}>Legal obligations.</Box>
                  </li>
                </ul>

                <Typography variant="body2" className={classes.paragraphs}>
                  <b>Other Legal and Regulatory Obligations</b>
                </Typography>
                <Typography variant="body2" className={classes.paragraphs}>
                  Northern Trust may process your personal information for the following purposes:
                </Typography>

                <ul>
                  <li>
                    <Box p={1}>
                      Reporting tax related information to tax authorities in order to comply with a
                      legal obligation applicable to Northern Trust.
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      Reporting IBAN and related bank account information to regulatory authorities
                      in order to comply with a legal obligation applicable to Northern Trust.
                    </Box>
                  </li>
                </ul>

                <Typography variant="body2" className={classes.paragraphs}>
                  The legal bases for this processing are:
                </Typography>

                <ul>
                  <li>
                    <Box p={1}>
                      Northern Trust's legitimate interests in supporting compliance with its legal
                      and regulatory obligations in the markets in which it operates;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>Public interest;</Box>
                  </li>
                  <li>
                    <Box p={1}>Contractual obligations;</Box>
                  </li>
                  <li>
                    <Box p={1}>Legal obligations.</Box>
                  </li>
                </ul>

                <Typography variant="body2" className={classes.paragraphs}>
                  <b>Protection and Administration of our Physical and Electronic Infrastructure</b>
                </Typography>
                <Typography variant="body2" className={classes.paragraphs}>
                  Northern Trust may process your personal information for the following purposes:
                </Typography>

                <ul>
                  <li>
                    Site management including the use of:
                    <ul>
                      <li>
                        <Box p={1}>CCTV;</Box>
                      </li>
                      <li>
                        <Box p={1}>Sign-in procedures; and</Box>
                      </li>
                      <li>
                        <Box p={1}>Access control measures.</Box>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Box p={1}>
                      Monitoring use of website and online infrastructure for technical improvement
                      and cybersecurity
                    </Box>
                  </li>
                </ul>

                <Typography variant="body2" className={classes.paragraphs}>
                  The legal bases for this processing are:
                </Typography>

                <ul>
                  <li>
                    <Box p={1}>
                      Northern Trust's legitimate interests (and the legitimate interests of our
                      clients) in administering and securing our infrastructure;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>Contractual obligations;</Box>
                  </li>
                  <li>
                    <Box p={1}>Legal obligations.</Box>
                  </li>
                </ul>

                <Typography variant="body2" className={classes.paragraphs}>
                  <b>Northern Trust Matrix</b>
                </Typography>
                <Typography variant="body2" className={classes.paragraphs}>
                  We may use personal information which you provided in connection with an
                  investment in a Fund, or which we have received from a Fund or Manager as a result
                  of services which we provide to that Fund or Manager, for the purposes of the
                  services provided via the Matrix Portal. We may also use this information for the
                  purposes of providing services to other Funds (or their Managers) in which you
                  have invested or in respect of which an Investor has appointed you to access the
                  Matrix Portal.
                </Typography>
                <Typography variant="body2" className={classes.paragraphs}>
                  Northern Trust may process your personal information in connection with the
                  Northern Trust Matrix:
                </Typography>
                <ul>
                  <li>
                    <Box p={1}>
                      Holding, maintaining and using the single source record, relating to each
                      Investor;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      Carrying out AML checks and related actions which Northern Trust considers
                      appropriate to meet any legal obligations imposed on the Fund(s) or Northern
                      Trust;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      Updating and maintaining records in connection with our use of the Northern
                      Trust Matrix.
                    </Box>
                  </li>
                </ul>

                <Typography variant="body2" className={classes.paragraphs}>
                  Where an Investor has agreed to Northern Trust’s centralised AML procedures, and
                  your personal data is required in connection with that Investor’s AML compliance
                  Northern Trust, may process your personal data as a controller by:
                </Typography>
                <ul>
                  <li>
                    <Box p={1}>
                      Retaining AML and other records of individuals to assist with the subsequent
                      screening and ongoing AML customer due diligence and monitoring of Investors
                      by Northern Trust and its affiliates, including in relation to other funds or
                      clients of Northern Trust or any of its affiliates.
                    </Box>
                  </li>
                </ul>

                <Typography variant="body2" className={classes.paragraphs}>
                  Where you have subscribed to use Matrix Portal Northern Trust, as a controller,
                  will additionally use your personal data for:
                </Typography>
                <ul>
                  <li>
                    <Box p={1}>
                      Security purposes in connection with Matrix Portal, which are necessary in
                      Northern Trust’s and your legitimate interests, or where you have provided
                      your explicit consent in advance to Northern Trust’s use of biometric data
                      relating to you;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      Facilitation of electronic dealings and access to information in connection
                      with investments in the Fund(s), including without limitation the processing
                      of subscription, redemption, conversion, transfer and additional subscription
                      requests, where the relevant Fund permits electronic dealings;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      Disclosing information to third party service and application providers in
                      order to facilitate payment and other services accessed in connection with and
                      to facilitate the functioning of Matrix Portal.
                    </Box>
                  </li>
                </ul>

                <Typography variant="body2" className={classes.paragraphs}>
                  The legal bases for this processing are:
                </Typography>
                <ul>
                  <li>
                    <Box p={1}>
                      Northern Trust's legitimate interests and the legitimate interests of our
                      clients in providing and using the Northern Trust Matrix;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>Contractual obligations;</Box>
                  </li>
                  <li>
                    <Box p={1}>Legal obligations;</Box>
                  </li>
                  <li>
                    <Box p={1}>
                      Consent (including explicit consent where required by applicable law).
                    </Box>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>

            <Typography variant="h6" className={classes.heading}>
              When We Act As Processor
            </Typography>
            <Accordion classes={{ root: classes.accordion }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="body2" className={classes.heading}>
                  NORTHERN TRUST WILL NOT AT ANY TIME BE ACTING AS A JOINT CONTROLLER WITH ITS
                  INSTITUTIONAL CLIENTS OR A FUND - READ MORE
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Typography variant="body2" className={classes.paragraphs}>
                  While this notice sets out how Northern Trust will use your personal data as
                  controller (and as joint controller with other members of the Northern Trust
                  group), you should also note that where Northern Trust provides services to a Fund
                  or other institutional client, Northern Trust will continue to act as a processor
                  for that institutional client, Fund and / or its Manager, which will act as
                  controller(s). You should refer to the relevant controller's data protection
                  notice to understand how your personal information will be used in connection with
                  that institutional client or investment in that Fund by you or the Investor that
                  has appointed you to act on its behalf. Northern Trust will not at any time be
                  acting as a joint controller with its institutional clients or a Fund.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Typography variant="h6" className={classes.heading}>
              When We Share Your Data with Others
            </Typography>
            <Accordion classes={{ root: classes.accordion }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="body2" className={classes.heading}>
                  NORTHERN TRUST MAY DISCLOSE YOUR PERSONAL INFORMATION - READ MORE
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Typography variant="body2" className={classes.paragraphs}>
                  Northern Trust may disclose your personal information as follows:
                </Typography>
                <ul>
                  <li>
                    <Box p={1}>
                      to its service providers, including its affiliates, and other third party
                      service providers engaged by Northern Trust in order to process the data for
                      the above mentioned purposes;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      to competent authorities (including tax authorities), courts and bodies as
                      required by law or requested or to affiliates for internal investigations and
                      reporting;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      to Northern Trust’s affiliates as controllers in connection with Northern
                      Trust’s centralised AML procedure (where applicable), in connection with
                      investment funds to which those affiliates act as a service provider;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      to credit reference agencies (e.g. Experian) in order to carry out money
                      laundering and identity checks and comply with legal obligations;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      to Northern Trust’s affiliates as controllers in connection with the operation
                      and provision of information via Northern Trust’s website, systems and / or
                      the Matrix Portal, where you have subscribed to use these; and
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      in the event of a merger or proposed merger, any (or any proposed) transferee
                      of, or successor in title to, the whole or any part of the Northern Trust
                      group’s business, and their respective officers, employees, agents and
                      advisers, to the extent necessary to give effect to such transaction.
                    </Box>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>

            <Typography variant="h6" className={classes.heading}>
              When We Transfer Your Data Internationally
            </Typography>
            <Accordion classes={{ root: classes.accordion }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="body2" className={classes.heading}>
                  NORTHERN TRUST MAINTAINS A GLOBAL OPERATING MODEL TO PROVIDE SERVICES AND PRODUCTS
                  TO CLIENTS GLOBALLY - READ MORE
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Typography variant="body2" className={classes.paragraphs}>
                  Northern Trust maintains a global operating model to provide services and products
                  to clients globally. The disclosure of personal information to the third parties
                  set out above may therefore involve the transfer of data to the USA and other
                  jurisdictions outside the European Economic Area (“EEA”) in accordance with
                  applicable data protection laws in your jurisdiction.
                </Typography>
                <Typography variant="body2" className={classes.paragraphs}>
                  Where such countries may not have the same data protection laws as your
                  jurisdiction, and are not recognised as having an adequate level of data
                  protection by the European Commission or under data protection law in your
                  jurisdiction, we take appropriate steps to implement alternative arrangements to
                  ensure your data continues to be adequately protected and your privacy rights
                  respected.
                </Typography>
                <Typography variant="body2" className={classes.paragraphs}>
                  Northern Trust maintains a global operating model to provide services and products
                  to clients globally. The disclosure of personal information to the third parties
                  set out above may therefore involve the transfer of data to the USA and other
                  jurisdictions outside the European Economic Area (“EEA”) in accordance with
                  applicable data protection laws in your jurisdiction.
                </Typography>
                <Typography variant="body2" className={classes.paragraphs}>
                  Please contact{' '}
                  <a className={classes.hyperLink} href="mailto:Privacy_Compliance@ntrs.com">
                    Privacy Compliance
                  </a>{' '}
                  for copies of the Standard Contractual Clauses that have been entered into by
                  Northern Trust.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Typography variant="h6" className={classes.heading}>
              How Long We Keep Your Data
            </Typography>
            <Accordion classes={{ root: classes.accordion }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="body2" className={classes.heading}>
                  NORTHERN TRUST WILL RETAIN YOUR PERSONAL INFORMATION FOR AS LONG AS REQUIRED FOR
                  NORTHERN TO PERFORM THE SERVICES - READ MORE
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Typography variant="body2" className={classes.paragraphs}>
                  Northern Trust will retain your personal information for as long as required for
                  Northern to perform the services. In general terms, we will retain your personal
                  information for the length of time we perform the services (including the Northern
                  Trust Matrix) or the service to the institution you represent. We may maintain
                  different retention periods for different products and services.
                </Typography>
                <Typography variant="body2" className={classes.paragraphs}>
                  We will also retain your personal information for the period as required by tax
                  and company laws, or other regulations such as anti-money laundering and
                  know-your-customer regulations, to which we or your institution are subject, and
                  as long as necessary for you to be able to bring a claim against us and for us to
                  defend ourselves against any legal claims. This will generally be the length of
                  the relationship plus the length of any applicable statutory limitation period
                  under local laws.
                </Typography>
                <Typography variant="body2" className={classes.paragraphs}>
                  In certain circumstances, information may need to be retained for a longer period
                  of time, for example where we are in ongoing correspondence or there is a
                  continuing claim or investigation.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Typography variant="h6" className={classes.heading}>
              Keeping Your Personal Data Up To Date
            </Typography>
            <Accordion classes={{ root: classes.accordion }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="body2" className={classes.heading}>
                  NORTHERN TRUST WILL USE REASONABLE EFFORTS TO KEEP YOUR PERSONAL INFORMATION UP TO
                  DATE - READ MORE
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Typography variant="body2" className={classes.paragraphs}>
                  Northern Trust will use reasonable efforts to keep your personal information up to
                  date. However, you will need to notify Northern Trust without delay in the event
                  of any change in your personal circumstances, or those of the others mentioned
                  above, so that Northern Trust can keep the personal information up to date. You
                  should do this through Matrix Portal or by contacting{' '}
                  <a className={classes.hyperLink} href="mailto:Privacy_Compliance@ntrs.com">
                    Privacy Compliance
                  </a>{' '}
                  .
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Typography variant="h6" className={classes.heading}>
              Your Data Subject Rights
            </Typography>
            <Accordion classes={{ root: classes.accordion }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="body2" className={classes.heading}>
                  YOU HAVE THE FOLLOWING RIGHTS, IN CERTAIN CIRCUMSTANCES AND SUBJECT TO CERTAIN
                  EXCEPTIONS, IN RELATION TO YOUR PERSONAL INFORMATION - READ MORE
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Typography variant="body2" className={classes.paragraphs}>
                  You have the following rights, in certain circumstances and subject to certain
                  exceptions, in relation to your personal information:
                </Typography>

                <ul>
                  <li>
                    <Box p={1}>
                      Right to access your personal information – You have a right to request that
                      we provide you with a copy of your personal information and to be informed
                      about how we obtained and use your personal information, who it is shared with
                      and who is the controller.
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      Right to rectify your personal information – You have a right to request that
                      we correct inaccurate personal information.
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      Right to restrict the use of your personal information – In certain
                      circumstances you have a right to ask us to restrict the use of your personal
                      information. This applies if you contest the accuracy of the personal
                      information (to allow us to verify its accuracy), the processing is unlawful
                      but you do not want the information erased, it is no long needed for the
                      purpose for which we collected the information but is required by you to
                      establish or exercise legal claims or defences, or because you have exercised
                      your right to object and we are considering your request. Depending on the
                      situation we may refuse your request, in particular if using this information
                      is necessary for legal claims.
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      Right to request that your personal information is erased – In certain
                      circumstances you have a right to ask us to erase your personal information
                      (this is also often called the "right to be forgotten"). This applies to
                      personal information that we no longer need for the purpose for which it was
                      collected, if the processing is based on consent and you withdraw that
                      consent, if you have successfully exercised your right to object, if the
                      personal information has been processed unlawfully, or if we are legally
                      required to erase the data. Depending on the situation we may refuse your
                      request, in particular if we need to process this information to meet our
                      legal obligations or if the information is necessary for legal claims.
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      Right to object to processing of your personal information – In certain
                      circumstances you have the right to object to the processing of your personal
                      information. This applies if we process the personal information on the basis
                      of our legitimate interests or if the information is processed for the purpose
                      of direct marketing. We will review this request to establish if we have
                      compelling legitimate interests which override your rights and freedoms.
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      Right to data portability – In certain circumstances you have the right to ask
                      us to provide your personal information to you or another controller in a
                      structured, commonly used, machine-readable format. This applies when we
                      process your information by automated means and this processing is based on
                      your consent or performance of a contract with you.
                    </Box>
                  </li>
                </ul>

                <Typography variant="body2" className={classes.paragraphs}>
                  Where you have provided your consent to processing (e.g. to receive information
                  about products and services which may be of interest to you or the institution you
                  represent), you may withdraw your consent at any time by contacting Northern Trust
                  by email at{' '}
                  <a className={classes.hyperLink} href="mailto:Privacy_Compliance@ntrs.com">
                    Privacy Compliance
                  </a>{' '}
                  .
                </Typography>
                <Typography variant="body2" className={classes.paragraphs}>
                  Where Northern Trust requires your personal information to comply with AML or
                  other legal requirements, failure to provide this information means Northern Trust
                  will not be able to provide services to you or the institution you represent,
                  facilitate dealings in the Fund(s) (whether electronic or otherwise) or the
                  provision of information in relation to the Funds to you via the Matrix Portal.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Typography variant="h6" className={classes.heading}>
              How To Contact Supervisory Authorities
            </Typography>
            <Accordion classes={{ root: classes.accordion }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="body2" className={classes.heading}>
                  YOU HAVE THE RIGHT TO LODGE A COMPLAINT IN RELATION TO NORTHERN TRUST’S PROCESSING
                  OF YOUR PERSONAL DATA - READ MORE
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Typography variant="body2" className={classes.paragraphs}>
                  You have the right to lodge a complaint in relation to Northern Trust’s processing
                  of your personal data:
                </Typography>

                <ul>
                  <li>
                    <Box p={1}>
                      with the supervisory authority in the EU Member State of your habitual
                      residence or place of work or in the place of the alleged infringement. A list
                      of the supervisory authorities within the EU can be found{' '}
                      <a
                        className={classes.hyperLink}
                        href="https://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080 "
                      >
                        here
                      </a>
                      {'; '}
                      or
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      where your habitual residence or place of work or the alleged infringement is
                      in Guernsey, with the Office of the Data Protection Commissioner, contactable
                      at St Martin’s House, Le Bordage, St Peter Port, Guernsey GY1 1BR,
                      enquiries@odpc.gg or +44 (0)1481 742074); or
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      where your habitual residence or place of work or the alleged infringement is
                      in the UK, with the UK Information Commissioner’s Office, contactable at
                      Wycliffe House, Water Lane, Wilmslow, Cheshire SK9 5AF (or at the relevant
                      regional office for Scotland, Wales or Northern Ireland, details of which are
                      available at https://ico.org.uk/global/contact-us/postal-addresses/),
                      casework@ico.org.uk or +44 (0)303 123 1113; or
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      where your habitual residence or place of work or the alleged infringement is
                      in Switzerland, with the Swiss Federal Data Protection and Information
                      Commissioner, contactable at Feldeggweg, CH-3003, Switzerland or +41 (0)58 462
                      43 95.
                    </Box>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>

            <Typography variant="h6" className={classes.heading}>
              How To Contact Us
            </Typography>
            <Accordion classes={{ root: classes.accordion }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="body2" className={classes.heading}>
                  QUESTIONS ABOUT OUR USE OF YOUR PERSONAL INFORMATION - READ MORE
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Typography variant="body2" className={classes.paragraphs}>
                  If you have any questions about our use of your personal information, please
                  contact our Data Protection Officer at{' '}
                  <a className={classes.hyperLink} href="mailto:Privacy_Compliance@ntrs.com">
                    Privacy Compliance
                  </a>{' '}
                  , and/or:
                </Typography>

                <ul>
                  <li>
                    <Box p={1}>
                      in Luxembourg, 10 rue du Château d’Eau, L-3364 Leudelange, Grand-Duché de
                      Luxembourg ;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      in the United Kingdom, 50 Bank Street, London, E14 5NT, United Kingdom;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      in Ireland, Georges Court, 54-62 Townsend Street, Dublin 2, Ireland;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      in Guernsey, PO Box 71, Trafalgar Court, Les Banques, St Peter Port, Guernsey,
                      GY1 3DA, GBR;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      in the Netherlands, Vinoly 7th floor, Claude Debussylaan 18A, 1082 MD
                      Amsterdam, The Netherlands;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>
                      in Sweden, Ingmar Bergmans gata 4, 1st Floor, 11434 Stockholm, Sweden;
                    </Box>
                  </li>
                  <li>
                    <Box p={1}>in Switzerland, Aeschenplatz 6, Basel, CH-4052, Switzerland.</Box>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>

            <Typography variant="h6" className={classes.heading}>
              Northern Trust Entities
            </Typography>
            <Accordion classes={{ root: classes.accordion }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="body2" className={classes.heading}>
                  EXPAND TO VIEW THE CHART FOR APPENDIX 1 – NORTHERN TRUST ENTITIES
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                  <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>NT Group entity</TableCell>
                        <TableCell align="right">Company Registration Address</TableCell>
                        <TableCell align="right">Data Protection Contact Email Address</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.name}>
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell align="right">{row.address}</TableCell>
                          {row.email !== '' ? (
                            <TableCell
                              align="right"
                              style={{ verticalAlign: 'top' }}
                              rowSpan={rows.length}
                            >
                              {row.email}
                            </TableCell>
                          ) : null}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
              * These entities also act as joint controller for the purposes of providing Northern
              Trust Matrix services.
            </Accordion>
          </div>
        </Container>
      </div>
    </TermsLayout>
  );
};

export default PrivacyDetailsPage;
