import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import DialogActions from '@material-ui/core/DialogActions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullContainer: {
      minHeight: '100vh',
     
    },
    container: {},
    pageTitle: {
      color: 'white',
      letterSpacing: '0.0625rem',
      fontWeight: 300,
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(4),
    },
    dialogBorder: {
      border: '1px solid #05a8cc',
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      borderRadius: 10,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(2),
      color: theme.palette.grey[500],
    },
    content: {
      color: 'white',
      fontWeight: 300,
      width: '100%',
      fontSize: '1.125rem',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    actionContainer: {
      display: 'flex',
      justifyContent: 'center',
      
    },
    dialogTitle:{
      display: 'flex',
      justifyContent: 'center',
      textAlign:'center',
      margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
    paper: {
      backgroundImage:
        'linear-gradient(180deg, rgba(12,26,41,0.9948354341736695) 33%, rgba(12,26,41,0.9948354341736695) 97%)',
      position: "absolute",
      left: 0,
      bottom: 30,
      width:320
    },
  }),
);

interface Props {
  state:any;
}
const CookieMessage: FC<Props> = ({state}) => {
  const classes = useStyles();
  const reloadPage = () => {
    window.location.reload();
  };
  return (
    <Dialog
      open={state}
      fullWidth
      keepMounted
      classes={{ paper: classes.paper }}
      data-cy="contactus-success-modal"
    >
      <div className={classes.dialogBorder}>
        <React.Fragment>
          <DialogTitle className={classes.dialogTitle}>
            <Typography variant="body1">Cookies are required</Typography>
           </DialogTitle>
          <DialogContent className={classes.content}>
            <Box m={1}>
              <Typography variant="body1">
                Cookies are disabled on your browser. Please enable Cookies and refresh this page.
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions className={classes.actionContainer}>
            <Button variant="contained" onClick={() => reloadPage()} color="secondary">
              Refresh
            </Button>
          </DialogActions>
        </React.Fragment>
      </div>
    </Dialog>
  );
};

export default CookieMessage;
