import { Box, createStyles, Icon, makeStyles, Theme, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { Application, ApplicationStatus as StatusEnum } from '../../store/applicationsSlice';
import React from 'react';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    applicationStatusWrapper: {
      display: 'flex'
    },
    statusText: {
      color: '#ffffff',
      display: 'flex',
      alignItems: 'center'
    },
    icon: {
      marginRight: '10px'
    },
    active: {
      color: '#03dac5'
    },
    pending: {
      color: '#985eff'
    },
    disabled: {
      color: '#cf6679'
    }
  }));

const ApplicationStatus: React.FC<Application> = (application: Application) => {
  const classes = useStyles();

  const renderIcon = () => {
    switch (application.status) {
      case StatusEnum.ENABLED: {
        return (
          <Icon className={clsx(
            classes.icon,
            classes.active
          )}>
            <CheckCircleIcon />
          </Icon>
        );
      }

      case StatusEnum.PENDING: {
        return (
          <Icon className={clsx(
            classes.icon,
            classes.pending
          )}>
            <InfoIcon />
          </Icon>
        );
      }

      case StatusEnum.DISABLED: {
        return (
          <Icon className={clsx(
            classes.icon,
            classes.disabled
          )}>
            <ErrorIcon />
          </Icon>
        );
      }
    }
  };

  return (
    <Box className={classes.applicationStatusWrapper}>
      <Typography variant={'body2'} className={classes.statusText}>
        {renderIcon()}
        {application.status}
      </Typography>
    </Box>
  );
};

export default ApplicationStatus;
