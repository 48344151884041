import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import { useNavigate } from 'react-router';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonBar: {
      marginTop: theme.spacing(10),
    },
    container: {
      width: '100%',
      backgroundColor: 'rgba(216,216,216, 0.10)',
      // padding: theme.spacing(3),
      minHeight: 187,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(4),
      },
    },
    name: {
      color: 'white',
    },
    icon: {
      paddingRight: theme.spacing(2),
      fontSize: 40,
    },
  }),
);

const Buttonbar: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <div className={classes.buttonBar}>
      <div className={classes.container}>
        <Container maxWidth="md">
          <Grid container justify="space-between" alignItems="center" spacing={4}>
            <Grid item lg={6} sm={4} xs={12}>
              <Typography variant="body2" className={classes.name}>
                Questions? Check out our FAQs to learn more.
              </Typography>
              <Button
                variant="text"
                color="primary"
                aria-label="view our frequently asked questions"
                onClick={() => navigate('/resources/faqs')}
              >
                <HelpOutlineOutlinedIcon color="primary" className={classes.icon} />
                VIEW FAQs
              </Button>
            </Grid>
            <Grid item lg={6} sm={8} xs={12}>
              <Typography variant="body2" className={classes.name}>
                Can’t find what you’re looking for? Our team is happy to assist.
              </Typography>
              <Button
                variant="text"
                color="primary"
                aria-label="contact us"
                onClick={() => navigate('/contact-us')}
              >
                <MailOutlineOutlinedIcon color="primary" className={classes.icon} />
                CONTACT US
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};
export default Buttonbar;
