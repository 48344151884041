import { makeStyles, Typography } from '@material-ui/core';
import React, { FC } from 'react';

const useStyles = makeStyles({
  inner: {
    borderLeft: '20px solid transparent',
    borderRight: '88px solid white',
    borderBottom: '80px solid transparent',
    height: 0,
    width: 0,
    position: 'absolute',
    right: 0,
    zIndex: 2,
    top: 0,
  },
  secinner: {
    borderLeft: '20px solid transparent',
    borderRight: '92px solid #241148',
    borderBottom: '85px solid transparent',
    height: 0,
    width: 0,
    position: 'absolute',
    right: 0,
    zIndex: 2,
    top: 0,
  },
  outer: {
    borderLeft: '44px solid transparent',
    borderRight: '96px solid white',
    borderBottom: '88px solid transparent',
    height: 0,
    width: 0,
    position: 'absolute',
    right: 0,
    zIndex: 1,
    top: 0,
  },
  text: {
    color: '#7436C1',
    fontSize: 11,
    fontWeight: 500,
    position: 'absolute',
    right: 0,
    zIndex: 10,
    width: 55,
    textAlign: 'center',
  },
});

const Soon: FC = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography color="primary" className={classes.text}>
        COMING SOON
      </Typography>

      <div className={classes.secinner}></div>
      <div className={classes.inner}></div>
      <div className={classes.outer}></div>
    </React.Fragment>
  );
};
export default Soon;
