import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { TermsLayout } from '../../components/layout';
import Dots from '../../components/dots';
import Grid from '@material-ui/core/Grid';
import { PolicyDetailsCard } from '../../components/cards';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useCookieContext } from '../../CookieContext';
import { useUserContext } from '../../UserContext';
import CookieMessage from '../../components/cookiemessage';
import ClickWrap from '../../components/clickwrap';
import SessiontTmeout  from '../../components/sessiontimeout';
import Appurls from '../../constants/appurls';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      padding: theme.spacing(2),
      minHeight: '100vh',
      marginTop:120
    },
    title: {
      fontSize: '1.625rem',
      color: 'white',
      margin: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },

    contentContainer: {
      paddingTop: theme.spacing(2),
      margin: theme.spacing(2),
      color: 'white',
    },
    heading: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    paragraphs: {
      fontSize: '1rem',
      marginTop: theme.spacing(2),
      color: 'white',
    },
    cards: {
      marginBottom: theme.spacing(2),
    },
    link: {
      textDecoration: 'none',
      paddingLeft: theme.spacing(1),
      paddingTop: theme.spacing(1),
    },
    bar: {
      height: 1,
      backgroundColor: '#05A8CC',
      opacity: 0.3,
    },
    contentTitle: {
      fontSize: '1.625rem',
      color: 'white',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(0),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      position: 'relative',
    },
  }),
);

const LAPrivacyPage: FC = () => {
  const classes = useStyles();
  const cookieDetails = useCookieContext();
  const caymanPrivacyNoticeUrl  = Appurls.getPdfUrl("cayman-privacy-notice.pdf");
  //@ts-ignore
  const { showCookieMessage } = cookieDetails;
  const userdetails = useUserContext();
  // @ts-ignore
  const { showClickWrap, showSessionTimeoutModel } = userdetails;
  const handleClickwrapClose = () => {
  
  };
  return (
    <TermsLayout>
      <div className={classes.content}>
        <Container maxWidth="md">
          <Link to="/global-privacy-standards" className={classes.link}>
            <Button variant="text" color="primary">
              <ArrowBackIosIcon fontSize="small" />
              GLOBAL PRIVACY STANDARDS
            </Button>
          </Link>
          <Typography variant="h4" className={classes.title}>
            Latin America
          </Typography>
          <Dots />
          <div className={classes.contentContainer}>
            <Typography variant="body1" className={classes.paragraphs}>
              Trust has always been the foundation of our relationship with our clients since our
              founding over a century ago in Chicago. We respect your privacy and safeguard your
              information because you trust us with your financial and other personal information.
              In order to preserve that trust, our family of financial service providers pledges to
              protect your privacy by adhering to the practices described in our privacy notices and
              other privacy documents.
            </Typography>
            <Typography variant="h5" className={classes.contentTitle}>
              Latin America Privacy Documents
            </Typography>

            <Grid container spacing={10} className={classes.cards}>
              <Grid item xs={12} sm={6} md={4}>
                <PolicyDetailsCard
                  title="Cayman Islands Privacy Notice"
                  to={caymanPrivacyNoticeUrl}
                  pdf={true}
                />
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
      <CookieMessage state={showCookieMessage} ></CookieMessage>
      <ClickWrap state={showClickWrap} handleClose={handleClickwrapClose}></ClickWrap>
      <SessiontTmeout state={showSessionTimeoutModel}></SessiontTmeout>
    </TermsLayout>
  );
};

export default LAPrivacyPage;
