export const REACT_APP_DISPLAY_MODALSESSION_TIMEOUT = (5*60*1000);
export const REACT_APP_OKTA_DEFAULT_SESSION_TIMEOUT = (8*60*60*1000);
export const PRODUCTION_HOST_NAME_LIST = ['developer.ntrs.com', 'developer.northerntrust.com','developers.northerntrust.com'];
export const APPLICATION_DETAILS_MAPPING = {
    "description":"Description"
};
export const KEY_DETAILS_MAPPING = {
    "apiKey": "Api Key",
    "apiSecret":"Client Secret"
};
export const EVENT_DETAILS_MAPPING= {
    "eventDescription":"Description",
    "callbackUrl":"Callback Url"
};
export const OKTA_OSK_ERRORS = {
 "ERR_OSK_USER_MANAGEMENT_000001":"Something went wrong. Please check with support team.",
"ERR_OSK_USER_MANAGEMENT_000004000":"Something went wrong. Please check with support team.",
"ERR_OSK_USER_MANAGEMENT_000004001":"Something went wrong. Please check with support team.",
"ERR_OSK_USER_MANAGEMENT_000004002":"Something went wrong. Please check with support team.",
"ERR_OSK_USER_MANAGEMENT_000004003":"Something went wrong. Please check with support team.",
"ERR_OSK_USER_MANAGEMENT_000004004":"Something went wrong. Please check with support team.",
"ERR_OSK_USER_MANAGEMENT_000004005":"Something went wrong. Please check with support team.",
"ERR_OSK_USER_MANAGEMENT_000004006":"Something went wrong. Please check with support team.",
"ERR_OSK_USER_MANAGEMENT_000004007":"Something went wrong. Please check with support team.",
"ERR_OSK_USER_MANAGEMENT_000004008":"Invalid old password provided.",
"ERR_OSK_USER_MANAGEMENT_000004009":"Invalid new password provided.",
"ERR_OSK_USER_MANAGEMENT_000004010":"Invalid credentials are provided.",
"ERR_OSK_USER_MANAGEMENT_000004011":"Old and New password are same.",
"ERR_OSK_USER_MANAGEMENT_000004012":"Invalid new password provided - commonly used password is not allowed.",
"ERR_OSK_USER_MANAGEMENT_000004013":"Your old password has been entered incorrectly. Please enter it again.",
"ERR_OSK_USER_MANAGEMENT_000004014":"Something went wrong. Please check with support team.",
"ERR_OSK_USER_MANAGEMENT_000004015":"Something went wrong. Please check with support team.",
"ERR_OSK_USER_MANAGEMENT_000004016":"Something went wrong. Please check with support team.",
"ERR_OSK_USER_MANAGEMENT_000004017":"Something went wrong. Please check with support team.",
"ERR_OSK_USER_MANAGEMENT_000004018":"Something went wrong. Please check with support team.",
"ERR_OSK_USER_MANAGEMENT_000004019":"Something went wrong. Please check with support team.",
"ERR_OSK_USER_MANAGEMENT_000004020":"Something went wrong. Please check with support team.",
"ERR_OSK_USER_MANAGEMENT_000004021":"Something went wrong. Please check with support team.",
"ERR_OSK_USER_MANAGEMENT_000004022":"Something went wrong. Please check with support team.",
"ERR_OSK_USER_MANAGEMENT_000004023":"Something went wrong. Please check with support team.",
"ERR_OSK_USER_MANAGEMENT_000004025":"Your password matches any of your previous 12 passwords. Historical passwords are not allowed.",
"ERR_OSK_USER_MANAGEMENT_000005000":"Something went wrong. Please check with support team.",
"ERR_OSK_USER_MANAGEMENT_DEFAULT":"Something went wrong. Please check with support team."
};
export const OKTA_OSK_SUCCESS_MESSAGE = 'You have successfully updated your password. You can use new password from next session onwards.'; 