import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { get } from '../utils';

export enum ApplicationStatus {
  ENABLED = 'ENABLED',
  PENDING = 'PENDING',
  DISABLED = 'DISABLED'
};
export const AppLevelOptions = {
  domain:'Domain',
  api:'API'
};

export type getAppsRequestParameters = {
  page: number;
  pageSize:number;
  sponsorCode:string;
};
export type getEntitlementsRequestParameters = {
  env:string;
};
export type apiData ={
  selectedApis:string[];
};


export type Application = {
  uuid: string;
  name: string;
  environment:string;
  status: ApplicationStatus;
  applicationDetails: {
    description: string;
  },
  keyTokenDetails:{
    apiKey:string;
    apiSecret:string;
  }
}
export type Domain = {
  name: string;
  apiProducts:string[];
}
export type ApiProduct = {
  uuid:string;
  grpId:string;
  name:string;
  version:string;
  selected:string;
};
export type ApplicationCollection = {
  [key: string]: Application;
};
export type DomainCollection = {
  [key: string]: Domain;
}
export type ApiProductCollection = {
  [key: string]: ApiProduct;
}
export type ApplicationAttributes = {
  [key: string]: string;
};
export type applicationListMapping= {
  [key: string]: string;
};

export type GetApplicationResponse = {
  data: {
    totalCount: number;
    applications: ApplicationCollection;
  };
};
export type GetEntitlementsResponse = {
  data: {
    apiProducts: ApiProductCollection;
    domains: DomainCollection;
  };
};

export interface ApplicationsState {
  data: ApplicationCollection;
  loading: boolean;
  totalCount:number;
  domains:DomainCollection;
  apiProducts:ApiProductCollection;
  selectedApiIds:string[];
  showAlert:boolean;
  message:string;
  currentApplication:any;
}

type FetchApplicationError = {
  status: number;
  message: string
};
const initialState: ApplicationsState = {
    data: {},
    loading: true,
    totalCount:0,
    domains:{},
    apiProducts:{},
    selectedApiIds:[''],
    showAlert:false,
    message:'',
    currentApplication:{}
};

export const fetchApplicationsData = createAsyncThunk<
  GetApplicationResponse,
  getAppsRequestParameters,
  {
    rejectValue: FetchApplicationError
  }
>(
  'account/fetchApplicationsData',
  async ({page,pageSize,sponsorCode},thunkApi) => {
    try {
     if(sponsorCode.length > 0){
      const response = await get(`/bff-private/mockapplications?pageNo=${(page)}&pageSize=${pageSize}&sponsorCode=${sponsorCode}`);
      return response as GetApplicationResponse
     }else{
      const response = await get(`/bff-private/applications?pageNo=${(page)}&pageSize=${pageSize}`);
      return response as GetApplicationResponse
     }
    


    }
    catch (error: any) {
      return thunkApi.rejectWithValue({
        status: error.status,
        message: error.message
      })
    }
  }
);
export const getEntitlementsData = createAsyncThunk<
  GetEntitlementsResponse,
  getEntitlementsRequestParameters,
  {
    rejectValue: FetchApplicationError
  }
>(
  'account/getEntitlementsData',
  async ({env},thunkApi) => {
    try {
      const response = await get(`/bff-private/domain-entitlements?env=${env}`);
      return response as GetEntitlementsResponse
     }
    catch (error: any) {
      return thunkApi.rejectWithValue({
        status: error.status,
        message: error.message
      })
    }
  }
);

export const ApplicationsSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    setSelectedApiIds: (state,action: PayloadAction<apiData>) => {
      state.selectedApiIds =action.payload.selectedApis;
    },
    setShowAlert: (state,action: PayloadAction<boolean>) => {
      state.showAlert =action.payload;
    },
    setMessage: (state,action: PayloadAction<string>) => {
      state.message = action.payload;
    },
    setSelectedApplication: (state, action:PayloadAction<any>) => {
      state.currentApplication = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchApplicationsData.fulfilled, (state, { payload }) => {
      state.totalCount = payload?.data?.totalCount;
      state.data = payload?.data?.applications;
      state.loading = false;
          
    });
    builder.addCase(fetchApplicationsData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchApplicationsData.rejected, (state) => {
      state.data = {};
      state.totalCount = 0;
      state.loading = false;
    });
    builder.addCase(getEntitlementsData.fulfilled, (state, { payload }) => {
      state.domains = payload?.data?.domains;
      state.apiProducts = payload?.data?.apiProducts;
    });
    builder.addCase(getEntitlementsData.pending, (state) => {
    });
    builder.addCase(getEntitlementsData.rejected, (state) => {
      state.domains = {};
      state.apiProducts = {};
    });
    
  }
});

export const { actions: accountActions, reducer: applicationsReducer } = ApplicationsSlice;
export const {  setSelectedApiIds, setShowAlert, setMessage, setSelectedApplication } = accountActions;
