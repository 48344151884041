import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Header from '../../header';
import Footer from '../../footer';
import bgImage from '../../../assets/topbg.jpg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: '100vh',
      background: 'linear-gradient(180deg, #0A021C 0%, #241148 100%)',
      position: 'relative',
    },
    rainbow: {
      height: 120,
      backgroundImage: `url(${bgImage})`,
      backgroundRepeat: 'no-repeat',
      mixBlendMode: 'normal',
      opacity: 0.19,
      // transform: `matrix(-1, 0, 0, 1, 0, 0)`,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        height: 100,
      },
    },
  }),
);

interface Props {
  children?: React.ReactElement<any, string> | React.ReactElement[];
}

const PricingLayout: FC<Props> = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.rainbow}></div>
      <Header />
      {children}
      <Footer />
    </div>
  );
};
export default PricingLayout;
