import React, { FC } from 'react';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import ShowMore from '../../showmore';
import Button from '@material-ui/core/Button';
import { checkUserPermission } from '../../../core/authorization/service/authorizationService';
import { useTypedSelector } from '../../../store';
import { getState } from '../../../selectors/selectors';
import { useUserContext } from '../../../UserContext';
import Tooltip from '@material-ui/core/Tooltip';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(1),
      backgroundColor: 'rgba(256,256,256,0.1)',
      mixBlendMode: 'normal',
      borderRadius: '10px',
      minWidth: 300,
      maxWidth:300,
      height: '100%',
      position: 'relative',
    },
    shadowTopRight: {
      borderLeft: '76px solid transparent',
      borderRight: '0px solid transparent',
      borderBottom: '124px solid rgba(16,4,40,0.2)',
      height: 0,
      width: 0,
      position: 'absolute',
      right: 0,
      zIndex: 2,
      top: 0,
    },
    shadowTopLeft: {
      borderLeft: '0px solid rgba(16,4,40,0.1)',
      borderRight: '206px solid transparent',
      borderBottom: '50px solid rgba(16,4,40,0.1)',
      height: 0,
      width: 0,
      position: 'absolute',
      left: 0,
      zIndex: 2,
      top: '33%',
    },
    shadowBottomLeft: {
      borderLeft: '0px solid rgba(16,4,40,0.1)',
      borderBottom: '97px solid rgba(16,4,40,0.1)',
      height: 0,
      width: 0,
      position: 'absolute',
      left: 0,
      zIndex: 2,
      bottom: 0,
      borderRight: '350px solid rgba(16,4,40,0.1)',
    },
    shadowBottomRight: {
      borderLeft: '180px solid transparent',
      borderRight: '75px solid rgba(16,4,40,0.1)',
      borderBottom: '96px solid rgba(16,4,40,0.1)',
      height: 0,
      width: 0,
      position: 'absolute',
      right: 0,
      zIndex: 2,
      bottom: 0,
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      justifyContent: 'left',
    },
    cardHeader: {
      display: 'inline-block',
    },
    alignleft: {
      float: 'left',
    },
    alignright: {
      float: 'right',
    },
    title: {
      color: 'white',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    border: {
      border: '1px solid #05595E',
      borderRadius: 10,
      height: '100%',
    },
    cardButton: {
      minWidth: 200,
      height: '100%',
      position: 'relative',
    },
    actionButton:{
      marginTop:20,
      marginBotton:20,
      maxWidth: '110px'
    },
    text: {
      color: '#7436C1',
      fontSize: 11,
      fontWeight: 500,
      position: 'absolute',
      top:10,
      right: 0,
      zIndex: 10,
      width: 55,
      textAlign: 'center',
    }
  }),
);
interface Props {
  name: string;
  description: string;
  eventId: string;
  onClickButton:any;
  eventStatus:string;
}

const EventCard: FC<Props> = ({ name, description, eventId, onClickButton, eventStatus }) => {
  const classes = useStyles(); 
  const user = useUserContext();
  const buttonText = eventStatus === "subscribed" ? "UNSUBSCRIBE": "SUBSCRIBE"; 
  const { userDetails } = useTypedSelector(state => getState(state));
  //@ts-ignore
  const { isAuthenticated }   = user;
  const hasPermissionSubscribeEvents = checkUserPermission(userDetails,'subscribeEvents');
  const tooltipMessage =  isAuthenticated && !hasPermissionSubscribeEvents?"You dont have permissions.Please check with support team":"";
 
  
  return (
    <ButtonBase focusRipple key={eventId} className={classes.cardButton}> 
      <Card className={classes.card} raised={true}>
      <div className={classes.border}>
        <CardContent>
          <div className={classes.cardContent}>
            <Typography variant="h6" className={classes.title}>
              {name}
            </Typography>
            <ShowMore textlength={110}>{description}</ShowMore>
            <Tooltip title={tooltipMessage} placement="bottom-start">
            <span>  
            <Button
                    variant="contained"
                    color="secondary"
                    aria-label={'Submit callback url'}
                    disabled={!hasPermissionSubscribeEvents && isAuthenticated}
                    className={classes.actionButton}
                    onClick={((event) => {
                      onClickButton(event,eventId,eventStatus,hasPermissionSubscribeEvents);
                    })} 
                  >
                   {buttonText}
                  </Button>
                  </span>    
            </Tooltip>    
          </div>
        </CardContent>
        </div>
      </Card>
    </ButtonBase>
  );
};

export default EventCard;
