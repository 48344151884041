import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SyntaxHighlighter from 'react-syntax-highlighter';
import nightOwl from 'react-syntax-highlighter/dist/cjs/styles/hljs/night-owl';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { get } from '../../utils';
import Appurls from '../../constants/appurls';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    secondaryCard: {
      backgroundColor: 'transparent',
      mixBlendMode: 'normal',
      borderRadius: '10px',
      border: '1px solid #3c4257',
    },
    secondaryCardheader: {
      backgroundColor: 'rgba(35, 53, 82, 1)',
      color: 'white',
      padding: theme.spacing(1),
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
    },
    paddingVertical: {
      marginTop: theme.spacing(2),
    },
  }),
);

type Props = {
  apiId?: string | string[] | null;
  path?: string;
  method?: string;
};

const CodeSnippet: FC<Props> = ({ apiId, path, method }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElEnv, setAnchorElEnv] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleEnvClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElEnv(event.currentTarget);
  };
  const [language, setLanguage] = useState('curl');
  const [envoirnment, setEnvoirnment] = useState('production');
  const handleClose = (id: string,value: string) => {
    if(id ==="envoirment"){
      setAnchorElEnv(null);
      setEnvoirnment(value);
      //@ts-ignore
      setCurrentsnippet(snippetData[value]);
      
    }else{
      setAnchorEl(null);
      setLanguage(value);
    }
   
  };
  const [snippetData, setSnippetData] = useState<null | {}>(null);
  const [currenSnippet, setCurrentsnippet] =useState<null | {}>(null);
  useEffect(() => {
    const getCodeSnippet = async () => {
      const snippetData = await get(Appurls.getSnippetData(apiId,path,method));
      setSnippetData(snippetData.data);
      console.log("maya"+ JSON.stringify(snippetData));
      setCurrentsnippet(snippetData.data[envoirnment]);
    };
    if (apiId !== null) {
      getCodeSnippet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiId, path, method]);
  useEffect(() => {
    if(snippetData !== null){
      //@ts-ignore
      //@ts-ignore
      setCurrentsnippet(snippetData[envoirnment]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [envoirnment]);

  return (
    <div className={clsx(classes.secondaryCard, classes.paddingVertical)} data-testid="snippet">
      <Box
        className={classes.secondaryCardheader}
        flexDirection="row"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body2">REQUEST</Typography>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
        <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="secondary"
            onClick={handleEnvClick}
          >
            {envoirnment}
          </Button>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="secondary"
            onClick={handleClick}
          >
            {language}
          </Button>
          <CopyToClipboard
            text={
              //@ts-ignore
              currenSnippet !== null ? currenSnippet[language] : ''
            }
          >
            <Button color="primary" startIcon={<FileCopyIcon />}>
              COPY
            </Button>
          </CopyToClipboard>
        </Box>
      </Box>

      <SyntaxHighlighter language={language.toUpperCase().toString()} style={nightOwl}>
        {
          //@ts-ignore
          currenSnippet !== null ? currenSnippet[language] : ''
        }
      </SyntaxHighlighter>
      <Menu
        id="env-menu"
        anchorEl={anchorElEnv}
        keepMounted
        open={Boolean(anchorElEnv)}
        onClose={() => handleClose('envoirment',envoirnment)}
      >
        { 
        //@ts-ignore
        snippetData !== null && snippetData && Object.keys(snippetData).length > 0  && Object.keys(snippetData).map((envKey) =>(
          <MenuItem 
            key={envKey}
            onClick={() => handleClose('envoirment',envKey)}>
          {envKey}
        </MenuItem>

        )) 

        }
      </Menu> 
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleClose('language',language)}
      >
        <MenuItem onClick={() => handleClose('language','curl')}>curl</MenuItem>
        <MenuItem onClick={() => handleClose('language','python')}>python</MenuItem>
        <MenuItem onClick={() => handleClose('language','php')}>php</MenuItem>
        <MenuItem onClick={() => handleClose('language','node')}>node</MenuItem>
        <MenuItem onClick={() => handleClose('language','java')}>java</MenuItem>
        <MenuItem onClick={() => handleClose('language','ruby')}>ruby</MenuItem>
        <MenuItem onClick={() => handleClose('language','go')}>go</MenuItem>
        <MenuItem onClick={() => handleClose('language','.net')}>.net</MenuItem>
      </Menu>
    </div>
  );  
};

export default CodeSnippet;
