import React, { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import ScrollToTop from './scrolltoTop';
import LandingPage from './pages/landing';
import ProductsPage from './pages/products';
import ContactUsPage from './pages/contactus';
import TermsPage from './pages/terms';
import CookiePage from './pages/cookie';
import Privacy, {
  EmeaPrivacyPage,
  NAPrivacyPage,
  PrivacyDetailsPage,
  CaliforniaRightsPage,
  LAPrivacyPage,
} from './pages/privacy';
import ReleasesPage from './pages/releases';
import ResourcesPage from './pages/resources';
import FaqPage from './pages/faqs';
import GetStartedBasePage, { GetStartedPage, AccessSetupPage, EventNotificationSetupPage } from './pages/getstarted';
import GroupDetailsPage from './pages/group';
import ScrollToTopButton from './scrolltoTopButton';
import EventsPage from './pages/events';
import NotFoundPage from './pages/notfound';
import SpecsPage from './pages/specs';
import AccountBasePage,{ AccountPage, MetricsPage, PrivateEventsPage, AppManagementPage, SettingsPage } from  './pages/account' ;    
import { UserContextProvider } from './UserContext';
import { CookieContextProvider } from './CookieContext';
import { Security } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import oktaConfig from './config';
import { RequiredAuth } from './components/secureRoute/SecureRoute';
const oktaAuth = new OktaAuth(oktaConfig.oidc);
oktaAuth.start();

const PageRoutes: FC = () => {
  const restoreOriginalUri = (_oktaAuth: any, originalUri: string) => {};
  // @ts-ignore

  return (
    <UserContextProvider>
      <CookieContextProvider>
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/account" element={<RequiredAuth />}>
              <Route path="/" element={<AccountBasePage />}>
                <Route path="/" element={<AccountPage />} />
              </Route>
              <Route path="/metrics" element={<AccountBasePage />}>
              <Route path="/" element={<MetricsPage />} />
              </Route>
              <Route path="/events" element={<AccountBasePage />}>
              <Route path="/" element={<PrivateEventsPage />} />
              </Route>
              <Route path="/application" element={<AccountBasePage />}>
              <Route path="/" element={<AppManagementPage />} />
	            </Route>
	            <Route path="/settings" element={<AccountBasePage />}>
              <Route path="/" element={<SettingsPage />} />
              </Route>	 
            </Route> 
            <Route path="/apis" element={<ProductsPage />} />
            <Route path="/apis/:name" element={<ProductsPage />} />
            <Route path="/groups/:id" element={<GroupDetailsPage />} />
            <Route path="/contact-us" element={<ContactUsPage />} />
            <Route path="/events" element={<EventsPage />} />
            <Route path="/events/:displayDomainName" element={<EventsPage />} />
            <Route path="/global-privacy-standards" element={<Privacy />} />
            <Route path="/groups/:id/specs" element={<RequiredAuth />}>
              <Route path="/" element={<SpecsPage />} />
            </Route>

            <Route
              path="/global-privacy-standards/emea-privacy-notice"
              element={<EmeaPrivacyPage />}
            />
            <Route
              path="/global-privacy-standards/north-america-privacy-notice"
              element={<NAPrivacyPage />}
            />
            <Route
              path="/global-privacy-standards/latin-america-privacy-notice"
              element={<LAPrivacyPage />}
            />
            <Route
              path="/global-privacy-standards/california-rights"
              element={<CaliforniaRightsPage />}
            />
            <Route
              path="/global-privacy-standards/emea-privacy-notice/details"
              element={<PrivacyDetailsPage />}
            />
            <Route path="/cookie-policy" element={<CookiePage />} />
            <Route path="/terms-and-conditions" element={<TermsPage />} />
            <Route path="/resources" element={<ResourcesPage />}>
              <Route path="/releases-and-maintanence" element={<ReleasesPage />} />
              <Route path="/faqs" element={<FaqPage />} />
            </Route>
            <Route path="/get-started" element={<GetStartedBasePage />}>
              <Route path="/" element={<GetStartedPage />} />
              <Route path="/access-setup" element={<AccessSetupPage />} />
              <Route path="/event-notification-setup" element={<EventNotificationSetupPage />} />
            </Route>
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
          <ScrollToTopButton />
        </Security>
      </CookieContextProvider>
    </UserContextProvider>
  );
};

export default PageRoutes;
