import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '../utils';


export type ApiProduct = {
  uuid: string;
  name: string;
  version :string;
  selected :string;
};
export type Domain = {
  name: string;
  apiProducts: string[];
};
export type ApiProductsCollection = {
  [key: string]: ApiProduct;
};
export type DomainsCollection = {
  [key: string]: Domain;
};

export type GetApiProductsResponse = {
  data: {
    domains: DomainsCollection;
    apiProducts:ApiProductsCollection;
  };
};

export type getApiProdcutsRequestParameters= {
  applicationId:string;
  environment: string;
};

export interface ApiProductsState {
  apiProducts:{
    data: ApiProductsCollection;
    loading: boolean;
    domains:DomainsCollection;
  },
  apiProductsLateinces:{
    data: ApiProductsCollection;
    loading: boolean;
    domains:DomainsCollection;
  }
}

type FetchAccountError = {
  status: number;
  message: string
};
const initialState: ApiProductsState = {
  apiProducts:{
    data: {},
    loading: false,
    domains:{}
  },
  apiProductsLateinces:{
    data: {},
    loading: false,
    domains:{}
  }
};
export const fetchApiProductsData = createAsyncThunk<
  GetApiProductsResponse,
  getApiProdcutsRequestParameters,
  {
    rejectValue: FetchAccountError
  }
>(
  'apiProducts/fetchApiProductsData',
  async ({applicationId, environment},thunkApi) => {
    try {
     const response = await get(`/bff-private/apiproducts?appId=${applicationId}&env=${environment}`);
     return response as GetApiProductsResponse
    }
    catch (error: any) {
      return thunkApi.rejectWithValue({
        status: error.status,
        message: error.message
      })
    }
  }
);
export const fetchApiProductsLatenicesData = createAsyncThunk<
  GetApiProductsResponse,
  getApiProdcutsRequestParameters,
  {
    rejectValue: FetchAccountError
  }
>(
  'apiProducts/fetchApiProductsLatenicesData',
  async ({applicationId,environment},thunkApi) => {
    try {
     const response = await get(`/bff-private/apiproducts?appId=${applicationId}&env=${environment}`);
     return response as GetApiProductsResponse
    }
    catch (error: any) {
      return thunkApi.rejectWithValue({
        status: error.status,
        message: error.message
      })
    }
  }
)
export const ApiProductsSlice = createSlice({
  name: 'apiProducts',
  initialState,
  reducers: {
    cleartrafficDomains: (state) => {
      state.apiProducts.domains = {};
    },
    clearLatencyDomains: (state) => {
      state.apiProductsLateinces.domains = {};
    },
  },
  extraReducers: (builder) => {
   builder.addCase(fetchApiProductsData.fulfilled, (state, { payload }) => {
      state.apiProducts.loading = false;
      state.apiProducts.data = payload?.data?.apiProducts;
      state.apiProducts.domains = payload?.data?.domains;  
    });
    builder.addCase(fetchApiProductsData.pending, (state) => {
      state.apiProducts.loading = true;
    });
    builder.addCase(fetchApiProductsData.rejected, (state) => {
      state.apiProducts.data = {};
      state.apiProducts.loading = false;
      state.apiProducts.domains = {};
    });
    builder.addCase(fetchApiProductsLatenicesData.fulfilled, (state, { payload }) => {
      state.apiProductsLateinces.loading = false;
      state.apiProductsLateinces.data = payload?.data?.apiProducts;
      state.apiProductsLateinces.domains = payload?.data?.domains;
    });
    builder.addCase(fetchApiProductsLatenicesData.pending, (state) => {
      state.apiProductsLateinces.loading = true;
    });
    builder.addCase(fetchApiProductsLatenicesData.rejected, (state) => {
      state.apiProductsLateinces.data = {};
      state.apiProductsLateinces.loading = false;
      state.apiProductsLateinces.domains = {};
    });
  }
});

export const { actions: accountActions, reducer: apiProductsReducer } = ApiProductsSlice;
export const { cleartrafficDomains, clearLatencyDomains } = accountActions;