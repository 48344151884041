import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Header from '../../header';
import Footer from '../../footer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: '100vh',
      backgroundImage:
        'linear-gradient(180deg, rgba(12,26,41,0.9948354341736695) 33%, rgba(12,26,41,0.9948354341736695) 97%)',
      position: 'relative',
    },
    topbg: {
      height: 120,
      top: 0,
      right: 0,
      width: '100%'
    },
  }),
);

interface Props {
  children?: React.ReactElement<any, string> | React.ReactElement[];
}

const ContactLayout: FC<Props> = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.topbg}></div>
      <Header />
      {children}
      <Footer />
    </div>
  );
};
export default ContactLayout;
