import React from 'react';
import { Outlet } from 'react-router-dom';
import { redirectToOktaUrl } from '../../utils/utils';
import { useUserContext } from '../../UserContext';
import { OktaAuth } from '@okta/okta-auth-js';
import oktaConfig from '../../config';
const oktaAuth = new OktaAuth(oktaConfig.oidc);

export const RequiredAuth: React.FC = () => {
  const userDetails = useUserContext();
  // @ts-ignore
  const { authUser, setAuthUser } = userDetails;
  const checkuserAuthenticated = async () => {
    try {
      const session = await oktaAuth.session.exists();
      const token = await oktaAuth.token.getWithoutPrompt();
      if (token !== null &&  session === true) {
        setAuthUser(true);
        let jwt: any ='';
        jwt = token.tokens.accessToken?.accessToken;
        localStorage.setItem('jwt', jwt);
      } else {
        setAuthUser(false);
        localStorage.setItem('jwt', '');
        redirectToOktaUrl();
      }
    } catch (e) {
      redirectToOktaUrl();
    }
  };
  try {
    localStorage.setItem(
      'apo_redirect_url',
      window?.location?.pathname + window?.location?.search || '/',
    );
    checkuserAuthenticated();
    if (!authUser ) {
      return null;
    } else return <Outlet />;
  } catch (e) {
    return null;
  }
};
