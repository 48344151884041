import { createSlice,createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '../utils';

export const StatsOptions = {
  avg:'AVERAGE RESPONSE TIME',
  min:'MINIMUM RESPONSE TIME',
  max:'MAXIMUM RESPONSE TIME'
};
export const TimeRangeOptions = {
  hour: "LAST 1 HOUR",
  day: "LAST 1 DAY",  
  week: "LAST 1 WEEK", 
  month: "LAST 1 MONTH",
  year: "LAST 1 YEAR" 
} ;
export const PastTimeRangeOptions = {
  month:"LAST 30 DAYS",
  week:"LAST 1 WEEK",
  day:"LAST 1 DAY",
  hour:"LAST 1 HOUR"
};
export const SplitByMapping = {
  day: 'by-hour',  
  hour: 'by-minute',
  week:  'by-day', 
  month: 'by-day',
  year: 'by-month' 
} ;
export const xAxisLabelMapping = {
  day: 'Duration (in hrs)',  
  hour: 'Duration (in mins)',
  week:  'Duration (in days)', 
  month: 'Duration (in days)',
  year: 'Duration (in months)' 
} ;
export type GenericObject = {
  [key: string]: string;
};
export type UserDetails = {
  firstName?: string;
  lastName?: string;
  organisationId?: string;
  organisationName?: string;
  email?: string;
  sponsorCode?: string;
};
export type LatencyCollection = {
  [key: string]: latencyDetails;
};
export type latencyDetails = {
  id:string;
  date:string;
  responseTime:number;
};
export type LatencyParamters = {
  timerange: string;
  split: string;
  bucket: string;
  stats: string;
  appId:string;
};
export type ApplicationAttributes = {
  [key: string]: string;
};
export type applicationListMapping= {
  [key: string]: string;
};
export type GetLatencyResponse = {
 latenciesTs: LatencyCollection;
  
};

export interface latencyState {
 latencies:{
    data: LatencyCollection;
    loading: boolean;
  };
}
type FetchLatencyError = {
  status: number;
  message: string
};

const initialState: latencyState = {
  latencies:{
    data: {},
    loading:false
  }
};
export const fetchLatency = createAsyncThunk<
  GetLatencyResponse,
  LatencyParamters,
  {
    rejectValue: FetchLatencyError  
  }
>(
  'applications/fetchLatency',
  async ({timerange,split,bucket,stats,appId}, thunkApi) => {

    try {
        const response = await get(`/bff-private/applications/${appId}/metrics/latency?timerange=${timerange}&split=${split}&bucket=${bucket}&stats=${stats}`);
        return response as GetLatencyResponse
    }
    catch (error: any) {
      return thunkApi.rejectWithValue({
        status: error.status,
        message: error.message
      })
    }
  }
);

export const latencySlice = createSlice({
  name: 'latency',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLatency.rejected, (state) => {
      state.latencies.loading = false;
      state.latencies.data ={}; 
    });
   builder.addCase(fetchLatency.pending, (state) => {
      state.latencies.loading = true;
    });
    builder.addCase(fetchLatency.fulfilled, (state, { payload }) => {
      state.latencies.loading = false;
      state.latencies.data = payload.latenciesTs ?? {};
    });
  }
});

export const { actions: latencyActions, reducer: latencyReducer } = latencySlice;
