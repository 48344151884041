import React, { FC, useEffect, useCallback, useRef, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {Container, Grid, Typography, LinearProgress, RootRef, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';
import { CommonLayout } from '../../components/layout';
import { GroupCard } from '../../components/cards';
import ErrorAlert from '../../components/erroralert';
import ClickWrap from '../../components/clickwrap';
import { useCookieContext } from '../../CookieContext';
import CookieMessage from '../../components/cookiemessage';
import { useUserContext } from '../../UserContext';
import SessiontTmeout  from '../../components/sessiontimeout';
import { useDispatch } from 'react-redux';
import { fetchProductsData } from '../../store/productsSlice';
import { useTypedSelector } from '../../store';
import { getProducts } from '../../selectors/selectors';
import { useLocation } from 'react-router';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import BarChartIcon from '@material-ui/icons/BarChart';
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import RecentActorsOutlinedIcon from '@material-ui/icons/RecentActorsOutlined';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullContainer: {
      minHeight: '100vh',
      marginTop:120,
      zIndex:1,
      paddingBottom: theme.spacing(10),
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
    container: {},
    pageTitle: {
      color: 'white',
      letterSpacing: '0.0625rem',
      fontWeight: 300,
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(2),
    },
    bar: {
      height: 1,
      backgroundColor: '#05a8cc',
    },
    description: {
      color: 'white',
    },
    selected: {
      backgroundColor: '#00C4B4',
      color: 'primary',
      marginRight: '15px',
      marginBottom:'5px'
    },
    notSelected: {
      color: 'white',
      marginRight: '15px',
      marginBottom:'5px'
    },
    navlinks: {
      fontWeight: 400,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    link: {
      textDecoration: 'none',
      color: 'white',
      cursor:'pointer',
      fontWeight:200
    },
    active: {
      fontWeight: 'bold',
    },
    navContainer: {
      padding: theme.spacing(2),
      position:'fixed'
    },
    navContainerMobile:{
      padding: theme.spacing(2)
    },
    navlinkContainer:{
      display:'flex',
      flexDirection:'row',
      alignItems:'center',
      height:45
    },
    icon:{
      marginRight:5,
      color:'white'
    }
  }),
);

type Product = {
  name: string;
  filterkey: string;
  description: string;
  groups: Array<string>;
  uuid: string;
  status: string;
};

export const ProductsPage: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { products, groups, loading, exception, currentDomain } = useTypedSelector(state => getProducts(state));
  const [activeNav, setActiveNav] = useState('');
  const allProducts = products;
  const allGroups = groups;
  const contentRef = useRef();
  
  const cookieDetails = useCookieContext();
  //@ts-ignore
  const { showCookieMessage } = cookieDetails;
  const userdetails = useUserContext();
  const {  state } = useLocation();
    // @ts-ignore
  const main = state?.main;
  // @ts-ignore
  const isSmallScreen = useMediaQuery((theme:any) => theme.breakpoints.down("sm"));
   // @ts-ignore
   const { showClickWrap, exceptionClickWrap, loadingClickWrap,acceptClickWrapdetails, showSessionTimeoutModel } = userdetails;

  const iconArray = [
    <AccountBalanceWalletIcon className={classes.icon}/>,
    <AccountBalanceIcon  className={classes.icon}/>,
    <LocalAtmIcon className={classes.icon}/>,
    <BusinessRoundedIcon className={classes.icon}/>,
    <BarChartIcon className={classes.icon}/>,
    <RecentActorsOutlinedIcon className={classes.icon}/>
  ];
  
  const handleClickwrapClose = () => {
    acceptClickWrapdetails();
  };
  useEffect(() => {
    if(allGroups && Object.keys(allGroups).length === 0){
      dispatch(fetchProductsData(''));
    }
   // eslint-disable-next-line
  }, []);
  
  const allProductsKeys = allProducts && Object.keys(allProducts);
 
  const filterData = useCallback(() => {
    if (allProductsKeys.length > 0 ) {
      if(allProductsKeys.includes(currentDomain)){
       let index = getIndexByName(currentDomain);
       if(index >= 0){
        handleNavClick(index,true);
       }
      }
      
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDomain, allProductsKeys]);

  const handleNavClick = (index: any, navigation:boolean = false)=>{
     // @ts-ignore
    const isMobile = window.screen.width < 400 ? true: false;
      //@ts-ignore
    const contentSections = contentRef.current.children;
    const section = contentSections[index];
    if(isMobile){
      //@ts-ignore
      if(navigation){
         //@ts-ignore
        setTimeout( window.scrollTo({
          top: section.offsetTop +310,
          behavior: 'smooth'
        })
       ,1000);
      }else{
         //@ts-ignore
        setTimeout( window.scrollTo({
          top: section.offsetTop ,
          behavior: 'smooth'
        })
       ,1000);
      }
      

    }else{
      //@ts-ignore
      setTimeout( window.scrollTo({
        top: section.offsetTop ,
        behavior: 'smooth'
      })
     ,100);
    }
    //@ts-ignore
    
   
    setActiveNav(section.id);  
  };
  const getIndexByName =(name:string) =>{
    let indexToReturn = -1;
     //@ts-ignore
    const contentSections = contentRef.current.children;
    if(contentSections.length > 0 ){
      //@ts-ignore
       for (let index = 0; index < contentSections.length; index++) {
        if(contentSections[index].id=== name){
          indexToReturn= index;
        }
      }
    }
    return indexToReturn;
  };
  useEffect(() => {
    if(currentDomain && currentDomain !== ''){
    if(main === undefined){
      filterData();
    }
    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDomain,products]);

  
  const handleScroll = () => {
    //@ts-ignore
    const contentSections = contentRef.current.children;
    const scrollPosition = window.scrollY;

    for (let i = 0; i < contentSections.length; i++) {
      const section = contentSections[i];
      const sectionTop = section.offsetTop-120;
      const sectionBottom = sectionTop + section.offsetHeight;

      if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
        setActiveNav(section.id);
        break;
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <React.Fragment>
      <CommonLayout>
        <div className={classes.fullContainer} data-testid="products">
        { (loading || loadingClickWrap) && <LinearProgress />}
          <Container maxWidth="lg">
          <Grid container>
            <Grid item sm={2} className={isSmallScreen? classes.navContainerMobile: classes.navContainer}>
              
            {allProductsKeys &&
                allProductsKeys.map((item, i) => {
                  const product: Product = allProducts[item];
                  return (
                    //@ts-ignore

                    <div className= {classes.navlinkContainer} onClick={(index:any)=>handleNavClick(i)}>
                    {iconArray[i]} 
                    <Typography variant="body1" className={classes.navlinks}>
                    <div data-testid={product.filterkey}   data-cy="buttonFilter" className={clsx(classes.link, (activeNav === product.filterkey) ? classes.active : '',)}
                    >
                    {product.name}
                  </div>
                    </Typography>
                   </div> 
                  );
              })}    
            </Grid>
            {!isSmallScreen && <Grid item sm={2}></Grid>}
            <Grid item sm={10}>
            <Container maxWidth="md" className={classes.container}>
        <Typography variant="h4" className={classes.pageTitle}>
           Explore APIs
        </Typography>
        <RootRef rootRef={contentRef}>
            <div>  
				{/* products rendered here */}
            { allProductsKeys &&
              allProductsKeys.map((eachProduct) => {
                const product: Product = allProducts[eachProduct];
                return (
                  //@ts-ignore
                    <Grid container spacing={4} direction="column" key={product.filterkey} id={product.filterkey}>
                      <Grid item xs={10} sm={8} md={8} lg={8}>
                        <Typography variant="h5" className={classes.pageTitle}>
                          {product.name}
                        </Typography>

                        <div className={classes.bar} />
                      </Grid>
                      <Grid item xs={10} sm={10} md={10} lg={10}>
                        <Typography variant="body1" className={classes.description}>
                          {product.description}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={4}>
                          {product.groups &&
                            product.groups.map((eachGroup, index) => {
                              const group = allGroups[eachGroup];
                              return (
                                group !== undefined && (
                                  <Grid sm={5} md={4} item key={group.uuid}>
                                    <GroupCard uuid={group.uuid}
                                      name={group.name}
                                      description={group.description}
                                      img={group.thumbnail}
                                      soon={group.soon}
                                      domainName={product.filterkey}
                                    />
                                  </Grid>
                                )
                              );
                            })}
                        </Grid>
                      </Grid>
                    </Grid>
                );
              })}
              </div>
              </RootRef>
              </Container>
            </Grid>
          </Grid>
          </Container>
          {(exception || exceptionClickWrap) && <ErrorAlert />}
        </div>
        <CookieMessage state={showCookieMessage} ></CookieMessage>
	     <ClickWrap state={showClickWrap} handleClose={handleClickwrapClose}></ClickWrap>
       <SessiontTmeout state={showSessionTimeoutModel}></SessiontTmeout>
      </CommonLayout>
    </React.Fragment>
  );
};

export default ProductsPage;