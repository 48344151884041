import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Typography, Icon } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { useNavigate } from 'react-router';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullContainer: {
      minHeight: '100vh',

    },
    container: {},
    pageTitle: {
      color: 'white',
      letterSpacing: '0.0625rem',
      fontWeight: 300,
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(4),
    },
    dialogBorder: {
      border: '1px solid #05a8cc',
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      borderRadius: 10,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(2),
      color: theme.palette.grey[500],
    },
    content: {
      color: 'white',
      fontWeight: 300,
      textAlign:'center',
      width: '100%',
      fontSize: '1.125rem',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    actionContainer: {
      display: 'flex',
      justifyContent: 'center',

    },
    icon: {
      position: 'absolute',
      top: 12,
      right: 10
    },
    active: {
      color: '#03dac5'
    },
    dialogTitle: {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
    paper: {
      backgroundImage:
        'linear-gradient(180deg, rgba(12,26,41,0.9948354341736695) 33%, rgba(12,26,41,0.9948354341736695) 97%)',
      position: "absolute",
      width: 500
    },
    hyperLink: {
      color: '#00C4B4',
      cursor: 'pointer',
    },
  }),
);

interface Props {
  state:boolean
  message: string;
  buttontext1:string;
  title:string;
  actionButton1:any;
  isdeaultContactusText:boolean;
  eventName:string;
}

const MessageBox: FC<Props> = ({ state, title, message, buttontext1, actionButton1, isdeaultContactusText, eventName}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const onContactUsClick = async (event: any) => {
    navigate('/contact-us');
    event.preventDefault();
  };

  function returnDefaultMessage () {
    return ( 
      <div className={classes.actionContainer}>
            <span>
            You are unable to access the subscription for {eventName} event. If you are interested in subscribing, please contact us via the{' '} 
            <a className={classes.hyperLink} onClick={(event)=>onContactUsClick(event)} href='#/'>
                Contact Us
            </a>
            {' '}page or get in touch with your Northern Trust contact.
            </span>
          </div>
    )
  }
  
  const onCloseClick = () =>{
    actionButton1();
  };
  return (
    <Dialog
      open={state}
      fullWidth
      keepMounted
      classes={{ paper: classes.paper }}
      data-cy="contactus-success-modal"
    >
      <div className={classes.dialogBorder}>
        <React.Fragment>
          <DialogTitle className={classes.dialogTitle}>
            <Typography variant="body1">{title}</Typography>
          <Icon className={clsx(
            classes.icon,
            classes.active
          )}
          data-testid="closebutton"
          onClick = {onCloseClick}
          >
          <CloseIcon />
        </Icon>
          </DialogTitle>
          <DialogContent className={classes.content}>
            <Box m={1}>
              <Typography variant="body1">
                 {isdeaultContactusText===true? returnDefaultMessage() :message}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions className={classes.actionContainer}>
            <Button variant="contained" onClick={() => actionButton1()} color="secondary">
              {buttontext1}
            </Button>
          </DialogActions>
        </React.Fragment>
      </div>
    </Dialog>
  );
};

export default MessageBox;
