import React, { ChangeEvent,FC, useRef, useState } from 'react';
import Container from '@material-ui/core/Container';
import { Box, useMediaQuery, makeStyles, Theme, createStyles, Typography, Grid, Tab, Tabs, Button, LinearProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import RootRef from '@material-ui/core/RootRef';
import { useCookieContext } from '../../CookieContext';
import CookieMessage from '../../components/cookiemessage';
import { useUserContext } from '../../UserContext';
import ClickWrap from '../../components/clickwrap';
import ErrorAlert from '../../components/erroralert';
import SessiontTmeout from '../../components/sessiontimeout';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullContainer: {
      minHeight: '100vh',
      [theme.breakpoints.between(0, 285)]: {
        width: '60%',
      },
      [theme.breakpoints.between(286, 400)]: {
        width: '90%',
      },
      '@media (orientation: landscape)': {
        width: '100%',
      }
    },
    container: {
      // minHeight: '1450px',
    },
    copyButton:{
      right:40,
      top:7,
      position:'absolute'
    },
    copyButtonMobile:{
      right:10,
      top:7,
      position:'absolute'
    },
    tabPanel:{
      overflowY:'auto'
    },
    pageTitle: {
      color: 'white',
      letterSpacing: '0.0625rem',
      fontWeight: 300,
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
    },
    subTitle: {
      color: 'white',
      fontWeight: 300,
      marginBottom: theme.spacing(1),
    },
    sectionTitle: {
      color: '#05a8cc',
      fontWeight: 500,
      fontSize: 15,
    },
    bodyText: {
      color: 'white',
    },
    tabs:{
      "& .MuiTabs-fixed":{
        borderBottom: '1px solid #424242'
      }
    },
    bar: {
      height: 1,
      backgroundColor: '#05a8cc',
    },
    box: {
      backgroundColor: '#1F2C41',
      borderRadius: 16,
      height: '100%',
      color: 'white',
      [theme.breakpoints.between(0, 285)]: {
        width: '50%',
      },
      '@media (orientation: landscape)': {
        width: '100%',
      }
    },
    codeSnippet: {
      wordWrap: 'break-word',
    },
    snippetRow: {
      alignItems: 'baseline',
      wordSpacing: 3,
    },
    tokenSnippet:{
      marginTop:-40,
      marginLeft:-12
    },
    tokenSnippetMobile:{
    },
    containerGrid: {
      width: '100%',
    },
    link: {
      textDecoration: 'none',
      color: '#00C4B4',
    },
    sandboxLink: {
      display: 'inline-block',
      color: '#00C4B4',
      cursor: 'pointer',
    },
    bodyTextBold: {
      color: 'white',
      fontWeight: 600,
    },
    bodyTextImportant: {
      color: '#f57c00',
      marginLeft: 10,
    },
    buttonText: {
      //textTransform: 'none',
      textDecoration: 'none',
    },
    download: {
      marginLeft: 700
    }, 
    buttonPosition: {
             marginLeft: 365,
            display: 'inline',
            [theme.breakpoints.down('sm')]: {
              marginLeft: 4,
            },
          },
    mobilecontainer: {
      width: '100%',
      maxWidth:340
    },
  }),
);

interface RefObject {
  [key: string]: any;
}
function TabPanel(props: { [x: string]: any; children: any; value: any; index: any; }) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={classes.tabPanel}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
const AccessSetupPage: FC = () => {
  const classes = useStyles();
  const cookieDetails = useCookieContext();
  const isSmallScreen = useMediaQuery((theme:any) => theme.breakpoints.down("sm"));
  const [basicCurlTab, setBasicCurlTab] = useState(0);
  const [advancedCurlTab, setAdvancedCurlTab] = useState(0);
  const base64Text = `Basic {{base64(api key:client secret)}}'`;
  const yourApiKeyText = `{{your_api_key}}`;
  const yourApiToken = `{{your_token}}`;

  const AdvancedCurlSandboxText = `curl -L -X GET
  'https://apisandbox.ntrs.com/ent/custody/v1/fxrates?targetCurrency=GBP&effectiveDate=2024-01-01&sourceCurrency=USD' \
  
  -H 'X-NT-API-Key:
  
  {{your_api_key}}' \
  -H 'Authorization: Bearer {{your_token}}'`;
 
  const AdvancedCurlProductionText =`curl -L -X GET
  'https://apiservices.ntrs.com/ent/custody/v1/fxrates?targetCurrency=GBP&effectiveDate=2024-01-01&sourceCurrency=USD' \
  
  -H 'X-NT-API-Key:
  
  {{your_api_key}}' \
  -H 'Authorization: Bearer {{your_token}}'`;  
 
  const basicCurlProductionText =`curl --location --request POST
  'https://apiservices.ntrs.com/auth/oauth/v2/token' \
  
  --header 'Content-Type:
  
  application/x-www-form-urlencoded' \
  --header 'Authorization: Basic {{base64(api key:client secret)}}' \
  --data-urlencode
  'grant_type=client_credentials'`;
  const basicCurlSandboxText =`curl --location --request POST
  'https://apisandbox.ntrs.com/auth/oauth/v2/token' \
  
  --header 'Content-Type:
  
  application/x-www-form-urlencoded' \
  --header 'Authorization: Basic {{base64(api key:client secret)}}' \
  --data-urlencode
  'grant_type=client_credentials'`;
 
   //@ts-ignore
  const { showCookieMessage } = cookieDetails;
  const userdetails = useUserContext();
  // @ts-ignore
  const {showClickWrap,exceptionClickWrap,loadingClickWrap,acceptClickWrapdetails,showSessionTimeoutModel,} = userdetails;

  const sandboxRef = useRef<RefObject>();
  const onButtonClick = () => {
    if (sandboxRef.current) {
      sandboxRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleChangeBasicCurl = (event: ChangeEvent<{}>, newValue: number) => {
    setBasicCurlTab(newValue);
  };
  const handleChangeAdvencedCurl = (event: ChangeEvent<{}>, newValue: number) => {
    setAdvancedCurlTab(newValue);
  };
  const handleClickwrapClose = () => {
    acceptClickWrapdetails();
  };
  const CodeSnippetOne = () => {
    return (
      <Box className={classes.box} p={1} >
        
        <Typography variant="subtitle1" className={classes.codeSnippet}>
          curl --location --request POST
        </Typography>
        <Hidden smUp>
          <Typography color="secondary" className={classes.codeSnippet}>
            'https://apiservices.ntrs.com
          </Typography>
          <Typography color="secondary" className={classes.codeSnippet}>
            /auth/oauth/v2/token' \
          </Typography>
        </Hidden>
        <Hidden smDown>
          <Typography color="secondary" className={classes.codeSnippet}>
            'https://apiservices.ntrs.com/auth/oauth/v2/token' \
          </Typography>
        </Hidden>

        <Typography color="secondary" className={classes.codeSnippet}>
          --header 'Content-Type:
        </Typography>
        <Typography variant="subtitle1" className={classes.codeSnippet}>
          application/x-www-form-urlencoded' \
        </Typography>
        <Typography color="secondary" className={classes.codeSnippet}>
          --header 'Authorization:
        </Typography>
        <Typography variant="subtitle1" className={classes.codeSnippet}>
          {base64Text} \
         </Typography>
        <Typography variant="subtitle1" className={classes.codeSnippet}>
          --data-urlencode
        </Typography>
        <Typography color="secondary" className={classes.codeSnippet}>
          'grant_type=client_credentials'
        </Typography>
        {/* </Hidden> */}
      </Box>
    );
  };
  const CodeSnippetSandboxOne = () => {
    return (
      <Box className={classes.box} p={1} >
        <Typography variant="subtitle1" className={classes.codeSnippet}>
          curl --location --request POST
        </Typography>
        <Hidden smUp>
          <Typography color="secondary" className={classes.codeSnippet}>
            'https://apisandbox.ntrs.com
          </Typography>
          <Typography color="secondary" className={classes.codeSnippet}>
            /auth/oauth/v2/token' \
          </Typography>
        </Hidden>
        <Hidden smDown>
          <Typography color="secondary" className={classes.codeSnippet}>
            'https://apisandbox.ntrs.com/auth/oauth/v2/token' \
          </Typography>
        </Hidden>

        <Typography color="secondary" className={classes.codeSnippet}>
          --header 'Content-Type:
        </Typography>
        <Typography variant="subtitle1" className={classes.codeSnippet}>
          application/x-www-form-urlencoded' \
        </Typography>
        <Typography color="secondary" className={classes.codeSnippet}>
          --header 'Authorization:
        </Typography>
        <Typography variant="subtitle1" className={classes.codeSnippet}> 
          {base64Text} \
        </Typography>
        <Typography variant="subtitle1" className={classes.codeSnippet}>
          --data-urlencode
        </Typography>
        <Typography color="secondary" className={classes.codeSnippet}>
          'grant_type=client_credentials'
        </Typography>
        {/* </Hidden> */}
      </Box>
       
    );
     
  };
  const CodeSnippetSandboxTwo = () => {
    return (
      <Box className={classes.box} p={2}>
        <Typography variant="subtitle1" className={classes.codeSnippet}>
          curl -L -X GET
        </Typography>
        <Typography color="secondary" className={classes.codeSnippet}>
          'https://apisandbox.ntrs.com/ent/custody/v1/fxrates?
        </Typography>

        <Typography color="secondary" className={classes.codeSnippet}>
          targetCurrency=GBP&effectiveDate=2024-01-01&sourceCurrency=USD' \
        </Typography>

        <Typography color="secondary" className={classes.codeSnippet}>
          -H 'X-NT-API-Key: 
        </Typography>

        <Typography variant="subtitle1" className={classes.codeSnippet}>
          {yourApiKeyText}' \
        </Typography>
        <Typography color="secondary" className={classes.codeSnippet}>
          -H 'Authorization: Bearer
        </Typography>
        <Typography variant="subtitle1" className={classes.codeSnippet}>
          {yourApiToken}'
        </Typography>
      </Box>
    );
  };
  const CodeSnippetTwo = () => {
    return (
      <Box className={classes.box} p={2}>
        <Typography variant="subtitle1" className={classes.codeSnippet}>
          curl -L -X GET
        </Typography>
        <Typography color="secondary" className={classes.codeSnippet}>
          'https://apiservices.ntrs.com/ent/custody/v1/fxrates?
        </Typography>

        <Typography color="secondary" className={classes.codeSnippet}>
          targetCurrency=GBP&effectiveDate=2024-01-01&sourceCurrency=USD' \
        </Typography>

        <Typography color="secondary" className={classes.codeSnippet}>
          -H 'X-NT-API-Key:
        </Typography>

        <Typography variant="subtitle1" className={classes.codeSnippet}>
          {yourApiKeyText}' \
        </Typography>
        <Typography color="secondary" className={classes.codeSnippet}>
          -H 'Authorization: Bearer
        </Typography>
        <Typography variant="subtitle1" className={classes.codeSnippet}>
          {yourApiToken}'
        </Typography>
      </Box>
    );
  };
  
  const onDownload = () => {
    const link = document.createElement("a");
    link.download = `Access Setup guide_v1.pdf`;
    link.href = "../Access Setup guide_v1.pdf";
    link.click();
  };


  return (  
    <React.Fragment>
      {loadingClickWrap && <LinearProgress />}
      <div className={classes.fullContainer}>
        <Container maxWidth="md" className={classes.container}>
        <Grid item xs={6} sm={10} md={10} lg={10}>
          <Typography variant="h4" className={classes.pageTitle}> 
            Access Setup  <Button data-cy="guide" onClick={onDownload}className={classes.buttonPosition} variant="contained" color="secondary">
        Download Guide
      </Button>
            
          </Typography>
          </Grid>
          <Grid container spacing={4} direction="column">
            <Grid item xs={10} sm={10} md={10} lg={10}>
              <Typography variant="body1" className={classes.bodyText}>
                Welcome to the Northern Trust API Store. This guide will help you get started with
                our APIs and allow you to begin integrating our services into your applications.
              </Typography>
            </Grid>
            {/** Registration section */}
            <Grid item xs={8} sm={8} md={8} lg={8}>
              <Typography variant="h5" className={classes.subTitle}>
                Registration
              </Typography>
              <div className={classes.bar} />
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={10}>
              <Typography variant="body1" className={classes.bodyText}>
                As you begin exploring the APIs that Northern Trust has to offer for your business,
                this guide will take you through the steps of registration, testing, and going live
                through our API Store.
              </Typography>
            </Grid>
            <Grid item xs={10} sm={8} md={8} lg={8}>
              <Typography variant="h6" className={classes.sectionTitle}>
                ACCESS LEVELS
              </Typography>
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={10}>
              <Typography variant="body1" className={classes.bodyText}>
                We have two levels of access for your business: Sandbox Access, and Production
                Access. You will be provided with{' '}
                <Typography
                  className={classes.sandboxLink}
                  data-testid={'sandboxlink'}
                  onClick={onButtonClick}
                >
                  Sandbox
                </Typography>{' '}
                level access when you are onboarded with us.
              </Typography>
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={10}>
              <Typography variant="body1" className={classes.bodyText}>
                If you are an existing Northern Trust client, please contact your relationship
                management team or{' '}
                <Link className={classes.link} to="/contact-us">
                  Contact Us.
                </Link>{' '}
              </Typography>
            </Grid>
           {/** Sandbox section */}
           <Grid item xs={12} sm={8} md={8} lg={8}>
              <RootRef rootRef={sandboxRef}>
                <Typography variant="h5" className={classes.subTitle}>
                  Sandbox
                </Typography>
              </RootRef>
              <div className={classes.bar} />
            </Grid>
            <Grid item xs={12} sm={10} md={10} lg={10}>
              <Typography variant="body1" className={classes.bodyText}>
                Our sandbox APIs provide a quick way to securely test your application using mocked
                data. You will be able to call our Sandbox APIs once you are onboarded to the
                Northern Trust API Platform. You will need to create a unique name for your
                application and add the APIs you want to use in your application in API Store.
              </Typography>
            </Grid>    
            {/** Going Live section */}
            <Grid item xs={12} sm={8} md={8} lg={8}>
              <Typography variant="h5" className={classes.subTitle}>
                Going Live
              </Typography>
              <div className={classes.bar} />
            </Grid>
            <Grid item xs={12} sm={10} md={10} lg={10}>
              <Typography variant="body1" className={classes.bodyText}>
                Once you are ready to migrate to production and have signed API License Agreement, we will 
                move your application to production and you can get the production API Key and Client Secret
                for your application from API Store.
              </Typography>
            </Grid>                    
            <Grid item xs={10} sm={8} md={8} lg={8}>
              <Typography variant="h6" className={classes.sectionTitle}>
                EXPLORE OUR OFFERINGS
              </Typography>
            </Grid>
            <Grid item xs={12} sm={10} md={10} lg={10}>
              <Typography variant="body1" className={classes.bodyText}>
                Prior to onboarding, you can explore our{' '}
                <Link className={classes.link} to="/apis">
                  APIs
                </Link>{' '}
                and review the high level overview of our APIs.
              </Typography>
            </Grid>

            {/** Authentication section */}
            <Grid item xs={12} sm={8} md={8} lg={8}>
              <Typography variant="h5" className={classes.subTitle}>
                Authentication
              </Typography>
              <div className={classes.bar} />
            </Grid>
            <Grid item xs={12} sm={10} md={10} lg={10}>
              <Typography variant="body1" className={classes.bodyText} color="secondary">
                To call an API, first you must obtain an Access Token and then use this Access Token
                to call the API. To get Access Token you will need to obtain API Key and Client
                Secret for your Application from the Northern Trust API support team. This Access
                Token is in the form of JWT (JSON Web Token).
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8} lg={8}>
              <Typography variant="h6" className={classes.sectionTitle}>
                API KEY AND CLIENT SECRET
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8} lg={8}>
              <Typography variant="h6" className={classes.sectionTitle}>
                What are they?
              </Typography>
            </Grid>
            <Grid item xs={12} sm={10} md={10} lg={10}>
              <Typography variant="body1" className={classes.bodyText}>
                The API Key and Client Secret are unique credentials that allow us to recognize
                which application you are using.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={10} md={10} lg={10}>
              <Typography variant="body1" className={classes.bodyTextImportant}>
                Important! Your Client ID (aka API Key) and Client Secret identify you and are
                essential to protecting yourself and your customers. Keep them in a very safe place.
              </Typography>
            </Grid>
            <Grid item xs={10} sm={8} md={8} lg={8}>
              <Typography variant="h6" className={classes.sectionTitle}>
                API KEY (ALSO KNOWN AS CLIENT ID IN OAuth)
              </Typography>
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={10}>
              <Typography variant="body1" className={classes.bodyText}>
                This is the public identifier of your application. It’s used in every call so
                Northern Trust can tell which of your applications is requesting the information.
              </Typography>
            </Grid>
            <Grid item xs={10} sm={8} md={8} lg={8}>
              <Typography variant="h6" className={classes.sectionTitle}>
                CLIENT SECRET
              </Typography>
            </Grid>
            <Grid item xs={12} sm={10} md={10} lg={10}>
              <Typography variant="body1" className={classes.bodyText}>
                This is the private identifier of your application. It allows us to verify your
                identity in the authentication step of our APIs.
              </Typography>
            </Grid>
            <Grid item xs={10} sm={8} md={8} lg={8}>
              <Typography variant="h6" className={classes.sectionTitle}>
                HOW DO I GET THEM?
              </Typography>
            </Grid>
            <Grid item xs={12} sm={10} md={10} lg={10}>
              <Typography variant="body1" className={classes.bodyText}>
                You can get your API Key and Client Secret from the Northern Trust API
                Store once signed up. To sign-up, please contact your Northern Trust Relationship Manager.
                Alternatively, please get in contact via our{' '}
                <Link className={classes.link} to="/contact-us">
                  Contact Us
                </Link>{' '}
                page and provide us with the APIs you would like to use.
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={8}>
              <Typography variant="h6" className={classes.sectionTitle}>
                HOW IT WORKS?
              </Typography>
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={10}>
              <Typography variant="body1" className={classes.bodyText}>
                1) Before you can start testing our APIs, you'll need to authenticate with Northern
                Trust OAuth endpoint. You will have to pass the API Key and Client Secret as a
                base64 encoded string, for example,
              </Typography>
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={10}>
              <Box className={classes.box} p={2}>
                <Typography variant="subtitle1" className={classes.codeSnippet}>
                  base64(api key:client secret)
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={10}>
              <Typography variant="body1" className={classes.bodyText}>
                as part of Request Authorization Header and a payload with a Grant Type of:
              </Typography>
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={10}>
              <Box className={classes.box} p={2}>
                <Typography variant="subtitle1" className={classes.codeSnippet}>
                  “client_credentials”
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={10}>
              <Typography variant="body1" className={classes.bodyText}>
                Sample CURL to pull the token with your base64 encoded secret:
              </Typography>
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={10}> 
              <Box className={classes.box} p={0.1}>
                
                  <Box className={classes.box} p={0.1}>
                    <Tabs
                      value={basicCurlTab} 
                      className={classes.tabs}
                      onChange={(e:any, newValue:number)=>{handleChangeBasicCurl(e,newValue)}} 
                      name="basic"
                      scrollButtons="on">
                        <Tab label= "Sandbox" {...a11yProps(0)}/>
                        <Tab label= "Production" {...a11yProps(1)}/>
                        <CopyToClipboard text={basicCurlTab === 0 ? basicCurlSandboxText: basicCurlProductionText}>
                              <Button color="primary" 
                                  startIcon={<FileCopyIcon />} 
                                  className={isSmallScreen? classes.copyButtonMobile:classes.copyButton}>
                                COPY
                              </Button>
                        </CopyToClipboard>
                    </Tabs>
                  </Box>
                  <TabPanel value={basicCurlTab} index={0}><CodeSnippetSandboxOne/></TabPanel>
                  <TabPanel value={basicCurlTab} index={1}><CodeSnippetOne/></TabPanel>
              </Box>
            </Grid>
            <Grid item xs={12} sm={10} md={10} lg={10}>
              <Typography variant="body1" className={classes.bodyText}>
                2) If the credentials are valid, we will send a response with an encrypted JWT,
                which enables you to make further API calls, please also include the API Key as a
                HTTP Header with a name of X-NT-API-Key, see example Curl below.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={10} md={10} lg={10}>
              <Typography variant="body1" className={classes.bodyText}>
                3) Please note that the JWT is only valid for 30 minutes, you will have to call the
                OAuth endpoint again to generate a new JWT.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={10} md={10} lg={10}>
              <Typography variant="body1" className={classes.bodyText}>
                4) Include your JWT as a bearer token in the request to make further API calls.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={10} md={10} lg={10}>
              <Typography variant="body1" className={classes.bodyText}>
                Sample CURL to call the API by passing the token from previous step and API-Key:
              </Typography>
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={10}> 
              <Box className={classes.box} p={0.1}>
                  <Box className={classes.box} p={0.1}>
                    <Tabs
                      className={classes.tabs}
                      value={advancedCurlTab} 
                      onChange={(e:any, newValue:number)=>{handleChangeAdvencedCurl(e,newValue)}}  
                      scrollButtons="on">
                        <Tab label= "Sandbox"  {...a11yProps(0)}/>
                        <Tab label= "Production" {...a11yProps(1)}/>
                        <CopyToClipboard text={advancedCurlTab === 0 ? AdvancedCurlSandboxText: AdvancedCurlProductionText}>
                              <Button color="primary" 
                                  startIcon={<FileCopyIcon />} 
                                  className={isSmallScreen? classes.copyButtonMobile:classes.copyButton}>
                                COPY
                              </Button>
                        </CopyToClipboard>
                    </Tabs>
                  </Box>
                  <TabPanel value={advancedCurlTab} index={0}><CodeSnippetSandboxTwo/></TabPanel>
                  <TabPanel value={advancedCurlTab} index={1}><CodeSnippetTwo/></TabPanel>                
              </Box>
            </Grid>
           </Grid> 
        </Container>
        {exceptionClickWrap && <ErrorAlert></ErrorAlert>}
        <CookieMessage state={showCookieMessage}></CookieMessage>
        <ClickWrap state={showClickWrap} handleClose={handleClickwrapClose}></ClickWrap>
        <SessiontTmeout state={showSessionTimeoutModel}></SessiontTmeout>
      </div>
    </React.Fragment>
   
  );
};

export default AccessSetupPage;
