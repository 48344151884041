import { UserDetails } from '../../../store/accountSlice';
import { Rules } from '../rules';
import { IsNullOrUndefined } from '../../../utils';
import { RolePermissions } from '../__types__/entities';
const getUserPermissions = (user: UserDetails): RolePermissions => {
  const userRoles = user.roles ?? [];

  const permissions: RolePermissions = {
    static: []
  };

  userRoles.forEach((role: any) => {
    const rolePermissions = Rules[role];

    if (!IsNullOrUndefined(rolePermissions)) {
      permissions.static = [
        ...permissions.static,
        ...rolePermissions.static
      ];
    }
  });

  permissions.static = [...new Set<string>(permissions.static)];

  return permissions;
};

export const checkUserPermission =
  (user: UserDetails | null, permission: string, params?: any): boolean => {
    if (IsNullOrUndefined(user)) return false;

    const permissions = getUserPermissions(user ?? {});

    const staticPermissions = permissions.static;

    if (staticPermissions && staticPermissions.includes(permission)) return true;

    //const dynamicPermissions = permissions.dynamic;

    // if (dynamicPermissions) {
    //   const permissionCondition = dynamicPermissions[permission];

    //   return !permissionCondition
    //     ? false
    //     : permissionCondition(params);
    // }

    return false;
  };