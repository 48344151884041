import { Box, Container, Typography, LinearProgress } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useUserContext } from '../../UserContext';
import { fetchAccountData } from '../../store/accountSlice';
import { setPopupTextPrivatepage } from '../../store/eventSlice';
import ResetPassword from '../../components/resetPassword';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CookieMessage from '../../components/cookiemessage';
import ClickWrap from '../../components/clickwrap';
import SessiontTmeout  from '../../components/sessiontimeout';
import MessageBox from '../../components/messageBox';
import { useCookieContext } from '../../CookieContext';
import ErrorAlert from '../../components/erroralert';
import { useLocation } from 'react-router';	
import { PRODUCTION_HOST_NAME_LIST, OKTA_OSK_ERRORS, OKTA_OSK_SUCCESS_MESSAGE } from '../../constants/constants';
import { useTypedSelector } from '../../store';
import { getState } from '../../selectors/selectors';
import { postOkta } from '../../utils';
import Appurls from '../../constants/appurls';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accountPage: {
      minHeight: '100vh',
      paddingBottom: theme.spacing(10),
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
    loader:{
      margin:'10px 0px',
      height:30
    },
    pageTitle: {
      color: 'white',
      letterSpacing: '0.0625rem',
      fontWeight: 300,
      marginTop: theme.spacing(5),
      borderBottom:'1px solid',
      paddingBottom:20,
      [theme.breakpoints.down('md')]: {
        paddingBottom:10,
      },
    },
    container: {
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    }
  }));

const SettingsPage: React.FC<{}> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cookieDetails = useCookieContext();
  const location = useLocation();
  const querySearched = location.search || '';
  const currentHostName = window.location.hostname; 
  const params = new URLSearchParams(querySearched);
  const sponsorCode = params.get('sponsorCode') ||'';
  const { userDetails } = useTypedSelector(state => getState(state));
  const [alertMesaage, setAlertMesaage] = useState('');
  const [showAltert, setShowAltert] = useState(false);
  const [showLogOutAlert, setShowLogOutAlert] = useState(false);
  const [altertType, setAlertType] = useState("success");
  const [loading, setLoading] =useState(false);
  const logOutmessage = 'Password reset is successful, You will be logged out now, please login with new password.';
  //@ts-ignore 
  const userId = JSON.parse(localStorage.getItem('accessToken'))?.claims?.sub.toLowerCase() || '';
	//@ts-ignore 
	const { showCookieMessage } = cookieDetails;
  const {
    isAuthenticated,
    showClickWrap,
    exceptionClickWrap,
    acceptClickWrapdetails,
    handleLogout,
    showSessionTimeoutModel
  } = useUserContext();

  const handleClickwrapClose = () => {
    acceptClickWrapdetails();
  };
  const fetchData = useCallback(() => {
    if (isAuthenticated === true && userDetails.roles === undefined) {
      
      if (PRODUCTION_HOST_NAME_LIST.indexOf (currentHostName) >-1) {
        //if production urls
        dispatch(fetchAccountData(''));
      }else{
        if(querySearched && querySearched.includes("sponsorCode")){
          dispatch(fetchAccountData(querySearched));
        }else{
          dispatch(fetchAccountData(''));
        }
      }
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [dispatch, isAuthenticated,querySearched,currentHostName,userDetails,sponsorCode]);

  useEffect(() => {
    fetchData();
    return () => {
      dispatch(setPopupTextPrivatepage(''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated,dispatch]);
  
  const handleResetPasswordAlert = () => {
      setShowAltert(false);
  };
  
  const handleResetPassword = async(data:any) => {
     //@ts-ignore
    const userId = JSON.parse(localStorage.getItem('accessToken'))?.claims?.sub || '';
    setLoading(true);
    const changePasswordUrl = Appurls.changePasswordOskUrl(userId);
    let response = await postOkta(changePasswordUrl, data);
    if (response.error !== undefined) {
       //@ts-ignore
      const errorMessage = OKTA_OSK_ERRORS[response?.error?.code] || OKTA_OSK_ERRORS["ERR_OSK_USER_MANAGEMENT_DEFAULT"];
      setAlertMesaage(errorMessage);
      setLoading(false);
      setShowAltert(true);
      
      setAlertType("error");
    } else {
      setAlertMesaage(OKTA_OSK_SUCCESS_MESSAGE);
      setLoading(false);
      setShowAltert(true);
      setShowLogOutAlert(true);
      setAlertType("success");
    }

  };
  const onClickConfirmation =()=> {
    handleLogout();
    setShowLogOutAlert(false);
  };
  const renderSettings = ()=>{
    return (
      <React.Fragment>
          <Box data-testid='Account Page' className={classes.accountPage}>
          <Container maxWidth='md' className={classes.container}>
            <Typography variant="h4" className={classes.pageTitle}>
              Reset Password
            </Typography>
            {loading && (<div className={classes.loader}> <LinearProgress /></div>)}
            <ResetPassword 
              email={userId}
              passwordWrapper= {''}
              revokeSessions= {true}
              alertMessage ={alertMesaage}
              handleResetPassword ={handleResetPassword}
              showAltert= {showAltert}
              altertType={altertType}
              handleAltert={handleResetPasswordAlert}
            />
          </Container>
        </Box>
        <MessageBox
            state = {showLogOutAlert}
            title ={'Information'}
            message={logOutmessage}
            buttontext1={'Ok'}
            isdeaultContactusText={false}
            eventName={''}
            actionButton1={onClickConfirmation}   
        ></MessageBox>
        <CookieMessage state={showCookieMessage} ></CookieMessage>
          <ClickWrap state={showClickWrap} handleClose={handleClickwrapClose}></ClickWrap>
          <SessiontTmeout state={showSessionTimeoutModel}></SessiontTmeout>
          {exceptionClickWrap && <ErrorAlert></ErrorAlert>}
      </React.Fragment>
    );
  }
  return renderSettings(); 
};

export default SettingsPage;
