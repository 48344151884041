import React, { FC } from 'react';
import Card from '@material-ui/core/Card';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      background: 'transparent',
      flexShrink: 0,
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
      boxShadow: 'none',
    },
    titleTxt: {
      color: 'white',
      paddingBottom: theme.spacing(2),
    },
    box: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: theme.spacing(0),
    },
    textBox: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: theme.spacing(2),
    },
    subTitle: {
      fontWeight: 'bold',
    },
  }),
);

interface Props {
  title: string;
  name: string;
  version: string;
  features: Array<string>;
}

const ReleasesCard: FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <Card className={classes.card} raised>
      {props.title && (
        <Typography variant="body1" className={classes.titleTxt}>
          {props.title}
        </Typography>
      )}
      <div className={classes.box}>
        <CheckCircleIcon color="secondary" />
        <div className={classes.textBox}>
          <Typography variant="body1" className={classes.subTitle}>
            {props.name}
          </Typography>
          <Typography variant="body2"> {props.version}</Typography>
          <ul>
            {props.features.map((eachFeature, index) => (
              <li key={index}>
                <Typography variant="body1">{eachFeature}</Typography>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Card>
  );
};

export default ReleasesCard;
