import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { TermsLayout } from '../../components/layout';
import Dots from '../../components/dots';
import Grid from '@material-ui/core/Grid';
import { PolicyDetailsCard } from '../../components/cards';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useCookieContext } from '../../CookieContext';
import CookieMessage from '../../components/cookiemessage';
import ClickWrap from '../../components/clickwrap';
import { useUserContext } from '../../UserContext';
import SessiontTmeout  from '../../components/sessiontimeout';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      padding: theme.spacing(2),
      //backgroundColor: '#0A021C',
      marginTop:120,
      minHeight: '100vh',
    },
    title: {
      fontSize: '1.625rem',
      color: 'white',
      margin: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    contentContainer: {
      paddingTop: theme.spacing(2),
      margin: theme.spacing(2),
      color: 'white',
    },
    heading: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    paragraphs: {
      fontSize: '1rem',
      marginTop: theme.spacing(2),
      color: 'white',
    },
    cards: {
      marginTop: theme.spacing(2),
    },
    link: {
      textDecoration: 'none',
      paddingLeft: theme.spacing(1),
      paddingTop: theme.spacing(1),
    },
  }),
);

const EmeaPrivacyPage: FC = () => {
  const classes = useStyles();
  
 const cookieDetails = useCookieContext();
 //@ts-ignore
 const { showCookieMessage, showSessionTimeoutModel } = cookieDetails;
 const userdetails = useUserContext();
  // @ts-ignore
 const { showClickWrap } = userdetails;
 const handleClickwrapClose = () => {
  
 };
  return (
    <TermsLayout>
      <div className={classes.content}>
        <Container maxWidth="md">
          <Link to="/global-privacy-standards" className={classes.link}>
            <Button variant="text" color="primary">
              <ArrowBackIosIcon fontSize="small" />
              GLOBAL PRIVACY STANDARDS
            </Button>
          </Link>
          <Typography variant="h4" className={classes.title}>
            Europe, Middle East & Africa
          </Typography>
          <Dots />
          <div className={classes.contentContainer}>
            <Typography variant="body1" className={classes.paragraphs}>
              The EU General Data Policy Regulation (GDPR) aims to harmonize data protection law
              across EU Member States and introduces higher data protection standards as well as
              transparency of personal data collection and processing for our clients. We take your
              privacy seriously and value transparency at the highest possible standard. For more
              than half a century, we have continued to operate inside of the Europe, Middle East
              and Africa region and to meet the privacy needs of our clients.
            </Typography>
            <Grid container spacing={10} className={classes.cards}>
              <Grid item xs={12} sm={5}>
                <PolicyDetailsCard
                  title="EMEA Privacy Notice"
                  to="/global-privacy-standards/emea-privacy-notice/details"
                />
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
      <CookieMessage state={showCookieMessage} ></CookieMessage>
      <ClickWrap state={showClickWrap} handleClose={handleClickwrapClose}></ClickWrap>
      <SessiontTmeout state={showSessionTimeoutModel}></SessiontTmeout>
    </TermsLayout>
  );
};

export default EmeaPrivacyPage;
