import { Box, createStyles, makeStyles, Theme, Typography, Grid } from '@material-ui/core';
import { getApplications } from '../../selectors/selectors';
import React, { useEffect } from 'react';
import { useTypedSelector } from '../../store';
import { MetricsCard } from '../cards'; 
import AnalyticsChart from '../analyticsChart';
import { displayDate } from '../../utils/utils';
import moment from 'moment';
import { latencyDetails } from '../../store/latencySlice';
import ApiUsageContainer from '../../containers/apiUsageContainer';
import LatencyContainer from '../../containers/latencyContainer';
import QuataContainer from '../../containers/quataContainer/quataContainer';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ApplicationCollection } from '../../store/applicationsSlice';
import { useDispatch } from 'react-redux';
import { clearApiHitsData, clearLatenciesData } from '../../store/apiUsageSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    applicationsContainer: {
      flexDirection: 'column',
      width: 'calc(100% - 40px)',
      margin: 20,
      marginTop: 60,
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        marginTop: 30
      },
    },
    noAppsContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: 'calc(100% - 80px)',
      backgroundColor: 'rgba(255, 255, 255, 0.012)',
      borderRadius: 20,
      margin: 40
    },
    noAppsInfoTextContainer: {
      display: 'flex',
      width: '100%',
      paddingTop: 16,
      paddingBottom: 16,
      [theme.breakpoints.down('md')]: {
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 8,
        paddingBottom: 8
      },
      paddingLeft: 20,
      paddingRight: 20,
      flexDirection: 'column'
    },
    filterContainer: {
      display: 'flex',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        paddingLeft: 10,
        paddingRight: 10,
      },
      paddingLeft: 20,
      paddingRight: 20,
      flexDirection: 'column'
    },
    chartContainer:{
      width: '100%',
      height:260
    },
    noAppsInfoTitle: {
      color: '#ffffff',
      fontWeight: 400,
      marginBottom: 20
    },
    apiUsageContainerTitle: {
      color: '#00C4B4',
      fontWeight: 400
    },
    apiUsageContainer: {
     display:'flex',
     alignItems:'center',
     justifyContent:'center'
    },
    noAppsInfoBody: {
      color: '#ffffff',
      fontWeight: 300,
      marginTop:10
    },
    dummyAppListContainer: {

    },
    applicationName: {
      color: '#00e2d0',
      fontWeight: 500
    },
    myAppsTitle: {
      color: '#00C4B4',
      fontWeight: 500,
      marginBottom: 30
    },
    tableHeaders:{
      color: 'white',   
      fontWeight: 500
    },
    loader:{
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      height:300
    },
    noteMessage:{
      padding:10,
      color:'white',
      display:'flex',
      alignItems:'center',
      fontSize:16,
      background: '#ffffff1f',
      fontWeight: 300
    }
  }));

const MetricsList: React.FC<{}> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const applicationsdata = useTypedSelector(state => getApplications(state));
  const applications: ApplicationCollection = applicationsdata?.data;
  const isLoading = applicationsdata?.loading;

  useEffect(()=>{
    dispatch(clearApiHitsData());
    dispatch(clearLatenciesData());
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const data ={
		"1":{
			"id": "1",
			"date": "2022-02-03T00:00:00Z",
			"responseTime": 0
		},
		"2":{
			"id": "2",
			"date": "2022-02-10T00:02:00Z",
			"responseTime": 300
		},
		"3":{
			"id":"3",
			"date": "2022-02-17T00:01:00Z",
			"responseTime": 200
		},
    "4":{
			"id":"4",
			"date": "2022-02-24T00:01:00Z",
			"responseTime": 200
		},
    "5":{
			"id":"5",
			"date": "2022-03-03T00:01:00Z",
			"responseTime": 200
		}
	}
  const chartOptions = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      position: "nearest",
      layout: {
        padding: {
           bottom: 50,  
           top:50
        }
      },
      titleFontColor:'#3f4142',
      titleFontStyle:'normal',
      yAlign: 'top',
      callbacks: {
      title: function(context:any) {
        const label = moment(context[0].xLabel).format("MMMM Do YYYY, h:mm:ss")
        return label;
      }, 
      labelColor: function(context:any) {
         return {
              borderColor: '#e1fcff',
              backgroundColor: '#e1fcff',
              borderWidth: 2
          };
        },
        labelTextColor: function(context:any) {
         return '#3f4142';
        }
      },
    backgroundColor: '#e1fcff',
    borderColor:'red'
  },
  plugins: {
      legend: {
        display: false
      },
      tooltip:{
        enabled:false
      } 
      
    },
    scales: {
      yAxes: [{
        gridLines: {
          display : true,
          color:'#576378'
          },
          ticks: {
              fontColor: "white",
              beginAtZero: true,
              fontStyle:'normal',
              fontSize:12
          },
          scaleLabel: {
            display: true,
            labelString: 'Quota Consumption(%)',
            fontColor:'white',
            fontStyle:'normal'
          }
      }],
      xAxes: [{
          gridLines: {
          display : false,
          },
          ticks: {
              fontColor: "white",
              callback: function(value:string, index:any, values: any) {
                    return displayDate(value,"date");
                }
          },
          scaleLabel: {
            display: true,
            labelString: 'Date',
            fontColor:'white',
            
          }
      }]
  }
  };
  const xAxisLabels =Object.values(data).map((latency: latencyDetails) =>{
    return latency.date
  });
  const yAxisLabels  = Object.values(data).map((latency: latencyDetails) =>(
  latency.responseTime
  ));
  const chartData = {
    labels: xAxisLabels,
      datasets: [
        {
          label: 'Quota consupmtion',
          data: yAxisLabels,
          lineTension: 0,
          backgroundColor: [
            "#00dec5"
          ],
          borderColor: "#00dec5",
          fill: false,
          borderWidth: 1,
          hoverBackgroundColor: "rgba(232,105,90,0.8)",
          hoverBorderColor: "orange",
          scaleStepWidth: 1,
        }
      ]
    };

  const renderNoMetrics = () => {
    return (
      <Box
        data-testid={'NoAppsContainer'}
        data-cy={'NoAppsContainer'}
        className={classes.noAppsContainer}>
             <Grid container spacing={2} >
              <Grid item xs={12} sm={12} md={7}>
              <Box className={classes.noAppsInfoTextContainer}>
                <Typography variant={'h6'} className={classes.noAppsInfoTitle}>
                  No Applications
                </Typography>
                <Typography variant={'body2'} className={classes.noAppsInfoBody}>
                  Currently you have no applications.Once your organisation admin adds the first application, you should see it in the following format:
                </Typography>
              </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={5}>
        <Box className={classes.noAppsInfoTextContainer}>
                <div className={classes.apiUsageContainerTitle}>
                  API USAGE
                </div>
              </Box>
          <div className={classes.apiUsageContainer}>
          <MetricsCard count={'0'}
                  description= {'API Hits'}
          ></MetricsCard>
          <MetricsCard count={'0'}
                  description= {'Application Hits'}
          ></MetricsCard>
          </div>    
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
        
        </Grid>   
        <Grid item xs={12} sm={12} md={12}>
        <Box className={classes.chartContainer}> 
          <AnalyticsChart 
              isLoading = {isLoading}
              chartOptions ={chartOptions}
              chartData = {chartData} 
              xAxisLabels = {xAxisLabels}
              yAxisLabels ={yAxisLabels}
            />
          </Box>  
        </Grid>   
        </Grid>   
      </Box>
    )
  }

  const renderMetrics = () => {
    return (
      <Box
        data-testid={'AppsContainer'}
        data-cy={'AppsContainer'}
        className={classes.applicationsContainer}>
        <Grid container spacing={2} >
          <Grid item xs={12} sm={12} md={12}>
          <div className={classes.noteMessage}>
           Note: Metrics details are populated only for Production applications; Sandbox applications are excluded.
          </div>
              <ApiUsageContainer/>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
            <QuataContainer/>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
            <LatencyContainer chartName={'Traffic'}/>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
            <LatencyContainer chartName={'latency'}/>
        </Grid>      
        </Grid>
      </Box>
    )
  }

  const renderContent = () =>
  applications && Object.keys(applications).length > 0
      ? renderMetrics()
      : renderNoMetrics();
    return isLoading ? <React.Fragment > 
  <div className={classes.loader}> <CircularProgress /></div>
  </React.Fragment > : 
  renderContent();
};

export default MetricsList;