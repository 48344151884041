import { AppBar, Box, createStyles, makeStyles, Tab, Tabs, Theme, Typography, withStyles,Grid, InputAdornment, IconButton, Input, useMediaQuery } from '@material-ui/core';
import React, { PropsWithChildren, useState, useEffect } from 'react';
import { useTypedSelector } from '../../store';
import { getApplications, getLatencies, getState } from '../../selectors/selectors';
import {  ApplicationAttributes, applicationListMapping } from '../../store/applicationsSlice';
import { fetchApiProductsData } from '../../store/apiProductsSlice'; 
import { APPLICATION_DETAILS_MAPPING, KEY_DETAILS_MAPPING } from '../../constants/constants';
import  AnalyticsChart  from '../../components/analyticsChart';
import FilterContainer from '../../components/filterContainer';
import ApiProductsContainer from '../../containers/apiProductsContainer';
import { checkUserPermission } from '../../core/authorization/service/authorizationService';
import { StatsOptions, fetchLatency, latencyDetails, GenericObject, SplitByMapping, xAxisLabelMapping } from '../../store/latencySlice';
import { displayDate } from '../../utils/utils';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useAccordionTable } from '../accordionTable/context';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import InfoIcon from '@material-ui/icons/Info';
import Button from '@material-ui/core/Button';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%'
    },
    mobilecontainer: {
      width: '100%',
      maxWidth:340
    },
    keys:{
      color: 'white',   
      fontWeight: 400,
      fontSize:15,
      marginLeft:20
    },
    keysCreds:{
      color: 'white',   
      fontWeight: 400,
      fontSize:15
    },
    copyButton:{
      marginTop:20,

    },
    actionContainer:{
      display:'flex',
      justifyContent:'center',
      alignItems:'center'
    },
    values:{
      color: 'white',   
      fontWeight: 300,
      fontSize:14
    },
    chartContainer:{
      height:240,
      width: '100%'
    },
    accordion: {
      backgroundColor: 'transparent',
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    listDetails:{
      display:'flex',
      alignItems:'center',
      padding:'8px',
      borderBottom:'1px solid #566071'
    },
    heading: {
      fontWeight: 'bold',
    },
    link: {
      textDecoration: 'none',
      color: '#00C4B4',
    },
    paragraphs: {
      fontSize: '1rem'
    },
    tabPanel:{
      background:'#31405a',
      minHeight:350,
      maxHeight:350,
      overflowY:'auto',
      '&::-webkit-scrollbar': {
        width: '0.3em',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#00c4b4',
        outline: '1px solid slategrey',
      },
    },
    hyperLink: {
      cursor: 'pointer',
      textDecoration: 'none',
      color: '#00C4B4',
    },
    helpContainer:{
      display:'flex',
      color:'white',
      padding:'25px 10px',
      fontWeight:400

    },
    input: {
      display:'flex',
      flexDirection:"row",
      flexwrap: 'wrap',
      alignItems:'center', 
      color: 'white',   
      fontWeight: 300,
      fontSize:16,
      background: '#25334a',
      borderRadius: '10px',
      '& input':{
          margin: 10
      }
    },
    eye: {
      overflowX: 'auto',
      whiteSpace: 'nowrap',
      marginRight:5
    },
    infoIcon:{
      marginRight:5,
      marginTop:-1
    }
  }));

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function TabPanel(props: { [x: string]: any; children: any; value: any; index: any; }) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={classes.tabPanel}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}
interface Stats {
  [key:string]:string;
 }

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: 'rgba(0,0,0,0)',
    },
  },
})((props: StyledTabsProps) => <Tabs {...props} variant='scrollable'  TabIndicatorProps={{ children: <span /> }} />);

interface StyledTabProps {
  label: string;
};

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'uppercase',
      borderTopLeftRadius : '15px',
      borderTopRightRadius : '15px',
      color: '#00C4B4',
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(13),
      marginRight: theme.spacing(1),
      opacity: 1,
      '&.Mui-selected': {
        backgroundColor: '#31405a'
      }
    },
  }),
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

export type ApplicationDetailsProps = {
  applicationId: string;
};
export const ApplicationDetails: React.FC<ApplicationDetailsProps> =
  (props: PropsWithChildren<ApplicationDetailsProps>) => {
  
    const classes = useStyles();
    const statsValues: Stats =  StatsOptions;
    const dispatch = useDispatch();
    const { applicationId } = props;
    const [currentStat,setCurrentStat] = useState('avg');
    const [currentTimeRange,setCurrentTimeRange] = useState('day');
    
    const splitBy : GenericObject = SplitByMapping;
    const currentSplitBy = splitBy[currentTimeRange];
    const xAxisLabelMap: GenericObject = xAxisLabelMapping;
    const currentXLabel = xAxisLabelMap[currentTimeRange];
    const { activeId } = useAccordionTable();
    const currentApplicationId = activeId || '';
    const isSmallScreen = useMediaQuery((theme:any) => theme.breakpoints.down("sm"));
    const isActiveApplication = currentApplicationId === applicationId ? true:false;
    const chartOptions = {
      legend: {
        display: false
      },
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        position: "nearest",
        titleFontColor:'#3f4142',
        titleFontStyle:'normal',
        yAlign: 'top',
        opacity: 0.5,
        callbacks: {
        title: function(context:any) {
          const label = moment(context[0].xLabel).format("MMMM Do YYYY, h:mm:ss")
          return label;
        }, 
        labelColor: function(context:any) {
           return {
                borderColor: '#e1fcff',
                backgroundColor: '#e1fcff',
                borderWidth: 2
            };
          },
          labelTextColor: function(context:any) {
           return '#3f4142';
          }
        },
      backgroundColor: '#e1fcff',
      borderColor:'red'
    },
    plugins: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltip:{
          enabled:false
        } 
        
      },
      scales: {
        yAxes: [{
          gridLines: {
            display : true,
            color:'#576378'
            },
            ticks: {
                fontColor: "white",
                beginAtZero: true
            },
            scaleLabel: {
              display: true,
              labelString: 'Response Time (in ms)',
              fontColor:'white',
              
            }
        }],
        xAxes: [{
            gridLines: {
            display : false,
            },
            ticks: {
                fontColor: "white",
                callback: function(value:string, index:any, values: any) {
                      return displayDate(value,currentTimeRange);
                  }
            },
            scaleLabel: {
              display: true,
              labelString: currentXLabel,
              fontColor:'white',
              
            }
        }]
    }
    };
    
    const { data } = useTypedSelector(state => getApplications(state));
    const { latencies } = useTypedSelector(state=>getLatencies(state));
    const { userDetails } = useTypedSelector(state => getState(state));
    const hasPermissionViewCreds = checkUserPermission(userDetails,'viewCreds');
    
    const environment  = data &&  data[applicationId]?.environment;
    
    const viewCreds = hasPermissionViewCreds || environment === "Sandbox"; 
    const canViewAnalytics = environment === "Production";
    const isLoading = latencies.loading;
    const xAxisLabels =Object.values(latencies.data).map((latency: latencyDetails) =>{
      return latency.date
    });
    const yAxisLabels  = Object.values(latencies.data).map((latency: latencyDetails) =>(
    latency.responseTime
    ));
    
    const onChange = (e :any) =>{
      switch(e.target.name){
        case "stats": setCurrentStat(e.target.value); 
                      break;
        case "timeRange": setCurrentTimeRange(e.target.value); 
                      break;
      }
     
    };

    const chartData = {
      labels: xAxisLabels,
        datasets: [
          {
            label: statsValues[currentStat],
            data: yAxisLabels,
            lineTension: 0,
            backgroundColor: [
              "#00dec5"
            ],
            borderColor: "#00dec5",
            fill: false,
            borderWidth: 1,
            hoverBackgroundColor: "rgba(232,105,90,0.8)",
            hoverBorderColor: "orange",
            scaleStepWidth: 1,
          }
        ]
      };
    console.log(data);  
    const applicationDetails :ApplicationAttributes = data && data[applicationId]?.applicationDetails;
    const keyTokenDetails :ApplicationAttributes = data && data[applicationId]?.keyTokenDetails;
    const applicationListMapping :applicationListMapping = APPLICATION_DETAILS_MAPPING;
    const KeyTokenDetailsMapping :applicationListMapping = KEY_DETAILS_MAPPING;
    
    const [value, setValue] = React.useState(0);
 
 const [keyTokenVisibility, setKeyTokenVisibility] = useState<boolean[]>([false,false]);
 const handleToggleKeyTokenVisibility =(index: number) => {
   setKeyTokenVisibility((previousVisibility) => {
     const newVisibility = [...previousVisibility];
     newVisibility[index] = !newVisibility[index];
     return newVisibility;
   });
 };
 

    const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
      setValue(newValue);
    };
   
  
    useEffect(()=>{
      if(currentApplicationId.length > 0  && isActiveApplication === true){
        
        dispatch(fetchApiProductsData({
          applicationId:applicationId,
          environment:environment
        }));
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isActiveApplication]);
    useEffect(()=>{
      if(currentApplicationId.length > 0  && isActiveApplication === true){
        const parameters ={
          timerange: currentTimeRange,
          split: currentSplitBy,
          bucket: 'apps',
          stats: currentStat,
          appId:applicationId
        };
        dispatch(fetchLatency(parameters));
        
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentStat,currentTimeRange,dispatch,applicationId, currentSplitBy,isActiveApplication]);
    return (
      <Box className={isSmallScreen ? classes.mobilecontainer: classes.container}>
        <AppBar position="static" style={{ background: '#25334a' }}>
          <StyledTabs  value={value} onChange={handleChange} aria-label="application details">
            <StyledTab label="Details" {...a11yProps(0)} />
            {viewCreds && <StyledTab label="Key & Secret" {...a11yProps(1)} />}
            <StyledTab label="API Products" {...a11yProps(2)} />
            {canViewAnalytics && <StyledTab label="analytics" {...a11yProps(3)} />}
          </StyledTabs>
        </AppBar>
        <TabPanel value={value} index={0}>
           {
             applicationDetails && Object.keys(applicationDetails).length && Object.keys(applicationDetails).map((application:string ) => (
              <Grid container spacing={5} direction="row">
              <Grid item xs={6} sm={3}>
              <Typography className ={classes.keys}>{applicationListMapping[application]}</Typography>
              </Grid>
              <Grid item xs={6} sm={9}>
             <Typography className ={classes.values}>{applicationDetails[application]}</Typography>
              </Grid>
              </Grid>
            ))
          }
        </TabPanel>
        {viewCreds && <TabPanel value={value} index={1}>
           {
             keyTokenDetails && Object.keys(keyTokenDetails).length && Object.keys(keyTokenDetails).map((key:string, index: number ) => (
              <div>
              <Grid container spacing={2} direction="row" className={classes.listDetails}>
              <Grid item xs={12} sm={12} md={9} lg={9}>
              <Typography className ={classes.keysCreds}>{KeyTokenDetailsMapping[key]}</Typography>
              <Typography className ={classes.input}>
                  <Input type ={keyTokenVisibility[index] ? 'keyTokenDetails[key]': 'password'}
                  readOnly value={keyTokenDetails[key]}
                  fullWidth={true}
                  disableUnderline={true}
                  key={keyTokenDetails[key]}
                  data-testid="MaskUnmask"
                 />
                <InputAdornment position="end" ></InputAdornment>
                    <Typography className ={classes.values}>
                    <IconButton 
                       className={classes.eye}
                       data-cy="MaskUnmask"
                       data-testid="MaskUnmaskIconButton"
                       onClick={() => handleToggleKeyTokenVisibility(index)}
                       edge="end"> 
             { keyTokenVisibility[index] ? <VisibilityOff /> : <Visibility />}
               </IconButton>
               </Typography> 
              </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} className={classes.actionContainer}>
              <CopyToClipboard text={keyTokenDetails[key]}>
                  <Button color="primary" startIcon={<FileCopyIcon />} className={isSmallScreen?'':classes.copyButton}>
                    COPY
                  </Button>
                </CopyToClipboard>
              </Grid>
              </Grid>
              
            </div>
            ))
          }
          <div className={classes.helpContainer}>
            <InfoIcon className={classes.infoIcon}></InfoIcon>
            <span><></>   
            <Link className={classes.hyperLink} to="/get-started/access-setup">
              Click here
            </Link>
            {' '}to learn how to use your{' '} credentials.            
            </span>
          </div>
        </TabPanel>}
        <TabPanel value={value} index={viewCreds?2:1} data-testid="apiProducts">
        <Grid container spacing={1}>
        
              
              <Grid sm={12} md={12} xs={12} item key={'ok'}>
                   <ApiProductsContainer 
                    selelectionFilter={false}
                    checkedApis={{}}
                    handleDomainsChange={()=>{}}
                    handleApisChange={()=>{}}
                   ></ApiProductsContainer>
                  </Grid>
           
        </Grid>
        </TabPanel>
        {canViewAnalytics && (<TabPanel value={value} index={viewCreds?3:2} >
          <Grid container spacing={1} direction="row">
              <Grid sm={12} md={12} xs={12} item >
              <FilterContainer 
                  chartName={'Traffic'}
                  isFilterApiproductsBydomain={false}
                  currentDomain={''}
                  domains={false}
                  labelName = {''}
                  stats = {true}
                  timeRange = {true}
                  apis ={false}
                  apps ={false}
                  currentApp={''}
                  currentApi= {['']}
                  pastTimeRange ={false}
                  handleChange = {onChange} 
                  currentTimeRange={currentTimeRange}
                  onCloseSelect ={()=>{}}
                  currentStat={currentStat}
                  currentPastTimeRange={''}
                  isRangeDisabled={true}
                />
             </Grid>
              <Grid sm={12} md={12} xs={12} item >
                <Box className={classes.chartContainer}> 
                 <AnalyticsChart  
                  isLoading={isLoading}
                  xAxisLabels={xAxisLabels} 
                  yAxisLabels={yAxisLabels}
                  chartData={chartData}
                  chartOptions={chartOptions}
              ></AnalyticsChart>
               </Box>  
              </Grid>
          </Grid>
        </TabPanel> )}
      </Box>
    );
  };
  