import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { ReleasesCard } from '../../components/cards';
import Alert from '@material-ui/lab/Alert';
import { useCookieContext } from '../../CookieContext';
import { useUserContext } from '../../UserContext';
import CookieMessage from '../../components/cookiemessage';
import ClickWrap from '../../components/clickwrap';
import LinearProgress from '@material-ui/core/LinearProgress';
import ErrorAlert from '../../components/erroralert';
import SessiontTmeout  from '../../components/sessiontimeout';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullContainer: {
      minHeight: '100vh',
    },
    container: {},
    pageTitle: {
      color: 'white',
      letterSpacing: '0.0625rem',
      fontWeight: 300,
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
    },
    subTitle: {
      fontWeight: 500,
      marginBottom: theme.spacing(2),
    },
  }),
);

const ReleasesPage: FC = () => {
  const classes = useStyles();
  const cookieDetails = useCookieContext();
  //@ts-ignore
  const { showCookieMessage } = cookieDetails;
  const userdetails = useUserContext();
  // @ts-ignore
  const { showClickWrap, exceptionClickWrap, loadingClickWrap,acceptClickWrapdetails,showSessionTimeoutModel } = userdetails;
 
 
  const handleClickwrapClose = () => {
    acceptClickWrapdetails();
  };
  return (
    <div className={classes.fullContainer}>
      {loadingClickWrap && <LinearProgress />}
      <Container maxWidth="md" className={classes.container}>
        <Typography variant="h4" className={classes.pageTitle}>
          Releases and Maintenance
        </Typography>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography variant="body1" color="primary" className={classes.subTitle}>
              RELEASES
            </Typography>
            <ReleasesCard
              title="31-JAN-2024: Release"
              name="Banking and Treasury APIs"
              version="1.0.0"
              features={[
                'Payment Initiation','Payment Status'
              ]}
            />    
        <ReleasesCard
            title="30-MAR-2023: Release"
            name="Risk and Performance APIs"
            version="2.1.1"
            features={[
              'Metrics'
            ]}
        />    
        <ReleasesCard
            title="11-FEB-2023: Release"
            name="Custody APIs"
            version="1.0.1"
            features={[
              'FX Rates'
            ]}
        />
        <ReleasesCard
        title="07-DEC-2022: Release"
        name="Custody APIs"
        version="3.0.0"
        features={[
          'Cash Projection','Cash Activity'
        ]}
      />
   
       <ReleasesCard
        title="17-NOV-2022: Release"
        name="	Custody APIs"
        version="2.0.1"
        features={[
          'Trade Status'
        ]}
      />
       <ReleasesCard
          title="21-OCT-2022: Release"
          name="	Custody APIs"
          version="1.1.0"
          features={[
            'Account Valuations','	Income Receivable','Pending Transactions','	Custody Positions','Accounting Activity','Position Exposure'
          ]}
        />
         <ReleasesCard
          title=""
          name="Middle Office APIs"
          version="1.1.0"
          features={[
            'Account Valuations','	Income Receivable','Pending Transactions','	Custody Positions','Accounting Activity','Position Exposure'
          ]}
        />  
        </Grid>
          <Grid item sm={6}>
            <Typography variant="body1" color="primary" className={classes.subTitle}>
              PLANNED MAINTENANCE
            </Typography>
            <Alert severity="info">
              <strong>No planned maintenance.</strong>
            </Alert>
          </Grid>
        </Grid>
      </Container>
      {exceptionClickWrap && <ErrorAlert></ErrorAlert>}
      <CookieMessage state={showCookieMessage} ></CookieMessage>
      <ClickWrap state={showClickWrap} handleClose={handleClickwrapClose}></ClickWrap>
      <SessiontTmeout state={showSessionTimeoutModel}></SessiontTmeout>
    </div>
  );
};

export default ReleasesPage;
