import { Box, createStyles, LinearProgress, makeStyles, Theme } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useTypedSelector } from '../../store';
import { MetricsCard } from '../../components/cards'; 
import FilterContainer from '../../components/filterContainer';
import { useDispatch } from 'react-redux';
import { getUsage, getState } from '../../selectors/selectors';
import { FetchUsage } from '../../store/apiUsageSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
   noAppsInfoTextContainer: {
      display: 'flex',
      width: '100%',
      paddingTop: 16,
      paddingBottom: 16,
      [theme.breakpoints.down('md')]: {
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 8,
        paddingBottom: 8
      },
      paddingLeft: 20,
      paddingRight: 20,
      flexDirection: 'column'
    },
    filterContainer: {
      display: 'flex',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        paddingLeft: 10,
        paddingRight: 10,
      },
      paddingLeft: 20,
      paddingRight: 20,
      flexDirection: 'column',
      marginTop:'0.5rem',
      marginBottom:'0.5rem'
    },
    apiUsageContainer: {
     display:'flex',
     alignItems:'center',
     justifyContent:'center',
     padding:10,
     [theme.breakpoints.down('md')]: {
      padding:5,
    },
    },
    noAppsInfoBody: {
      color: '#ffffff',
      fontWeight: 300,
      marginTop:10
    },
    loader:{
      display:'flex',
      alignItems:'center',
      justifyContent:'center'

    }
  }));

const ApiUsageContainer: React.FC = () => {
  const classes = useStyles();
  const usageDetails = useTypedSelector(state => getUsage(state));
  const { userDetails } = useTypedSelector(state => getState(state));
  const orgId =  userDetails.orgUuid ||'';
  const dispatch = useDispatch();
  const [currentPastTimeRange,setCurrentPastTimeRange] = useState('month');
  const { loading ,data} =  usageDetails.usage;
  const averageAPILatency = data.averageAPILatency? parseFloat(data.averageAPILatency).toFixed(2).toString()+" ms": "0 ms";
  const errorRate = data.errorRate? parseFloat(data.errorRate).toFixed(2).toString()+" %" : "0 %"
  const onChange = (e :any) =>{
    switch(e.target.name){
      case "pastTimeRange": setCurrentPastTimeRange(e.target.value); 
                            break;
     }
   
  };
  useEffect(()=>{
    if(orgId?.length > 0){
      const parameters= {
        orgId:orgId,
        timerange:currentPastTimeRange
      };
      dispatch(FetchUsage(parameters))
  
    }
   
  },[currentPastTimeRange,orgId,dispatch]);

  const renderApiUsageContainer = () => {
    return (
      <React.Fragment >
         <Box className={classes.noAppsInfoTextContainer}>
            <FilterContainer 
              chartName={'Traffic'}
              labelName = {'API USAGE'}
              stats = {false}
              currentApp={''}
              currentApi= {['']}
              timeRange = {false}
              onCloseSelect ={()=>{}}
              apis ={false}
              apps ={false}
              pastTimeRange ={true}
              handleChange = {onChange} 
              currentTimeRange={''}
              currentStat={''}
              currentPastTimeRange={currentPastTimeRange}
              isRangeDisabled={false}
              currentDomain={''}
              domains={false}
              isFilterApiproductsBydomain={false}
            />  
            </Box>
            { loading && (<LinearProgress />)}
            <div className={classes.apiUsageContainer}>
          <MetricsCard count={data.apiHits}
                  description= {'API Hits'}
          ></MetricsCard>
          <MetricsCard count={averageAPILatency}
                  description= {'Average API Latency'}
          ></MetricsCard>
          <MetricsCard count={errorRate}
                  description= {'Error Rate'}
          ></MetricsCard>
          </div>    
      </React.Fragment >

    )
  }

  const renderContent = () => renderApiUsageContainer()
  return renderContent();
};

export default ApiUsageContainer;
