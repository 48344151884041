/* istanbul ignore next */
import moment from 'moment'
export const truncate = (input: string, length: number) =>
  input.split(' ').length > length ? `${input.split(' ').splice(0, length).join(' ')}...` : input;

export const validUuid = (id: string) => {
  const uuidRegex = new RegExp(
    '\\b[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-\\b[0-9a-fA-F]{12}\\b',
  );
  return uuidRegex.test(id);
};

export const phoneNumberRegex = new RegExp('([(+]*[0-9]+[()+. -]*)');
export const alphanumericRegex = new RegExp((/^[a-z0-9 -]*$/i))

export const emailRegex = new RegExp(
  "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$",
);
//eslint-disable-next-line
export const urlRegex = new RegExp(/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/);
export const trapSpacesForRequiredFields = (value: string) => !!value.trim();

export const sorting = (value: string[]) => {
  const sortingValue = value;
  return sortingValue.sort();
};

export const redirectToOktaUrl = () => {
  const envSettings = window as any;
  const oktaRedirectUrl = envSettings?.apo_env?.REACT_APP_OKTA_URL;
  window.location.assign(oktaRedirectUrl);
};

export const endAndStartTimer = (timer: any, timeOut: any, callbackfn: any) => {
  //@ts-ignore
  window.clearTimeout(window[timer]);
  //@ts-ignore
  window[timer] = window.setInterval(callbackfn, timeOut);
}

/* istanbul ignore next */
export const clearTimers = () => {
  //@ts-ignore
  if (window['sessionTimer']) {
    //@ts-ignore
    window.clearTimeout(window['sessionTimer']);
  }
  //@ts-ignore
  if (window['displaysessionTimer']) {
    //@ts-ignore
    window.clearTimeout(window['displaysessionTimer']);
  }

};

export const concatName = (firstName?: string, lastName?: string): string => {
  const firstNameIsEmpty = IsNullUndefinedOrEmpty(firstName);
  const lastNameIsEmpty = IsNullUndefinedOrEmpty(lastName);

  if (firstNameIsEmpty && lastNameIsEmpty) return '';

  if (!firstNameIsEmpty && !lastNameIsEmpty) return `${firstName} ${lastName}`;

  return firstNameIsEmpty
    ? lastName!
    : firstName!;
}

/* istanbul ignore next */
export const parseJwt = (token: string): any => {
  return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
}

export const IsNullOrUndefined: (value: any) => boolean = (value) =>
  value === null || typeof value === 'undefined';

export const IsNullUndefinedOrEmpty: (value?: string | any[] | {} | null) => boolean =
  (value) => {
    if (IsNullOrUndefined(value)) return true;

    const isObject = typeof value === 'object' && !Array.isArray(value);

    if (isObject) {
      value = Object.keys(value as {});
    }

    if ((value! as string | any[]).length < 1) return true;

    return false;
  }
  export const displayDate = (date: string,displayFormat: string) => {
    let label='';
    switch(displayFormat){
      case "day":label = moment(date).format("hh:mm"); 
                  break;
      case "hour":label = moment(date).format("mm"); 
                  break;
      case "week":label = moment(date).format("dddd"); 
                  break;
      case "month":label = moment(date).format("D MMM"); 
                  break;
      case "year":label = moment(date).format("MMMM"); 
                  break;
      case "date":label = moment(date).format("D MMM"); 
                  break;
    }
    return label;
  }

  export const getColors = () => {
    let colors: string[]= ['#00e2c5','#ff7c00','#fff688','#1c646c','#755437','#b481f4','#ffffff','#C72561'];
    const dynmicColors =  ['#460fa8', '#38ea4a', '#74606b', '#61c45b', '#1e2fbb', '#b10c1c', '#d93bbc', '#fb1b3c', '#4e60ac', 
    '#e7165d', '#1e6fb', '#85f222', '#6fe98', '#ca234d', '#8cb89b', '#74f510', '#e39b50', '#cd11f7', '#7c06b2', '#2ace9b', '#f0be2a',
     '#33eb67', '#9a3fdc', '#8487f3', '#7e1d6', '#7e8d85', '#4bca50', '#c759d7', '#c1aca1', '#730614', '#bbe6e1', '#b90988', '#f86aa6', 
     '#d6ddea', '#be2f62', '#7416d9', '#3d4a1f', '#648da', '#e32786', '#3d45d3', '#18b22d', '#c990f3', '#88251c', '#a821fd', '#71cc04', 
     '#cb3c33', '#ad150b', '#a57b66', '#e362a2', '#32f18a', '#2ef32f', '#169389', '#330aaf', '#513d66', '#1a5f0', '#a86e41', '#3c0b7a', 
     '#f70c7', '#64f37c', '#d7125e', '#3e8cbc', '#13083f', '#c66777', '#32632f', '#a3acac', '#ad98f5', '#fb6708', '#2bdb68', '#6c12dd', 
     '#3f43fa', '#505bc1', '#4893cc', '#a3d2ba', '#1ae303', '#e86e3d', '#5388d6', '#c6b266', '#3fadb3', '#f2f4cd', '#ccc162']
    colors = colors.concat(dynmicColors);
    return colors;
  }

  export const getOskWrapperBaseUrl = () => {
    let oskWrapperBaseUrl = '';
    const  baseUrl = window.location.origin;
    switch(baseUrl){
      case "https://apo-store-ui-int.wrkld-apo-1-system-1.k8s.ntrs.com":
            oskWrapperBaseUrl = 'https://applist-d.ntrs.com:3000';
                  break;
      case "https://developer.uat.ntrs.com":
            oskWrapperBaseUrl = 'https://applist-a.ntrs.com';
                  break;
      case "https://developer.ntrs.com":
            oskWrapperBaseUrl = 'https://applist.ntrs.com';
            break;
      case "http://localhost:8080":
            oskWrapperBaseUrl = 'https://applist-d.ntrs.com:3000';
            break;
            
    }
    return oskWrapperBaseUrl;
  }