import { Avatar, Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { useUserContext } from '../../UserContext';
import React from 'react';
import { concatName } from '../../utils/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accountHeader: {
      display: 'flex',
      flex: 1,
      padding: 40,
      paddingBottom: 30,
      borderBottom: `1px solid ${theme.palette.primary.main}`
    },
    accountDetails: {
      display: 'flex'
    },
    avatarWrapper: {
      display: 'flex',
      marginRight: 20
    },
    avatar: {
      background: 'rgba(0,0,0,0)',
      color: '#ffffff',
      border: `1px solid ${theme.palette.primary.main}`,
      width: 65,
      height: 65
    },
    userInfo: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    userDeveloper:{
      position: 'relative',
      top: 65,
      right: 85,
    },
    userAdmin:{
      position: 'relative',
      top: 65,
      right: 75,
    }
  }));

const AccountHeader: React.FC<{}> = () => {
  const classes = useStyles();
  const { userDetails } = useUserContext();
  const roleName = userDetails?.roles?.[0];
  const userRole = roleName === "Developer" ? classes.userDeveloper : classes.userAdmin ;
  return (
    <Box className={classes.accountHeader}>
      <Box className={classes.accountDetails}>
        <Box className={classes.avatarWrapper}>
          <Avatar role='avatar' className={classes.avatar}>
            {`
              ${userDetails.firstName?.substr(0, 1) ?? ''}
              ${userDetails.lastName?.substr(0, 1) ?? ''}`
            }
          </Avatar>
          
        </Box>
        <Box className={userRole}>
          <Typography role='name' variant={'body1'} color={'textPrimary'}>
           {roleName}
          </Typography>
        </Box>  
        <Box className={classes.userInfo}>
          <Typography role='name' variant={'body1'} color={'textPrimary'}>
            {concatName(userDetails.firstName, userDetails.lastName)}
          </Typography>
          <Typography role='email' variant={'body2'} color={'textPrimary'}>
            {userDetails.email}
          </Typography>
          <Typography role='orgId' variant={'body1'} color={'textPrimary'}>
            {userDetails.organisationName}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AccountHeader;
