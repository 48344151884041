import { Box, createStyles, makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import React, { useState, useEffect, PropsWithChildren } from 'react';
import { useTypedSelector } from '../../store';
import FilterContainer from '../../components/filterContainer';
import { useDispatch } from 'react-redux';
import { getUsage, getState, getApiProducts } from '../../selectors/selectors';
import AnalyticsChart from '../../components/analyticsChart';
import { FetchApiHits, GetHitsResponse, GetLatencisResponse, FetchLatencies, clearApiHitsData, clearLatenciesData } from '../../store/apiUsageSlice';
import moment from 'moment';
import { displayDate, getColors } from '../../utils/utils';
import { GenericObject, SplitByMapping, xAxisLabelMapping } from '../../store/latencySlice';
import { fetchApiProductsData, fetchApiProductsLatenicesData, clearLatencyDomains, cleartrafficDomains } from '../../store/apiProductsSlice';

export type LatencyContainerProps = {
  chartName:string;
  
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
   noAppsInfoTextContainer: {
      display: 'flex',
      width: '100%',
      paddingTop: 16,
      paddingBottom: 16,
      [theme.breakpoints.down('md')]: {
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 8,
        paddingBottom: 8
      },
      paddingLeft: 20,
      paddingRight: 20,
      flexDirection: 'column'
    },
    filterContainer: {
      display: 'flex',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        paddingLeft: 10,
        paddingRight: 10,
      },
      paddingLeft: 20,
      paddingRight: 20,
      flexDirection: 'column',
      marginTop:'0.5rem',
      marginBottom:'0.5rem'
    },
    LatencyContainer: {
     display:'flex',
     alignItems:'center',
     justifyContent:'center'
    },
    noAppsInfoBody: {
      color: '#ffffff',
      fontWeight: 300,
      marginTop:10
    },
    loader:{
      display:'flex',
      alignItems:'center',
      justifyContent:'center'

    },
    chartContainer:{
      height:320,
      width: '100%',
      padding:10,
      [theme.breakpoints.down('md')]: {
        padding:5
      },
    },
    labelContainer:{
      display:'flex',
      padding: '10px 0px',
      color: '#ffffff',
      fontWeight: 500,
    }
  }));

const LatencyContainer: React.FC<LatencyContainerProps> = (props: PropsWithChildren<LatencyContainerProps>) => {
  const classes = useStyles();
  const usageDetails = useTypedSelector(state => getUsage(state));
  const { userDetails } = useTypedSelector(state => getState(state));
  const productData = useTypedSelector(state => getApiProducts(state));
  const apiProductsDropdownData = productData?.apiProducts?.data 
  const { chartName } = props;
  const orgId =  userDetails.orgUuid ||'';
  const chartLabel = chartName === "Traffic"? "TRAFFIC" : "LATENCY";
  const xAxisLabel = chartName === "Traffic"? "API Hits" : "API Latency (in ms)";
  const dispatch = useDispatch();
  const { loading , data} = chartName === "Traffic"? usageDetails.apiHits : usageDetails.latencies;
  const apiHits: GetHitsResponse = data || {};
  const latencies: GetLatencisResponse = data || {};
  const [currentPastTimeRange,setCurrentPastTimeRange] = useState('month');
  const xAxisLabelMap: GenericObject = xAxisLabelMapping;
  const currentXLabel = xAxisLabelMap[currentPastTimeRange];
  const splitBy : GenericObject = SplitByMapping;
  const currentSplitBy = splitBy[currentPastTimeRange];

  const apiProducts = chartName === "Traffic" ? productData.apiProducts : productData.apiProductsLateinces;
  const isDataLoading = apiProducts.loading || loading;
  const colors = getColors();
  const dataset: any = [];
  let xAxisLabels: any = [];
  const getDataPoints = () => {

  if(chartName ==="Traffic") {
    let index = 0;
    Object.keys(apiHits).forEach(function(applicationList) {
      const hitDetails = apiHits[applicationList].hits || {}
      const responseTimes = []; 
      for (const [key] of Object.entries(hitDetails)) {
        responseTimes.push(hitDetails[key].responseTime);
      }
      for (const [key] of Object.entries(hitDetails)) {
        xAxisLabels.push(hitDetails[key].date);
      }
      if(apiProductsDropdownData){
        dataset.push({
          data:responseTimes,
          responseTime:responseTimes,                
          label:apiProductsDropdownData[apiHits[applicationList].uuid]?.name+' - '+apiProductsDropdownData[apiHits[applicationList]?.uuid]?.version,
          lineTension: 0,
          backgroundColor: [
            colors[index]
          ],
          borderColor: colors[index],
          fill: false,
          borderWidth: 1,
          hoverBackgroundColor: "rgba(232,105,90,0.8)",
          hoverBorderColor: "orange",
          scaleStepWidth: 1,
                          
      });
      index++;
      }
      
  });
  }else{
    let index = 0;  
      Object.keys(latencies).forEach(function(applicationList) {
        
          const latenciesDetails = latencies[applicationList].latencies || {}
          const responseTimes = []; 
          for (const [key] of Object.entries(latenciesDetails)) {
            responseTimes.push(latenciesDetails[key].responseTime);
          }
          for (const [key] of Object.entries(latenciesDetails)) {
            xAxisLabels.push(latenciesDetails[key].date);
          }
          if(apiProductsDropdownData){
             console.log(JSON.stringify(apiProductsDropdownData))
            dataset.push({
              data:responseTimes,
              responseTime:responseTimes,                
              label:apiProductsDropdownData[latencies[applicationList]?.uuid]?.name+' - '+apiProductsDropdownData[latencies[applicationList]?.uuid]?.version,
              lineTension: 0,
              backgroundColor: [
                colors[index]
              ],
              borderColor:colors[index],
              fill: false,
              borderWidth: 1,
              hoverBackgroundColor: "rgba(232,105,90,0.8)",
              hoverBorderColor: "orange",
              scaleStepWidth: 1,
                              
          });
          index++;

          }
          
      });
  }
  };
  
  getDataPoints();
  const DatesSet = new Set(xAxisLabels);
  xAxisLabels = [...DatesSet];
  const yAxisLabels  = [1,2,3,5];
  const chartData = {
    labels: xAxisLabels,
    datasets: dataset || []
  };
  
  const [ currentApp, setCurrentApp ] = useState('');
  const [currentApi, setCurrentApi ] = useState<string[]>([]);
  const [currentApiSet, setCurrentApiSet] = useState<string[]>([]);
  const [currentDomain, setCurrentDomain] = useState('');
  const onChange = (e :any) =>{
    switch(e.target.name){
      case "pastTimeRange": setCurrentPastTimeRange(e.target.value);
      
                  break;
      case "apps": setCurrentApp(e.target.value);
                  if(chartName === "Traffic"){
                    dispatch(fetchApiProductsData({applicationId:e.target.value, environment:'Production'}));
                    dispatch(clearApiHitsData());
                    dispatch(cleartrafficDomains());
                  }else{
                    dispatch(fetchApiProductsLatenicesData({applicationId:e.target.value, environment:'Production'}));
                    dispatch(clearLatenciesData());
                    dispatch(clearLatencyDomains());
                  }
                   setCurrentApi([]);
                   setCurrentApiSet([]);
                   setCurrentDomain('');
                   break;
      case "apis": 
        const currenApiValue =e.target.value;
        setCurrentApi(currenApiValue);
        break;
      case "domains": 
        const currentDomainValue = e.target.value;
        setCurrentDomain(currentDomainValue);
        setCurrentApi([]);
        setCurrentApiSet([]);
        if(chartName === "Traffic"){
          dispatch(clearApiHitsData());
        }else{
          dispatch(clearLatenciesData());
        }
        break;   
     }
   
  };
  const onClose = (e :any) =>{
      setCurrentApiSet(currentApi);
  };
  const isSmallScreen = useMediaQuery((theme:any) => theme.breakpoints.down("sm"));
  const chartOptions = {
    legend: {
      display: true,
      position: isSmallScreen?'bottom':'right',
      usePointStyle:false,
      pointStyle:'circle',
      labels: {
        usePointStyle: true,
        pointStyle:'circle',
        fontColor: '#ffffff',
        fontSize:12,
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      position: "nearest",
      layout: {
        padding: {
           bottom: 50,  
           top:50
        }
      },
      titleFontColor:'#3f4142',
      titleFontStyle:'normal',
      yAlign: 'top',
      callbacks: {
      title: function(context:any) {
        const label = moment(context[0].xLabel).format("MMMM Do YYYY, h:mm:ss")
        return label;
      }, 
      labelColor: function(context:any) {
         return {
              borderColor: '#e1fcff',
              backgroundColor: '#e1fcff',
              borderWidth: 2
          };
        },
        labelTextColor: function(context:any) {
         return '#3f4142';
        }
      },
    backgroundColor: '#e1fcff',
    borderColor:'red'
  },
  plugins: {
      legend: {
        display: true
      },
      tooltip:{
        enabled:false
      } 
      
    },
    scales: {
      yAxes: [{
        gridLines: {
          display : true,
          color:'#576378'
          },
          ticks: {
              fontColor: "white",
              beginAtZero: true,
              fontStyle:'normal',
              fontSize:12
          },
          scaleLabel: {
            display: true,
            labelString: xAxisLabel,
            fontColor:'white',
            fontStyle:'normal'
          }
      }],
      xAxes: [{
          gridLines: {
          display : false,
          },
          ticks: {
              fontColor: "white",
              callback: function(value:string, index:any, values: any) {
                  return displayDate(value,currentPastTimeRange);
                }
          },
          scaleLabel: {
            display: true,
            labelString: currentXLabel,
            fontColor:'white',
            
          }
      }]
  }
  };
  useEffect(()=>{
    if(orgId?.length > 0  && currentApiSet?.length> 0 && currentApp.length> 0){
      const parameters= {
        orgId:orgId,
        timerange:currentPastTimeRange,
        appIds:currentApp,
        apiIds:currentApiSet,
        split:currentSplitBy
      };
      chartName === "Traffic"? dispatch(FetchApiHits(parameters)) :dispatch(FetchLatencies(parameters));
  }
   
  },[currentPastTimeRange,orgId,dispatch,currentApp,currentApiSet,chartName,currentSplitBy]);

  const renderLatencyContainer = () => {
    return (
      <React.Fragment >
         <Box className={classes.filterContainer}> 
         <div className={classes.labelContainer}>
            {chartLabel}
         </div>
            <FilterContainer 
            currentDomain={currentDomain}
            domains={true}
            labelName = {''}
            chartName = {chartName}
            currentApi= {currentApi}
            stats = {false}
            timeRange = {false}
            currentApp={currentApp}
            onCloseSelect ={onClose}
            isFilterApiproductsBydomain={true}
            apis ={true}
            apps ={true}
            pastTimeRange ={true}
            handleChange = {onChange} 
            currentTimeRange={''}
            currentStat={''}
            currentPastTimeRange={currentPastTimeRange}
            isRangeDisabled={false}
          />
       </Box>
       <Box className={classes.chartContainer}> 
          <AnalyticsChart 
              isLoading = {isDataLoading}
              chartOptions ={chartOptions}
              chartData = {chartData} 
              xAxisLabels = {xAxisLabels}
              yAxisLabels ={yAxisLabels}
            />
        </Box>   
      </React.Fragment >

    )
  }

  const renderContent = () => renderLatencyContainer()
  return renderContent();
};

export default LatencyContainer;
