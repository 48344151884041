import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import { createStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ClickWrapContent from '../../pages/terms/termscontent/clickwrapcontent';
import { useNavigate } from 'react-router';
import { useOktaAuth } from '@okta/okta-react';
import { useUserContext } from '../../UserContext';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullContainer: {
      minHeight: '100vh',
    },
    dialogTitle: {
      display: 'flex',
      justifyContent: 'center',
      fontWeight: 200,
    },
    dialogMessage: {
      display: 'flex',
      justifyContent: 'center',
      fontWeight: 100,
      textAlign: 'center',
    },
    pageTitle: {
      color: 'white',
      letterSpacing: '0.0625rem',
      fontWeight: 300,
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(4),
      textAlign: 'center',
    },
    pageDescription: {
      color: 'white',
      fontWeight: 400,
      width: '80%',
      fontSize: '1.125rem',
      marginBottom: theme.spacing(6),
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    dialogBorder: {
      border: 'none',
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      borderRadius: 10,
      overflow: 'auto',
    },
    content: {
      color: 'white',
      overflow: 'auto',
      fontWeight: 300,
      width: '100%',
      background: '#f1f1f114',
      padding: theme.spacing(2),
      fontSize: '1.125rem',
      maxHeight: 400,
      [theme.breakpoints.down('md')]: {
        width: '98%',
        '@media (orientation: landscape)': {
          maxHeight: 300,
        },
      },
      [theme.breakpoints.down('sm')]: {
        maxHeight: 300,
        '@media (orientation: landscape)': {
          maxHeight: 150,
        },
      },
      '&::-webkit-scrollbar': {
        width: '0.3em',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#00c4b4',
        outline: '1px solid slategrey',
      },
    },
    actionContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    paper: {
      background: '#0C1A29',
      position: 'absolute',
      width: '100%',
      borderRadius: '10px',
    },
    hyperLink: {
      color: '#00C4B4',
      cursor: 'pointer',
    },
  }),
);
interface Props {
  state: boolean;
  handleClose: any;
}

const Clickwrap: FC<Props> = ({ state, handleClose }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { oktaAuth } = useOktaAuth();
  const userDetails = useUserContext();
  // @ts-ignore
  const { setAuthUser } = userDetails;

  const onContactUsClick = async (event: any) => {
    try {
      localStorage.setItem('apo_redirect_url', '/contact-us');
      setAuthUser(false);
      localStorage.setItem('is-user-authenticated', '');
      localStorage.setItem('jwt', '');
      await oktaAuth.signOut();
    } catch (e) {
      console.log(' session call error', e);
    }
    event.preventDefault();
  };

  const onLogOutClick = async (event: any) => {
    try {
      localStorage.setItem('apo_redirect_url', '');
      await oktaAuth.signOut();
      setAuthUser(false);
      localStorage.setItem('is-user-authenticated', '');
      localStorage.setItem('jwt', '');
      navigate('/');
    } catch (e) {
      console.log(' session call error', e);
    }
    event.preventDefault();
  };
  return (
    <Dialog
      open={state}
      fullWidth
      maxWidth="md"
      keepMounted
      classes={{ paper: classes.paper }}
      data-cy="clickwrap-success-modal"
    >
      <div className={classes.dialogBorder}>
        <React.Fragment>
          <DialogTitle>
            <Typography variant="h6" className={classes.dialogTitle}>
              Before you continue
            </Typography>
            <Typography variant="body1" className={classes.dialogMessage}>
              {' '}
              We know it’s tempting to skip these Terms of Service, but it’s important to establish
              what you can expect from us as you use Northern Trust services, and what we expect from
              you.
            </Typography>
          </DialogTitle>
          <DialogContent className={classes.content}>
            <ClickWrapContent></ClickWrapContent>
          </DialogContent>
          <DialogActions className={classes.actionContainer}>
            <Button
              variant="contained"
              data-cy="acceptclickWrap"
              onClick={() => {
                handleClose();
              }}
              color="secondary"
            >
              ACCEPT & CONTINUE
            </Button>
          </DialogActions>
          <div className={classes.actionContainer}>
            <span>
            Got some questions ? {' '} 
            <a className={classes.hyperLink} onClick={(event)=>onContactUsClick(event)} href='#/'>
              {' '}
              Contact Us
            </a>
            , otherwise please{' '} 
            <a className={classes.hyperLink}   onClick={(event) => onLogOutClick(event)}  href='#/'>{' '}logout</a>
            </span>
          </div>
        </React.Fragment>
      </div>
    </Dialog>
  );
};
export default Clickwrap;
