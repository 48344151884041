import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useCookieContext } from '../../CookieContext';
import CookieMessage from '../../components/cookiemessage';
import { useUserContext } from '../../UserContext';
import ClickWrap from '../../components/clickwrap';
import LinearProgress from '@material-ui/core/LinearProgress';
import ErrorAlert from '../../components/erroralert';
import SessiontTmeout from '../../components/sessiontimeout';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullContainer: {
      minHeight: '100vh',
      paddingBottom: theme.spacing(10),
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
    container: {
      marginBottom: theme.spacing(5),
    },
    pageTitle: {
      color: 'white',
      letterSpacing: '0.0625rem',
      fontWeight: 300,
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
    },
    subTitle: {
      color: 'white',
      fontWeight: 300,
      marginBottom: theme.spacing(1),
    },
    sectionTitle: {
      color: '#05a8cc',
      fontWeight: 500,
      fontSize: 15,
    },
    bodyText: {
      color: 'white',
    },
    bar: {
      height: 1,
      backgroundColor: '#05a8cc',
    },
    box: {
      backgroundColor: '#1F2C41',
      borderRadius: 16,
      height: '100%',
      color: 'white',
      [theme.breakpoints.between(0, 285)]: {
        width: '50%',
      },
      '@media (orientation: landscape)': {
        width: '100%',
      },
    },
    codeSnippet: {
      wordWrap: 'break-word',
    },
    snippetRow: {
      alignItems: 'baseline',
      wordSpacing: 3,
    },

    containerGrid: {
      width: '100%',
    },

    link: {
      textDecoration: 'none',
      color: '#00C4B4',
    },
    sandboxLink: {
      display: 'inline-block',
      color: '#00C4B4',
      cursor: 'pointer',
    },
    bodyTextBold: {
      color: 'white',
      fontWeight: 600,
    },
    bodyTextImportant: {
      color: '#f57c00',
      marginLeft: 10,
    },
    buttonText: {
      //textTransform: 'none',
      textDecoration: 'none',
    },
  }),
);

const EventNotificationSetupPage: FC = () => {
  const classes = useStyles();
  const cookieDetails = useCookieContext();
  //@ts-ignore
  const { showCookieMessage } = cookieDetails;
  const userdetails = useUserContext();
  // @ts-ignore
  const {
    showClickWrap,
    exceptionClickWrap,
    loadingClickWrap,
    acceptClickWrapdetails,
    showSessionTimeoutModel,
  } = userdetails;

  const handleClickwrapClose = () => {
    acceptClickWrapdetails();
  };
  const CodeSnippetOne = () => {
    return (
      <Box className={classes.box} p={2}>
        <Typography variant="body1" className={classes.bodyText}>
          Example Call-back URL
        </Typography>
        <Typography color="secondary" className={classes.codeSnippet}>
          https://company.com/v1/event-notifications
        </Typography>
      </Box>
    );
  };
  const CodeSnippetTwo = () => {
    return (
      <Box className={classes.box} p={2}>
        <Typography variant="body1" className={classes.bodyText}>
          Example Event Notification Request
        </Typography>
        <Typography color="secondary" className={classes.codeSnippet}>
          POST /event-notifications HTTP/1.1
        </Typography>
        <Typography color="secondary" className={classes.codeSnippet}>
          Content-Type: application/jwt
        </Typography>
        <Grid item>
        <Typography color="secondary" className={classes.codeSnippet}>
        {String.fromCharCode(60)}your-encoded-jwt{String.fromCharCode(62)}
        </Typography>
        </Grid>
      </Box>
    );
  };
  const CodeSnippetThree = () => {
    return (
      <Box className={classes.box} p={2}>
        <Typography color="secondary" className={classes.codeSnippet}>
          {String.fromCharCode(123)}
        </Typography>
        <Box pl={2}>
          <Typography color="secondary" className={classes.codeSnippet}>
            "iss": "https://northerntrust.com/",
          </Typography>
          <Typography color="secondary" className={classes.codeSnippet}>
            "iat": 1516239022,
          </Typography>
          <Typography color="secondary" className={classes.codeSnippet}>
            "sub": "someone@lgim.com",
          </Typography>
          <Typography color="secondary" className={classes.codeSnippet}>
            "event": {String.fromCharCode(123)}
          </Typography>
          <Box pl={2}>
            <Typography color="secondary" className={classes.codeSnippet}>
              "eventType": "TA-ContractNote-Ready",
            </Typography>
            <Typography color="secondary" className={classes.codeSnippet}>
              "rid": "cntrnt-1234-007",
            </Typography>
            <Typography color="secondary" className={classes.codeSnippet}>
              "rty": "account-access-consent",
            </Typography>
            <Typography color="secondary" className={classes.codeSnippet}>
              "rlk": [{String.fromCharCode(123)}
            </Typography>
            <Box pl={2}>
              <Typography color="secondary" className={classes.codeSnippet}>
                "version": "v1",
              </Typography>
              <Typography color="secondary" className={classes.codeSnippet}>
                "link": "https://northerntrust.com/apo/v1/ContractNote/cntrnt-1234-007"
              </Typography>
            </Box>
            <Typography color="secondary" className={classes.codeSnippet}>
              {String.fromCharCode(125)}]
            </Typography>
          </Box>
          <Typography color="secondary" className={classes.codeSnippet}>
            {String.fromCharCode(125)}
          </Typography>
        </Box>
        <Typography color="secondary" className={classes.codeSnippet}>
          {String.fromCharCode(125)}
        </Typography>
      </Box>
    );
  };
  const CodeSnippetFour = () => {
    return (
      <Box className={classes.box} p={2}>
        <Typography color="secondary" className={classes.codeSnippet}>
          {String.fromCharCode(123)}
        </Typography>
        <Box pl={2}>
          <Typography color="secondary" className={classes.codeSnippet}>
            "alg": "HS256",
          </Typography>
          <Typography color="secondary" className={classes.codeSnippet}>
            "typ": "JWT"
          </Typography>
        </Box>
        <Typography color="secondary" className={classes.codeSnippet}>
          {String.fromCharCode(125)}
        </Typography>
      </Box>
    );
  };
  const CodeSnippetFive = () => {
    return (
      <Box className={classes.box} p={2}>
        <Typography variant="body1" className={classes.bodyText}>
          Example Signature
        </Typography>
        <Typography color="secondary" className={classes.codeSnippet}>
          HMACSHA256(
        </Typography>
        <Box pl={2}>
          <Typography color="secondary" className={classes.codeSnippet}>
            base64UrlEncode(header) + "." +
          </Typography>
          <Typography color="secondary" className={classes.codeSnippet}>
            base64UrlEncode(payload),
          </Typography>
          <Typography color="secondary" className={classes.codeSnippet}>
          {String.fromCharCode(60)}your-256-bit-secret{String.fromCharCode(62)}
          </Typography>
        </Box>
        <Typography color="secondary" className={classes.codeSnippet}>
          )
        </Typography>
      </Box>
    );
  };
  return (
    <React.Fragment>
      {loadingClickWrap && <LinearProgress />}
      <div className={classes.fullContainer}>
        <Container className={classes.container}>
          <Typography variant="h4" className={classes.pageTitle}>
            Event Notification Setup
          </Typography>
          <Grid container spacing={4} direction="column">
            <Grid item xs={6} sm={10} md={10} lg={10}>
              <Typography variant="body1" className={classes.bodyText}>
                Welcome to the Northern Trust API Store. This guide will help you get started with
                our Events and allow you to begin receiving the notifications based on the Event
                Subscriptions.
              </Typography>
            </Grid>
            {/** Event Notification Subscription section */}
            <Grid item xs={6} sm={8} md={8} lg={8}>
              <Typography variant="h5" className={classes.subTitle}>
                Event Notification Subscription
              </Typography>
              <div className={classes.bar} />
            </Grid>
            <Grid item xs={6} sm={10} md={10} lg={10}>
              <Typography variant="body1" className={classes.bodyText}>
                Event is a change in status due to a change in business process within Northern
                Trust. Event Notification is the communication that is initiated by NT to inform the
                client of this change.
              </Typography>
            </Grid>
            <Grid item xs={6} sm={10} md={10} lg={10}>
              <Typography variant="body1" className={classes.bodyText}>
                It is implemented using a Call-back URL approach.
              </Typography>
            </Grid>
            <Grid item xs={6} sm={8} md={8} lg={8}>
              <Typography variant="h6" className={classes.sectionTitle}>
                SUBSCRIPTION
              </Typography>
            </Grid>
            <Grid item xs={6} sm={10} md={10} lg={10}>
              <Typography variant="body1" className={classes.bodyText}>
                In order to subscribe to Event Notification. Clients’ needs to setup a Public POST
                Call-back End-point at their end and provide NT that URL.
              </Typography>
            </Grid>
            <Grid item xs={6} sm={10} md={10} lg={10}>
              <CodeSnippetOne />
            </Grid>
            <Grid item xs={6} sm={8} md={8} lg={8}>
              <Typography variant="h6" className={classes.sectionTitle}>
                NOTIFICATION
              </Typography>
            </Grid>
            <Grid item xs={6} sm={10} md={10} lg={10}>
              <Typography variant="body1" className={classes.bodyText}>
                Once Call-back URL has been added to NT event notification system, you will start
                receiving notifications form NT in JWT format.
              </Typography>
            </Grid>
            <Grid item xs={6} sm={10} md={10} lg={10}>
              <CodeSnippetTwo />
            </Grid>
            <Grid item xs={6} sm={10} md={10} lg={10}>
              <Typography variant="body1" className={classes.bodyText}>
                In order to process above information you would need a Certificate with Public
                Key(Provided by NT by email). You need to make sure that notification has originated
                from NT and discard it otherwise. To do that you should validate three separate
                pieces of information within JWT as mentioned below.
              </Typography>
            </Grid>

            <Grid item xs={6} sm={8} md={8} lg={8}>
              <Typography variant="h6" className={classes.sectionTitle}>
                1. Validate JWT Body
              </Typography>
            </Grid>
            <Grid item xs={6} sm={10} md={10} lg={10}>
              <Typography variant="body1" className={classes.bodyText} color="secondary">
                Once you open JWT message using Certificate with Public Key, you should see
                something like below.
              </Typography>
            </Grid>
            <Grid item xs={6} sm={10} md={10} lg={10}>
              <CodeSnippetThree />
            </Grid>
            <Grid item xs={6} sm={10} md={10} lg={10}>
              <Typography variant="body1" className={classes.bodyText}>
                You then, must ensure that the iat claim has a date-time value set in the past. And
                value in iss claim matches Northern Trust.
              </Typography>
            </Grid>
            <Grid item xs={6} sm={8} md={8} lg={8}>
              <Typography variant="h6" className={classes.sectionTitle}>
                2. Validate JWT Header
              </Typography>
            </Grid>
            <Grid item xs={6} sm={10} md={10} lg={10}>
              <Typography variant="body1" className={classes.bodyText}>
                You must validate that JWT header is a valid JSON object.
              </Typography>
            </Grid>
            <Grid item xs={6} sm={10} md={10} lg={10}>
              <CodeSnippetFour />
            </Grid>
            <Grid item xs={6} sm={10} md={10} lg={10}>
              <Typography variant="body1" className={classes.bodyText}>
                To do that you should check that typ header if specified has value JWT. And the
                value specified in alg is one of the asymmetric algorithms defined in &nbsp;
                <a
                  className={classes.link}
                  href="https://tools.ietf.org/html/rfc7518#section-3.1"
                  target="_blank"
                  rel="noreferrer"
                >
                  RFC 7518
                </a>
                .
              </Typography>
            </Grid>
            <Grid item xs={6} sm={8} md={8} lg={8}>
              <Typography variant="h6" className={classes.sectionTitle}>
                3. Verify the Signature
              </Typography>
            </Grid>
            <Grid item xs={6} sm={10} md={10} lg={10}>
              <Typography variant="body1" className={classes.bodyText}>
                The user must verify the signature, as defined in &nbsp;
                <a
                  className={classes.link}
                  href="https://tools.ietf.org/html/rfc7515#section-5.2"
                  target="_blank"
                  rel="noreferrer"
                >
                  RFC 7515
                </a>
                .
              </Typography>
            </Grid>
            <Grid item xs={6} sm={10} md={10} lg={10}>
              <CodeSnippetFive />
            </Grid>
          </Grid>
        </Container>
        {exceptionClickWrap && <ErrorAlert></ErrorAlert>}
        <CookieMessage state={showCookieMessage}></CookieMessage>
        <ClickWrap state={showClickWrap} handleClose={handleClickwrapClose}></ClickWrap>
        <SessiontTmeout state={showSessionTimeoutModel}></SessiontTmeout>
      </div>
    </React.Fragment>
  );
};

export default EventNotificationSetupPage;
