import React, { FC, useState } from 'react';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Soon from '../../soon';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useNavigate } from 'react-router';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import CookieMessage from '../../cookiemessage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(1),
      backgroundColor: 'rgba(256,256,256,0.1)',
      mixBlendMode: 'normal',
      borderRadius: '10px',
      minWidth: 300,
      height: '100%',
      width:'100%',
      position: 'relative',
    },
    shadowTopRight: {
      borderLeft: '76px solid transparent',
      borderRight: '0px solid transparent',
      borderBottom: '124px solid rgba(16,4,40,0.2)',
      height: 0,
      width: 0,
      position: 'absolute',
      right: 0,
      zIndex: 2,
      top: 0,
    },
    shadowTopLeft: {
      borderLeft: '0px solid rgba(16,4,40,0.1)',
      borderRight: '206px solid transparent',
      borderBottom: '50px solid rgba(16,4,40,0.1)',
      height: 0,
      width: 0,
      position: 'absolute',
      left: 0,
      zIndex: 2,
      top: '33%',
    },
    shadowBottomLeft: {
      borderLeft: '0px solid rgba(16,4,40,0.1)',
      borderBottom: '97px solid rgba(16,4,40,0.1)',
      height: 0,
      width: 0,
      position: 'absolute',
      left: 0,
      zIndex: 2,
      bottom: 0,
      borderRight: '350px solid rgba(16,4,40,0.1)',
    },
    shadowBottomRight: {
      borderLeft: '180px solid transparent',
      borderRight: '75px solid rgba(16,4,40,0.1)',
      borderBottom: '96px solid rgba(16,4,40,0.1)',
      height: 0,
      width: 0,
      position: 'absolute',
      right: 0,
      zIndex: 2,
      bottom: 0,
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      justifyContent: 'left',
    },
    cardHeader: {
      display: 'inline-block',
    },
    alignleft: {
      float: 'left',
    },
    alignright: {
      float: 'right',
    },
    title: {
      color: 'white',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    description: {
      color: 'white',
      overflow: 'hidden',
    },
    avatar: {
      color: '#00C4B4',
    },
    actions: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 5,
    },
    imgContainer: {
      height: 10,
      width: '100%',
      float: 'right',
    },
    prodImg: {
      objectFit: 'cover',
      objectPosition: 'center',
    },
    btn: {
      zIndex: 5,
    },

    version: {
      color: '#00C4B4',
      overflow: 'hidden',
    },
    border: {
      border: '1px solid #05595E',
      borderRadius: 10,
      height: '100%',
    },
    cardButton: {
      minWidth: 200,
      height: '100%',
      position: 'relative',
      width:'100%'
    },
    doclink: {
      position: 'absolute',
      bottom: theme.spacing(1.5),
      right: theme.spacing(2),
    },
  }),
);

interface Props {
  name: string;
  description: string;
  version: string;
  environment?: string;
  uuid: string;
  soon?: boolean;
  groupId: string;
  hidespec?: boolean;
}

const ApiCard: FC<Props> = ({
  name,
  description,
  version,
  environment = 'LIVE',
  uuid,
  soon,
  groupId,
  hidespec,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [showCookieMessage, setShowCookieMessage] = useState<boolean>(false);

  // @ts-ignore
  const navigateToSpec = (groupId: string, uuid: string, event: any) => {
    try {
      localStorage.getItem('apo_redirect_url');
      localStorage.getItem('jwt');
      navigate(`/groups/${groupId}/specs?api=${uuid}`);
    } catch (e) {
      setShowCookieMessage(true);
    }
    event.preventDefault();
  };
  return (
    <ButtonBase
      focusRipple
      key={uuid}
      disabled={hidespec}
      className={classes.cardButton}
      onClick={(event) => navigateToSpec(groupId, uuid, event)}
    >
      <Card className={classes.card} raised={true}>
        {soon && <Soon />}
        <div className={classes.border}>
          <CardContent>
            <div className={classes.cardContent}>
              <div className={classes.cardHeader}>
                <div className={classes.alignleft}>
                  <Typography variant="subtitle1" className={classes.description}>
                    {environment} | <span className={classes.version}>{version}</span>
                  </Typography>
                </div>
                {hidespec && (
                  <div className={classes.alignright}>
                    <div className={classes.imgContainer}>
                      <LockOutlinedIcon />
                    </div>
                  </div>
                )}
              </div>
              <Typography variant="h5" className={classes.title}>
                {name}
              </Typography>
              <Typography variant="body1" className={classes.description}>
                {description}
              </Typography>
              {!hidespec && (
                <Typography variant="button" color="primary" className={classes.doclink}>
                  Documentation
                </Typography>
              )}
            </div>
          </CardContent>
        </div>
      </Card>
      <CookieMessage state={showCookieMessage}></CookieMessage>
    </ButtonBase>
  );
};

export default ApiCard;
