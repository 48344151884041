import React, { FC } from 'react';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../theme';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
  journey: {
    minWidth: 298,
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    padding: theme.spacing(3),
    background: 'rgba(255, 255, 255, 0.07)',
    borderRadius: '10px',
    // transform: 'matrix(1, 0, 0, -1, 0, 0)',
    // height: '325px',
    position: 'relative',
    flexShrink: 0,
  },
  journeyBtn: {
    alignSelf: 'flex-end',
    marginTop: theme.spacing(4),
  },
  journeyTop: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: 200,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  iconHolder: {
    height: 150,
    width: 150,
    borderRadius: 75,
    borderWidth: 22,
    borderColor: 'rgba(256,256,256, 0.3)',
    borderStyle: 'solid',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleTxt: {
    color: 'white',
    alignSelf: 'flex-end',
  },
  titleDescription: {
    textAlign: 'center',
  },
});

interface Props {
  title: string;
  description: string;
  icon: any;
}

const JourneyCard: FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.journey}>
      <Card className={classes.card} raised>
        <div className={classes.journeyTop}>
          <div className={classes.iconHolder}>{props.icon}</div>
          <Typography variant="h6" className={classes.titleTxt}>
            {props.title}
          </Typography>
        </div>
        <Typography variant="body1" className={classes.titleDescription}>
          {props.description}
        </Typography>
      </Card>
    </div>
  );
};

export default JourneyCard;
