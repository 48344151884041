import React, { FC } from 'react';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { CardActions, CardContent } from '@material-ui/core';
import theme from '../../../theme';

const useStyles = makeStyles({
  journey: {
    minWidth: 298,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  card: {
    padding: theme.spacing(3),
    background: 'rgba(255, 255, 255, 0.07)',
    borderRadius: '10px',
    position: 'relative',
    flexShrink: 0,
    [theme.breakpoints.down('md')]: {
      height: 250,
    },
    height: 300,
  },
  journeyBtn: {
    alignSelf: 'flex-end',
    marginTop: theme.spacing(4),
  },
  journeyTop: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: theme.spacing(1),
  },
  iconHolder: {
    height: 150,
    width: 150,
    backgroundColor: '#0B021F',
    borderRadius: 75,
    borderWidth: 22,
    borderColor: 'rgba(256,256,256, 0.3)',
    borderStyle: 'solid',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  titleTxt: {
    color: 'white',
    fontWeight: 500,
    // width: '51%',
    textDecoration: 'none',
  },
  paragraphs: {
    fontSize: '1rem',
    color: 'white',
  },
  rule: {
    backgroundColor: '#05a8cc',
    height: 4,
    width: '30%',
    marginTop: theme.spacing(2),
  },
  link: {
    textDecoration: 'none',
  },
});

interface Props {
  title: string;
  subtitle?: string;
  to: string;
  pdf?: boolean;
}

const PolicyDetailsCard: FC<Props> = ({ title, subtitle, to, pdf = false }) => {
  const classes = useStyles();
  return (
    <div className={classes.journey}>
      {!pdf && (
        <Link to={to} className={classes.link}>
          <Card className={classes.card} raised>
            <CardContent>
              <div className={classes.journeyTop}>
                <Typography variant="h5" className={classes.titleTxt}>
                  {title}
                </Typography>
                <Typography className={classes.titleTxt}>{subtitle}</Typography>
                <div className={classes.rule} />
              </div>
              <Button variant="text" color="primary">
                Learn more
              </Button>
            </CardContent>
            <CardActions></CardActions>
          </Card>
        </Link>
      )}
      {pdf && (
        <Card className={classes.card} raised>
          <CardContent>
            <div className={classes.journeyTop}>
              <Typography variant="h5" className={classes.titleTxt}>
                {title}
              </Typography>
              <Typography className={classes.paragraphs}>{subtitle}</Typography>
              <div className={classes.rule} />
            </div>
            <Button variant="text" color="primary" target="_blank" href={to}>
              Learn more
            </Button>
          </CardContent>
          <CardActions></CardActions>
        </Card>
      )}
    </div>
  );
};

export default PolicyDetailsCard;
