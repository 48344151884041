import React, { FC, useState } from 'react';

import AppBar from '@material-ui/core/AppBar';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import Hidden from '@material-ui/core/Hidden';
import MobileDrawer from '../drawer';
import { Container } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router';
import { useOktaAuth } from '@okta/okta-react';
import { useUserContext } from '../../UserContext';
import { redirectToOktaUrl, clearTimers } from '../../utils/utils';
import { useCookieContext } from '../../CookieContext';
import logoImage from '../../assets/nt-stack-2-line.svg';
import bgImageOther from '../../assets/topbgHeader.png';
//import bgImage from '../../assets/topbgDefault.jpg';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      height: 120,
      display: 'flex',
      boxShadow: 'none',
      position:'fixed',
      top:0,
      left:0,
      zIndex:1000,
      backgroundImage: `linear-gradient(45deg, #000510, #000510)`,
      backgroundRepeat: 'no-repeat',
      backgroundSize:'cover',
      paddingTop: theme.spacing(2)
    },
    appBarother: {
      height: 120,
      display: 'flex',
      boxShadow: 'none',
      position:'fixed',
      top:0,
      left:0,
      zIndex:1000,
      backgroundColor:'#000510',
      backgroundImage: `url(${bgImageOther})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize:'cover',
      paddingTop: theme.spacing(2)
    },
    toolBar: {
      justifyContent: 'space-between',
    },
    logo: {
      marginRight: theme.spacing(10),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
    },
    logoImg: {
      width: theme.spacing(20),
      [theme.breakpoints.up('md')]: {
        width: theme.spacing(30),
      },
    },
    navs: {
      color: 'white',
      display: 'flex',
      justifyContent: 'space-around',
      flexGrow: 1,
    },
    navlinks: {
      fontWeight: 400,
    },
    link: {
      textDecoration: 'none',
      color: 'inherit',
    },
    forDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    forMobile: {
      display: 'flex',
    },
    menuButton: {
      marginLeft: theme.spacing(2),
    },
    active: {
      color: '#00C4B4',
    },
    logoPipe: {
      width: 1,
      height: 40,
      backgroundColor: 'white',
      marginRight: 20,
    },
  }),
);


const Header: FC = () => {
   const classes = useStyles();
  //@ts-ignore
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const handleDrawerToggle = (index: number) => setMobileOpen(!mobileOpen);
  const { pathname } = useLocation();
  const { oktaAuth } = useOktaAuth();
  const userDetails = useUserContext();
  const navigate = useNavigate();
  const cookieDetails = useCookieContext();
  //@ts-ignore
  const { setshowCookieMessage } = cookieDetails;
  // @ts-ignore
  const { authUser, setAuthUser } = userDetails;

  const onLogoClick = (event: any) => {
    // @ts-ignore
    try {
      localStorage.setItem('apo_redirect_url', '');
      navigate('/');
    } catch (e) {
      console.log(' session call error', e);
    }
    event.preventDefault();
  };

  const onLogOutClick = async (event: any) => {
    // @ts-ignore
    try {
      localStorage.setItem('apo_redirect_url', '');
      await oktaAuth.signOut();
      setAuthUser(false);
      clearTimers();
      localStorage.setItem('is-user-authenticated', '');
      localStorage.setItem('jwt', '');
      navigate('/');
    } catch (e) {
      console.log(' session call error', e);
    }
    event.preventDefault();
  };

  const onLoginClick = (event: any) => {
    // @ts-ignore
    try {
      if (!window.navigator.cookieEnabled) {
        setshowCookieMessage(true);
      } else {
        localStorage.setItem('apo_redirect_url', window.location.pathname || '/');
        redirectToOktaUrl();
      }
    } catch (e) { }
    event.preventDefault();
  };
  return (
    <React.Fragment>
      <AppBar position="fixed" color="transparent" className={pathname === '/'?classes.appBar: classes.appBarother}>
        <Container>
          <Toolbar className={classes.toolBar}>
            <Link to="/" onClick={(event) => onLogoClick(event)} className={classes.link}>
              <div id='HeaderLogo' className={classes.logo} data-testid='HeaderLogo'>
                <img
                  src={logoImage}
                  alt="Northern Trust logo"
                  className={classes.logoImg}
                  data-cy="logoImg"
                />
              </div>
            </Link>
            <Hidden mdDown={true} implementation="js">
              <div className={clsx(classes.navs, classes.forDesktop)} data-cy="navbar">
              {authUser ? (
              <Link
                  to="/account"
                  className={clsx(classes.link, pathname === '/account' ? classes.active : '')}
                >
                  <Typography variant="h6" className={classes.navlinks}>
                    My Account
                  </Typography>
                </Link>):null}
                <Link
                  to="/get-started"
                  className={clsx(classes.link, pathname === '/get-started' ? classes.active : '')}
                >
                  <Typography variant="h6" className={classes.navlinks}>
                    Get Started
                  </Typography>
                </Link>
                <Link
                  to="/apis"
                  state={{"main":true}}
                  className={clsx(classes.link, pathname === '/products' ? classes.active : '')}
                >
                  <Typography variant="h6" className={classes.navlinks}>
                    Explore APIs
                  </Typography>
                </Link>
                <Link
                  to="/events"
                  className={clsx(classes.link, pathname === '/events' ? classes.active : '')}
                >
                  <Typography variant="h6" className={classes.navlinks}>
                    Events
                  </Typography>
                </Link>
                <Link
                  to="/resources/releases-and-maintanence"
                  className={clsx(
                    classes.link,
                    pathname === '/resources/releases-and-maintanence' ? classes.active : '',
                  )}
                >
                  <Typography variant="h6" className={classes.navlinks}>
                    Resources
                  </Typography>
                </Link>

                <Link
                  to="/contact-us"
                  className={clsx(classes.link, pathname === '/contact-us' ? classes.active : '')}
                >
                  <Typography variant="h6" className={classes.navlinks}>
                    Contact Us
                  </Typography>
                </Link>

                {authUser ? (
                  <Link
                    to="/"
                    onClick={(event) => onLogOutClick(event)}
                    className={clsx(classes.link)}
                  >
                    <Typography variant="h6" className={classes.navlinks}>
                      Log Out
                    </Typography>
                  </Link>
                ) : (
                  <Link
                    to="/"
                    onClick={(event) => onLoginClick(event)}
                    className={clsx(classes.link)}
                  >
                    <Typography variant="h6" className={classes.navlinks}>
                      Log In
                    </Typography>
                  </Link>
                )}
              </div>
            </Hidden>
            <Hidden lgUp={true} implementation="js">
              <div className={classes.forMobile}>
                <IconButton
                  data-testid="mobilemenu"
                  data-cy="menuicon"
                  edge="start"
                  className={classes.menuButton}
                  color="default"
                  aria-label="open menu"
                  onClick={() => handleDrawerToggle(0)}
                >
                  <MenuIcon />
                </IconButton>
              </div>
            </Hidden>
          </Toolbar>
        </Container>
      </AppBar>
      <Hidden lgUp>
        <MobileDrawer mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
      </Hidden>
    </React.Fragment>
  );
};

export default Header;
