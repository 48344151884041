import React, { FC, useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import clsx from 'clsx';
import { CommonLayout } from '../../components/layout';
import { DetailList, FeatureList } from '../../components/lists';
import { ApiCard } from '../../components/cards';
import ErrorAlert from '../../components/erroralert';
import { useTypedDispatch, useTypedSelector, fetchGroup, clearGroup } from '../../store';
import { validUuid } from '../../utils/utils';
import NotFoundPage from '../notfound';
import { useCookieContext } from '../../CookieContext';
import { useUserContext } from '../../UserContext';
import CookieMessage from '../../components/cookiemessage';
import ClickWrap from '../../components/clickwrap';
import SessiontTmeout  from '../../components/sessiontimeout';

import fundaccountingtransactionsherojpg from '../../assets/fund-accounting/transactions/hero.jpg';
import fundaccountingtransactionshero from '../../assets/fund-accounting/transactions/hero.jpg';
import custodyactivitymonitoringherojpg from '../../assets/custody/activity-monitoring/hero.jpg';
import fundaccountingsupplementaldataherojpg from '../../assets/fund-accounting/supplemental-data/hero.jpg';
import custodytransactionsherojpg from '../../assets/custody/transactions/hero.jpg';
import custodyvaluationherojpg from '../../assets/custody/valuation/hero.jpg';
import fundaccountingholdingsherojpg from '../../assets/fund-accounting/holdings/hero.jpg';
import fundaccountingvaluationherojpg from '../../assets/fund-accounting/valuation/hero.jpg';
import riskandperformanceattributionsherojpg from '../../assets/risk-and-performance/attributions/hero.jpg';
import riskandperformanceholdingsherojpg from '../../assets/risk-and-performance/holdings/hero.jpg';
import riskandperformancemetricsherojpg from '../../assets/risk-and-performance/metrics/hero.jpg';
import transferagencyfunddetailsherojpg from '../../assets/transfer-agency/fund-details/hero.jpg';
import transferagencyinvestordetailsherojpg from '../../assets/transfer-agency/investor-details/hero.jpg';
import transferagencyinvestorholdingsherojpg from '../../assets/transfer-agency/investor-holdings/hero.jpg';
import transferagencyinvestortransactionsherojpg from '../../assets/transfer-agency/investor-transactions/hero.jpg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullContainer: {
      minHeight: '100vh',
      marginTop:120,
      paddingBottom: theme.spacing(10),
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
      position: 'relative',
    },
    groupTitleBox: {
      position: 'relative',
      paddingLeft: theme.spacing(3),
      minHeight: 340,
      [theme.breakpoints.down('sm')]: {
        minHeight: 400,
      },
    },
    groupTitleBG: {
      minHeight: 340,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      mixBlendMode: 'normal',
      opacity: 0.3,
      width: '100%',
      [theme.breakpoints.up('md')]: {
        // width: '120%',
      },
      [theme.breakpoints.down('sm')]: {
        minHeight: 400,
      },
      left: 0,
      position: 'absolute',
    },
    link: {
      textDecoration: 'none',
    },
    backBtn: {
      paddingTop: theme.spacing(4),
    },
    groupTitleDetails: {
      paddingLeft: theme.spacing(2),
    },
    pageTitle: {
      color: 'white',
      fontWeight: 400,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    pageDescription: {
      color: 'white',
      fontWeight: 400,
      fontSize: '1.125rem',
      marginBottom: theme.spacing(6),
    },
    details: {
      color: 'white',
    },
    subTitle: {
      color: 'white',
      fontWeight: 'bold',
      marginBottom: theme.spacing(2),
    },

    seperator: {
      height: 3,
      width: '100%',
      backgroundColor: '#05a8cc',
    },
    detailContainer: {
      padding: theme.spacing(3),
    },
    groupDetails: {
      height: 3,
      backgroundColor: 'red',
    },
    hseperator: {
      width: 1,
      backgroundColor: '#05a8cc',
      minHeight: 100,
      opacity: 0.6,
    },

    apis: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
  }),
);

const GroupDetailsPage: FC = () => {
  const classes = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const [exception] = useState<boolean>(false);
  const { group, loading } = useTypedSelector((state) => state.group);
  const dispatch = useTypedDispatch();
  const cookieDetails = useCookieContext();
  //@ts-ignore
  const { showCookieMessage } = cookieDetails;
  const urlpath = group && group.hero.toLowerCase().split("/").slice(6,9).join("").replace(/[.-]/g, '');
  const urlMapping : any = {
    "fundaccountingtransactionsherojpg":fundaccountingtransactionsherojpg,
    "fundaccountingtransactionshero":fundaccountingtransactionshero,
    "custodyactivitymonitoringherojpg":custodyactivitymonitoringherojpg, 
    "fundaccountingsupplementaldataherojpg":fundaccountingsupplementaldataherojpg,
    "custodytransactionsherojpg":custodytransactionsherojpg, 
    "custodyvaluationherojpg":custodyvaluationherojpg,  
    "fundaccountingholdingsherojpg":fundaccountingholdingsherojpg, 
    "fundaccountingvaluationherojpg":fundaccountingvaluationherojpg,  
    "riskandperformanceattributionsherojpg":riskandperformanceattributionsherojpg, 
    "riskandperformanceholdingsherojpg":riskandperformanceholdingsherojpg,  
    "riskandperformancemetricsherojpg":riskandperformancemetricsherojpg,  
    "transferagencyfunddetailsherojpg":transferagencyfunddetailsherojpg,  
    "transferagencyinvestordetailsherojpg":transferagencyinvestordetailsherojpg, 
    "transferagencyinvestorholdingsherojpg":transferagencyinvestorholdingsherojpg,
    "transferagencyinvestortransactionsherojpg":transferagencyinvestortransactionsherojpg,
  };
  const userdetails = useUserContext();
  // @ts-ignore
  const { showClickWrap, exceptionClickWrap, loadingClickWrap,acceptClickWrapdetails, showSessionTimeoutModel } = userdetails;

  useEffect(() => {
    dispatch(clearGroup());
    // @ts-ignore:
    if (validUuid(id)) {
      // @ts-ignore:
      dispatch(fetchGroup(id));
    } else {
      navigate('/notFound');
    }
    // @ts-ignore:
  }, [id, dispatch, navigate]);
  const handleClickwrapClose = () => {
    acceptClickWrapdetails();
  };
  const goBack = () => {
    dispatch(clearGroup());
    navigate('/apis');
  };

  return (group && group.uuid !== '') || loading ? (
    <React.Fragment>
      
      <CommonLayout>
      <div className={classes.fullContainer}>
        {((loading || loadingClickWrap)) && <LinearProgress />}
          <Container maxWidth="md">
            <div className={classes.groupTitleBox}>
              {group && (

                <div
                  className={classes.groupTitleBG}
                  style={{
                    backgroundImage: `url(${urlMapping[urlpath]})`,
                  }}
                />
              )}
              <div className={classes.backBtn}>
                {/* <Link to="/apis" className={classes.link}> */}
                <Button variant="text" color="primary" onClick={goBack}>
                  <ArrowBackIosIcon fontSize="small" />
                  BACK
                </Button>
                {/* </Link> */}
              </div>
              {group && (
                <div className={classes.groupTitleDetails}>
                  <Typography variant="h4" className={classes.pageTitle}>
                    {`${group.product} ${group.name}`}
                  </Typography>
                  <Typography variant="body1" className={classes.pageDescription}>
                    {group.description}
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => navigate('/get-started')}
                  >
                    GET STARTED
                  </Button>
                </div>
              )}
            </div>
            <div className={classes.seperator} />
            {group && (
              <div className={classes.detailContainer}>
                <Grid container spacing={4}>
                  {group.about !== '' && (
                    <Grid item sm={10} md={5}>
                      <Typography variant="body1" className={classes.subTitle}>
                        ABOUT {group.product.toUpperCase()} {group.name.toUpperCase()}
                      </Typography>
                      <Typography variant="body1" className={classes.details}>
                        {group.about}
                      </Typography>
                    </Grid>
                  )}
                  {group.details?.length > 0 && (
                    <Grid item sm={6} md={3}>
                      <Box display="flex">
                        <Hidden smDown={true}>
                          <div className={classes.hseperator} />
                        </Hidden>
                        <Box display="flex" flexDirection="column">
                          {group.details.map((eachDetail, index) => (
                            <DetailList
                              iconkey={eachDetail?.icon}
                              key={index}
                              list={eachDetail?.title}
                            />
                          ))}
                        </Box>
                      </Box>
                    </Grid>
                  )}
                  {group.features.length > 0 && (
                    <Grid item sm={6} md={4}>
                      <Box display="flex">
                        <Hidden xsDown={true}>
                          <div className={classes.hseperator} />
                        </Hidden>

                        <Box display="flex" flexDirection="column">
                          {group.features.map((eachFeature, index) => (
                            <FeatureList key={index} list={eachFeature} />
                          ))}
                        </Box>
                      </Box>
                    </Grid>
                  )}
                </Grid>

                <Grid container spacing={4}>
                  <Grid item sm={10} md={8}>
                    <Typography variant="body1" className={classes.subTitle}>
                      REGIONAL AVAILABILITY
                    </Typography>
                    {group && (
                      <Typography variant="body1" className={classes.details}>
                        {group.availability}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item md={8}>
                    <Typography variant="body1" className={clsx(classes.subTitle, classes.apis)}>
                      APIs IN THIS GROUP
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  {group.apis !== undefined &&
                    Object.keys(group.apis).map((eachApi, index) => {
                      const api = group.apis[eachApi];
                      return (
                        <Grid key={index} item xs={10} sm={6} md={6}>
                          <ApiCard
                            name={api.name}
                            description={api.description}
                            version={api.version}
                            uuid={eachApi}
                            soon={api.soon}
                            groupId={group.uuid}
                            hidespec={api.hidespec}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </div>
            )}
          </Container>
          {(exception || exceptionClickWrap) && <ErrorAlert />}
        </div>
      </CommonLayout>
      <CookieMessage state={showCookieMessage} ></CookieMessage>
      <ClickWrap state={showClickWrap} handleClose={handleClickwrapClose}></ClickWrap>
      <SessiontTmeout state={showSessionTimeoutModel}></SessiontTmeout>
    </React.Fragment>
  ) : (
    <React.Fragment>
      {(exception || exceptionClickWrap) && <ErrorAlert />}
      <NotFoundPage />
    </React.Fragment>
  );
};

export default GroupDetailsPage;
