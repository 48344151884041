import { createStyles, IconButton, makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import clsx from 'clsx';
import React, { PropsWithChildren, useEffect } from 'react';
import { useAccordionTable } from './context';
import { useTypedSelector } from '../../store';
import { getEventsCount } from '../../selectors/selectors';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    trView: {
      position: 'relative',
      borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
      '& td': {
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 20,
        paddingRight: 20,
        textAlign: 'left'
      },
      '&.active': {
        backgroundColor: '#25334a',
        borderRadius: '0px 20px 20px 0px',
        borderBottom: 'none'
      }
    },
    trViewMobile: {
      position: 'relative',
      borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
      '& td': {
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 0,
        paddingRight: 0,
        textAlign: 'left'
      },
      '&.active': {
        backgroundColor: '#25334a',
        borderRadius: '0px 20px 20px 0px',
        borderBottom: 'none'
      }
    },
    trFold: {
      display: 'none',
      '&.active': {
        display: 'table-row',
        backgroundColor: 'rgb(126 192 249 / 21%)',
        borderBottom: '#4c5564'
      },
      '& td': {
        paddingTop: 0,
      }
    },
    showMoreButton: {
      position: 'absolute',
      right: 0,
      top: 'calc(50% - 15px)'
    },
    showMoreButtonMobile: {
      position: 'absolute',
      right: -20,
      top: 'calc(50% - 15px)'
    },
    accordionContent: {
      backgroundColor:'#25334a'
    }
  }));

export type TableRowProps = {
  activeId: string;
  disabled?: boolean;
  tableRowContent: () => JSX.Element;
  accordionContent: () => JSX.Element;
}

export const TableRow: React.FC<TableRowProps> =
  (props: PropsWithChildren<TableRowProps>) => {
    const classes = useStyles();
    const isSmallScreen = useMediaQuery((theme:any) => theme.breakpoints.down("sm"));
    const totalcount = useTypedSelector(state => getEventsCount(state));
    const {
      tableRowContent: TableRowContent,
      accordionContent: AccordionContent
    } = props;

    const {
      activeId,
      toggleActive,
      updateActive,
      numColumns
    } = useAccordionTable();

    const showMoreClick = () => {
      toggleActive(props.activeId);
    };

    useEffect(() => {
      if(localStorage.getItem("currenteEventId") !=='' && totalcount !== 0 ){
        const currenteEventId= localStorage.getItem("currenteEventId");
         //@ts-ignore
         updateActive(currenteEventId);
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [totalcount,localStorage.getItem("currenteEventId")]);

    const isActive = activeId === props.activeId;
    
    const renderMoreIconButton = () => (
      <IconButton
        size="small"
        className={isSmallScreen?classes.showMoreButtonMobile:classes.showMoreButton}
        name='showMore'
        disabled={props.disabled}
        onClick={() => showMoreClick()}>
        <MoreVertIcon />
      </IconButton>
    );

    return (
      <React.Fragment>
        <tr
          className={clsx(
            isSmallScreen?classes.trViewMobile:classes.trView,
            isActive && 'active'
          )}>
          <TableRowContent />
          {renderMoreIconButton()}
        </tr>
        <tr className={clsx(
          classes.trFold,
          isActive && 'active'
        )}>
          <td colSpan={numColumns}>
            <div className={classes.accordionContent}>
              <AccordionContent />
            </div>
          </td>
        </tr>
      </React.Fragment>
    );
  };
  