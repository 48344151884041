import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useTypedSelector } from '../../store';
import FilterContainer from '../../components/filterContainer';
import { useDispatch } from 'react-redux';
import { getUsage, getState } from '../../selectors/selectors';
import AnalyticsChart from '../../components/analyticsChart';
import { FetchQuata, ConsumptionDetails, TimeBy, TimeRangeMapping, QuotaSplitMapping } from '../../store/apiUsageSlice';

import { GenericObject, SplitByMapping, xAxisLabelMapping } from '../../store/latencySlice';
import moment from 'moment';
import { displayDate } from '../../utils/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
   noAppsInfoTextContainer: {
      display: 'flex',
      width: '100%',
      paddingTop: 16,
      paddingBottom: 16,
      [theme.breakpoints.down('md')]: {
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 8,
        paddingBottom: 8
      },
      paddingLeft: 20,
      paddingRight: 20,
      flexDirection: 'column'
    },
    filterContainer: {
      display: 'flex',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        paddingLeft: 10,
        paddingRight: 10,
      },
      paddingLeft: 20,
      paddingRight: 20,
      flexDirection: 'column',
      marginTop:'0.5rem',
      marginBottom:'0.5rem'
    },
    QuataContainer: {
     display:'flex',
     alignItems:'center',
     justifyContent:'center'
    },
    noAppsInfoBody: {
      color: '#ffffff',
      fontWeight: 300,
      marginTop:10
    },
    loader:{
      display:'flex',
      alignItems:'center',
      justifyContent:'center'

    },
    chartContainer:{
      width: '100%',
      height:320,
      padding:10,
      [theme.breakpoints.down('md')]: {
        padding:5
      },
    },
  }));

const QuataContainer: React.FC<{}> = () => {
  const classes = useStyles();
  const usageDetails = useTypedSelector(state => getUsage(state));
  const { userDetails } = useTypedSelector(state => getState(state));
  const timeBy : TimeBy = TimeRangeMapping;
  const splitBy : GenericObject = SplitByMapping;
  const QuotaSplitBy : GenericObject = QuotaSplitMapping;
  const orgId =  userDetails.orgUuid ||'';
 
  const dispatch = useDispatch();
  const { loading , data} =  usageDetails.quata;
  const consumptionPoints = data?.consumptionPoints || {};
  const [currentPastTimeRange,setCurrentPastTimeRange] = useState('month');
  const currentSplitBy = timeBy[currentPastTimeRange];
  const currentTime = splitBy[currentPastTimeRange];
  const currentQutaSplit =QuotaSplitBy[currentPastTimeRange];
  const xAxisLabelMap: GenericObject = xAxisLabelMapping;
  const currentXLabel = xAxisLabelMap[currentPastTimeRange];
  const chartOptions = { 
    legend: {
      display: false,
      
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      position: "nearest",
      layout: {
        padding: {
           bottom: 50,  
           top:50
        }
      },
      titleFontColor:'#3f4142',
      titleFontStyle:'normal',
      yAlign: 'top',
      callbacks: {
      title: function(context:any) {
        const label = moment(context[0].xLabel).format("MMMM Do YYYY, h:mm:ss")
        return label;
      }, 
      labelColor: function(context:any) {
         return {
              borderColor: '#e1fcff',
              backgroundColor: '#e1fcff',
              borderWidth: 2
          };
        },
        labelTextColor: function(context:any) {
         return '#3f4142';
        }
      },
    backgroundColor: '#e1fcff',
    borderColor:'red'
  },
  plugins: {
      legend: {
        display: false
      },
      tooltip:{
        enabled:false
      } 
      
    },
    scales: {
      yAxes: [{
        gridLines: {
          display : true,
          color:'#576378'
          },
          ticks: {
              fontColor: "white",
              beginAtZero: true,
              fontStyle:'normal',
              fontSize:12
          },
          scaleLabel: {
            display: true,
            labelString: 'Quota Consumption (%)',
            fontColor:'white',
            fontStyle:'normal'
          }
      }],
      xAxes: [{
          gridLines: {
          display : false,
          },
          ticks: {
              fontColor: "white",
              callback: function(value:string, index:any, values: any) {
                    return displayDate(value,currentPastTimeRange);
                }
          },
          scaleLabel: {
            display: true,
            labelString: currentXLabel,
            fontColor:'white'
            
          }
      }]
  }
  };
  const xAxisLabels = Object.values(consumptionPoints).map((consupmtion: ConsumptionDetails) =>{
    return consupmtion.date;
  });
  const yAxisLabels  = Object.values(consumptionPoints).map((consupmtion: ConsumptionDetails) =>{
    return consupmtion.consumption
   });
  const chartData = {
    labels: xAxisLabels,
      datasets: [
        {
          label: 'Quota Consumption',
          data: yAxisLabels,
          lineTension: 0,
          backgroundColor: [
            "#00dec5"
          ],
          borderColor: "#00dec5",
          fill: false,
          borderWidth: 1,
          hoverBackgroundColor: "rgba(232,105,90,0.8)",
          hoverBorderColor: "orange",
          scaleStepWidth: 1,
        }
      ]
    };
  const onChange = (e :any) =>{
    switch(e.target.name){
      case "pastTimeRange": setCurrentPastTimeRange(e.target.value); 
                            break;
     }
   
  };
  useEffect(()=>{
    if(orgId?.length > 0){
      const parameters= {
        orgId:orgId,
        time:currentTime,
        timerange:currentPastTimeRange,
        split:currentQutaSplit
      };
      dispatch(FetchQuata(parameters))
  
    }
   
  },[currentPastTimeRange,orgId,dispatch,currentSplitBy,currentTime,currentQutaSplit]);

  const renderQuataContainer = () => {
    return (
      <React.Fragment >
         <Box className={classes.filterContainer}> 
            <FilterContainer 
            chartName={'Traffic'}
            currentDomain={''}
            domains={false}
            isFilterApiproductsBydomain={false}
            labelName = {'QUOTA CONSUMPTION'}
            currentApi= {['']}
            onCloseSelect ={()=>{}}
            stats = {false}
            timeRange = {false}
            currentApp={''}
            apis ={false}
            apps ={false}
            pastTimeRange ={true}
            handleChange = {onChange} 
            currentTimeRange={''}
            currentStat={''}
            currentPastTimeRange={currentPastTimeRange}
            isRangeDisabled={false}
          />
       </Box>
       <Box className={classes.chartContainer}> 
          <AnalyticsChart 
              isLoading = {loading}
              chartOptions ={chartOptions}
              chartData = {chartData} 
              xAxisLabels = {xAxisLabels}
              yAxisLabels ={yAxisLabels}
            />
        </Box>   
      </React.Fragment >

    )
  }

  const renderContent = () => renderQuataContainer()
  return renderContent();
};

export default QuataContainer;
