import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '../utils';
export type TimeBy = {
  [key: string]: string;
};
export const TimeRangeMapping = {
  day: "by-day",  
  hour: "by-hour",
  week: "by-week", 
  month: "by-month",
  year: "by-year" 
} ;
export const QuotaSplitMapping = {
  day: "daily",  
  week: "daily", 
  month: "daily"
} ;
export type usageParamters = {
  orgId?: string;
  timerange: string;
};
export type quataParameters = {
  orgId?: string;
  time: string;
  split:string;
  timerange:string;
};
export type hitsParameters = {
  orgId?: string;
  timerange: string;
  appIds:string;
  apiIds:string[];
  split:string;
};
export type ConsumptionDetails = {
  id:string;
  date:string;
  consumption:number;
};
export type apiHitsDetails = {
  id:string;
  date:string;
  responseTime:number;
};
export type apiHitsCollection = {
  [key: string]: apiHitsDetails;
};
export type latenciesCollection = {
  [key: string]: apiHitsDetails;
};
export type ApiHitsData = {
  uuid: string;
  name: string;
  hits?:apiHitsCollection;
};
export type LatenciesData = {
  uuid: string;
  name: string;
  latencies?:latenciesCollection;
};
export type ConsumptionCollection = {
  [key: string]: ConsumptionDetails;
};

export type GetUsageResponse = {
  apiHits:string;
  averageAPILatency:string;
  errorRate:string;
};
export type GetQuataResponse = {
  currentConsumption:string;
  consumptionPoints?:ConsumptionCollection;
};
export type GetHitsResponse = {
  [key: string]: ApiHitsData;
};
export type GetLatencisResponse = {
  [key: string]: LatenciesData;
};


export interface UsageState {
  usage:{
    data: GetUsageResponse;
    loading: boolean;
  },
  quata:{
    data?:GetQuataResponse,
    loading:boolean;
  },
  apiHits:{
    data?:GetHitsResponse
    loading:boolean;
  },
  latencies:{
    data?:GetLatencisResponse
    loading:boolean;
  }
}
type FetchUsageError = {
  status: number;
  message: string
};

const initialState: UsageState = {
  usage:{
    data: {
      apiHits:'0',
      averageAPILatency:'0.0ms',
      errorRate:'0.0%'
    },
    loading:false
  },
  quata:{
    data:{
      currentConsumption:'0',
      consumptionPoints:{}
    },
    loading:false
  },
  apiHits:{
    data:{},
    loading:false
  },
  latencies:{
    data:{},
    loading:false
  }
};
export const FetchUsage = createAsyncThunk<
  GetUsageResponse,
  usageParamters,
  {
    rejectValue: FetchUsageError  
  }
>(
  'apiUsage/FetchUsage',
  async ({timerange,orgId}, thunkApi) => {

    try {
        const response = await get(`/bff-private/metrics/api-usages?orgId=${orgId}&timerange=${timerange}`);
        return response as GetUsageResponse
    }
    catch (error: any) {
      return thunkApi.rejectWithValue({
        status: error.status,
        message: error.message
      })
    }
  }
);
export const FetchQuata = createAsyncThunk<
  GetQuataResponse,
  quataParameters,
  {
    rejectValue: FetchUsageError  
  }
>(
  'apiUsage/FetchQuata',
  async ({time,orgId,split,timerange}, thunkApi) => {

    try {
        const response = await get(`/bff-private/metrics/api-quotas?orgId=${orgId}&time=${time}&split=${split}&timerange=${timerange}`);
        return response as GetQuataResponse
    }
    catch (error: any) {
      return thunkApi.rejectWithValue({
        status: error.status,
        message: error.message
      })
    }
  }
);
export const FetchApiHits = createAsyncThunk<
  GetHitsResponse,
  hitsParameters,
  {
    rejectValue: FetchUsageError  
  }
>(
  'apiUsage/FetchApiHits',
  async ({timerange, orgId, appIds, apiIds, split}, thunkApi) => {

    try {
      const response = await get(`/bff-private/metrics/api-hits?orgId=${orgId}&timerange=${timerange}&appIds=${appIds}&apiIds=${apiIds}&split=${split}`);
      return response as GetHitsResponse
        
    }
    catch (error: any) {
      return thunkApi.rejectWithValue({
        status: error.status,
        message: error.message
      })
    }
  }
);
export const FetchLatencies = createAsyncThunk<
  GetLatencisResponse,
  hitsParameters,
  {
    rejectValue: FetchUsageError  
  }
>(
  'apiUsage/FetchLatencies',
  async ({timerange, orgId, appIds, apiIds, split}, thunkApi) => {

    try {
        const response = await get(`/bff-private/metrics/api-latencies?orgId=${orgId}&timerange=${timerange}&appIds=${appIds}&apiIds=${apiIds}&split=${split}`);
        return response as GetHitsResponse
      }
    catch (error: any) {
      return thunkApi.rejectWithValue({
        status: error.status,
        message: error.message
      })
    }
  }
);
export const apiUsageSlice = createSlice({
  name: 'apiUsage',
  initialState,
  reducers: {
    clearApiHitsData: (state) => {
      state.apiHits.data =  {};
    },
    clearLatenciesData: (state) => {
      state.latencies.data =  {};
    }
   },
  extraReducers: (builder) => {
    builder.addCase(FetchUsage.rejected, (state) => {
      state.usage.loading = false;
      state.usage.data =  {
        apiHits:'0',
        averageAPILatency:'0.0ms',
        errorRate:'0.0%'
      }; 
    });
   builder.addCase(FetchUsage.pending, (state) => {
      state.usage.loading = true;
    });
    builder.addCase(FetchUsage.fulfilled, (state, { payload }) => {
      state.usage.loading = false;
      state.usage.data = payload ?? {};
    });
    builder.addCase(FetchQuata.fulfilled, (state, { payload }) => {
      state.quata.loading = false;
      state.quata.data = payload ?? {};
    
    });
    builder.addCase(FetchQuata.pending, (state) => {
      state.quata.loading = true;
    });
    builder.addCase(FetchQuata.rejected, (state) => {
      state.quata.loading = false;
      state.quata.data =  {
        currentConsumption:"0",
        consumptionPoints: {}
      }; 
    });
    builder.addCase(FetchApiHits.pending, (state) => {
      state.apiHits.loading = true;
    });
    builder.addCase(FetchApiHits.rejected, (state) => {
      state.apiHits.loading = false;
      state.apiHits.data =  {};
    });
    builder.addCase(FetchApiHits.fulfilled, (state, { payload }) => {
      state.apiHits.loading = false;
      state.apiHits.data = payload ?? {};
    });
    builder.addCase(FetchLatencies.pending, (state) => {
      state.latencies.loading = true;
    });
    builder.addCase(FetchLatencies.rejected, (state) => {
      state.latencies.loading = false;
      state.latencies.data =  {};
    });
    builder.addCase(FetchLatencies.fulfilled, (state, { payload }) => {
      state.latencies.loading = false;
      state.latencies.data = payload ?? {};
    });
  }
});

export const { actions: latencyActions, reducer: apiUsageReducer } = apiUsageSlice;

export const { clearApiHitsData,clearLatenciesData } = latencyActions;
