import React, { FC, useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import { ContactLayout } from '../../components/layout';
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Controller, useForm } from 'react-hook-form';
import { post, phoneNumberRegex, emailRegex, trapSpacesForRequiredFields, concatName } from '../../utils';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import DialogActions from '@material-ui/core/DialogActions';
import { useCookieContext } from '../../CookieContext';
import { useUserContext } from '../../UserContext';
import CookieMessage from '../../components/cookiemessage';
import ClickWrap from '../../components/clickwrap';
import LinearProgress from '@material-ui/core/LinearProgress';
import ErrorAlert from '../../components/erroralert';
import SessiontTmeout from '../../components/sessiontimeout';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullContainer: {
      minHeight: '100vh'
    },
    container: {},
    pageTitle: {
      color: 'white',
      letterSpacing: '0.0625rem',
      fontWeight: 300,
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(4),
    },
    pageDescription: {
      color: 'white',
      fontWeight: 400,
      width: '80%',
      fontSize: '1.125rem',
      marginBottom: theme.spacing(6),
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },

    options: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    dialogBorder: {
      border: '1px solid #05a8cc',
      margin: theme.spacing(2),
      padding: theme.spacing(2),
      borderRadius: 10,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(3),
      top: theme.spacing(3),
      color: theme.palette.grey[500],
    },
    content: {
      color: 'white',
      fontWeight: 400,
      width: '80%',
      fontSize: '1.125rem',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    actionContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    paper: {
      backgroundImage:
        'linear-gradient(180deg, rgba(12,26,41,0.9948354341736695) 33%, rgba(12,26,41,0.9948354341736695) 97%)',
    },
  }),
);

interface IFormInput {
  category: string;
  email: string;
  name: string;
  organisationName: string;
  sponsorCode: string;
  phoneNumber: string;
  dataDomain: string;
  location: string;
  details: string;
}

const ContactUsPage: FC = () => {
  const classes = useStyles();
  const [formError, setformError] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const cookieDetails = useCookieContext();
  const {
    isAuthenticated,
    userDetails,
    showClickWrap,
    exceptionClickWrap,
    loadingClickWrap,
    acceptClickWrapdetails,
    showSessionTimeoutModel
  } = useUserContext();
  const ariaLabel = 'aria-label';
  //@ts-ignore
  const { showCookieMessage } = cookieDetails;
  const handleClickwrapClose = () => {
    acceptClickWrapdetails();
  };

  const { firstName, lastName, email, organisationId, sponsorCode } = userDetails;

  const { control, handleSubmit, reset, errors, setValue } = useForm<IFormInput>({
    defaultValues: {
      name: concatName(firstName, lastName),
      email: email ?? '',
      organisationName: organisationId ?? '',
      sponsorCode: sponsorCode ?? '',
      phoneNumber: '',
      dataDomain: '',
      location: '',
      details: '',
    },
  });

  useEffect(() => {
    setValue('name', concatName(firstName, lastName));
    setValue('email', email ?? '');
    setValue('organisationName', organisationId ?? '');
    setValue('sponsorCode', sponsorCode ?? '');
  }, [email, firstName, lastName, sponsorCode, organisationId, setValue]);

  const
    onSubmit = async (data: IFormInput) => {
      console.log("true");
      setLoading(true);
      setformError('');

      const contactUrl = isAuthenticated
        ? '/bff-private/email'
        : '/bff/email';

      const response = await post(contactUrl, {
        category: 'API Store Enquiry',
        name: data.name,
        email: data.email,
        organisationName: data.organisationName,
        phoneNumber: data.phoneNumber,
        sponsorCode: data.sponsorCode,
        dataDomain: data.dataDomain,
        location: data.location,
        details: data.details,
      });
      if (response.error !== undefined) {
        setformError(response.error.message);
        setLoading(false);
      } else {
        setLoading(false);
        setOpen(true);
        reset();
      }
    };

  return (
    <React.Fragment>
      {loadingClickWrap && <LinearProgress />}
      <ContactLayout>
        <div className={classes.fullContainer}>
          <Container maxWidth="md" className={classes.container}>
            <Typography variant="h4" className={classes.pageTitle}>
              Contact Us
            </Typography>
            <Typography variant="body2" className={classes.pageDescription}>
              We want to hear from you! We’re here to help and answer any questions you might have.
              Please fill out the contact form below and we will be in touch as soon as possible.
            </Typography>

            <Grid container item spacing={2} data-cy="contactus-form" xs={12} md={10}>
              <React.Fragment>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="name"
                    control={control}
                    rules={{
                      required: true,
                      validate: trapSpacesForRequiredFields,
                    }}
                    render={({ onChange, value }) => (
                      <FormControl fullWidth>
                        <TextField
                          id="name"
                          type="name"
                          label="Full Name"
                          required
                          fullWidth
                          variant="filled"
                          value={value}
                          onChange={onChange}
                          inputProps={{ [ariaLabel]: 'Please enter your name' }}
                          error={errors.name !== undefined}
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: true,
                      pattern: emailRegex,
                    }}
                    render={({ onChange, value }) => (
                      <FormControl fullWidth>
                        <TextField
                          id="email-address"
                          type="email"
                          label="Work Email"
                          required
                          fullWidth
                          variant="filled"
                          value={value}
                          onChange={onChange}
                          inputProps={{ [ariaLabel]: 'Please enter your email address' }}
                          error={errors.email !== undefined}
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    rules={{
                      required: true,
                      pattern: phoneNumberRegex,
                    }}
                    render={({ onChange, value }) => (
                      <FormControl fullWidth>
                        <TextField
                          id="phone-number"
                          type="phoneNumber"
                          label="Phone Number"
                          required
                          fullWidth
                          variant="filled"
                          value={value}
                          onChange={onChange}
                          inputProps={{ [ariaLabel]: 'Please enter your phone number' }}
                          error={errors.phoneNumber !== undefined}
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="organisationName"
                    control={control}
                    rules={{
                      required: true,
                      validate: trapSpacesForRequiredFields,
                    }}
                    render={({ onChange, value }) => (
                      <FormControl fullWidth>
                        <TextField
                          id="organisation-name"
                          type="organisationName"
                          label="Organization"
                          required
                          fullWidth
                          variant="filled"
                          value={value}
                          onChange={onChange}
                          inputProps={{ [ariaLabel]: 'Please enter your organisation name' }}
                          error={errors.organisationName !== undefined}
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="location"
                    control={control}
                    rules={{ required: true }}
                    render={({ onChange, value }) => {
                      return (
                        <FormControl fullWidth error={errors.location !== undefined}>
                          <InputLabel
                            id="labelLocation"
                            required
                            style={{ paddingLeft: 10 }}>
                            Location
                          </InputLabel>
                          <Select
                            fullWidth
                            variant="filled"
                            value={value}
                            onChange={onChange}
                            required
                            id="location"
                            labelId="labelLocation"
                            inputProps={{
                              [ariaLabel]: 'Please choose your location',
                              'data-testid': 'labelLocation'
                            }}
                          >
                            <MenuItem value={'Americas'}>Americas</MenuItem>
                            <MenuItem value={'Europe & Middle East'}>Europe & Middle East</MenuItem>
                            <MenuItem value={'Asia Pacific'}>Asia Pacific</MenuItem>
                          </Select>
                        </FormControl>
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="dataDomain"
                    control={control}
                    render={({ onChange, value }) => {
                      return (
                        <FormControl fullWidth>
                          <InputLabel
                            id="labelDataDomain"
                            style={{ paddingLeft: 10 }}>
                            APIs
                          </InputLabel>
                          <Select
                            fullWidth
                            variant="filled"
                            value={value}
                            onChange={onChange}
                            id="dataDomain"
                            labelId="labelDataDomain"
                            inputProps={{
                              [ariaLabel]: 'Please choose your business area of interest',
                              'data-testid': 'dataDomain'
                            }}
                          >
                            <MenuItem value={'Banking and Treasury'}>Banking and Treasury</MenuItem>
                            <MenuItem value={'Custody'}>Custody</MenuItem>
                            <MenuItem value={'Fund Accounting'}>Fund Accounting</MenuItem>
                            <MenuItem value={'Middle Office'}>Middle Office</MenuItem>
                            <MenuItem value={'Transfer Agency'}>Transfer Agency</MenuItem>
                            <MenuItem value={'Risk & Performance'}>Risk & Performance</MenuItem>
                            <MenuItem value={'General'}>General/Other</MenuItem>
                          </Select>
                        </FormControl>
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="sponsorCode"
                    control={control}
                    render={({ onChange, value }) => (
                      <FormControl fullWidth>
                        <TextField
                          id="sponsor-code"
                          type="sponsorCode"
                          label="Sponsor Code"
                          fullWidth
                          variant="filled"
                          value={value}
                          onChange={onChange}
                          inputProps={{ [ariaLabel]: 'Please enter your sponsor code' }}
                          error={errors.sponsorCode !== undefined}
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Controller
                    name="details"
                    control={control}
                    render={({ onChange, value }) => (
                      <FormControl fullWidth>
                        <TextField
                          fullWidth
                          id="additional-information"
                          label="Additional Information"
                          type="text"
                          rows={5}
                          multiline={true}
                          variant="filled"
                          value={value}
                          onChange={onChange}
                          inputProps={{
                            [ariaLabel]: 'Please enter additional details of your enquiry',
                          }}
                          error={errors.details !== undefined}
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography variant="body2" color="error">
                    {formError}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} container justify="flex-end" alignItems="flex-end">
                  <Button
                    variant="contained"
                    onClick={handleSubmit(onSubmit)}
                    color="secondary"
                    aria-label={'Submit enquiry form'}
                  >
                    {loading ? 'SUBMITTING' : 'SUBMIT'}
                  </Button>
                </Grid>
              </React.Fragment>
            </Grid>

            <Dialog
              open={open}
              fullWidth
              keepMounted
              onClose={() => setOpen(false)}
              classes={{ paper: classes.paper }}
              data-cy="contactus-success-modal"
            >
              <div className={classes.dialogBorder}>
                <React.Fragment>
                  <DialogTitle>
                    <Typography variant="body1">Success</Typography>
                    <IconButton
                      aria-label="close"
                      className={classes.closeButton}
                      onClick={() => setOpen(false)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent className={classes.content}>
                    <Box m={1}>
                      <Typography variant="body1">Thank you for getting in touch!</Typography>
                    </Box>
                    <Box m={1}>
                      <Typography variant="body1">
                        We appreciate that you’ve taken the time to write us. We’ll get back to you
                        very soon.
                      </Typography>
                    </Box>
                    <Box m={1}>
                      <Typography variant="body1">Have a great day!</Typography>
                    </Box>
                  </DialogContent>
                  <DialogActions className={classes.actionContainer}>
                    <Button variant="contained" onClick={() => setOpen(false)} color="secondary">
                      OK
                    </Button>
                  </DialogActions>
                </React.Fragment>
              </div>
            </Dialog>
          </Container>
          {exceptionClickWrap && <ErrorAlert></ErrorAlert>}
        </div>
        <CookieMessage state={showCookieMessage} ></CookieMessage>
        <ClickWrap state={showClickWrap} handleClose={handleClickwrapClose}></ClickWrap>
        <SessiontTmeout state={showSessionTimeoutModel}></SessiontTmeout>
      </ContactLayout>
    </React.Fragment>
  );
};

export default ContactUsPage;
