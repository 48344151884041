import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Typography, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appsHeader:{
      display:'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    myAppsTitle: {
      color: '#ffffff',
      fontWeight: 500,
      marginBottom: 30,
      flexGrow:1
    },
    actionButton:{
      maxWidth: '120px',
      color:'#000000de'
    },
    beta:{
      padding:7,
      margin:10,
      color:'#00c4b4',
      border:'1px solid #00c4b4',
      borderRadius:7
    }
  }),
);

interface Props {
  title:string;
  action:string;
  actionHandler:(action?: string, data?:any) => void;
  isActionDisabled:boolean;
  isBeta: boolean;
  actionMessage:string;
}

const TableHeader: FC<Props> = ({title, action, actionHandler, isBeta, actionMessage, isActionDisabled}) => {
  const classes = useStyles();

  return (
    <div className={classes.appsHeader}>  
    {title && (<Typography variant={'body2'} className={classes.myAppsTitle}>
      {title}
    </Typography>)}
    
    <Tooltip title={actionMessage} placement="bottom-start">
    <span> 
    <Button
      variant="contained"
      color="secondary"
      aria-label={'create app list'}
      className={classes.actionButton}
      onClick={()=>{actionHandler("create",{}) }}
      disabled={isActionDisabled}
    >
      {action}
    </Button>
    </span> 
    </Tooltip>
    {isBeta && (<div className={classes.beta}>Sandbox</div>)}
    </div>
  );
};

export default TableHeader;
