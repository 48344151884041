import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type subScriptionsData ={
  callbackUrl: string
  eventId:string,
  subscriptionId:string,
  eventName:string,
  domain:string,
  status:string,
  action:string
};
export interface publicEventsState {
  loading: boolean;
  totalCount:number;
  popUpValues: { popUpText: string, errorText: string },
  currentsubScriptionsData:subScriptionsData,
  subscriptionId:string;
  confirmationboxDisplayStatus:boolean;
  currentDomain: string [];
  
};
export type eventRecord = {
  eventId:string;
  callbackUrl:string
};
const initialState: publicEventsState = {
    loading: false,
    totalCount:0,
    currentDomain:[],
    popUpValues:{
      popUpText:'',
      errorText:''
    },
    confirmationboxDisplayStatus:false,
    currentsubScriptionsData:{
      callbackUrl: '',
      eventId:'',
      subscriptionId:'',
      eventName:'',
      domain:'',
      status:'',
      action:''
    },
    subscriptionId:''
};

export const PublicEventsSlice = createSlice({
  name: 'publicEvents',
  initialState,
  reducers: {
    setPopupText: (state,action: PayloadAction<string>) => {
      state.popUpValues.popUpText =  action.payload;
    },
    setCurrentSubscriptionsData: (state,action: PayloadAction<subScriptionsData>) => {
      state.currentsubScriptionsData =  action.payload;
    },
    setConfirmationBoxDisplayStatus: (state,action: PayloadAction<boolean>) => {
      state.confirmationboxDisplayStatus =  action.payload;
    },
    setErrorPopupText: (state,action: PayloadAction<string>) => {
      state.popUpValues.errorText =  action.payload;
    },
    setEventsLoading: (state,action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setCurrentDomain: (state, action: PayloadAction< string []>) => {
      const filterDomain = action.payload;
      state.currentDomain = filterDomain;
    },
   }
});

export const { actions: PublicEventActions, reducer: publicEventsReducer } = PublicEventsSlice;
export const { setPopupText, setErrorPopupText, setCurrentSubscriptionsData, setConfirmationBoxDisplayStatus,setEventsLoading, setCurrentDomain } = PublicEventActions;
