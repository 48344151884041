import { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      display: 'flex',
      height: 1,
      background: 'rgb(37,38,39)',
      border: '1px solid #fff',
      borderImageSource:
        'linear-gradient(180deg, rgba(6,30,48,0.9668242296918768) 33%, rgba(12,26,41,0.9948354341736695) 97%)',
      borderImageSlice: 1,
    },
    dot: {
      height: 4,
      width: 4,
      background: '#FFFFFF',
      marginLeft: theme.spacing(2),

      // borderRadius: 2,
      //   opacity: 0.8,
    },
  }),
);

const Dots: FC = () => {
  const classes = useStyles();
  return <div className={classes.container}></div>;
};
export default Dots;
