import { configureStore } from '@reduxjs/toolkit';
import { groupReducer } from './groupSlice';
import { specsReducer } from './specsSlice';
import { accountReducer } from './accountSlice';
import { apiProductsReducer } from './apiProductsSlice';
import { productsReducer } from './productsSlice';
import { latencyReducer } from './latencySlice';
import { apiUsageReducer } from './apiUsageSlice';
import { applicationsReducer } from './applicationsSlice';
import { eventsReducer } from './eventSlice';
import { publicEventsReducer } from './publicEventSlice';

export const store = configureStore({
  reducer: {
    account: accountReducer,
    applications:applicationsReducer,
    apiProducts:apiProductsReducer,
    apiUsage: apiUsageReducer,
    events: eventsReducer,
    latency: latencyReducer,
    group : groupReducer, 
    specs : specsReducer,
    products :productsReducer,
    publicEvents:publicEventsReducer
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
