import React, { FC } from 'react';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Soon from '../../soon';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { setCurrentDomain } from '../../../store/productsSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      minWidth: 280,
      backgroundColor: 'rgba(256,256,256,0.1)',
      mixBlendMode: 'normal',
      borderRadius: '10px',
      height: '340px',
      position: 'relative',
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      justifyContent: 'center',
    },
    title: {
      color: 'white',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      fontWeight: 'bold',
    },
    description: {
      color: 'white',
      overflow: 'hidden',
    },
    avatar: {
      color: '#00C4B4',
    },
    actions: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 5,
    },
    imgContainer: {
      height: 160,
      width: '100%',
    },
    prodImg: {
      objectFit: 'cover',
      objectPosition: 'center',
    },
    btn: {
      zIndex: 5,
    },
  }),
);
interface Props {
  name: string;
  description: string;
  img: string;
  soon?: boolean;
}

const ProductCard: FC<Props> = ({ name, description, img, soon = false }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const onCardClick =()=>{
    dispatch(setCurrentDomain(name.toLowerCase().split(' ').join('-')));
    navigate(`/apis`);
  };
  return (
    <Card className={classes.card} raised={true}>
      {soon && <Soon />}
      <CardContent>
        <div className={classes.cardContent}>
          <div className={classes.imgContainer}>
            <img
              src={img}
              className={classes.prodImg}
              height={160}
              width={'100%'}
              alt="product logo"
            />
          </div>
          <Typography variant="body1" className={classes.title}>
            {name}
          </Typography>
          <Typography variant="body2" className={classes.description}>
            {description}
          </Typography>
        </div>
        <CardActions className={classes.actions}>
          <Button
            variant="text"
            color="primary"
            className={classes.btn}
            aria-label={`view more details about ${name.toLowerCase()} product`}
            onClick={onCardClick}
          >
            VIEW MORE DETAILS
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
};

export default ProductCard;
