import React, { useEffect, useState, FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Typography, Icon } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { Controller, useForm } from 'react-hook-form';
import clsx from 'clsx';
import { trapSpacesForRequiredFields, urlRegex } from '../../utils';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullContainer: {
      minHeight: '100vh',

    },
    container: {},
    pageTitle: {
      color: 'white',
      letterSpacing: '0.0625rem',
      fontWeight: 300,
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(4),
    },
    dialogBorder: {
      border: '1px solid #05a8cc',
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      borderRadius: 10,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(2),
      color: theme.palette.grey[500],
    },
    content: {
      color: 'white',
      fontWeight: 300,
      textAlign:'center',
      width: '100%',
      fontSize: '1.125rem',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    actionContainer: {
      display: 'flex',
      justifyContent: 'center',

    },
    form:{
      padding:30
    },
    icon: {
      position: 'absolute',
      top: 12,
      right: 10
    },
    active: {
      color: '#03dac5'
    },
    dialogTitle: {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
    paper: {
      backgroundImage:
        'linear-gradient(180deg, rgba(12,26,41,0.9948354341736695) 33%, rgba(12,26,41,0.9948354341736695) 97%)',
      position: "absolute",
      width: 500
    },
  }),
);
interface IFormInput {
  callbackUrl: string;
  action:''
 }
interface Props {
  state:boolean
  message: string;
  buttontext1:string;
  buttontext2:string;
  title:string;
  actionButton1:any;
  actionButton2:any;
  onClose: () => void;
}

const EventFormDialog: FC<Props> = ({ state, title, message, buttontext1, buttontext2, actionButton1, actionButton2, onClose  }) => {
  const classes = useStyles();
  const ariaLabel = 'aria-label';
  const { control, handleSubmit, errors, setValue } = useForm<IFormInput>({
    defaultValues: {
      callbackUrl: '',
      action:''
    },
  });
  const currentCallbackUrl = '';
  const [currentAction, setCurrentAction] = useState("default");

  const onCloseClick = () =>{
    actionButton2();
  };
  useEffect(() => {
    setValue('callbackUrl', currentCallbackUrl?? '');
    setValue('action', currentAction);
  }, [currentCallbackUrl, setValue, currentAction]);
  return (
    <Dialog
      open={state}
      fullWidth
      keepMounted
      classes={{ paper: classes.paper }}
      data-cy="contactus-success-modal"
    >
      <div className={classes.dialogBorder}>
        <React.Fragment>
          <DialogTitle className={classes.dialogTitle}>
            <Typography variant="body1">{title}</Typography>
          <Icon 
            data-testid="closebutton"
            className={clsx(
            classes.icon,
            classes.active
          )} 
          onClick = {onCloseClick}
          >
          <CloseIcon />
        </Icon>
          </DialogTitle>
          <DialogContent className={classes.content}>
            <Box m={1}>
              <Typography variant="body1">
                 {message}
              </Typography>
            </Box>
          </DialogContent>
          <div className={classes.form}>
          <Controller
                    name="callbackUrl"
                    control={control}
                    
                    rules={{
                      required: true,
                      validate: trapSpacesForRequiredFields,
                      pattern: urlRegex
                    }}
                    render={({ onChange, value }) => (
                      <FormControl fullWidth>
                        <TextField
                          id="callbackUrl"
                          type="callbackUrl"
                          label="Callback Url"
                          data-testid="CallbackUrl"
                          required
                          fullWidth
                          variant="filled"
                          value={value}
                          onChange={onChange}
                          inputProps={{ [ariaLabel]: 'Please enter callback url' }}
                          error={errors.callbackUrl !== undefined}
                        />
                      </FormControl>
                    )}
                  />
            </div>      
          <DialogActions className={classes.actionContainer}>
            <Button variant="contained"  
            onClick={handleSubmit((data) => {
              setCurrentAction('submit');
               // @ts-ignore
               data.action ='submit';
              setValue('action', 'submit');
              actionButton1(data);
            })}
            
            color="secondary">
              {buttontext1}
            </Button>
           {buttontext2 && (<Button variant="contained" onClick={() => actionButton2()} color="secondary">
            {buttontext2}
            </Button>)}
          </DialogActions>
        </React.Fragment>
      </div>
    </Dialog>
  );
};

export default EventFormDialog;
