const { v1: uuidv1 } = require('uuid');

const envSettings = window as any;

const call = async (url: string, options: any) => {
  const commonError = {
    status: 500,
    message: 'Something went wrong. Please try again',
  };
  const timeout = envSettings.apo_env?.REACT_APP_API_TIMEOUT
    ? parseInt(envSettings.apo_env.REACT_APP_API_TIMEOUT)
    : 30000;
  const apikey = envSettings.apo_env?.REACT_APP_API_KEY
    ? envSettings.apo_env.REACT_APP_API_KEY
    : 'l7f69ade736fd74ba3b4b618f9ac5bae51';

  try {
    const controller = new AbortController();
    const signal = controller.signal;
    let jwt = localStorage.getItem('jwt');
    setTimeout(() => controller.abort(), timeout);
    const response = await fetch(
      `${envSettings.apo_env?.REACT_APP_API_ENDPOINT
        ? envSettings.apo_env.REACT_APP_API_ENDPOINT
        : 'https://gw.sws-dev.ntrs.com/dev/v1'
      }${url}`,
      {
        signal,
        ...options,
        headers: {
          'X-NT-API-Key': apikey,
          'X-B3-TraceId': uuidv1(),
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + jwt
        },
      },

    ).catch((e) => {
      throw e;
    });
    const body = options.isBlob ? await response.blob() : await response.json();
    const status = await response.status;
    if (!response.ok) {
      return { error: { ...body.apierror, status } };
    }

    return body;
  } catch (e) {
    return { error: { ...commonError } };
  }
};
const callOktaUrl = async (url: string, options: any) => {
  const commonError = {
    status: 500,
    message: 'Something went wrong. Please try again',
  };
  const timeout = envSettings.apo_env?.REACT_APP_API_TIMEOUT
    ? parseInt(envSettings.apo_env.REACT_APP_API_TIMEOUT)
    : 30000;
  const apikey = envSettings.apo_env?.REACT_APP_API_KEY
    ? envSettings.apo_env.REACT_APP_API_KEY
    : 'l7f69ade736fd74ba3b4b618f9ac5bae51';

  try {
    const controller = new AbortController();
    const signal = controller.signal;
    let jwt = localStorage.getItem('jwt');
    setTimeout(() => controller.abort(), timeout);
    const response = await fetch(
      url,
      {
        signal,
        ...options,
        headers: {
          'X-NT-API-Key': apikey,
          'X-B3-TraceId': uuidv1(),
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + jwt
        },
      },

    ).catch((e) => {
      throw e;
    });
    const body = options.isBlob ? await response.blob() : await response.json();
    const status = await response.status;
    if (!response.ok) {
      return { error: { ...body, status } };
    }

    return body;
  } catch (e) {
    return { error: { ...commonError } };
  }
};
export const get = async (url: string, isBlob: boolean = false) => await call(url, { isBlob });
export const post = async (url: string, body: any, options = {}) =>
  await call(url, {
    ...options,
    isBlob: false,
    method: 'POST',
    body: JSON.stringify(body),
  });
export const put = async (url: string, body: any, options = {}) =>
  await call(url, {
    ...options,
    isBlob: false,
    method: 'put',
    body: JSON.stringify(body),
  });
  export const postOkta = async (url: string, body: any, options = {}) =>
  await callOktaUrl(url, {
    ...options,
    isBlob: false,
    method: 'POST',
    body: JSON.stringify(body),
  });