const envSettings = window as any;
const oktaConfig = {
  oidc: {
    issuer: envSettings?.apo_env?.REACT_APP_OKTA_ISSUER || 'https://loginsys.ntrs.com/oauth2/ausch0efzA1f3utyx1d6',
    clientId: envSettings?.apo_env?.REACT_APP_CLIENT_ID || '0oa2d4shtxUjA0KMD1d7',
    redirectUri: envSettings?.apo_env?.REACT_APP_REDIRECT_URI || 'http://localhost:8080/login/callback',
    scopes: envSettings?.apo_env?.REACT_APP_AUTH_SCOPES?.split(", ") || ['openid', 'profile', 'email', 'apo.role'],
    pkce:  envSettings?.apo_env?.REACT_APP_PKCE === "true"? true: false || true,
    tokenManager: {
      storage:  envSettings?.apo_env?.REACT_APP_STORAGE || "memory",
      autoRenew:false,
      autoRemove:false
    },
  },
};
export default oktaConfig;
