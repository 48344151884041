import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { PropsWithChildren  } from 'react';
import { MenuItem, Select, Box, Checkbox, ListItemText } from '@material-ui/core';
import { StatsOptions, TimeRangeOptions,PastTimeRangeOptions } from '../../store/latencySlice';
import { useTypedSelector } from '../../store'; 
import { getApiProducts, getApplications } from '../../selectors/selectors';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
interface Stats {
 [key:string]:string;
}

interface TimeRange {
  [key:string]:string;
}
type ApiProduct = {
  uuid: string;
  name: string;
  version: string;
  displayName?: string;
};
type Domain = {
  name: string;
  apiProducts: string [];
};

interface AllApiProducts {
  [key: string]: ApiProduct;
}
interface AllDomains {
  [key: string]: Domain;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
     menuContainer:{
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap:'wrap'
    },
    menuItem:{
      background:'#31405a'
    },
    dropDown:{
      
      '& > ul': {
       padding: 0
      }
    },
    apiMenuDrdopDown:{
      background:'#3d4b63',
      height:300,
      '& > ul': {
       padding: 0
      },
      '&::-webkit-scrollbar': {
        width: '0.2em',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#FFF',
        outline: '1px solid slategrey',
      },
    },
    apiDrdopDown:{
      width:230,
      marginTop:'0px !important'
    },
    apiInputLabel:{
      position: 'absolute',
      left: 0,
      top: 0,
      transform: 'translate(0, 12px) scale(1)'
    },
    labelContainer: {
      color: '#ffffff',
      fontWeight: 500,
      padding:'0.5rem'
    }
    
  }));

export type FilterContainerProps = {
  chartName:String;
  labelName:String;
  stats:Boolean;
  timeRange:Boolean;
  domains:Boolean;
  currentDomain:string;
  apis:Boolean;
  apps:Boolean;
  pastTimeRange:boolean;
  currentStat:string;
  currentTimeRange:string;
  currentApp:string;
  currentApi:string[];
  currentPastTimeRange:string;
  isRangeDisabled:boolean;
  isFilterApiproductsBydomain: Boolean;
  handleChange:(e:any) => void;
  onCloseSelect:(e:any) => void;
};

const FilterContainer: React.FC<FilterContainerProps> = 
(props: PropsWithChildren<FilterContainerProps>) => {

  const classes = useStyles();
  const { handleChange, stats, timeRange, currentStat, pastTimeRange, isRangeDisabled,
    labelName,onCloseSelect,
    apps,apis,currentApi, domains, currentTimeRange, currentPastTimeRange, currentApp, chartName, currentDomain, isFilterApiproductsBydomain } = props;
  const statsValues: Stats =  StatsOptions;
  const timeRangeValues: TimeRange = TimeRangeOptions; 
  let pastTimeRangeValues: TimeRange = PastTimeRangeOptions;
  const { hour ,...filteredpastTimeRangeValues} = pastTimeRangeValues;
  pastTimeRangeValues = labelName === "QUOTA CONSUMPTION" ? filteredpastTimeRangeValues :pastTimeRangeValues;
  const{ data } = useTypedSelector(state => getApplications(state));
  const filterNestedApplications = (apps :any, nestedKey :string, value: string) => {
    let filteredApplications = {};
    if(apps && Object.keys(apps).length> 0){
      Object.keys(apps).forEach(key => {
        if(apps[key] && typeof apps[key] === 'object' && nestedKey in apps[key] && apps[key][nestedKey] === value){
          // @ts-ignore
         filteredApplications[key] = apps[key];
        }
      })
    }
    return filteredApplications;

 };
 
  let applications = data;
  applications =  filterNestedApplications(applications,"environment","Production");
  const productData = useTypedSelector(state => getApiProducts(state));
  const apiProducts = chartName === "Traffic" ? productData.apiProducts : productData.apiProductsLateinces;
  const currentDomains : AllDomains = apiProducts.domains || {};
  const isApiProductsLoading = apiProducts.loading;
  let currentapiProducts : AllApiProducts = apiProducts.data;
  
  const getFilterdApisBydomain =() =>{
    let products = {};
    if(currentDomain &&  currentDomains && currentDomain.length > 0 && currentDomains[currentDomain] && currentapiProducts){
      currentDomains[currentDomain].apiProducts.map((apiProduct:string)=>(
        //@ts-ignore
        products[apiProduct] = currentapiProducts[apiProduct]
      ))
    }
    return products;
   };
   const filteredApis = getFilterdApisBydomain();
   currentapiProducts = isFilterApiproductsBydomain=== true? filteredApis: apiProducts.data;
   //@ts-ignore
   let newProducts: any = {};
   Object.keys(currentapiProducts).length > 0 &&  Object.keys(currentapiProducts).forEach((productKey)=>{
    const {name, version } = currentapiProducts[productKey];
    if(currentapiProducts[productKey]){
      newProducts[productKey] = {
        ...currentapiProducts[productKey],
        displayName:`${name} - ${version}`
      } 
    }
  });
   //@ts-ignore
   newProducts = newProducts && Object.fromEntries(
    //@ts-ignore
    Object.entries(newProducts).sort(([,a], [,b]) => a.displayName.localeCompare(b.displayName))
  );
  return (
    <React.Fragment>
     <div  className={classes.menuContainer}>
      {labelName.length > 0 && (
        <div className={classes.labelContainer}>{labelName}</div>
      ) }
      {stats && (<Select
          name='stats'
          className={classes.dropDown}
          value={currentStat}
          disableUnderline= {true}
          label="stats"
          data-testid="stats"
          
          onChange={handleChange}>
            {
        Object.keys(statsValues).map(( stat) =>(
          <MenuItem 
            value={stat} 
            key ={stat}
            className={classes.menuItem}> 
            {statsValues[stat]} 
          </MenuItem>
      ))
    }
    </Select>)}
    {apps && (
        <FormControl >
        <InputLabel shrink={false}
         className={classes.apiInputLabel}
        >
        {currentApp.length === 0  && 'Select Application'}
      </InputLabel>
        <Select
          name='apps'
          className={classes.apiDrdopDown}
          value={currentApp}
          disableUnderline= {true}
          label="apps"
          data-testid="apps"
          placeholder="Select Application"
          MenuProps ={{
            classes: {
              paper: classes.apiMenuDrdopDown
            }
          }}
          onChange={handleChange}>
            {
            applications && Object.keys(applications).map(( application) =>(
          <MenuItem 
            value={application} 
            key ={application}
            className={classes.menuItem}> 
            {applications[application].name} 
          </MenuItem>
      ))
    }
    </Select> </FormControl>)}
    {domains && (
      <FormControl >
        <InputLabel shrink={false}
         className={classes.apiInputLabel}
        >
        {currentDomain.length === 0  && 'Select Domains'}
      </InputLabel>
        <Select
          name='domains'
          className={classes.apiDrdopDown}
          value={currentDomain}
          disabled = {isApiProductsLoading}
          disableUnderline= {true}
          label="apis"
          placeholder="Select Domains"
          data-testid="domains"
          onClose={onCloseSelect}
          MenuProps ={{
            classes: {
              paper: classes.apiMenuDrdopDown
            }
          }}
          onChange={handleChange}>
            {
        Object.keys(currentDomains).map((domain) =>(
          <MenuItem 
            value={ currentDomains[domain].name} 
            key ={currentDomains[domain].name}
            className={classes.menuItem}> 
             {currentDomains[domain].name} 
          </MenuItem>
      ))
    }
    </Select>
    </FormControl>
      )}
    {apis && (
      <FormControl >
        <InputLabel shrink={false}
         className={classes.apiInputLabel}
        >
        {currentApi.length === 0  && 'Select Apis'}
      </InputLabel>
        <Select
          name='apis'
          className={classes.apiDrdopDown}
          value={currentApi}
          disabled = {isApiProductsLoading}
          disableUnderline= {true}
          renderValue={(currentApi:any) => currentApi.map((current:any) =>{
            return currentapiProducts[current]?.name+' - '+ currentapiProducts[current]?.version
          }).join(', ')}
          label="apis"
          placeholder="Select Apis"
          data-testid="apis"
          onClose={onCloseSelect}
          multiple
          MenuProps ={{
            classes: {
              paper: classes.apiMenuDrdopDown
            }
          }}
          onChange={handleChange}>
            {
        Object.keys(newProducts).map(( apiProduct) =>(
          <MenuItem 
            value={ newProducts[apiProduct].uuid} 
            key ={newProducts[apiProduct].uuid}
            className={classes.menuItem}> 
            <Checkbox checked={currentApi.indexOf(newProducts[apiProduct].uuid) > -1} />
            <ListItemText primary={newProducts[apiProduct].displayName } />
          </MenuItem>
      ))
    }
    </Select>
    </FormControl>
      )}
    {  timeRange && (<Box>
      <Select
        name='timeRange'
        disableUnderline= {true}
        className={classes.dropDown}
        value={currentTimeRange}
        label="timeRange"
        data-testid="timeRange"
        onChange={handleChange}
      >{  Object.keys(timeRangeValues).map((timeRange)=>(
            <MenuItem value={timeRange} 
                      className={classes.menuItem}
                      key ={timeRange}
                      > 
                {timeRangeValues[timeRange]} 
            </MenuItem>
          ))}
      </Select>   
    </Box>)}    
    {  pastTimeRange && (<Box>
      <Select
        name='pastTimeRange'
        disableUnderline= {true}
        className={classes.dropDown}
        value={currentPastTimeRange}
        label="pastTimeRange"
        data-testid="pastTimeRange"
        onChange={handleChange}
        disabled={isRangeDisabled}
      >{  Object.keys(pastTimeRangeValues).map((timeRange)=>(
            <MenuItem value={timeRange} 
                      className={classes.menuItem}
                      key ={timeRange}
                      data-testid={timeRange}
                      > 
                {pastTimeRangeValues[timeRange]} 
            </MenuItem>
          ))}
      </Select>   
    </Box>)}  
  </div>
    </React.Fragment>   
  );
};

export default FilterContainer;
