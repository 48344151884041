import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import DialogActions from '@material-ui/core/DialogActions';
import { useOktaAuth } from '@okta/okta-react';
import { useUserContext } from '../../UserContext';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullContainer: {
      minHeight: '100vh',

    },
    container: {},
    pageTitle: {
      color: 'white',
      letterSpacing: '0.0625rem',
      fontWeight: 300,
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(4),
    },
    dialogBorder: {
      border: '1px solid #05a8cc',
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      borderRadius: 10,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(2),
      color: theme.palette.grey[500],
    },
    content: {
      color: 'white',
      fontWeight: 300,
      width: '100%',
      fontSize: '1.125rem',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    actionContainer: {
      display: 'flex',
      justifyContent: 'center',

    },
    dialogTitle: {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
    paper: {
      backgroundImage:
        'linear-gradient(180deg, rgba(12,26,41,0.9948354341736695) 33%, rgba(12,26,41,0.9948354341736695) 97%)',
      position: "absolute",
      width: 500
    },
  }),
);

interface Props {
  state: any;
}

const SessionTimeOut: FC<Props> = ({ state }) => {
  const classes = useStyles();
  const userDetails = useUserContext();
  //@ts-ignore
  const { setAuthUser, tokenDetails,setTokenDetails, setShowSessionTimeoutModel, handleLogout,pauseTimer,reactivateTimer } = userDetails;
  const { oktaAuth } = useOktaAuth();
  let jwt: any = '';
  const handleStaySignedIn = async () => {
    try {
      //@ts-ignore
      const token = await oktaAuth.token.renew(tokenDetails?.tokens?.accessToken || tokenDetails);
      if (token !== null) {
        
        pauseTimer();
        setShowSessionTimeoutModel(false);
        reactivateTimer();
        jwt = JSON.stringify(token);
        const parsed = JSON.parse(jwt);
        const parsedJwt = parsed['accessToken'];
         //@ts-ignore
        setTokenDetails(token);
        setAuthUser(true);
        localStorage.setItem('is-user-authenticated', 'true');
        localStorage.setItem('jwt', parsedJwt);
      } else {
        setAuthUser(false);
        localStorage.setItem('jwt', '');
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Dialog
      open={state}
      fullWidth
      keepMounted
      classes={{ paper: classes.paper }}
      data-cy="contactus-success-modal"
    >
      <div className={classes.dialogBorder}>
        <React.Fragment>
          <DialogTitle className={classes.dialogTitle}>
            <Typography variant="body1">Session Expiring Soon</Typography>
          </DialogTitle>
          <DialogContent className={classes.content}>
            <Box m={1}>
              <Typography variant="body1">
                Your session is about to expire.Do you want to continue?
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions className={classes.actionContainer}>
            <Button variant="contained" onClick={() => handleLogout()} color="secondary">
              SIGN OUT
            </Button>
            <Button variant="contained" onClick={() => handleStaySignedIn()} color="secondary">
              STAY SIGNED IN
            </Button>
          </DialogActions>
        </React.Fragment>
      </div>
    </Dialog>
  );
};

export default SessionTimeOut;
