import React, { FC } from 'react';
import { createStyles, Icon, makeStyles, Theme } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import clsx from 'clsx';

interface props {
  ruleStatus: any,
  ruleDetail:string
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      marginRight: '10px'
    },
    success: {
      color: '#03dac5'
    },
    error: {
      color: '#cf6679'
    }
}));
export const Requirement: FC<props> = ({ruleDetail, ruleStatus }) => {
    const classes = useStyles();
    const iconType = ruleStatus === true ? classes.success: classes.error;
    const iconName =  ruleStatus === true ? <CheckCircleOutlineIcon></CheckCircleOutlineIcon>: <RadioButtonUncheckedIcon></RadioButtonUncheckedIcon>;

  const renderIcon = () => {
      return (
        <p><Icon className={clsx(
          classes.icon,
          iconType
        )}>
          {iconName}
        </Icon>
          <span className="padding-md">{ruleDetail}</span>
        </p>
      );
  };

  return (
    <p className="display-flex align-items-center">
        {renderIcon()}
    </p>
  );
};