import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';
import clsx from 'clsx';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      minHeight: 120,
      background: '#102336',
      display: 'flex',
      alignItems: 'center',
      color: 'white',
      padding: theme.spacing(2),
      position: 'absolute',
      // bottom: 0,
      width: '100%',
    },
    linkTexts: {
      fontWeight: 400,
      // textAlign: 'left',
    },
    copyright: {
      paddingLeft: theme.spacing(2),
    },
    footerLinks: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    link: {
      textDecoration: 'none',
      color: 'inherit',
    },
  }),
);

const Footer: FC = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <footer className={clsx(classes.footer)} data-cy="footer">
        <Container>
          <List className={classes.footerLinks}>
            <ListItem>
              <ListItemText>
                <Link to="/global-privacy-standards" className={classes.link}>
                  Privacy
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Link to="/terms-and-conditions" className={classes.link}>
                  Terms and Conditions
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Link to="/cookie-policy" className={classes.link}>
                  Cookie Policy
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Link to="/contact-us" className={classes.link}>
                  Contact Us
                </Link>
              </ListItemText>
            </ListItem>
          </List>

          <div className={classes.copyright} data-cy="copyright">
            <Typography variant="body2" className={classes.linkTexts}>
              © 2021 Northern Trust Corporation. All Rights Reserved.
            </Typography>
          </div>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
