import { makeStyles, Typography } from '@material-ui/core';
import React, { FC, useState } from 'react';

const useStyles = makeStyles({
  text: {
    color: 'white',
    overflow: 'hidden',
    wordWrap: 'break-word',
    minHeight: 50,
  },
  readMore:{
    color: '#05a8cc',
    fontWeight: 500,
    fontSize: 15
  }

});
interface Props {
  textlength: number;
 
}

const ShowMore: FC<Props> = ({ children, textlength}) => {
  const text = children;
  
  const classes = useStyles();
  //@ts-ignore
  const [showMore, setShowMore] = useState(text.length>=textlength?true:false);
  //@ts-ignore
  const isTruncated = text.length>=textlength?true:false;
 
   //@ts-ignore
  const resultString = showMore ? text.slice(0,textlength):text;  

  const toggleShowMore = () =>{
    setShowMore(!showMore);
   };

  return (
  <Typography variant="body2" className={classes.text}>
    {resultString}
    {isTruncated ?<span onClick={toggleShowMore} className={classes.readMore} >
    {showMore ? "...read more" : "show less"}
    </span>:<></>}
  </Typography>
  );
};
export default ShowMore;
