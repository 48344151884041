import React, { FC } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(1.5),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0.75),
        margin:5,
      },
      backgroundColor: '#3d4b63',
      display:'flex',
      justifyContent: 'center',
      alignItems:'center',
      borderRadius:10,
      margin:10,
      flexDirection:'column',
      width:'100%'
    },
    count: {
      color: '#00C4B4',
      fontSize: '2.50rem',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.25rem',
      },
    },
    description: {
      color: 'white',
      display:'flex',
      alignItems:'center',
      justifyContent:'center'
    }
  }),
);
interface Props {
  count: string;
  description: string;
}

const MetricsCard: FC<Props> = ({ count, description }) => {
  const classes = useStyles();
  return (

      <div className={classes.card}>
          <div  className={classes.count}>
            {count}
          </div>
          <div  className={classes.description}>
            {description}
          </div>
      </div>
    
  );
};

export default MetricsCard;
